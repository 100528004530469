//import { setAlert } from "./alert";
import ibthost from './api/ibthost'

import {
  //USER_SUCCESS,
  USER_ERROR,
  SWITCH_SUCCESS,
  SWITCH_LIST,
  SWITCH_CLEAR,
  //LOGOUT,
} from "./types";

// Switch User
export const switchUser = (email, callback) => async (dispatch) => {
  const config = {
    headers: {
      'Access-Control-Allow-Origin': true,
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email: email });

  try {
    //do we need to read user if we have userId
    // console.log('before user switch' + email)
    const switchUser = await ibthost.post("/uservalidate", { 
      email: email,
    });

    //  dispatch({ type: USER_SUCCESS, payload: responseUser.data, });

    const userId = switchUser.data._id
    try {

      const switchEntity = await ibthost.get(`/item/${userId}`, body, config);
      await dispatch({ type: SWITCH_SUCCESS, payload: switchEntity.data });

      //console.log(switchEntity)

      try {

        if (callback) {
          callback()
        }

      } catch (err) {
        await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
        console.log('Login Error ' + err)
      }

    } catch (err) {
      await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
      console.log('Entity Error ' + err)
    }


  } catch (err) {
    await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
    console.log('User Error ' + err)
  }
};

// Switch List
export const switchList = (userId, action, callback) => async (dispatch) => {
  const config = {
    headers: {
      'Access-Control-Allow-Origin': true,
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ userId: userId });

  console.log('ACTION' + userId + action)

  if (action = 'CLEAR') {
    console.log('SWITCH CLEAR ' + JSON.stringify(userId, 0, 2))
    await dispatch({ type: SWITCH_CLEAR });
  }


  try {

    const switchEntity = await ibthost.get(`/item/${userId}`, body, config);
    /*
        console.log(JSON.stringify(switchEntity.data.item.length, 0, 2))
        // console.log(JSON.stringify(switchEntity.data, 0, 2))
        const flaggedItems = []
        for (let i = 0; i < switchEntity.data.item.length; i++) {
          // console.log('comments in Loop' + payload.item[i]._id  )
          if (switchEntity.data.item[i].flagged) {
            // console.log('found item to keep before reducer' + flaggedItems)
            flaggedItems.push(switchEntity.data.item[i]);
          }
    
        }
       
    */

    console.log('SWITCH ENTITY USER ' + JSON.stringify(switchEntity.data.user, 0, 2))
    await dispatch({ type: SWITCH_LIST, payload: switchEntity.data });

    //console.log(switchEntity)

    try {

      if (callback) {
        callback()
      }

    } catch (err) {
      await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
      console.log('Login Error ' + err)
    }

  } catch (err) {
    await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
    console.log('Entity Error ' + err)
  }



};
