import React, { useState, } from "react";
//import {  NavLink, Outlet, useSearchParams, useNavigate } from "react-router-dom";

import { useSelector, connect } from "react-redux";

import {
  VStack,
  SimpleGrid,
  GridItem,
  Text,
  Box,
  Button,
  ButtonGroup,
  CSSReset,
  Heading,
  //Icon,
  //Link,
  ThemeProvider,
  theme,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useBreakpointValue,

  useToast,
} from '@chakra-ui/react';

import { Form, Field, useField, } from 'react-final-form'
import validateRecord from '../forms/validateItemRecord'

import Styles from './StylesForms'
import ImageUpload from '../items/ImageUpload'

import {
  ImagePreview,
  PreviewContainer,
} from "../file-upload/file-upload.styles";

//import { CreatableSelect } from "chakra-react-select";

import { uploadDocument } from "../../actions/itemAction"
//import ibtColors from "../common/ibtColors";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const ItemRecordForm = ({ uploadDocument, onSubmit, show, selectedRecord }) => {
  //console.log('Item Form ' + show.name)

  // const [selectedRecord, setSelectedRecord] = useState(null);
  //const [showClass, setshowClass] = useState(false);
  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(false)
  const [isUploading, setIsUploading] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [attachSelected, setAttachSelected] = useState(null);

  const [showAttachment, setshowAttachment] = useState(false);
  const [createClassList, setCreateClassList] = useState([])

  //const [temporaryState2, setTemporaryState2] = useState(null);
  const [temporaryState1, setTemporaryState1] = useState(null);
  const [newThing, setNewThing] = useState(null);

  const [isThingOpen, setIsThingOpen] = useState(false)
  const [chooseClass, setChooseClass] = useState('START');

  //let navigate = useNavigate();
  //const cancelRef = useRef()
  //const cancelThingRef = useRef()

  const toast = useToast();

  const colSpan = useBreakpointValue({ base: 2, md: 1 })
  const formSpan = useBreakpointValue({ base: 1, md: 2 })
  const boxWidth = useBreakpointValue({ base: 310, md: 800 })


  const onThingClose = () => {
    //  setFinalThing(true)
    setIsThingOpen(false)

  }


  const availableUser = useSelector((state) => state.user.user);

  const onFormSubmit = async values => {

    const classItems = []

    if (values.ibepets) { classItems.push('ibepets') }
    if (values.ibegone) { classItems.push('ibegone') }
    if (values.ibehandy) { classItems.push('ibehandy') }
    if (values.ibehouse) { classItems.push('ibehouse') }
    if (values.ibehealth) { classItems.push('ibehealth') }
    if (values.ibepaper) { classItems.push('ibepaper') }
    if (values.ibemoney) { classItems.push('ibemoney') }
    if (values.ibemember) { classItems.push('ibemember') }
    if (values.ibetechno) { classItems.push('ibetechno') }
    if (values.ibeevent) { classItems.push('ibeevent') }
    if (values.ibesocial) { classItems.push('ibesocial') }
    if (values.ibecontacts) { classItems.push('ibecontacts') }
    if (values.ibebusy) { classItems.push('ibebusy') }
    if (values.ibecareer) { classItems.push('ibecareer') }
    if (values.ibememoir) { classItems.push('ibememoir') }
    if (values.ibeframed) { classItems.push('ibeframed') }

    //NEW

    if (!selectedRecord._id) {

     // console.log('CANCEL' + values.cancelResponse)
      if (values.cancelResponse !== 'CANCEL') {
        if (attachment) {
          values.recordDocument = attachment
          //setShowAttachment(false)
        }

        //console.log('FINAL ADD' + (JSON.stringify(values, 0, 2)))
        const newData2 = {
          recordId: null,
          descrip: values.recordDescrip,
          thing: values.recordThing,
          date: values.recordDate,
          //type: values.recordType.label,
          document: values.recordDocument,
        }

        values.records = [...values.records, newData2]
        // Save values to use in AutoAdd of new classification
        setTemporaryState1(values)

        // setTemporaryState2(values.records)

        const checkThing = (res) => {
          return res.toLowerCase() === values.recordThing.toLowerCase();
        }

        const okayThing = classItems.find(checkThing)

        if (okayThing) {
          values.recordResponse = 'OKAY'

          //  console.log('okay in new record' + okayThing)
        } else {
          //  console.log(' not okay in new record' + okayThing)
          values.recordResponse = 'NOT OKAY'
          setNewThing(values.recordThing)
          setIsThingOpen(true);
        }
        // console.log('FINAL ADD' + (JSON.stringify(values.records, 0, 2)))
        await onSubmit(values);
        await sleep(300)
      } else {
        await onSubmit(values);
        await sleep(300)
      }

    }

    // UPDATE RECORD
    if (selectedRecord._id) {

      // console.log('UPDATE' + (JSON.stringify(values, 0, 2)))

      const nextValues = values.records.map((select) => {
        if (selectedRecord._id === select._id) {
          const checkThing = (res) => {
            return res.toLowerCase() === values.recordThing.toLowerCase();
          }

          const okayThing = classItems.find(checkThing)

          if (okayThing) {
            // console.log('okay' + okayThing)
            values.recordResponse = 'OKAY'
            // setShowSelectedRecord(false)
            //setFinalThing(true)
            if (attachment) {
              values.recordDocument = attachment
              // setShowAttachment(false)
            }
            return {
              recordId: values.recordId,
              descrip: values.recordDescrip,
              thing: values.recordThing,
              date: values.recordDate,
              type: values.recordType,
              document: values.recordDocument,
            }
          } else {
            //   console.log(' not okay' + okayThing)
            values.recordResponse = 'NOT OKAY'
            setNewThing(values.recordThing)
            if (attachment) {
              values.recordDocument = attachment
              //setShowAttachment(false)
            }
            setIsThingOpen(true);
            return {
              recordId: values.recordId,
              descrip: values.recordDescrip,
              thing: values.recordThing,
              date: values.recordDate,
              type: values.recordType,
              document: values.recordDocument,
            }
          }

        } // end of processing selected record

        if (selectedRecord._id !== select._id) {
          //   console.log('NOT MATCHY' + select._id + selectedRecord._id)
          return select
        }

      })
      setTemporaryState1(values)
      values.records = nextValues
      // console.log('FINAL UPDATE' + (JSON.stringify(values.records, 0, 2)))
      await onSubmit(values);
      await sleep(300)
    }
  }

  const autoAddThing = async (values) => {
    //   console.log((JSON.stringify(temporaryState1, 0, 2)))

    if (newThing === 'iBePets') {
      values.ibepets = true
      const tempClassItems = createClassList
      tempClassItems.push('ibepets')
      setCreateClassList(tempClassItems)
    }
    if (newThing === 'iBeGone') {
      values.ibegone = true
      const tempClassItems = createClassList
      tempClassItems.push('ibegone')
      setCreateClassList(tempClassItems)
    }
    if (newThing === 'iBeHandy') {
      values.ibehandy = true
      const tempClassItems = createClassList
      tempClassItems.push('ibehandy')
      setCreateClassList(tempClassItems)
    }
    if (newThing === 'iBeHouse') {
      values.ibehouse = true
      const tempClassItems = createClassList
      tempClassItems.push('ibehouse')
      setCreateClassList(tempClassItems)
    }
    if (newThing === 'iBeHealth') {
      values.ibehealth = true
      const tempClassItems = createClassList
      tempClassItems.push('ibehealth')
      setCreateClassList(tempClassItems)
    }
    if (newThing === 'iBePaper') {
      values.ibepaper = true
      const tempClassItems = createClassList
      tempClassItems.push('ibepaper')
      setCreateClassList(tempClassItems)
    }
    if (newThing === 'iBeMoney') {
      values.ibemoney = true
      const tempClassItems = createClassList
      tempClassItems.push('ibemoney')
      setCreateClassList(tempClassItems)
    }
    if (newThing === 'iBeMember') {
      values.ibemember = true
      const tempClassItems = createClassList
      tempClassItems.push('ibemember')
      setCreateClassList(tempClassItems)
    }
    if (newThing === 'iBeTechno') {
      values.ibetechno = true
      const tempClassItems = createClassList
      tempClassItems.push('ibetechno')
      setCreateClassList(tempClassItems)
    }
    if (newThing === 'iBeEvent') {
      values.ibeevent = true
      const tempClassItems = createClassList
      tempClassItems.push('ibeevent')
      setCreateClassList(tempClassItems)
    }
    if (newThing === 'iBeSocial') {
      values.ibesocial = true
      const tempClassItems = createClassList
      tempClassItems.push('ibesocial')
      setCreateClassList(tempClassItems)
    }
    if (newThing === 'iBeContacts') {
      values.ibecontacts = true
      const tempClassItems = createClassList
      tempClassItems.push('ibecontacts')
      setCreateClassList(tempClassItems)
    }
    if (newThing === 'iBeBusy') {
      values.ibebusy = true
      const tempClassItems = createClassList
      tempClassItems.push('ibebusy')
      setCreateClassList(tempClassItems)
    }
    if (newThing === 'iBeCareer') {
      values.ibecareer = true
      const tempClassItems = createClassList
      tempClassItems.push('ibecareer')
      setCreateClassList(tempClassItems)
    }
    if (newThing === 'iBeMemoir') {
      values.ibememoir = true
      const tempClassItems = createClassList
      tempClassItems.push('ibememoir')
      setCreateClassList(tempClassItems)
    }
    if (newThing === 'iBeFramed') {
      values.ibeframed = true
      const tempClassItems = createClassList
      tempClassItems.push('ibeframed')
      setCreateClassList(tempClassItems)
    }

    if (attachment) {
      values.recordDocument = attachment
      // console.log(attachment)
    }
   values.recordResponse = 'OKAY'

    //   console.log('AUTO ADD' + (JSON.stringify(values, 0, 2)))
    onThingClose()
    await onSubmit(values);
    await sleep(300)
  }


  const handleDocSelect = async (file) => {
    setIsUploading(true);
    let fileType3 = file.type.split("/")[1];
    const random3 = Math.random().toString().replace(/0\./, ""); // remove "0." from start of string
    const docId = availableUser._id + random3 + "." + fileType3;
    //console.log(docId)
    const blobName3 = `https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBgMobile${docId}`;
    const result = await uploadDocument(file, docId, blobName3);
    setAttachSelected(file)
    setAttachment(blobName3)
    setshowAttachment(true)
    setIsUploading(false);

    toast({
      duration: 5000, status: "success", isClosable: true,
      title: "Upload Attachment Complete.",
      description: "Saved your attachment on infoBee!",
    });
  };



  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Box w={boxWidth} p={1}  >
        <br />
        <Form
          onSubmit={onFormSubmit}
          initialValues={{
            id: show._id,
            itemName: show.name,
            recordId: selectedRecord._id,
            recordDescrip: selectedRecord.descrip,
            recordThing: selectedRecord.thing,
            recordDate: selectedRecord.date,
            recordType: selectedRecord.type,
            recordDocument: selectedRecord.document,
            recordResponse: 'INITIAL',
            text: show.text,
            category: show.category,
            image: show.image,
            itemId: show._id,
            wishname: show.wishname,
            wishitem: show.wishitem,
            flagged: show.flagged,
            ibegone: show.ibegone,
            ibepets: show.ibepets,
            ibehandy: show.ibehandy,
            ibehealth: show.ibehealth,
            ibehouse: show.ibehouse,
            ibepaper: show.ibepaper,
            ibemoney: show.ibemoney,
            ibemember: show.ibemember,
            ibetechno: show.ibetechno,
            ibeevent: show.ibeevent,
            ibesocial: show.ibesocial,
            ibecontacts: show.ibecontacts,
            ibebusy: show.ibebusy,
            ibecareer: show.ibecareer,
            ibememoir: show.ibememoir,
            ibeframed: show.ibeframed,
            records: show.records,
            itemDate: show.itemDate,
            trackType: show.trackType,
            wishes: show.wishes,
            picklist: show.picklist,
            comments: show.comments,
            cancelResponse: 'INITIAL',
          }}
          validate={validateRecord}
          render={({
            handleSubmit,
            form,
            errors,
            subscription = { submitting: true },
            submitting,
            pristine,
            values
          }) => (
            <>
              <Box as="form" p={4} borderWidth="3px"
                rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)"
                onSubmit={handleSubmit}
              >
                <SimpleGrid columns={2} columnGap={3} rowGap={3} width="full">
                  <>
                    <GridItem colSpan={1} width="full">

                      <Text fontSize="xlg">Classification</Text>
                      <Field name="recordThing" component="select"
                        placeholder="Select a classification..."
                        closemenuonselect="false" size="sm">
                        <option value="inventory" >Select a Classification</option>
                        <option value="iBePets" >iBePets</option>
                        <option value="iBeCareer">iBeCareer</option>
                        <option value="iBeHandy">iBeHandy</option>
                        {availableUser.status === 'Basic'
                          || availableUser.status === 'Active'
                          ? (<>
                            <option value="iBeHouse">iBeHouse</option>
                            <option value="iBeGone">iBeGone</option>
                            <option value="iBePaper">iBePaper</option>
                            <option value="iBeMoney">iBeMoney</option>
                            <option value="iBeMember">iBeMember</option>
                          </>) : null}


                        {availableUser.status === 'Active'
                          ? (<>
                            <option value="iBeTechno">iBeTechno</option>
                            <option value="iBeEvent">iBeEvent</option>
                            <option value="iBeSocial">iBeSocial</option>
                            <option value="iBeContacts">iBeContacts</option>
                            <option value="iBeBusy">iBeBusy</option>
                            <option value="iBeMemoir">iBeMemoir</option>
                            <option value="iBeHealth" >iBeHealth</option>
                            <option value="iBeFramed">iBeFramed</option>
                          </>) : null}

                      </Field>
                    </GridItem>

                  </>


                  {values.recordThing !== 'other' ? (
                    <>

                      <GridItem colSpan={colSpan}>
                        <Text>Date for Record</Text>
                        <InputControl name="recordDate" type="date" mr={10} />
                      </GridItem>

                      <GridItem colSpan={2} minWidth="full">
                        <Text>Record Description </Text>
                        <Styles>
                          <div>
                            <Field name="recordDescrip" component="textarea" minWidth="full"
                              placeholder="Record Description  " maxLength={600} />
                          </div>
                        </Styles>
                      </GridItem>
                    </>) : (
                    <>

                    </>)

                  }

                </SimpleGrid>




                <SimpleGrid columns={4} columnGap={3} rowGap={1} width="full">


                  <GridItem colSpan={2} marginLeft={35}>
                    <VStack>
                      <br />
                      <div>
                        {values.recordDocument ? (
                          <img className="ui tiny bordered left floated image"
                            alt="alt" src={values.recordDocument} />
                        ) : null}
                      </div>
                      <div>
                        {attachment ? (<PreviewContainer>
                          <ImagePreview
                            src={URL.createObjectURL(attachSelected)}
                            alt={`file preview`}
                          />
                        </PreviewContainer>) : null}
                      </div>
                      <div style={{ marginLeft: 25 }}>
                        <ImageUpload isUploading={isUploading}
                          onPhotoSelect={handleDocSelect}
                          label="Upload Image or pdf" />
                      </div>

                    </VStack>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <VStack>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <div>
                        <br />
                        <br />
                        <ButtonGroup marginRight={5}>
                          <Button
                            isLoading={submitting}
                            onClick={() => {
                              //  values.userFirst = 'cancel';
                              //  values.userLast = 'cancel';
                              //  console.log('multiple lines');
                              values.cancelResponse = "CANCEL";
                            }

                            }
                            loadingText="Cancelling"

                            type="cancel"
                          >
                            Cancel
                          </Button>
                          {!selectedRecord._id ? (<Button
                            isLoading={submitting}
                            loadingText="Submitting"
                            // size="md"
                            colorScheme="teal"
                            type="submit"
                          >  <Heading size="sm">Add
                            </Heading></Button>)
                            : (<Button
                              isLoading={submitting}
                              loadingText="Submitting"
                              // variantcolor="teal"
                              //  size="lg"
                              colorScheme="teal"
                              type="submit"
                            >
                              <Heading size="sm">Update
                              </Heading></Button>)}



                        </ButtonGroup>
                      </div>
                    </VStack>
                  </GridItem>

                </SimpleGrid>
              </Box>
            </>
          )}>




        </Form>


        <AlertDialog

          isOpen={isThingOpen}
          // leastDestructiveRef={cancelThingRef}
          onClose={onThingClose}
        >

          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Add Classification to Item </AlertDialogHeader>
              <AlertDialogBody>
                This classification is not associated with the item - do  you want to add it?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button colorScheme='red' onClick={onThingClose}> No - Cancel </Button>
                <Button ml={3}
                  onClick={() => {
                    autoAddThing(temporaryState1)
                    //   onThingClose()
                  }}
                > Yes add {newThing}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>


      </Box>
    </ThemeProvider>
  )
}


const Control = ({ name, ...rest }) => {
  const {
    meta: { error, touched }
  } = useField(name, { subscription: { touched: true, error: true } })
  return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
  const {
    meta: { error }
  } = useField(name, { subscription: { error: true } })
  return <FormErrorMessage>{error}</FormErrorMessage>
}

const InputControl = ({ name, label, type, maxLength, pattern, component }) => {
  //console.log(component)
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={4}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        {...input}
        isInvalid={meta.error && meta.touched}
        id={name}
        placeholder={label}
        type={type}
        //minLength={minLength}
        maxLength={maxLength}
      //pattern={pattern}
      // component={component}
      />
      <Error name={name} />

    </Control>
  )
}

const Input2Control = ({ name, label, type, minLength, maxLength, pattern, component }) => {
  //console.log(component)
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={4} component="textarea" size="lg">
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        {...input}
        isInvalid={meta.error && meta.touched}
        id={name}
        placeholder={label}
        // type={type}
        minLength={minLength}
        maxLength={maxLength}
        //size="lg"
        //pattern={pattern}
        component={component}
      />
      <Error name={name} />

    </Control>
  )
}


export default connect(null, { uploadDocument })(ItemRecordForm);


/*


 const PetsSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibepetsOptions}
        placeholder="Start typing to create a custom label"
        closeMenuOnSelect={false}>

      </CreatableSelect>
    )
  }

  const GoneSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibegoneOptions}
        placeholder="Start typing to create a custom label"
        closeMenuOnSelect={false}>

      </CreatableSelect>
    )
  }

  const HandySelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibehandyOptions}
        placeholder="Start typing to create a custom label"
        closeMenuOnSelect={false}>

      </CreatableSelect>
    )
  }
  const HouseSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibehouseOptions}
        placeholder="Start typing to create a custom label"
        closeMenuOnSelect={false}>

      </CreatableSelect>
    )
  }
  const HealthSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibehealthOptions}
        placeholder="Start typing to create a custom label"
        closeMenuOnSelect={false}>

      </CreatableSelect>
    )
  }
  const PaperSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibepaperOptions}
        placeholder="Start typing to create a custom label"
        closeMenuOnSelect={false}>

      </CreatableSelect>
    )
  }
  const MoneySelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibemoneyOptions}
        placeholder="Start typing to create a custom label"
        closeMenuOnSelect={false}>

      </CreatableSelect>
    )
  }
  const MemberSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibememberOptions}
        placeholder="Start typing to create a custom label"
        closeMenuOnSelect={false}>

      </CreatableSelect>
    )
  }
  const TechnoSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibetechnoOptions}
        placeholder="Start typing to create a custom label"
        closeMenuOnSelect={false}>

      </CreatableSelect>
    )
  }
  const EventSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibeeventOptions}
        placeholder="Start typing to create a custom label"
        closeMenuOnSelect={false}>

      </CreatableSelect>
    )
  }
  const SocialSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibesocialOptions}
        placeholder="Start typing to create a custom label"
        closeMenuOnSelect={false}>

      </CreatableSelect>
    )
  }
  const ContactsSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibecontactsOptions}
        placeholder="Start typing to create a custom label"
        closeMenuOnSelect={false}>

      </CreatableSelect>
    )
  }
  const BusySelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibebusyOptions}
        placeholder="Start typing to create a custom label"
        closeMenuOnSelect={false}>

      </CreatableSelect>
    )
  }
  const CareerSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibecareerOptions}
        placeholder="Start typing to create a custom label"
        closeMenuOnSelect={false}>

      </CreatableSelect>
    )
  }
  const MemoirSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibememoirOptions}
        placeholder="Start typing to create a custom label"
        closeMenuOnSelect={false}>

      </CreatableSelect>
    )
  }
  const FramedSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibeframedOptions}
        placeholder="Start typing to create a custom label"
        closeMenuOnSelect={false}>

      </CreatableSelect>
    )
  }




<GridItem colSpan={1}>
                        <Text>{`${values.recordThing} Label`}</Text>

                        {values.recordThing === 'iBePets' ?
                          (
                            <Field
                              name="recordType" label={`${values.recordThing} Label`} maxLength={15}

                              component={PetsSelectAdapter}
                            />) : null
                        }

                        {values.recordThing === 'iBeGone' ?
                          (
                            <Field
                              name="recordType" label={`${values.recordThing} Label`} maxLength={15}

                              component={GoneSelectAdapter}
                            />) : null
                        }

                        {values.recordThing === 'iBeHandy' ?
                          (
                            <Field
                              name="recordType" label={`${values.recordThing} Label`} maxLength={15}

                              component={HandySelectAdapter}
                            />) : null
                        }

                        {values.recordThing === 'iBeHouse' ?
                          (
                            <Field
                              name="recordType" label={`${values.recordThing} Label`} maxLength={15}

                              component={HouseSelectAdapter}
                            />) : null
                        }

                        {values.recordThing === 'iBeHealth' ?
                          (
                            <Field
                              name="recordType" label={`${values.recordThing} Label`} maxLength={15}

                              component={HealthSelectAdapter}
                            />) : null
                        }

                        {values.recordThing === 'iBePaper' ?
                          (
                            <Field
                              name="recordType" label={`${values.recordThing} Label`} maxLength={15}

                              component={PaperSelectAdapter}
                            />) : null
                        }

                        {values.recordThing === 'iBeMoney' ?
                          (
                            <Field
                              name="recordType" label={`${values.recordThing} Label`} maxLength={15}

                              component={MoneySelectAdapter}
                            />) : null
                        }


                        {values.recordThing === 'iBeMember' ?
                          (
                            <Field
                              name="recordType" label={`${values.recordThing} Label`} maxLength={15}

                              component={MemberSelectAdapter}
                            />) : null
                        }

                        {values.recordThing === 'iBeTechno' ?
                          (
                            <Field
                              name="recordType" label={`${values.recordThing} Label`} maxLength={15}

                              component={TechnoSelectAdapter}
                            />) : null
                        }

                        {values.recordThing === 'iBeEvent' ?
                          (
                            <Field
                              name="recordType" label={`${values.recordThing} Label`} maxLength={15}

                              component={EventSelectAdapter}
                            />) : null
                        }

                        {values.recordThing === 'iBeSocial' ?
                          (
                            <Field
                              name="recordType" label={`${values.recordThing} Label`} maxLength={15}

                              component={SocialSelectAdapter}
                            />) : null
                        }

                        {values.recordThing === 'iBeContacts' ?
                          (
                            <Field
                              name="recordType" label={`${values.recordThing} Label`} maxLength={15}

                              component={ContactsSelectAdapter}
                            />) : null
                        }

                        {values.recordThing === 'iBeBusy' ?
                          (
                            <Field
                              name="recordType" label={`${values.recordThing} Label`} maxLength={15}

                              component={BusySelectAdapter}
                            />) : null
                        }

                        {values.recordThing === 'iBeCareer' ?
                          (
                            <Field
                              name="recordType" label={`${values.recordThing} Label`} maxLength={15}

                              component={CareerSelectAdapter}
                            />) : null
                        }

                        {values.recordThing === 'iBeMemoir' ?
                          (
                            <Field
                              name="recordType" label={`${values.recordThing} Label`} maxLength={15}

                              component={MemoirSelectAdapter}
                            />) : null
                        }


                        {values.recordThing === 'iBeFramed' ?
                          (
                            <Field
                              name="recordType" label={`${values.recordThing} Label`} maxLength={15}

                              component={FramedSelectAdapter}
                            />) : null
                        }

                      </GridItem>






  <Field name="recordDescrip" placeholder="Record Description  " maxLength={20} >
                    {props => (
                      <div>
                        <textarea {...props.input} />
                      </div>
                    )}
                  </Field>



    <InputControl name="recordType" label={`${values.recordThing} Label`} maxLength={20} />
      <Input2Control name="recordDescrip" label="Record Description" component="textarea" type="text" maxLength={600} />
*/