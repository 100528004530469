import React, { useEffect, useState, useRef } from 'react'
import { useSelector, connect } from "react-redux";

import {
    Text,
    Divider,
    Avatar,
    // Heading,
    //Link,
    useColorModeValue,
    Heading,
    Input,
    //Box,
    // Text,
    //Checkbox,
    //Link,
    VStack,
    Button,
    ButtonGroup,
    IconButton,
    HStack,
    //Divider,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,

    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
} from '@chakra-ui/react';

import {
    StarIcon, CloseIcon, SunIcon,
    ChevronDownIcon, AddIcon, CheckIcon,
} from '@chakra-ui/icons'

import { NavLink, Outlet, useSearchParams, useParams, useNavigate } from "react-router-dom";

import ItemCommentForm from '../forms/ItemCommentForm'

import { refreshUser } from "../../actions/userAction"
import { switchUser } from "../../actions/switchAction"
import { deleteCurrentItem, editNoImage } from "../../actions/itemAction"


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const Comments = ({ refreshUser, switchUser, editNoImage }) => {

    const [switchName, setSwitchName] = useState("");
    const [filteredComments, setfilteredComments] = useState(null);
    const [switchEmail, setSwitchEmail] = useState("");



    const [selectedComment, setSelectedComment] = useState(null);
    const [showSelectedComment, setShowSelectedComment] = useState(false);
    const [replySelectedComment, setReplySelectedComment] = useState(false);
    const [oldComment, setOldComment] = useState(null);
    const [anotherComment, setAnotherComment] = useState(null);
    //  let items = getItems();
    let [searchParams, setSearchParams] = useSearchParams();

    let params = useParams();
    let navigate = useNavigate();

    //const onClose = () => setIsOpen(false)
    const cancelRef = useRef()
    const cancelThingRef = useRef()

    const onCommentClose = () => setIsCommentOpen(false)
    const cancelCommentRef = useRef()
    const [isCommentOpen, setIsCommentOpen] = useState(false)


    const availableUser = useSelector((state) => state.user.user);
    const availableSwitch = useSelector((state) => state.switchUser.switchEntity);
    const availableComments = useSelector((state) => state.switchUser.switchComments);
    const availableToken = useSelector((state) => state.user.token)
    const availableEmail = useSelector((state) => state.user.googleEmail)


    const secondaryTextColor = useColorModeValue('gray.600', 'gray.400')


    useEffect(() => {
        const refresh = async () => {
            await refreshUser(availableEmail)
        };

        if (availableToken && !availableUser) {
            refresh();
        }

    }, [availableToken, availableUser, availableEmail]);

    /*
        useEffect(() => {
    
            //   const refresh = async () => {
            //       await refreshUser(availableEmail)
            //  };
    
            const newUser = async (accountEmail) => {
                //    console.log('abc' + accountEmail)
                await switchUser(accountEmail)
            };
    
            //  if (availableToken && !availableUser) {
            //      refresh()
            // } 
            availableUser.account.filter(act => {
    
                if (act.accountId === params.accountId) {
                    setSwitchName(act.accountName)
                    setSwitchEmail(act.accountEmail)
                    return (newUser(act.accountEmail))
                }
    
    
            })
    
    
        }, [availableUser.account, params.accountId, switchUser]);
    
        */

    useEffect(() => {

        //console.log('STARTxx' + JSON.stringify(availableComments, 0, 2))
        const userComments = []
        // setfilteredComments(null)
        return (
            availableComments ? (
                availableComments.filter(useritems => {
                    // console.log('swicth' + useritems.switchId)
                    //if multiple switch accounts
                    if (useritems.switchId === params.accountId) {
                        //   console.log('found swicth' + useritems.switchId)
                        return useritems
                        // userComments.push(comm.comment)
                        //        setSwitchName(act.accountName)
                        //       setSwitchEmail(act.accountEmail)
                        //    //  return (newUser(act.accountEmail))
                    }
                    // console.log(userComments)

                }).filter(comm => {
                    // console.log('COMM' + JSON.stringify(comm, 0, 2))
                    //console.log('COMM' + comm.comment.length)
                    for (let i = 0; i < comm.comment.length; i++) {
                        // console.log(comm.comment[i].name)
                        console.log('LEngth comments' + comm.comment[i].comments.length)
                        for (let c = 0; c < comm.comment[i].comments.length; c++) {
                            console.log('List Length' + comm.comment[i].comments[c].commentlist.length)
                            for (let l = 0; l < comm.comment[i].comments[c].commentlist.length; l++) {
                                if (comm.comment[i].comments[c].commentlist[l].commentemail
                                    === availableUser.email
                                    || comm.comment[i].comments[c].commentfrom === availableUser.email
                                ) {
                                    const tempData = {
                                        showitemname: comm.comment[i].name,
                                        showitemid: comm.comment[i]._id,
                                        showitemimage: comm.comment[i].image,
                                        showitem: comm.comment[i],
                                        showcomment: comm.comment[i].comments[c].comment,
                                        showcommentId: comm.comment[i].comments[c]._id,
                                        showcommentfrom: comm.comment[i].comments[c].commentfrom,
                                        showcommentread: comm.comment[i].comments[c].commentlist[l].commentread,
                                        showcommentemail: comm.comment[i].comments[c].commentlist[l].commentemail,
                                        showcommentname: comm.comment[i].comments[c].commentlist[l].commentname,
                                        showcommentlist: comm.comment[i].comments[c].commentlist,
                                        showcommentdate: comm.comment[i].comments[c].date,
                                        showcommentrd: comm.comment[i].comments[c].read,
                                    }

                                    //  return true
                                    userComments.push(tempData)
                                }
                                /*
                                if (params.accountId
                                    === availableUser._id) {
                                    const tempData = {
                                        showitemname: comm.comment[i].name,
                                        showitemid: comm.comment[i]._id,
                                        showitemimage: comm.comment[i].image,
                                        showitem: comm.comment[i],
                                        showcommentId: comm.comment[i].comments[c]._id,
                                        showcomment: comm.comment[i].comments[c].comment,
                                        showcommentfrom: comm.comment[i].comments[c].commentfrom,
                                        showcommentread: comm.comment[i].comments[c].commentlist[l].commentread,
                                        showcommentemail: comm.comment[i].comments[c].commentlist[l].commentemail,
                                        showcommentname: comm.comment[i].comments[c].commentlist[l].commentname,
                                        showcommentlist: comm.comment[i].comments[c].commentlist,
                                        showcommentdate: comm.comment[i].comments[c].date,
                                        showcommentrd: comm.comment[i].comments[c].read,
                                    }

                                    //  return true
                                    userComments.push(tempData)
                                }
                                */
                            }

                        }
                    }
                    //   console.log('USER' + JSON.stringify(userComments, 0, 2))
                    setfilteredComments(userComments)
                })


            ) : null




        )




    }, [params.accountId, switchUser, availableUser,]);

    // console.log('SUBMITxx' + JSON.stringify(filteredComments, 0, 2))

    const onCommentRead = async (values, list) => {
        await sleep(300)
        //   console.log('Comment available' + (JSON.stringify(values.comments, 0, 2)))
        //  console.log('Comment read' + (JSON.stringify(list, 0, 2)))

        for (let i = 0; i < values.comments.length; i++) {
            for (let c = 0; c < values.comments[i].commentlist.length; c++) {
                if (values.comments[i].commentlist[c]._id === list._id) {
                    values.comments[i].commentlist[c].commentread = true
                }
            }
        }

        //console.log('Comment updated' + (JSON.stringify(values.comments, 0, 2)))
        values.itemName = values.name
        values.itemId = values._id

        // console.log('SUBMIT' + (JSON.stringify(values.comments, 0, 2)))
        console.log('params' + params.accountId)

        editNoImage(values, params.accountId, (props) => {
            //  console.log('props' + props)
            // navigate(`/flaglist/${props}`);
            // navigate(`/flaglist/${params.accountId}/${props}`);
        })
        // }
    }

    const onCommentSubmit = async values => {
        await sleep(300)
        console.log('What happened? ' + values.commentResponse)

        if (values.cancelResponse === 'CANCEL') {
            setSelectedComment(null)
            setShowSelectedComment(false)
            setReplySelectedComment(false)
        }
        if (values.cancelResponse !== 'CANCEL') {
            console.log('SUBMIT' + (JSON.stringify(values, 0, 2)))

            editNoImage(values, params.accountId, (props) => {
                setSelectedComment(null)
                setShowSelectedComment(false)
                setReplySelectedComment(false)
                // navigate(`/switchlist`);
                // navigate(`/inventory/itemlist/${props}`);

            })
        }
    }

    const onCommentReadX = async (values, comment) => {
        await sleep(300)
        //console.log('Comment available' + (JSON.stringify(values, 0, 2)))
        //console.log('Comment read' + (JSON.stringify(comment, 0, 2)))

        values.itemName = values.name
        values.itemId = values._id

        for (let i = 0; i < values.comments.length; i++) {
            if (values.comments[i]._id === comment._id) {
                values.comments[i].read = true
            }
        }

        //console.log('Comment updated' + (JSON.stringify(values.comments, 0, 2)))

        // values.comments.commentlist.commentread
        // if (values.commentResponse === 'OKAY') {
        // console.log('SUBMIT' + (JSON.stringify(values.comments, 0, 2)))
        // console.log('params' + params.accountId)

        editNoImage(values, availableUser._id, (props) => {
            console.log('props' + props)
            navigate(`/inventory/itemlist/${props}`);

        })
        // }
    }

    const countComment = (commentread) => {
        if (!commentread) {
            // console.log('setting')
            // setNewMessage(true)
        }
    }

    const onCommentDelete = async (selectedComment, item) => {
        // console.log('SUBMIT' + JSON.stringify(selectedComment, 0, 2))
        //console.log('SUBMIT' + JSON.stringify(item, 0, 2))
        const values = item.showitem
        const tempComments = []
        for (let i = 0; i < item.showitem.comments.length; i++) {
            // console.log('comments in Loop' + item.showitem.comments[i]._id + item.showcommentId)
            if (item.showitem.comments[i]._id !== item.showcommentId) {
                //  console.log('found comment to keep')
                tempComments.push(item.showitem.comments[i]);
            }

        }
        // console.log('SUBMIT' + (JSON.stringify(tempComments, 0, 2)))
        values.comments = tempComments
        values.itemName = item.showitem.name
        values.itemId = item.showitem._id
        values.image = item.showitem.image

        // console.log('SUBMIT' + (JSON.stringify(values, 0, 2)))

        editNoImage(values, availableUser._id, (props) => {
            setSelectedComment(null)
            //   navigate(`/inventory/itemlist/${props}`);
            navigate(`/switchlist`);
        })

    }




    const renderComments = (item) => {
        //console.log('FILTERED COMMENTS' + (JSON.stringify(filteredComments[0].showitem.comments[0].commentfrom, 0, 2)))
        //console.log('FILTERED COMMENTS' + (JSON.stringify(filteredComments, 0, 2)))
        //console.log('SELECTED' + (JSON.stringify(selectedComment, 0, 2)))
        return (
            <>


                {showSelectedComment ? (renderSelectedComment(selectedComment, "NEW")) : null}
                {replySelectedComment ? (renderSelectedComment(selectedComment, 'REPLY')) : null}
                <br />

                <AlertDialog
                    isOpen={isCommentOpen}
                    leastDestructiveRef={cancelCommentRef}
                    onCommentClose={onCommentClose}
                >
                    <AlertDialogOverlay>
                        <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Delete Comment
                            </AlertDialogHeader>
                            <AlertDialogBody>
                                Are you sure? You can't undo this action afterwards.

                            </AlertDialogBody>
                            <AlertDialogFooter>
                                <Button ref={cancelCommentRef}
                                    onClick={onCommentClose}>
                                    Cancel
                                </Button>
                                <Button colorScheme='red'
                                    onClick={() => {
                                        onCommentDelete(selectedComment._id, selectedComment);

                                        // console.log('Deleting' + selectedComment._id + selectedComment.comment);
                                        onCommentClose()
                                    }} ml={3}>
                                    Delete
                                </Button>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialogOverlay>
                </AlertDialog>

                <Heading size="lg">Comments in render</Heading>
                <HStack spacing={3}>
                    <input
                        value={searchParams.get("filter") || ""}
                        onChange={event => {
                            let filter = event.target.value;
                            if (filter) {
                                setSearchParams({ filter });
                            } else {
                                setSearchParams({});
                            }
                        }}
                        placeholder='Filter by FROM Email'
                    />
                    <input
                        value={searchParams.get("filter2") || ""}
                        onChange={event => {
                            let filter2 = event.target.value;
                            if (filter2) {
                                setSearchParams({ filter2 });
                            } else {
                                setSearchParams({});
                            }
                        }}
                        placeholder='Filter by TO Email'
                    />
                    <input
                        value={searchParams.get("filter3") || ""}
                        onChange={event => {
                            let filter3 = event.target.value;
                            if (filter3) {
                                setSearchParams({ filter3 });
                            } else {
                                setSearchParams({});
                            }
                        }}
                        placeholder='Filter by STATUS - type NOT READ'
                    />

                </HStack>




                <Table variant='striped' colorScheme='gray'>
                    <TableCaption>Some sort of foot note</TableCaption>
                    <Thead>
                        <Tr>
                            <Th><Heading size="md" ></Heading>Action</Th>
                            <Th><Heading size="md" >Image </Heading> </Th>
                            <Th><Heading size="md" >Item Name </Heading> </Th>
                            <Th><Heading size="md"  >From/To </Heading> </Th>
                            <Th><Heading mr={170} size="md"  >Comment </Heading> </Th>
                            <Th><Heading size="md" mr={14}  >Date </Heading> </Th>



                        </Tr>
                    </Thead>
                    <Tbody>
                        {filteredComments.filter(comm => {
                            let filter = searchParams.get("filter");
                            if (!filter) return true;
                            // console.log('FILTER' + filter)
                            let commentfilter1 = comm.showcommentfrom;
                            // let commentfilter2 = comm.showcommentemail;
                            //   return name.startsWith(filter.toLowerCase());

                            return commentfilter1.includes(filter.toLowerCase());
                        }).filter(comm2 => {
                            // console.log('FILTERED COMMENTS' + (JSON.stringify(comm2, 0, 2)))
                            let filter2 = searchParams.get("filter2");
                            if (!filter2) return true;
                            // console.log('FILTER2' + filter2)
                            let commentfilter2 = comm2.showcommentemail;
                            // let commentfilter2 = comm.showcommentemail;
                            //   return name.startsWith(filter.toLowerCase());
                            return commentfilter2.includes(filter2.toLowerCase());
                        }).filter(comm3 => {
                            // console.log('FILTERED COMMENTS' + (JSON.stringify(comm2, 0, 2)))
                            let filter3 = searchParams.get("filter3");
                            let filter3a = null
                            if (!filter3) return true;
                            // console.log('FILTER3' + filter3)
                            let commentfilter3 = comm3.showcommentread;
                            if (filter3.substring(0, 1).toLowerCase() === 'n') {
                                filter3a = false
                                return filter3a !== commentfilter3;
                            } else {
                                filter3a = true
                                return filter3a === commentfilter3;
                            }
                            console.log(filter3a)
                            // let commentfilter2 = comm.showcommentemail;
                            //   return name.startsWith(filter.toLowerCase());

                        }).map((comment) => {


                            return (
                                <>


                                    <Tr key={comment.showitemid + comment.showcommentId}>
                                        {comment.showcommentfrom === availableUser.email
                                            && availableUser._id !== params.accountId

                                            ? <Td>
                                                {availableUser.email === comment.showcommentfrom ? (
                                                    <Text>SENT</Text>
                                                ) : null}

                                                {comment.showcommentlist.map((list) => {
                                                    if (list.commentemail === availableUser.email)
                                                        return (
                                                            list.commentread ? (<>
                                                                <CheckIcon /><Text as='em' color="green">read</Text>
                                                            </>)
                                                                :
                                                                (<>
                                                                    <Button onClick={() => onCommentRead(comment.showitem, list)}
                                                                        size='md' height='50px' width='50px' borderWidth='2px' bordercolor="teal">

                                                                        <Text as='i'  >unread</Text>
                                                                    </Button>
                                                                </>)
                                                        )
                                                })
                                                }

                                            </Td> : (
                                                <Td>

                                                    <Menu>
                                                        <MenuButton px={4} py={2} transition='all 0.2s' borderRadius='md'
                                                            borderWidth='1px' _hover={{ bg: 'gray.400' }}
                                                            _focus={{ boxShadow: 'outline' }}   >
                                                            <ChevronDownIcon />
                                                        </MenuButton>
                                                        {availableUser._id === params.accountId ? (
                                                            <MenuList>

                                                                <MenuItem onClick={() => {

                                                                    var today = new Date();
                                                                    var dd = String(today.getDate()).padStart(2, '0');
                                                                    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                                                                    var yyyy = today.getFullYear();
                                                                    today = yyyy + '-' + mm + '-' + dd;
                                                                    const tempSelectedComment = {
                                                                        // _id: Math.random().toString().replace(/0\./, ""),
                                                                        _id: null,
                                                                        comment: null,
                                                                        descrip: null,
                                                                        //  thing: null,
                                                                        date: today,
                                                                        //  type: null,
                                                                        // document: null,
                                                                    };
                                                                    setAnotherComment(tempSelectedComment)
                                                                    setSelectedComment(comment)
                                                                    // set comment for item stuff
                                                                    setShowSelectedComment(true)





                                                                }}>New</MenuItem>

                                                                <MenuItem onClick={() => {

                                                                    setSelectedComment(comment)
                                                                    setIsCommentOpen(true);
                                                                }}>Delete </MenuItem>

                                                            </MenuList>
                                                        )
                                                            : (
                                                                <>

                                                                    <MenuList>
                                                                        <MenuItem onClick={() => {
                                                                            const tempSelectedComment = {
                                                                                _id: comment.showcommentId,
                                                                                comment: comment.showcomment,
                                                                                commentfrom: comment.showcommentfrom,
                                                                                //  thing: comment.showitem.comments._id,
                                                                                // date: today,
                                                                                // type: comment.showitem.comments._id,
                                                                                // document: 'SOMETHING',
                                                                            };

                                                                            setOldComment(tempSelectedComment)
                                                                            setSelectedComment(comment)
                                                                            setReplySelectedComment(true)
                                                                        }}>Reply</MenuItem>

                                                                    </MenuList>


                                                                </>


                                                            )}
                                                    </Menu>
                                                    {comment.showcommentlist.map((list) => {
                                                        if (list.commentemail === availableUser.email)
                                                            return (
                                                                list.commentread ? (<>
                                                                    <CheckIcon /><Text as='em' color="green">read</Text>
                                                                </>)
                                                                    :
                                                                    (<>
                                                                        <Button onClick={() => onCommentRead(comment.showitem, list)}
                                                                            size='md' height='50px' width='50px' borderWidth='2px' bordercolor="teal">

                                                                            <Text as='i'  >unread</Text>
                                                                        </Button>
                                                                    </>)
                                                            )
                                                    })
                                                    }

                                                </Td>
                                            )}

                                        <Td>  {comment.showitemimage ? (<Avatar size={'xl'} src={comment.showitemimage}
                                        />) : <Avatar size={'lg'} bg="grey.100" icon={<SunIcon fontSize='1.5rem' />}
                                        />}</Td>
                                        <Td>{comment.showitemname}</Td>

                                        <Td>
                                            <HStack>
                                                <Heading size="sm">FROM:  </Heading>
                                                <Text> {comment.showcommentfrom} </Text>

                                            </HStack>
                                            <br />
                                            <Heading size="sm">TO:</Heading>

                                            <VStack>

                                                <HStack>
                                                    <Text>{comment.showcommentemail}</Text>
                                                    {comment.showcommentread ? (
                                                        <>

                                                            <CheckIcon /><Text as='em' color="green">read</Text>
                                                        </>)
                                                        : (
                                                            <>
                                                                <div onClick={() => onCommentRead(item, comment)}
                                                                    size='md' height='50px' width='50px' border='2px' bordercolor="black">

                                                                    <Text as='i' onClick={() => onCommentRead(item, comment)}>unread</Text>
                                                                </div>
                                                            </>
                                                        )}

                                                    )

                                                </HStack>
                                            </VStack>
                                        </Td>
                                        <Td>{comment.showcomment}</Td>

                                        <Td>{comment.showcommentdate.substring(0, 10)}</Td>


                                    </Tr>

                                </>
                            )
                        })}

                    </Tbody>
                    <Tfoot>
                        <Tr>
                            <Th>Expand</Th>
                            <Th>Status</Th>
                            <Th> ItemName</Th>
                        </Tr>
                    </Tfoot>
                </Table>



            </>

        )
    }


    const renderCommentlist = (commentlist) => {
        //  console.log(commentlist)
        const showlist = []
        // commentlist.map((item) => {
        //   showlist.push(item.commentemail);
        // })

        return showlist

    }

    const renderCommentlistX = (commentlist) => {
        //  console.log(commentlist)
        const showlist = []
        commentlist.map((item) => {
            if (item.commentread) {
                showlist.push(`${item.commentemail}(Read) -- `);
            } else {
                //add counter
                showlist.push(`${item.commentemail}(Not Read) --  `);
            }
            //showlist.push(`${item.commentemail}(${item.commentemail}) `);
        })

        return showlist

    }


    const renderSelectedComment = (comment, commentAction) => {
        //  console.log(('COMMNET ' + JSON.stringify(selectedComment, 0, 2)))
        //  console.log(('SHOWCOMMEN' + JSON.stringify(comment, 0, 2)))
        //console.log(('OLDCOMMENT' + JSON.stringify(oldComment, 0, 2)))
        console.log(('ANOTHERCOMMENT' + JSON.stringify(anotherComment, 0, 2)))

        console.log('PROPS' + commentAction)



        return (
            selectedComment ? (

                <>
                    {commentAction === 'NEW' ? (
                        <ItemCommentForm onSubmit={onCommentSubmit}
                            //  comment={selectedComment.showitem} item={selectedComment.showitem} selectedComment={selectedComment} />
                            comment={selectedComment.showitem}
                            item={commentAction}
                            selectedComment={anotherComment} />
                    ) : (<ItemCommentForm onSubmit={onCommentSubmit}
                        //  comment={selectedComment.showitem} item={selectedComment.showitem} selectedComment={selectedComment} />
                        comment={selectedComment.showitem}
                        item={commentAction} selectedComment={oldComment} />)}

                </>

            ) : null

        )
    }



    return (
        <div style={{ display: "flex" }}>



            <nav style={{ borderLeft: "solid 1px", padding: "1rem" }} >
                <Heading>Comments</Heading>
                <HStack spacing={3}>

                </HStack>


                {filteredComments ? (renderComments()) : null}

            </nav>
            <Outlet />
        </div>
    );
}


export default connect(null, { switchUser, refreshUser, editNoImage })(Comments);


/*
 (<td onClick={() => onCommentRead(comment.showitem, list)}> NOT READ</td>)
 {availableUser.email === comment.showcommentfrom ? (
                                            <Td>SENT</Td>
                                        ) : null}

                                        {comment.showcommentlist.map((list) => {
                                            if (list.commentemail === availableUser.email)
                                                return (
                                                    list.commentread ? (<Td>  <CheckIcon />Read</Td>)
                                                        : (<td onClick={() => onCommentRead(comment.showitem, list)}> NOT READ</td>)
                                                )
                                        })
                                        }





 */