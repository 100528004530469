import React from "react";
//import {  useNavigate } from "react-router-dom";

import { connect, useSelector } from "react-redux";

import {
  SimpleGrid,
  GridItem,
  Text,
  Box,
  VStack,
  Button,
  ButtonGroup,
  CSSReset,
  Heading,
  //Link,
  ThemeProvider,
  theme,
  FormControl,
  // FormLabel,
  FormErrorMessage,
  // Input,
  Checkbox,
  useBreakpointValue,
} from '@chakra-ui/react';

import { Form, useField, } from 'react-final-form'
//import validate from './validateItemRecord'
//must add at least one?

import ibtColors from "../common/ibtColors";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))



const ItemClassForm = (props) => {
  //   let navigate = useNavigate();

  // const [selectedRecord, setSelectedRecord] = useState(null);
  //const [showClass, setShowClass] = useState(false);

  //  const [isOpen, setIsOpen] =  useState(false)
  //const onClose = () => setIsOpen(false)
  //const cancelRef =  useRef()

  const colSpan = useBreakpointValue({ base: 2, md: 1 })
  const formSpan = useBreakpointValue({ base: 1, md: 2 })
  const boxWidth = useBreakpointValue({ base: 320, md: 800 })
  const baseClass = useBreakpointValue({ base: false, md: true })

  const availableUser = useSelector((state) => state.user.user);

  const onClassSubmit = async values => {
    // console.log('in RecordForm onClass change' + values )
    await sleep(300)
    // console.log(JSON.stringify(values, 0, 2))

    await props.onSubmit(values, null);
  }





  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Box w={boxWidth} p={4} m="20px auto">

        <Form
          onSubmit={onClassSubmit}
          initialValues={{
            id: props.item._id,
            itemName: props.item.name,
            text: props.item.text,
            category: props.item.category,
            image: props.item.image,
            itemId: props.item._id,
            wishname: props.item.wishname,
            wishitem: props.item.wishitem,
            flagged: props.item.flagged,
            ibegone: props.item.ibegone,
            ibepets: props.item.ibepets,
            ibehandy: props.item.ibehandy,
            ibehealth: props.item.ibehealth,
            ibehouse: props.item.ibehouse,
            ibepaper: props.item.ibepaper,
            ibemoney: props.item.ibemoney,
            ibemember: props.item.ibemember,
            ibetechno: props.item.ibetechno,
            ibeevent: props.item.ibeevent,
            ibesocial: props.item.ibesocial,
            ibecontacts: props.item.ibecontacts,
            ibebusy: props.item.ibebusy,
            ibecareer: props.item.ibecareer,
            ibememoir: props.item.ibememoir,
            ibeframed: props.item.ibeframed,
            records: props.item.records,
            itemDate: props.item.itemDate,
            trackType: props.item.trackType,
            picklist: props.item.picklist,
            comments: props.item.comments,
            classResponse: 'INITIAL',

          }}

          // validate={validate}
          render={({
            handleSubmit,
            form,
            errors,
            subscription = { submitting: true },
            submitting,
            pristine,
            values
          }) => (
            <>

              <Box
                as="form"
                p={4}
                borderWidth="3px"
                rounded="lg"
                shadow="1px 1px 3px rgba(0,0,0,0.3)"
                onSubmit={handleSubmit}
              >

                {baseClass ? (
                  <>
                    <SimpleGrid columns={6} spacing={10}  >

                      <VStack>

                        <div  >
                          <CheckboxControl name="ibepets" isChecked={values.ibepets}
                          ><Text fontSize="2xl" color={`${ibtColors.ibtpets}`}>iBePets</Text></CheckboxControl>
                        </div>
                        {availableUser.status === 'Basic'
                          || availableUser.status === 'Active'
                          ? (<>
                            <div  >
                              <CheckboxControl name="ibegone" isChecked={values.ibegone}
                              ><Text fontSize="2xl" color={`${ibtColors.ibtgone}`}>iBeGone</Text></CheckboxControl>
                            </div>
                          </>) : null}
                        {availableUser.status === 'Active'
                          ? (<>
                            <div  >
                              <CheckboxControl name="ibetechno" isChecked={values.ibetechno}
                              ><Text fontSize="2xl" color={`${ibtColors.ibttechno}`}>iBeTechno</Text></CheckboxControl>
                            </div>
                            <div  >
                              <CheckboxControl name="ibebusy" isChecked={values.ibebusy}
                              ><Text fontSize="2xl" color={`${ibtColors.ibtbusy}`}>iBeBusy</Text></CheckboxControl>
                            </div>
                          </>) : null}

                      </VStack>

                      <VStack>

                        <div  >
                          <CheckboxControl name="ibecareer" isChecked={values.ibecareer}
                          ><Text fontSize="2xl" color={`${ibtColors.ibtcareer}`}>iBeCareer</Text></CheckboxControl>
                        </div>

                        {availableUser.status === 'Basic'
                          || availableUser.status === 'Active'
                          ? (<>
                            <div  >
                              <CheckboxControl name="ibepaper" isChecked={values.ibepaper}
                              ><Text fontSize="2xl" color={`${ibtColors.ibtpaper}`}>iBePaper</Text></CheckboxControl>
                            </div>
                          </>) : null}
                        {availableUser.status === 'Active'
                          ? (<>
                            <div  >
                              <CheckboxControl name="ibeevent" isChecked={values.ibeevent}
                              ><Text fontSize="2xl" color={`${ibtColors.ibtevent}`}>iBeEvent</Text></CheckboxControl>
                            </div>
                            <div  >
                              <CheckboxControl name="ibememoir" isChecked={values.ibememoir}
                              ><Text fontSize="2xl" color={`${ibtColors.ibtmemoir}`}>iBeMemoir</Text></CheckboxControl>
                            </div>
                          </>) : null}

                      </VStack>

                      <VStack>
                        <div  >
                          <CheckboxControl name="ibehandy" isChecked={values.ibehandy}
                          ><Text fontSize="2xl" color={`${ibtColors.ibthandy}`}>iBeHandy</Text></CheckboxControl>
                        </div>
                        {availableUser.status === 'Basic'
                          || availableUser.status === 'Active'
                          ? (<>
                            <div  >
                              <CheckboxControl name="ibemoney" isChecked={values.ibemoney}
                              ><Text fontSize="2xl" color={`${ibtColors.ibtmoney}`}>iBeMoney</Text></CheckboxControl>
                            </div>
                          </>) : null}
                        {availableUser.status === 'Active'
                          ? (<>
                            <div  >
                              <CheckboxControl name="ibesocial" isChecked={values.ibesocial}
                              ><Text fontSize="2xl" color={`${ibtColors.ibtsocial}`}>iBeSocial</Text></CheckboxControl>
                            </div>
                            <div  >
                              <CheckboxControl name="ibehealth" isChecked={values.ibehealth}
                              ><Text fontSize="2xl" color={`${ibtColors.ibthealth}`}>iBeHealth</Text></CheckboxControl>
                            </div>
                          </>) : null}


                      </VStack>

                      <VStack>
                        {availableUser.status === 'Basic'
                          || availableUser.status === 'Active'
                          ? (<>
                            <div  >
                              <CheckboxControl name="ibehouse" isChecked={values.ibehouse}
                              ><Text fontSize="2xl" color={`${ibtColors.ibthouse}`}>iBeHouse</Text></CheckboxControl>
                            </div>
                            <div  >
                              <CheckboxControl name="ibemember" isChecked={values.ibemember}
                              ><Text fontSize="2xl" color={`${ibtColors.ibtmember}`}>iBeMember</Text>
                              </CheckboxControl>
                            </div>
                          </>) : null}
                        {availableUser.status === 'Active'
                          ? (<>
                            <div  >
                              <CheckboxControl name="ibecontacts" isChecked={values.ibecontacts}
                              ><Text fontSize="2xl" color={`${ibtColors.ibtcontacts}`}>iBeContacts</Text></CheckboxControl>
                            </div>
                            <div  >
                              <CheckboxControl name="ibeframed" isChecked={values.ibeframed}
                              ><Text fontSize="2xl" color={`${ibtColors.ibtframed}`}>iBeFramed</Text></CheckboxControl>
                            </div>
                          </>) : null}

                      </VStack>






                    </SimpleGrid>



                  </>
                ) : (<>

                  <SimpleGrid columns={2} spacing={10} >

                    <VStack>
                      <div  >
                        <CheckboxControl name="ibepets" isChecked={values.ibepets}
                        ><Text fontSize="2xl" color={`${ibtColors.ibtpets}`}>iBePets</Text></CheckboxControl>
                      </div>
                      <div  >
                        <CheckboxControl name="ibehandy" isChecked={values.ibehandy}
                        ><Text fontSize="2xl" color={`${ibtColors.ibthandy}`}>iBeHandy</Text></CheckboxControl>
                      </div>
                      {availableUser.status === 'Basic'
                        || availableUser.status === 'Active'
                        ? (<>
                          <div  >
                            <CheckboxControl name="ibegone" isChecked={values.ibegone}
                            ><Text fontSize="2xl" color={`${ibtColors.ibtgone}`}>iBeGone</Text></CheckboxControl>
                          </div>
                          <div  >
                            <CheckboxControl name="ibemoney" isChecked={values.ibemoney}
                            ><Text fontSize="2xl" color={`${ibtColors.ibtmoney}`}>iBeMoney</Text></CheckboxControl>
                          </div>
                        </>) : null}
                      {availableUser.status === 'Active'
                        ? (<>
                          <div  >
                            <CheckboxControl name="ibetechno" isChecked={values.ibetechno}
                            ><Text fontSize="2xl" color={`${ibtColors.ibttechno}`}>iBeTechno</Text></CheckboxControl>
                          </div>
                          <div  >
                            <CheckboxControl name="ibebusy" isChecked={values.ibebusy}
                            ><Text fontSize="2xl" color={`${ibtColors.ibtbusy}`}>iBeBusy</Text></CheckboxControl>
                          </div>
                          <div  >
                            <CheckboxControl name="ibesocial" isChecked={values.ibesocial}
                            ><Text fontSize="2xl" color={`${ibtColors.ibtsocial}`}>iBeSocial</Text></CheckboxControl>
                          </div>
                          <div  >
                            <CheckboxControl name="ibehealth" isChecked={values.ibehealth}
                            ><Text fontSize="2xl" color={`${ibtColors.ibthealth}`}>iBeHealth</Text></CheckboxControl>
                          </div>
                        </>) : null}

                    </VStack>

                    <VStack>
                      <div  >
                        <CheckboxControl name="ibecareer" isChecked={values.ibecareer}
                        ><Text fontSize="2xl" color={`${ibtColors.ibtcareer}`}>iBeCareer</Text></CheckboxControl>
                      </div>

                      {availableUser.status === 'Basic'
                        || availableUser.status === 'Active'
                        ? (<>

                          <div  >
                            <CheckboxControl name="ibehouse" isChecked={values.ibehouse}
                            ><Text fontSize="2xl" color={`${ibtColors.ibthouse}`}>iBeHouse</Text></CheckboxControl>
                          </div>
                          <div  >
                            <CheckboxControl name="ibepaper" isChecked={values.ibepaper}
                            ><Text fontSize="2xl" color={`${ibtColors.ibtpaper}`}>iBePaper</Text></CheckboxControl>
                          </div>
                          <div  >
                            <CheckboxControl name="ibemember" isChecked={values.ibemember}
                            ><Text fontSize="2xl" color={`${ibtColors.ibtmember}`}>iBeMember</Text>
                            </CheckboxControl>
                          </div>

                        </>) : null}
                      {availableUser.status === 'Active'
                        ? (<>

                          <div  >
                            <CheckboxControl name="ibeevent" isChecked={values.ibeevent}
                            ><Text fontSize="2xl" color={`${ibtColors.ibtevent}`}>iBeEvent</Text></CheckboxControl>
                          </div>
                          <div  >
                            <CheckboxControl name="ibememoir" isChecked={values.ibememoir}
                            ><Text fontSize="2xl" color={`${ibtColors.ibtmemoir}`}>iBeMemoir</Text></CheckboxControl>
                          </div>
                          <div  >
                            <CheckboxControl name="ibecontacts" isChecked={values.ibecontacts}
                            ><Text fontSize="2xl" color={`${ibtColors.ibtcontacts}`}>iBeContacts</Text></CheckboxControl>
                          </div>
                          <div  >
                            <CheckboxControl name="ibeframed" isChecked={values.ibeframed}
                            ><Text fontSize="2xl" color={`${ibtColors.ibtframed}`}>iBeFramed</Text></CheckboxControl>
                          </div>
                        </>) : null}

                    </VStack>




                  </SimpleGrid>


                </>)}






                <ButtonGroup>
                  <Button
                    isLoading={submitting}
                    onClick={() => {
                      values.classResponse = "CANCEL";
                    }
                    }
                    loadingText="Cancelling"
                    type="cancel"
                  >
                    Cancel
                  </Button>

                  <Button
                    isLoading={submitting}
                    loadingText="Submitting"
                    colorScheme="teal"
                    disabled={submitting}
                    type="submit"
                  >
                    Update
                  </Button>

                </ButtonGroup>


              </Box>
            </>
          )}></Form>




      </Box>
    </ThemeProvider>
  )
}




const CheckboxControl = ({ name, value, children, isChecked, color }) => {
  const {
    input: { checked, ...input },
    meta: { error, touched, invalid }
  } = useField(name, {
    type: 'checkbox' // important for RFF to manage the checked prop
  })
  return (
    <FormControl isInvalid={touched && invalid} my={4}>
      <Checkbox {...input} isInvalid={touched && invalid} my={4} size="lg" colorScheme={color}
        color={color}
        iconColor='white' iconSize='5rem'
        defaultIsChecked={isChecked}>
        {children}
      </Checkbox>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}




export default ItemClassForm;

/*

const Control = ({ name, ...rest }) => {
  const {
    meta: { error, touched }
  } = useField(name, { subscription: { touched: true, error: true } })
  return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
  const {
    meta: { error }
  } = useField(name, { subscription: { error: true } })
  return <FormErrorMessage>{error}</FormErrorMessage>
}
*/