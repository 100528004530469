import React, { useEffect } from "react";

function TermConditions() {

  useEffect(() => {
   window.location.href = "https://www.termsfeed.com/live/badb644f-5fe3-4d06-9373-58fa895fde31";
  }, []);

  return (
    <div>
          <h2>Contact</h2>
         
    </div>
  );
}

export default TermConditions;