const validateProxy = values => {
    const errors = {}
    if (!values.proxyFirst) {
        errors.proxyFirst = 'Required'
    }
    if (!values.proxyLast) {
        errors.proxyLast = 'Required'
    }
    if (!values.xrefuser) {
        errors.xrefuser = 'Required'
    } else {
        if (values.xrefuser !== values.xrefuser2) {
            errors.xrefuser2 = 'Email must match'
        }
    }



    return errors
}
export default validateProxy