import React, { useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import {
    Text,
    VStack,
    Code,
    SimpleGrid,
    GridItem,
    Select,
    useBreakpointValue,
    useToast,
    Box,
    Button,
    ButtonGroup,
    CSSReset,
    Heading,
    Icon,
    Link,
    ThemeProvider,
    theme,
    FormControl,
    FormLabel,
    FormErrorMessage,

    Input,
    Checkbox,
    Progress,
    Radio,
    RadioGroup,
    Stack,
    Textarea
} from '@chakra-ui/react';

import { Form, Field, useField, useForm } from 'react-final-form'


import {
    FileUploadContainer,
    FormField,
    DragDropText,
    UploadFileBtn,
    FilePreviewContainer,
    ImagePreview,
    PreviewContainer,
    PreviewList,
    FileMetaData,
    RemoveFileIcon,
    InputLabel,
} from "../file-upload/file-upload.styles";

import ImageUpload from '../items/ImageUpload'
import { uploadDocument } from "../../actions/itemAction"

import validate from './validateProfile'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))



const ProfileForm = ({ uploadDocument, onSubmit }) => {
    // console.log(props.item)


    const availableUser = useSelector((state) => state.user.user);

    //  const availableToken = useSelector((state) => state.user.token)
    // const availableEmail = useSelector((state) => state.user.googleEmail)
    const availableEntity = useSelector((state) => state.entity.entity);


    const [isUploading, setIsUploading] = useState(false);
    const [photo, setPhoto] = useState(null);
    const [fileSelected, setFileSelected] = useState(null);


    let navigate = useNavigate();
    const toast = useToast();

    const colSpan = useBreakpointValue({ base: 2, md: 1 })
    const formSpan = useBreakpointValue({ base: 1, md: 2 })
    const boxWidth = useBreakpointValue({ base: 350, md: 1000 })


    const onFormSubmit = async values => {
        // console.log('in NmeForm onSubmit' + values.itemName)
        if (photo) {
            values.avatar = photo
        }

        await sleep(300)
        await onSubmit(values);

    }

    const handlePhotoSelect = async (file) => {
        setIsUploading(true);
        //  console.log('UPLOAD PHOTO ' + file)

        let fileType2 = file.type.split("/")[1];
        const random2 = Math.random().toString().replace(/0\./, ""); // remove "0." from start of string
        const photoId = availableUser._id + random2 + "." + fileType2;
        // console.log(photoId)
        const blobName2 = `https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBgMobile${photoId}`;
        const result = await uploadDocument(file, photoId, blobName2);

        setFileSelected(file)
        setPhoto(blobName2)
        setIsUploading(false);

        toast({
            duration: 5000, status: "success", isClosable: true,
            title: "Upload Complete.",
            description: "Saved your photo on infoBee!",
        });
    };



    return (
        <ThemeProvider theme={theme}>
            <CSSReset />
            <Box w={boxWidth} p={4} m="20px auto">
                <Heading as="h1" size="xl" textAlign="center">
                    infoBee Profile Form
                </Heading>

                <Button onClick={() => {
                    navigate(`/profile`)
                }}
                >
                    Cancel - GoBack to item
                </Button>



                <Form
                    onSubmit={onFormSubmit}
                    initialValues={{
                        userFirst: availableUser.first,
                        userLast: availableUser.last,
                        avatar: availableUser.avatar,

                        profileResponse: 'INITIAL',

                    }}
                    validate={validate}
                    render={({
                        handleSubmit,
                        form,
                        errors,
                        subscription = { submitting: true },
                        submitting,
                        pristine,
                        values
                    }) => (
                        <>

                            <Box
                                as="form"
                                p={4}
                                borderWidth="3px"
                                rounded="lg"
                                shadow="1px 1px 3px rgba(0,0,0,0.3)"
                                onSubmit={handleSubmit}
                            >
                                <div> {photo ? (<PreviewContainer>
                                    <ImagePreview
                                        src={URL.createObjectURL(fileSelected)}
                                        alt={`file preview`}

                                    />
                                </PreviewContainer>) : null}

                                </div>


                                <SimpleGrid columns={2} columnGap={3} rowGap={6} width="full">
                                    <GridItem colSpan={colSpan}>
                                        <InputControl name="userFirst" label="First Name" maxLength={15} />

                                    </GridItem>
                                    <GridItem colSpan={colSpan}>
                                        <InputControl name="userLast" label="Last Name" maxLength={40} />

                                    </GridItem>

                                    <GridItem colSpan={2}>
                                        <ImageUpload isUploading={isUploading}
                                            onPhotoSelect={handlePhotoSelect}
                                            label={"Upload Profile Image"} />

                                    </GridItem>


                                </SimpleGrid>


                                <br />
                                <Box justifyContent={"center"}>

                                </Box>
                                <br />


                                <ButtonGroup>
                                    <Button
                                        isLoading={submitting}
                                        onClick={() => {
                                            //  values.userFirst = 'cancel';
                                            //  values.userLast = 'cancel';
                                            //  console.log('multiple lines');
                                            values.profileResponse = "CANCEL";
                                        }

                                        }
                                        loadingText="Cancelling"

                                        type="cancel"
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        isLoading={submitting}
                                        loadingText="Submitting"
                                        colorScheme="teal"
                                        disabled={submitting}
                                        type="submit"
                                    >
                                        Update
                                    </Button>

                                </ButtonGroup>





                            </Box>
                        </>
                    )}
                />
            </Box>
        </ThemeProvider>
    )
}


const Control = ({ name, ...rest }) => {
    const {
        meta: { error, touched }
    } = useField(name, { subscription: { touched: true, error: true } })
    return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
    const {
        meta: { error }
    } = useField(name, { subscription: { error: true } })
    return <FormErrorMessage>{error}</FormErrorMessage>
}

const InputControl = ({ name, label, type, maxWidth, maxLength }) => {
    const { input, meta } = useField(name)
    return (
        <Control name={name} my={4}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Input
                {...input}
                isInvalid={meta.error && meta.touched}
                id={name}
                placeholder={label}
                type={type}
                maxWidth={maxWidth}
                maxLength={maxLength}
            />
            <Error name={name} />
        </Control>
    )
}



export default connect(null, { uploadDocument })(ProfileForm);

/*
{fieldState => { <pre>{(JSON.stringify(fieldState, 0, 2))}</pre> }}  
*/