import React from "react";

import {
  SimpleGrid,
  GridItem,
  Box,
  Button,
  ButtonGroup,
  CSSReset,
  Heading,
  ThemeProvider,
  theme,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useBreakpointValue,
  Input,
} from '@chakra-ui/react';

import { Form, useField, } from 'react-final-form'
import validate from './validatePasscode'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const PasswordForm = ({ onSubmit, email, status }) => {

  const colSpan = useBreakpointValue({ base: 2, md: 1 })
  const formSpan = useBreakpointValue({ base: 1, md: 2 })
  const boxWidth = useBreakpointValue({ base: 260, md: 1000 })

  const onPasswordSubmit = async values => {
    //  console.log("reset in progress" )
    await onSubmit(values);
    await sleep(300)
    // window.alert('Resetting in Progress ')
  }
  const onCancel = async (values) => {
    values.profileResponse = "CANCEL";
    //window.alert("cancelled");
    await onSubmit(values);
    await sleep(300)
  }

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Box w={boxWidth} p={4} m="20px auto">
     
        <Form
          onSubmit={onPasswordSubmit}
          validate={validate}
          initialValues={{
            email: email,
            profileResponse: 'INITIAL'
          }}
          render={({
            handleSubmit,
            form,
            errors,
            subscription = { submitting: true },
            submitting,
            pristine,
            values
          }) => (

            <>
              <Box as="form" p={4} borderWidth="3px" rounded="lg"
                shadow="1px 1px 3px rgba(0,0,0,0.3)"
                onSubmit={handleSubmit}  >

                <SimpleGrid columns={2} columnGap={3} rowGap={6} width="full">

                  {status ? (<GridItem colSpan={2}>
                    <InputControl name="passcode" label="Passcode" maxLength={6} />
                  </GridItem>) : null}

                  <GridItem colSpan={colSpan}>
                    <InputControl name="password" label="Password" type="password" minLength={6} maxLength={40} />
                  </GridItem>
                  <GridItem colSpan={colSpan}>
                    <InputControl name="password2" label="Confirm Password" type="password" />
                  </GridItem>
                </SimpleGrid>

                <ButtonGroup>
                  {status !== 'RESET' ? (<Button
                    onClick={() =>
                      onCancel(values)
                    }
                    type="button"
                  >
                    Cancel
                  </Button>) : null}


                  <Button
                    isLoading={submitting}
                    loadingText="Submitting"
                    colorScheme="teal"
                    disabled={submitting}
                    type="submit"
                  >
                    Submit
                  </Button>

                </ButtonGroup>


              </Box>
            </>
          )}
        />
      </Box>
    </ThemeProvider>
  )
}


const Control = ({ name, ...rest }) => {
  const {
    meta: { error, touched }
  } = useField(name, { subscription: { touched: true, error: true } })
  return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
  const {
    meta: { error }
  } = useField(name, { subscription: { error: true } })
  return <FormErrorMessage>{error}</FormErrorMessage>
}

const InputControl = ({ name, label, type, minLength, maxLength, pattern }) => {
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={4}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        {...input}
        isInvalid={meta.error && meta.touched}
        id={name}
        placeholder={label}
        type={type}
        minLength={minLength}
        maxLength={maxLength}
        pattern={pattern}
      />
      <Error name={name} />
    </Control>
  )
}



export default PasswordForm;
