import React, { useState, useEffect } from "react";
//import {  NavLink, Outlet, useSearchParams, useNavigate } from "react-router-dom";

import { useSelector, connect } from "react-redux";


import {
  Text,
  VStack,
  SimpleGrid,
  GridItem,
  Box,
  Button,
  ButtonGroup,
  CSSReset,
  Heading,
  //Link,
  ThemeProvider,
  theme,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Checkbox,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useBreakpointValue,

  // useToast,
} from '@chakra-ui/react';

import { Form, useField, } from 'react-final-form'
//import validateComment from '../forms/validateItemComment'



//import { uploadDocument } from "../../actions/itemAction"

//import ibtColors from "../common/ibtColors";

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))



const ItemCommentForm = ({ onSubmit, comment, item, selectedComment }) => {



  const [createCommentList, setCreateCommentList] = useState([])

  //const [showCommentList, setShowCommentList] = useState(false);

  const [temporaryState1, setTemporaryState1] = useState(null);
  const [newThing, setNewThing] = useState(null);

  const [isThingOpen, setIsThingOpen] = useState(false)

  // let navigate = useNavigate();
  //const cancelRef = useRef()
  //const cancelThingRef = useRef()

  //const toast = useToast();

  const onThingClose = () => {
    //  setFinalThing(true)
    setIsThingOpen(false)

  }

  const colSpan = useBreakpointValue({ base: 2, md: 1 })
  const formSpan = useBreakpointValue({ base: 1, md: 2 })
  const boxWidth = useBreakpointValue({ base: 320, md: 800 })


  const availableUser = useSelector((state) => state.user.user);

  useEffect(() => {
    // console.log('Picklist in Loop' + (JSON.stringify(item, 0, 2)))
    const availPick = async () => {
      // console.log('Picklist in Loop' + (JSON.stringify(comment, 0, 2)))

      const pickComment = []
      for (let i = 0; i < comment.picklist.length; i++) {
        //  console.log('Picklist in Loop' + pickItems) 
        let pickItem = {
          "commentemail": comment.picklist[i].pickemail,
          "commentname": comment.picklist[i].pickfirst,
          "picked": false
        }

        pickComment.push(pickItem);

      }

      setCreateCommentList(pickComment)
      // console.log('Picklist in Loop' + (JSON.stringify(pickComment, 0, 2)) )
    };

    availPick();

  }, [comment.picklist]);


  const onFormSubmit = async values => {
    //console.log('START  ' + (JSON.stringify(values.selectlist, 0, 2)))

    const commentItems = []

    //NEW
    if (!selectedComment._id) {

      let new_array = values.selectlist.filter

        (element => {
          if (element.picked) {
            return (
              {
                ...element, commentemail: element.commentemail,
                commentname: element.commentname,
                commentread: false
              })
          }
        })
      values.commentlist = new_array

      // console.log('BUILD  ' + (JSON.stringify(values.commentlist, 0, 2)))

      await sleep(300)
      if (values.cancelResponse !== 'CANCEL') {
        if (new_array.length === 0 && item !== 'REPLY') {
          console.log('NOT ENOUGH LENGTH' + new_array.length)
          return
        }
      }



      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      today = yyyy + '-' + mm + '-' + dd;

      const newData2 = {
        commentId: null,
        comment: values.commentDescrip,
        commentlist: values.commentlist,
        commentDate: today,
        commentfrom: availableUser.email,
        commentread: false,
      }

      values.comments = [...values.comments, newData2]
      values.commentResponse = 'OKAY'

      await onSubmit(values);
      await sleep(300)
    }

    // REPLY
    if (selectedComment._id) {

      for (let i = 0; i < values.comments.length; i++) {
        if (values.comments[i]._id === selectedComment._id) {
          for (let c = 0; c < values.comments[i].commentlist.length; c++) {
            if (values.comments[i].commentlist[c].commentemail === availableUser.email) {
              values.comments[i].commentlist[c].commentread = true
            }

          }
        }
      }


      values.commentResponse = 'OKAY'
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      today = yyyy + '-' + mm + '-' + dd;
      const replyData2 = {
        commentId: selectedComment._id,
        comment: values.commentDescrip,
        commentlist: [{
          commentemail: selectedComment.commentfrom,
          commentname: 'Reply To Name',
          commentread: false
        }],

        commentDate: today,
        commentfrom: availableUser.email,
        commentread: false,
      }

      values.comments = [...values.comments, replyData2]


      // setTemporaryState1(values)
      //  values.comments = nextValues
      console.log('FINAL UPDATE' + (JSON.stringify(values.comments, 0, 2)))
      await onSubmit(values);
      await sleep(300)
      //  }

    }
  }



  const mySelectList = (values) => {
    return (
      values.picklist.map((myValue, myIndex) => {
        return (
          <div key={myIndex}>
            <CheckboxControl key={myIndex} name={`selectlist[${myIndex}].picked`} >
              <Heading size="md"> {myValue.pickfirst} - {myValue.pickemail}</Heading>
            </CheckboxControl>
          </div>
        )
      })
    )
  }







  return (
    <ThemeProvider theme={theme}>

      <CSSReset />

      <Box w={boxWidth} p={4} m="20px auto">

        <Form
          onSubmit={onFormSubmit}
          initialValues={{
            id: comment._id,

            itemName: comment.name,
            commentId: selectedComment._id,
            commentDescrip: selectedComment.descrip,
            //commentThing: selectedComment.thing,
            //  commentDate: selectedcomment.date,
            //commentType: selectedComment.type,
            // commentDocument: selectedComment.document,
            commentResponse: 'INITIAL',
            text: comment.text,
            category: comment.category,
            image: comment.image,
            itemId: comment._id,
            wishname: comment.wishname,
            wishitem: comment.wishitem,
            flagged: comment.flagged,
            ibegone: comment.ibegone,
            ibepets: comment.ibepets,
            ibehandy: comment.ibehandy,
            ibehealth: comment.ibehealth,
            ibehouse: comment.ibehouse,
            ibepaper: comment.ibepaper,
            ibemoney: comment.ibemoney,
            ibemember: comment.ibemember,
            ibetechno: comment.ibetechno,
            ibeevent: comment.ibeevent,
            ibesocial: comment.ibesocial,
            ibecontacts: comment.ibecontacts,
            ibebusy: comment.ibebusy,
            ibecareer: comment.ibecareer,
            ibememoir: comment.ibememoir,
            ibeframed: comment.ibeframed,
            comments: comment.comments,
            records: comment.records,
            itemDate: comment.itemDate,
            trackType: comment.trackType,
            wishes: comment.wishes,
            picklist: comment.picklist,

            selectlist: createCommentList,
            cancelResponse: 'INITIAL',
          }}
          // validate={validateComment}
          render={({
            handleSubmit,
            form,
            errors,
            subscription = { submitting: true },
            submitting,
            pristine,
            values
          }) => (
            <>


              <Box as="form" p={4} borderWidth="3px"
                rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)"
                onSubmit={handleSubmit}
              >
                {item !== 'REPLY' ? (mySelectList(values)) : <Text>
                  Reply to Message -  {selectedComment.comment}
                </Text>}




                <SimpleGrid columns={2} columnGap={3} rowGap={3} width="full">



                  <GridItem colSpan={2}>
                    <InputControl name="commentDescrip" label="Message To Selected Recipients "
                      type="Textarea" maxLength={300} />
                  </GridItem>

                </SimpleGrid>



                <SimpleGrid columns={4} columnGap={3} rowGap={3} width="full">





                  <GridItem colSpan={1}>
                    <VStack alignItems="center">

                      <ButtonGroup style={{ marginLeft: 120 }}>
                        <Button
                          isLoading={submitting}
                          onClick={() => {
                            values.cancelResponse = "CANCEL";
                          }}
                          loadingText="Cancelling"
                          type="cancel"
                        >
                          Cancel
                        </Button>

                        {item !== 'REPLY' ? (<Button
                          isLoading={submitting}
                          loadingText="Submitting"
                          colorScheme="teal"
                          type="submit"
                        >  Add Message </Button>)
                          : (<Button
                            isLoading={submitting}
                            loadingText="Submitting"
                            colorScheme="teal"
                            type="submit"
                          > Reply </Button>)}


                      </ButtonGroup>
                    </VStack>
                  </GridItem>

                </SimpleGrid>
              </Box>
            </>
          )}>




        </Form>


        <AlertDialog

          isOpen={isThingOpen}
          // leastDestructiveRef={cancelThingRef}
          onClose={onThingClose}
        >

          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Add Classification to Item </AlertDialogHeader>
              <AlertDialogBody>
                This classification is not associated with the item - do  you want to add it?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button colorScheme='red' onClick={onThingClose}> No - Cancel </Button>
                <Button ml={3}
                  onClick={() => {
                    //  autoAddThing(temporaryState1)
                    //   onThingClose()
                  }}
                > Yes add {newThing}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>


      </Box>
    </ThemeProvider>
  )
}


const Control = ({ name, ...rest }) => {
  const {
    meta: { error, touched }
  } = useField(name, { subscription: { touched: true, error: true } })
  return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
  const {
    meta: { error }
  } = useField(name, { subscription: { error: true } })
  return <FormErrorMessage>{error}</FormErrorMessage>
}

const InputControl = ({ name, label, type, maxLength }) => {
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={4}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        {...input}
        isInvalid={meta.error && meta.touched}
        id={name}
        placeholder={label}
        type={type}
        maxLength={maxLength}
      />
      <Error name={name} />

    </Control>
  )
}

const CheckboxControl = ({ name, value, children, isChecked }) => {
  const {
    input: { checked, ...input },
    meta: { error, touched, invalid }
  } = useField(name, {
    type: 'checkbox' // important for RFF to manage the checked prop
  })
  return (
    <FormControl isInvalid={touched && invalid} my={4}>
      <Checkbox {...input} isInvalid={touched && invalid} my={4}
        defaultIsChecked={isChecked}>
        {children}
      </Checkbox>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}

export default connect(null, {})(ItemCommentForm);