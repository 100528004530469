const validateProfile = values => {
    const errors = {}

    //console.log('Validate' + JSON.stringify(values, 0, 2))

    if (values.profileResponse !== 'CANCEL') {
        if (!values.userFirst) {
            errors.userFirst = 'Required'
        }
    }
    if (values.profileResponse !== 'CANCEL') {
        if (!values.userLast) {
            errors.userLast = 'Required'
        }
    }

    return errors
}
export default validateProfile