//
//Ximport { setAlert } from "./alert";
import ibthost from './api/ibthost'

import {
  USER_SUCCESS,
  USER_ERROR,
  RESET_ERROR,
  EMAIL_SUCCESS,
  ENTITY_SUCCESS,
  LOGIN_SUCCESS,
  LOGOUT,
} from "./types";

// Logout - Google and Database
export const googleSignOut = () => async (dispatch) => {
  try {
    dispatch({
      type: LOGOUT
    });
  } catch (err) {
    await dispatch({ type: USER_ERROR, payload: 'Logout Error' });
    console.log('Logout Error ' + err)
  }

};

// Login User
export const login = (formValues, callback) => async (dispatch, getState) => {

  const config = {
    headers: {
      'Access-Control-Allow-Origin': true,
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email: formValues.email.toLowerCase().trim(), password: formValues.password });


  try {
    const responseUser = await ibthost.post('/user',
      body, config);
    //console.log('USER sucess')
    dispatch({ type: USER_SUCCESS, payload: responseUser.data, });

    const userId = responseUser.data._id
    try {
      const responseEntity = await ibthost.get(`/item/${userId}`, body, config);
      await dispatch({ type: ENTITY_SUCCESS, payload: responseEntity.data });
      try {
        const responseToken = await ibthost.post('/signin', body, config);
        dispatch({ type: LOGIN_SUCCESS, payload: responseToken.data.token, });

        if (callback) {
          callback()
        }

      } catch (err) {
        await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
        console.log('Login Error ' + err)
      }
    } catch (err) {
      await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
      console.log('Entity Error ' + err)
    }
  } catch (err) {
    await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
    console.log('User Error ' + err)
  }
};

// Register
export const register = ({ email, name, first, last }, callback) => {
  return async (dispatch) => {
    const emailLower = email.toLowerCase().trim();
    try {
      const response = await ibthost.post(`/signup`, {
        name: `${first} ${last}`,
        first: first,
        last: last,
        email: emailLower,
        password: "123456",
      });

      if (callback) {
        callback();
      }
    } catch (err) {
      await dispatch({ type: USER_ERROR, payload: 'User Already Registered' });
      console.log('User already registered ' + err)
    }

  };
};


// OTP
export const otp = (formValues, callback) => {
  return async (dispatch) => {

    //console.log('in otp' + formValues.password + formValues.passcode + formValues.email)

    const config = {
      headers: {
        'Access-Control-Allow-Origin': true,
        "Content-Type": "application/json",
      },
    };

    //await dispatch({ type: RESET_ERROR, payload: 'Reset' });

    //console.log('in otp after reset')

    const body = JSON.stringify({
      email: formValues.email.toLowerCase().trim(),
      passcode: formValues.passcode, password: formValues.password
    });

    try {
      const response = await ibthost.post(`/userotp`, body, config);

      // console.log(callback)

      if (callback) {
        callback();
      }
    } catch (err) {
      await dispatch({ type: USER_ERROR, payload: 'Invalid Passcode' });
      console.log('Invalid Passcode ' + err)
    }
  };
};


// RESET PASSWORD
export const reset = (formValues, callback) => {
  return async (dispatch) => {

    const config = {
      headers: {
        'Access-Control-Allow-Origin': true,
        "Content-Type": "application/json",
      },
    };

    await dispatch({ type: RESET_ERROR, payload: 'Reset' });

    const body = JSON.stringify({ email: formValues.email.toLowerCase().trim() });

    try {
      const response = await ibthost.post(`/reset`, body, config);

      if (callback) {
        callback();
      }
    } catch (err) {
      await dispatch({ type: USER_ERROR, payload: 'Invalid Email' });
      console.log('Invalid Email ' + err)
    }
  };
};


// Refresh User
export const refreshUser = (email, callback) => async (dispatch) => {

  const config = {
    headers: {
      'Access-Control-Allow-Origin': true,
      "Content-Type": "application/json",
    },
  };

  console.log("Refresh User - action" + email)

  const body = JSON.stringify({ email: email });

  try {
    if (email) {
      dispatch({ type: EMAIL_SUCCESS, payload: email });

      const responseUser = await ibthost.post("/uservalidate", {
        email: email,
      });

      dispatch({ type: USER_SUCCESS, payload: responseUser.data, });

      const userId = responseUser.data._id
      try {
        const responseEntity = await ibthost.get(`/item/${userId}`, body, config);
        await dispatch({ type: ENTITY_SUCCESS, payload: responseEntity.data });

        try {
          /////// SignInValidate Not in Production
          //const responseToken = await ibthost.post('/signinvalidate', body, config);
          const localToken = localStorage.getItem("token")
          dispatch({ type: LOGIN_SUCCESS, payload: localToken, });

          if (callback) {
            callback()
          }

        } catch (err) {
          await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
          console.log('Login Error ' + err)
        }

      } catch (err) {
        await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
        console.log('Entity Error ' + err)
      }
    }

  } catch (err) {
    await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
    console.log('User Error ' + err)
  }
};

// Update Profile

export const profile = (values, email, callback) => async (dispatch) => {
  //console.log("test profile" + values);
  const config = {
    headers: {
      'Access-Control-Allow-Origin': true,
      "Content-Type": "application/json",
    },
  }


  try {
    const response = await ibthost.post(`/thingusername`, {
      userName: `${values.userFirst} ${values.userLast}`,
      email: email,
      userFirst: values.userFirst,
      userLast: values.userLast,
      avatar: values.avatar,
    });

    const responseUser = await ibthost.post("/uservalidate", {
      email: email,
    });

    dispatch({ type: USER_SUCCESS, payload: responseUser.data, });
    //console.log(JSON.stringify(responseUser.data, 0, 2))
    // dispatch({ type: USER_UPDATE, payload: response.data, });

    if (callback) {
      callback();
    }
  } catch (err) {
    await dispatch({ type: USER_ERROR, payload: 'User Already Registered' });
    console.log('User already registered ' + err)
  }

};

// Update Profile

export const userpw = (values, email, callback) => async (dispatch) => {
  console.log("test password" + values);
  const config = {
    headers: {
      'Access-Control-Allow-Origin': true,
      "Content-Type": "application/json",
    },
  }


  try {
    const response = await ibthost.post(`/userpw`, {
      email: email,
      password: values.password,
    });



    if (callback) {
      callback();
    }
  } catch (err) {
    await dispatch({ type: USER_ERROR, payload: 'Change Password Error' });
    console.log('Change Password Error ' + err)
  }

};

// Contact Us Updated
export const contactus = (formValues, callback) => {
  return async (dispatch) => {

    //console.log('in otp' + formValues.message +  formValues.email)

    const config = {
      headers: {
        'Access-Control-Allow-Origin': true,
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      email: formValues.email.toLowerCase().trim(),
      message: formValues.message,
      name: formValues.removeDate
    });

    try {
      const response = await ibthost.post(`/contactus`, body, config);

      // console.log(callback)

      if (callback) {
        callback();
      }
    } catch (err) {
      await dispatch({ type: USER_ERROR, payload: 'Invalid Email' });
      console.log('Invalid Email ' + err)
    }
  };
};


/*




// Google Get Auth
export const googleGetAuth = () => async (dispatch) => {
  try {
   window.gapi.load('client:auth2', () => {
            window.gapi.client.init({
                clientId: '856243179890-n9ib5u22o59ns0kpk07d7g0ajvv15f62.apps.googleusercontent.com',
                scope: 'email'
            })
   })
  } catch (err) {
    await dispatch({ type: USER_ERROR, payload: 'Google Get Auth' });
    console.log('Google Get Auth Error ' + err)
  }
};


// Google Login
export const googleTrySignIn = ( callback) => async (dispatch) => {

  
   const config = {
   headers: {
      'Access-Control-Allow-Origin': true,
      "Content-Type": "application/json",
    },
  };
  
   const auth = await window.gapi.auth2.getAuthInstance();
   await auth.signIn() 
        
   const email = auth.currentUser.get().getBasicProfile().getEmail()            
   //console.log('Google' + email)
 

  const body = JSON.stringify({ email: email });
   
  try {
    if (email) {
        dispatch({ type: GOOGLE_SUCCESS, payload: email });
 
        const responseUser = await ibthost.post("/uservalidate", {
          email: email,
        });
      
        dispatch({ type: USER_SUCCESS, payload: responseUser.data, });
        
        const userId = responseUser.data._id
    try {
         const responseEntity = await ibthost.get(`/item/${userId}`, body, config);
        await dispatch({ type: ENTITY_SUCCESS, payload: responseEntity.data }); 
        
        try {
             /////// SignInValidate Not in Production
           const responseToken = await ibthost.post('/signinvalidate', body, config);
            dispatch({ type: LOGIN_SUCCESS, payload: responseToken.data.token, });
            
            if (callback) {
                callback()
              }

         } catch (err) {
            await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
             console.log('Login Error ' + err) }  
      
    } catch (err) {
        await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
            console.log('Entity Error ' + err)
        }
    }
      
  } catch (err) {
     await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
    console.log('User Error ' + err)
  }
};

// Refresh User
export const refreshUser = (email, callback) => async (dispatch) => {
  
   const config = {
   headers: {
      'Access-Control-Allow-Origin': true,
      "Content-Type": "application/json",
    },
  };
  
  const body = JSON.stringify({ email: email });
   
  try {
    if (email) {
        dispatch({ type: GOOGLE_SUCCESS, payload: email });
 
        const responseUser = await ibthost.post("/uservalidate", {
          email: email,
        });
      
        dispatch({ type: USER_SUCCESS, payload: responseUser.data, });
        
        const userId = responseUser.data._id
    try {
         const responseEntity = await ibthost.get(`/item/${userId}`, body, config);
        await dispatch({ type: ENTITY_SUCCESS, payload: responseEntity.data }); 
        
        try {
             /////// SignInValidate Not in Production
           //const responseToken = await ibthost.post('/signinvalidate', body, config);
            const localToken = localStorage.getItem("token")
          dispatch({ type: LOGIN_SUCCESS, payload: localToken, });
            
            if (callback) {
                callback()
              }

         } catch (err) {
            await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
             console.log('Login Error ' + err) }  
      
    } catch (err) {
        await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
            console.log('Entity Error ' + err)
        }
    }
      
  } catch (err) {
     await dispatch({ type: USER_ERROR, payload: 'Invalid Credentials' });
    console.log('User Error ' + err)
  }
};





*/