import React from "react";

import {
  SimpleGrid,
  GridItem,
  Box,
  Button,
  ButtonGroup,
  CSSReset,
  ThemeProvider,
  theme,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  useBreakpointValue,
} from '@chakra-ui/react';

import { Form, useField, } from 'react-final-form'
import validate from './validateEnvoi'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const EnvoiForm = ({ onSubmit, envoi }) => {

  const colSpan = useBreakpointValue({ base: 2, md: 1 })
  const formSpan = useBreakpointValue({ base: 1, md: 2 })
  const boxWidth = useBreakpointValue({ base: 280, md: 350 })

  const onFormSubmit = async values => {
    if (!values.id) {
      values.envName = `${values.first} ${values.last}`
    }

    // console.log('in Envoi onSubmit' + (JSON.stringify(values, 0, 2)))
    await onSubmit(values);
    await sleep(300)
  }
  const onCancel = async (values) => {
    values.envoiResponse = "CANCEL";
    //window.alert("cancelled");
    await onSubmit(values);
    await sleep(300)
  }

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Box w={boxWidth} p={4} m="5px auto">


        <Form
          onSubmit={onFormSubmit}
          // validate={validate}
          validate={values => {
            const errors = {}
            // console.log('CANCEL' + values.envoiResponse)
            if (!values.userid) {
              errors.userid = 'Required'
            }
            if (!values.userid2) {
              errors.userid2 = 'Required'
            } else {
              if (values.userid !== values.userid2) {
                errors.userid2 = 'Email not the same'
              }
            }
            return errors
          }}
          initialValues={{
            id: envoi._id,
            first: envoi.first,
            last: envoi.last,
            userid: envoi.userid,
            userid2: '',
            descrip: envoi.descrip,
            relation: envoi.relation,
            authentication: envoi.authentication,
            envName: envoi.name,
            envoiResponse: 'INITIAL'
          }}

          render={({
            handleSubmit,
            form,
            errors,
            subscription = { submitting: true },
            submitting,
            pristine,
            values,

          }) => (
            <>

              <Box
                as="form"
                p={4}
                borderWidth="3px"
                rounded="lg"
                shadow="1px 1px 3px rgba(0,0,0,0.3)"
                onSubmit={handleSubmit}
              >
                <SimpleGrid columns={1} columnGap={3} rowGap={6} width="full">
                  {!values.id ? (
                    <>
                      <GridItem colSpan={1}>
                        <InputControl name="first" label=" First Name" maxLength={45} />
                      </GridItem>
                      <GridItem colSpan={1}>
                        <InputControl name="last" label=" Last Name" maxLength={45} />
                      </GridItem>
                      <GridItem colSpan={1}>
                        <InputControl name="userid" type="email" label="Email" maxLength={60} />
                      </GridItem>
                      <GridItem colSpan={1}>
                        <InputControl name="userid2" type="email" label="Confirm Email" maxLength={60} />
                      </GridItem>
                    </>
                  ) : (
                    <><GridItem colSpan={1}>
                      <InputControl name="envName" label=" Name" maxLength={45} />
                    </GridItem>


                    </>
                  )}



                  <GridItem colSpan={1}>
                    <InputControl name="descrip" label="Description" type="Textarea" maxLength={300} />

                  </GridItem>


                </SimpleGrid>
                <br />

                <ButtonGroup>
                  <Button
                    onClick={() =>
                      onCancel(values)
                    }
                    type="button"
                  >
                    Cancel
                  </Button>

                  {!values.id ? (<Button
                    isLoading={submitting}
                    loadingText="Submitting"
                    colorScheme="teal"
                    disabled={submitting}
                    type="submit"
                  >
                    Add Envoi
                  </Button>) : (<Button
                    isLoading={submitting}
                    loadingText="Submitting"
                    colorScheme="teal"
                    disabled={submitting}
                    type="submit"
                  >
                    Update Envoi
                  </Button>)}



                </ButtonGroup>



              </Box>
            </>
          )}
        />
      </Box>
    </ThemeProvider>
  )
}


const Control = ({ name, ...rest }) => {
  const {
    meta: { error, touched }
  } = useField(name, { subscription: { touched: true, error: true } })
  return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
  const {
    meta: { error }
  } = useField(name, { subscription: { error: true } })
  return <FormErrorMessage>{error}</FormErrorMessage>
}

const InputControl = ({ name, label, type, maxLength }) => {
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={1}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        {...input}
        isInvalid={meta.error && meta.touched}
        id={name}
        placeholder={label}
        type={type}
        maxLength={maxLength}
      />
      <Error name={name} />
    </Control>
  )
}



export default EnvoiForm;

/*

*/