import React from "react";
import {
  Link,
  Outlet,
  useNavigate,
  useLocation,
} from "react-router-dom";

import { useSelector, connect } from "react-redux";
import {
  Box,
  Button,
  Heading,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorMode,
  Flex,
  Avatar,
  VStack,
  HStack,
  useBreakpointValue,
  //Tooltip,
  //Link,
  IconButton,
  useDisclosure,
  useColorModeValue,
  Stack,
} from '@chakra-ui/react';

import { ArrowRightIcon, HamburgerIcon, CloseIcon, MoonIcon, SunIcon } from '@chakra-ui/icons'

import { googleSignOut } from "../../actions/userAction";
import ibtColors from "../common/ibtColors";
//Phase 2import GoogleAuth from '../dashboard/GoogleAuth'


const Navbar = ({ googleSignOut }) => {

  let navigate = useNavigate();
  const location = useLocation();
  // console.log(location.pathname)

  const availSignedIn = useSelector((state) => state.user.isSignedIn);
  const availableUser = useSelector((state) => state.user.user);

  const { toggleColorMode, colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const bg = useColorModeValue('gray.200', 'gray.700')

  const colSpan = useBreakpointValue({ base: 2, md: 1 })
  const navSize = useBreakpointValue({ base: "md", lg: "lg" })
  const textSize = useBreakpointValue({ base: "md", lg: "3xl" })
  const boxWidth = useBreakpointValue({ base: 360, md: 500, lg: 1200 })
  const avatarSize = useBreakpointValue({ base: 'lg', lg: 'xl' })
  const formContent = useBreakpointValue({ base: 'flex-start', lg: 'space-between' })
  const showNav = useBreakpointValue({ base: false, lg: true })

  // console.log(showNav)



  const onSignOutClick = async () => {
    // const auth = await window.gapi.auth2.getAuthInstance();
    // await auth.signOut()
    googleSignOut()
    navigate("/login");
  }


  return (
    <Box width={boxWidth} px={7}>
      {!availSignedIn ? (<>
        <Link to=".." >  <Heading size="2xl" justifyContent="center"
          style={{ color: "#30B8C3" }}
        >infoBee  </Heading> </Link>
        <Box px={6} style={{ borderBottom: "solid 2px", paddingBottom: "1rem" }}>
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>

            <HStack spacing={8} alignItems={'center'}>
              <HStack as={'nav'} spacing={4} >
                <Link px={2} py={1} rounded={'md'} _hover={{
                  textDecoration: 'none', bg: bg,
                }} to="/login">
                  <Text fontSize={["md", "xl", "2xl"]} >Login |</Text></Link>
                <Link to="/register">
                  <Text fontSize={["md", "xl", "2xl"]}  >Register | </Text></Link>
                <Link to="/contact"     >
                  <Text fontSize={["md", "xl", "2xl"]}  >Contact Us! </Text></Link>
              </HStack>
            </HStack>
          </Flex>
        </Box>

        <Outlet />

      </>) :
        (<>
          {showNav ? (
            <VStack
              style={{ borderBottom: "solid 2px", paddingBottom: "2rem", margin: 5 }}>
              <Link to=".." >  <Heading size="2xl" justifyContent="center" marginBottom={35}
                style={{ color: "#30B8C3" }}
              >infoBee  </Heading> </Link>
              <Flex h={12} alignItems={'center'} justifyContent={'center'}>

                <HStack spacing={6} alignItems={'center'}>
                  {availableUser ? (<>
                    {location.pathname !== '/dashboard' ? (
                      <Avatar size={avatarSize} src={availableUser.avatar} mb={2}
                      />
                    ) : null}
                  </>
                  ) : null}



                  <HStack as={'nav'} spacing={12} display={{ base: 'none', md: 'flex' }}>
                    <Link to="/dashboard"
                      _selected={{ color: 'white', bg: ibtColors.ibtbasic }}>
                      <Text fontSize={["md", "xl", "2xl"]}>Dashboard</Text></Link>
                    <Link to="/itemfast">
                      <Text fontSize={["md", "xl", "2xl"]} >New Item </Text></Link>
                    <Link to="/inventory">
                      <Text fontSize={["md", "xl", "2xl"]}  >Inventory </Text></Link>

                    <Menu>
                      <MenuButton>
                        <Text fontSize={["md", "xl", "2xl"]}  >Settings </Text>
                      </MenuButton>


                      <MenuList >


                        <MenuItem>
                          <Text fontSize={["md", "xl", "2xl"]} onClick={() => navigate('/profile')}>Update Profile</Text>
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem>
                          <Text fontSize={["md", "xl", "2xl"]} onClick={() => navigate('/download')}>Download Items</Text>
                        </MenuItem>
                        <MenuDivider />
                        <MenuItem>
                          <Text fontSize={["md", "xl", "2xl"]} onClick={() => navigate('/help')}>Help</Text>

                        </MenuItem>
                        < MenuDivider />
                        <MenuItem>
                          <Text fontSize={["md", "xl", "2xl"]} onClick={() => navigate('/contact')}>Contact Us!</Text>

                        </MenuItem>
                        < MenuDivider />
                        <MenuItem marin={2}>
                          <Text fontSize={["md", "xl", "2xl"]} onClick={
                            () => {
                              toggleColorMode()
                              onClose()
                            }} >
                            <IconButton
                              icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                              variant="ghost"
                            />
                            Toggle Theme</Text>

                        </MenuItem>

                      </MenuList>
                    </Menu>


                    <Button
                      onClick={onSignOutClick}
                      variant={'solid'} colorScheme={"gray"} marginLeft={15}
                      size={navSize} mr={4}
                      leftIcon={<ArrowRightIcon />}>
                      <Text fontSize={["md", "xl", "2xl"]}>Logout</Text>
                    </Button>
                  </HStack>



                </HStack>

              </Flex>
            </VStack>
          ) : (<>

            <Box h={16} style={{ alignItems: 'center', justifyContent: 'center' }}>

              <HStack spacing={15} alignItems='center' justifyContent='center' >

                {availableUser ? (<>
                  {location.pathname !== '/dashboard' ? (
                    <Avatar size={avatarSize} src={availableUser.avatar} mb={2} ml={15}
                    />
                  ) : <Text ml={15} ></Text>}
                </>
                ) : null}

                <Link to=".." >  <Heading size="2xl" justifyContent="center" margin={35}
                  style={{ color: "#30B8C3" }}
                  display={{ base: 'flex', lg: 'none' }}>infoBee  </Heading> </Link>
                <IconButton size={'lg'} color="white" ml={10} borderColor="#30B8C3" borderWidth={3} color="#30B8C3"
                  // maginLeft={6}
                  icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                  aria-label={'Open Menu'}
                  display={{ lg: 'none' }}
                  onClick={isOpen ? onClose : onOpen}
                />

              </HStack>
            </Box>
            {isOpen ? (
              <Box pb={4} pt={1} display={{ md: 'none' }}>
                <Stack as={'nav'} spacing={4}>
                  <Link to="/itemfast" onClick={onClose}>
                    <Text fontSize={textSize}  >Add Item  </Text></Link>
                  <Link to="/dashboard" onClick={onClose}
                    _selected={{ color: 'white', bg: ibtColors.ibtbasic }}>
                    <Text fontSize={textSize}>Dashboard</Text></Link>
                  <Link to="/itemId" onClick={onClose}>   <Text fontSize={textSize}>Inventory</Text></Link>
                  <Menu>
                    <MenuButton>
                      <Text fontSize={textSize}>Settings</Text>
                    </MenuButton>
                    <MenuList >
                      <MenuItem>
                        <Text fontSize={textSize} onClick={() => {
                          onClose()
                          navigate('/profile')
                        }}>Update Profile</Text>
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem>
                        <Text fontSize={textSize} size="md" onClick={() => {
                          onClose()
                          navigate('/download')
                        }}>Download Items</Text>
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem>
                        <Text fontSize={textSize} size="md" onClick={() => {
                          navigate('/help')
                          onClose()
                        }}>Help</Text>
                      </MenuItem>
                      < MenuDivider />
                      <MenuItem marin={2}>
                        <Text fontSize={textSize} size="md" onClick={
                          () => {
                            toggleColorMode()
                            onClose()
                          }} >
                          <IconButton
                            icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                            variant="ghost"
                          />
                          Toggle Theme</Text>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <Text onClick={onClose} fontSize={textSize}>Close Menu</Text>
                  <Button
                    onClick={onSignOutClick}
                    variant={'solid'} colorScheme="gray" marginLeft={15}
                    size={'lg'} mr={4}
                    leftIcon={<ArrowRightIcon />}>
                    <Text fontSize={textSize}>Logout</Text>
                  </Button>

                </Stack>
              </Box>

            ) : null}
          </>

          )}

          <Outlet />

        </>)}

    </Box >


  );
};

export default connect(null, { googleSignOut })(Navbar);

/*

  <Link to="/switchlist">
                      <Text fontSize={textSize}  >Messages </Text></Link>
 <Tooltip hasArrow placement='top-start' label='Search places' bg='red.600'>
  <Button>Button</Button>
</Tooltip>
*/