
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_UPDATE = "USER_UPDATE";
export const USER_ERROR = "USER_ERROR";
//export const GOOGLE_SUCCESS = "GOOGLE_SUCCESS";
export const ENTITY_SUCCESS = "ENTITY_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
//export const SWITCH_ACCOUNT = "SWITCH_ACCOUNT";
export const SWITCH_SUCCESS = "SWITCH_SUCCESS";
export const SWITCH_LIST = "SWITCH_LIST";
export const SWITCH_CLEAR = "SWITCH_CLEAR";
export const SWITCH_UPDATE = "SWITCH_UPDATE";
export const EMAIL_SUCCESS = "EMAIL_SUCCESS";
export const PDF_SUCCESS = "PDF_SUCCESS";
export const RESET_ERROR = "RESET_ERROR";
//export const SET_ALERT = "SET_ALERT";
//export const REMOVE_ALERT = "REMOVE_ALERT";
