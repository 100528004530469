import React from "react";

import {
  SimpleGrid,
  GridItem,
  Box,
  Button,
  ButtonGroup,
  CSSReset,
  Heading,
  ThemeProvider,
  theme,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
} from '@chakra-ui/react';

import { Form, useField, } from 'react-final-form'
import validate from './validateEmail'


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))



const EmailForm = (props) => {

  const onEmailSubmit = async values => {
    //console.log('in Email onSubmit' )
    await props.onSubmit(values);
    await sleep(300)
    //window.alert('resetting in Progress ')
  }
  const onCancel = async (values) => {
    values.emailResponse = "CANCEL";
    //window.alert("cancelled");
    await props.onSubmit(values);
    await sleep(300)
  }
  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Box w={800} p={4} m="20px auto">
        <Heading as="h1" size="xl" textAlign="center">
          infoBee Password Form
        </Heading>

        <Form
          onSubmit={onEmailSubmit}
          validate={validate}
          initialValues={{
            emailResponse: 'INITIAL',
          }}

          render={({
            handleSubmit,
            form,
            errors,
            subscription = { submitting: true },
            submitting,
            pristine,
            values
          }) => (
            <>

              <Box
                as="form"
                p={4}
                borderWidth="3px"
                rounded="lg"
                shadow="1px 1px 3px rgba(0,0,0,0.3)"
                onSubmit={handleSubmit}
              >
                <SimpleGrid columns={2} columnGap={3} rowGap={6} width="full">
                  <GridItem colSpan={2}>
                    <InputControl name="email" label="Email" maxLength={60} />
                  </GridItem>
                </SimpleGrid>

                <br />

                <ButtonGroup>
                  <Button
                    onClick={() =>
                      onCancel(values)
                    }
                    type="button"
                  >
                    Cancel
                  </Button>

                  <Button
                    isLoading={submitting}
                    loadingText="Submitting"
                    //colorScheme='#DBC14B'
                    colorScheme={"teal"}
                    size="lg"
                    // variantcolor="teal"
                    type="submit"
                  >
                    Submit
                  </Button>

                </ButtonGroup>
                <br />


              </Box>
            </>
          )}
        />
      </Box>
    </ThemeProvider>
  )
}


const Control = ({ name, ...rest }) => {
  const {
    meta: { error, touched }
  } = useField(name, { subscription: { touched: true, error: true } })
  return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
  const {
    meta: { error }
  } = useField(name, { subscription: { error: true } })
  return <FormErrorMessage>{error}</FormErrorMessage>
}

const InputControl = ({ name, label, type, minLength, maxLength, pattern }) => {
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={4}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        {...input}
        isInvalid={meta.error && meta.touched}
        id={name}
        placeholder={label}
        type={type}
        minLength={minLength}
        maxLength={maxLength}
        pattern={pattern}
      />
      <Error name={name} />
    </Control>
  )
}



export default EmailForm;

/*

*/