import React, { useState } from "react";

import {
    Text,
    SimpleGrid,
    GridItem,
    Box,
    Button,
    CSSReset,
    Heading,
    ThemeProvider,
    theme,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    useBreakpointValue,
    useColorModeValue,
} from '@chakra-ui/react';

import { Form, useField, Field } from 'react-final-form'
import validate from './validateContact'
//import RenderCount from '../common/RenderCount'

import Styles from './StylesForms'
import StylesDark from './StylesDark'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const ContactForm = (props) => {

    const colSpan = useBreakpointValue({ base: 2, md: 1 })
    const formSpan = useBreakpointValue({ base: 1, md: 2 })
    const boxWidth = useBreakpointValue({ base: 350, md: 800 })
    const bg = useColorModeValue('blue.500', 'blue.200')
    const colorLight = useColorModeValue(true, false)
    const [remove, setRemove] = useState(false)

    const onSubmit = async values => {
        await props.onSubmit(values);
        await sleep(300)
        // window.alert('Registrating in Progress - please check your email')
    }

    // console.log(colorTheme)
    const onRemove = async (values) => {
        setRemove(!remove)
        // values.emailResponse = "REMOVE";
        //window.alert("cancelled");
    }

    return (
        <ThemeProvider theme={theme}>
            <CSSReset />
            <Box w={boxWidth} p={4} m="20px auto">
                <Heading as="h1" size="xl" textAlign="center">
                    Contact Us!
                </Heading>
                <Form
                    onSubmit={onSubmit}
                    validate={validate}
                    render={({
                        handleSubmit,
                        form,
                        errors,
                        subscription = { submitting: true },
                        submitting,
                        pristine,
                        values
                    }) => (
                        <><Box as="form" p={4} borderWidth="3px" rounded="lg"
                            shadow="1px 1px 3px rgba(0,0,0,0.3)"
                            onSubmit={handleSubmit}  >
                            <SimpleGrid columns={2} columnGap={3} rowGap={6} width="full">

                                <GridItem colSpan={2}>
                                    <InputControl name="email" type="email" label="Email" maxLength={60} />
                                </GridItem>
                                <GridItem colSpan={2}>
                                    <InputControl name="last" label="Name" maxLength={15} />
                                </GridItem>
                                <GridItem colSpan={2}>
                                    <Text>Leave us a message </Text>
                                    {colorLight ? (<Styles>
                                        <div style={{
                                            backgoundColor: "red",
                                            flex: 1,
                                            padding: 3,
                                            //  fontSize: 1 ,
                                            marginLeft: 15,
                                            borderColor: "teal",
                                            borderRadius: 3,
                                        }} >
                                            <Field name="message" component="textarea" minWidth="full"
                                                placeholder="Message  " maxLength={400}
                                                style={{
                                                    backgoundColor: "red",
                                                    flex: 1,
                                                    padding: 3,
                                                    //  fontSize: 1 ,
                                                    marginLeft: 15,
                                                    borderColor: "grey.100",
                                                    borderRadius: 3,
                                                }}
                                            />
                                        </div>

                                    </Styles>)
                                        : (<StylesDark>
                                            <div style={{
                                                backgoundColor: "red",
                                                flex: 1,
                                                padding: 3,
                                                //  fontSize: 1 ,
                                                marginLeft: 15,
                                                border: "teal",
                                                borderRadius: 3,
                                            }} >
                                                <Field name="message" component="textarea" minWidth="full"
                                                    placeholder="Message  " maxLength={400}
                                                    style={{
                                                        backgoundColor: "red",
                                                        flex: 1,
                                                        padding: 3,
                                                        //  fontSize: 1 ,
                                                        marginLeft: 15,
                                                        borderColor: "red",
                                                        borderRadius: 3,
                                                    }}
                                                />
                                            </div>

                                        </StylesDark>)}

                                </GridItem>

                                <GridItem colSpan={2}>
                                    {!remove ? <>
                                        <Button
                                            onClick={() =>
                                                onRemove(values)
                                            }
                                            colorScheme={"teal"}
                                            type="button"
                                        >
                                            Remove Account
                                        </Button></> : null}
                                    {remove ? <>
                                        <Button
                                            onClick={() =>
                                                onRemove(values)
                                            }
                                            colorScheme={"teal"}
                                            type="button"
                                        >
                                            Keep Account
                                        </Button>
                                        <Text>To request we delete all data from your account, confirm by entering a delete no sooner than date. </Text>
                                        <InputControl name="removeDate" label="Remove Account Date" maxLength={15} /></> : null}

                                </GridItem>


                            </SimpleGrid>


                            <br />
                            <Button
                                isLoading={submitting}
                                loadingText="Submitting"
                                colorScheme={"teal"}
                                size="lg"
                                type="submit"
                            >
                                Submit
                            </Button>
                            <br />
                        </Box>
                        </>
                    )}
                />
            </Box>
        </ThemeProvider>
    )
}


const Control = ({ name, ...rest }) => {
    const {
        meta: { error, touched }
    } = useField(name, { subscription: { touched: true, error: true } })
    return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
    const {
        meta: { error }
    } = useField(name, { subscription: { error: true } })
    return <FormErrorMessage>{error}</FormErrorMessage>
}

const InputControl = ({ name, label, type, minLength, maxLength, pattern }) => {
    const { input, meta } = useField(name)
    return (
        <Control name={name} my={4}>

            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Input
                {...input}
                isInvalid={meta.error && meta.touched}
                id={name}
                placeholder={label}
                type={type}
                minLength={minLength}
                maxLength={maxLength}
                pattern={pattern}
            />
            <Error name={name} />
        </Control>
    )
}

export default ContactForm;

/*

*/