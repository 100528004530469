import React from "react";

import { useSelector } from "react-redux";

import {
    SimpleGrid,
    GridItem,
    Box,
    Button,
    ButtonGroup,
    CSSReset,
    ThemeProvider,
    theme,
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    useBreakpointValue,
} from '@chakra-ui/react';

import { Form, useField, } from 'react-final-form'
import validate from './validateProxy'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const ProxyForm = ({ onSubmit, status }) => {

    const availableEntity = useSelector((state) => state.entity.entity);
    const availableUser = useSelector((state) => state.user.user);

    const colSpan = useBreakpointValue({ base: 2, md: 1 })
    const formSpan = useBreakpointValue({ base: 1, md: 2 })
    const boxWidth = useBreakpointValue({ base: 350, md: 650 })

    const onFormSubmit = async values => {
        values.proxy = `${values.proxyFirst} ${values.proxyLast}`
      //  console.log('in Proxyi onSubmit' + (JSON.stringify(values, 0, 2)))
        await onSubmit(values);
        await sleep(300)
    }

    return (
        <ThemeProvider theme={theme}>
            <CSSReset />
            <Box w={boxWidth} p={4} m="20px auto">


                <Form
                    onSubmit={onFormSubmit}
                    //  validate={validate}
                    initialValues={{
                        id: availableUser._id,
                        proxy: availableEntity.proxy,
                        proxyFirst: availableEntity.proxyFirst,
                        proxyLast: availableEntity.proxyLast,
                        xrefuser: availableEntity.xrefuser,
                        notes: availableEntity.notes,
                        method: availableEntity.xrefuser,
                        accountName: availableUser.name,
                        accountEmail: availableUser.email,
                        proxyResponse: 'INITIAL'
                    }}

                    render={({
                        handleSubmit,
                        form,
                        errors,
                        subscription = { submitting: true },
                        submitting,
                        pristine,
                        values,

                    }) => (
                        <>

                            <Box
                                as="form"
                                p={4}
                                borderWidth="3px"
                                rounded="lg"
                                shadow="1px 1px 3px rgba(0,0,0,0.3)"
                                onSubmit={handleSubmit}
                            >
                                <SimpleGrid columns={2} columnGap={3} rowGap={6} width="full">
                                    <GridItem colSpan={colSpan}>
                                        <InputControl name="proxyFirst" label="First Name" maxLength={15} />
                                    </GridItem>
                                    <GridItem colSpan={colSpan}>
                                        <InputControl name="proxyLast" label="Last Name" maxLength={30} />
                                    </GridItem>



                                </SimpleGrid>
                                <br />
                                <ButtonGroup>
                                    <Button
                                        isLoading={submitting}
                                        onClick={() => {
                                            //  values.userFirst = 'cancel';
                                            //  values.userLast = 'cancel';
                                            //  console.log('multiple lines');
                                            values.proxyResponse = "CANCEL";
                                        }

                                        }
                                        loadingText="Cancelling"

                                        type="cancel"
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        isLoading={submitting}
                                        loadingText="Submitting"
                                        colorScheme="teal"
                                        disabled={submitting}
                                        type="submit"
                                    >
                                        Update
                                    </Button>

                                </ButtonGroup>




                            </Box>
                        </>
                    )}
                />
            </Box>
        </ThemeProvider>
    )
}


const Control = ({ name, ...rest }) => {
    const {
        meta: { error, touched }
    } = useField(name, { subscription: { touched: true, error: true } })
    return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
    const {
        meta: { error }
    } = useField(name, { subscription: { error: true } })
    return <FormErrorMessage>{error}</FormErrorMessage>
}

const InputControl = ({ name, label, type, maxLength }) => {
    const { input, meta } = useField(name)
    return (
        <Control name={name} my={1}>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            <Input
                {...input}
                isInvalid={meta.error && meta.touched}
                id={name}
                placeholder={label}
                type={type}
                maxLength={maxLength}
            />
            <Error name={name} />
        </Control>
    )
}



export default ProxyForm;

/*

*/