import { combineReducers } from "redux";
import entity from "./entityReducer";
import user from "./userReducer";
import switchUser from "./switchReducer";

export default combineReducers({
  //alert,
  user,
  entity,
  switchUser,
});