import {
  ENTITY_SUCCESS,
  PDF_SUCCESS,
  LOGOUT,
} from "../actions/types";

var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear() + 1;

today = yyyy + '-' + mm + '-' + dd;

const initialState = {
  entity: null,
  errorMessage: "",
  records: [],
  pdfData: null,
  defaultReviewDate: today,
};

export default function (state = initialState, action) {
  console.log("REDUCER ENTITY -- " + action.type);

  const { type, payload } = action;
  switch (type) {
    case ENTITY_SUCCESS:
      return { ...state, entity: payload, errorMessage: "" };
    case PDF_SUCCESS:
      return { ...state, pdfData: payload, errorMessage: "" };
    
    case LOGOUT:
      return {
        ...state,
        entity: null,
        records: [],
        errorMessage: "",
        pdfData: null,
      };
    default:
      return state;
  }
}