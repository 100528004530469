//import logo from './logo.svg';
import React from "react";
import './App.css';

import {
  Routes,
  Route,
  Link,
} from "react-router-dom";


import {
  Box,
  Heading,
  Container,
  Flex,
  VStack,
  // Grid,
  useColorModeValue,
} from '@chakra-ui/react';


//import { useSelector } from "react-redux";

import HomePage from "./components/dashboard/HomePage"
import NavBar from './components/dashboard/Navbar'

import Login from "./components/dashboard/Login"
import Register from "./components/dashboard/Register"
import Contact from "./components/dashboard/Contact"
import HelpPage from "./components/dashboard/HelpPage"
import TermsConditions from "./components/common/TermsConditions";
import PrivacyPolicy from "./components/common/PrivacyPolicy";
import OneTimePasscode from "./components/dashboard/OneTimePasscode"


import Dashboard from "./components/dashboard/Dashboard"
import Profile from "./components/dashboard/Profile"
//import Help from "./components/dashboard/Help"
import DownloadItems from "./components/dashboard/DownloadItems"

import Inventory from "./components/items/Inventory";
import ItemList from "./components/items/lists/ItemList";
import ItemDetail from "./components/items/ItemDetail";
import PDFDisplay from "./components/common/pdf/PDFDisplay"

import ItemFast from "./components/items/ItemFast"


import GoneList from "./components/items/lists/GoneList";
import PetsList from "./components/items/lists/PetsList";

import HandyList from "./components/items/lists/HandyList";
import HouseList from "./components/items/lists/HouseList";
import HealthList from "./components/items/lists/HealthList";
import PaperList from "./components/items/lists/PaperList";
import MoneyList from "./components/items/lists/MoneyList";
import MemberList from "./components/items/lists/MemberList";
import TechnoList from "./components/items/lists/TechnoList";
import EventList from "./components/items/lists/EventList";
import SocialList from "./components/items/lists/SocialList";
import ContactList from "./components/items/lists/ContactList";
import BusyList from "./components/items/lists/BusyList";
import CareerList from "./components/items/lists/CareerList";
import MemoirList from "./components/items/lists/MemoirList";
import FramedList from "./components/items/lists/FramedList";

import FlaggedList from "./components/items/lists/FlaggedList";


import SwitchDetail from "./components/switch/SwitchDetail";
import FlagList from "./components/switch/FlagList";
//import SwitchList from "./components/switch/SwitchList";
import Comments from "./components/switch/Comments";


function App() {
  const bgColor = useColorModeValue('#F7FAFC', 'whiteAlpha.100')
  const secondaryTextColor = useColorModeValue('gray.600', 'gray.400')


  return (
    <Container maxWidth="container.xl" padding={0}>
      <Flex
        h={{ base: 'auto', md: "300vh" }} py={[0, 10, 20]} direction={{ base: 'column-reverse', md: "row" }}>
        <VStack w="full" h="full" bg={bgColor}
          p={5} spacing={10} alignItems="">
          <Box textAlign="center" fontSize="xl">

            <NavBar />
            <Routes>
              <Route path="/" element={<HomePage />} />

              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="contact" element={<Contact />} />
              <Route path="help" element={<HelpPage />} />


              <Route path="termsconditions" element={<TermsConditions />} />
              <Route path="privacypolicy" element={<PrivacyPolicy />} />

              <Route path="onetimepasscode/:email" element={<OneTimePasscode />} />

              <Route path=":itemId" element={<ItemDetail />}> </Route>
              <Route path="itemlist" element={<ItemList />} />



              <Route path="dashboard" element={<Dashboard />} />

              <Route path="itemfast" element={<ItemFast />} />
              <Route path="download" element={<DownloadItems />} />
              <Route path="pdfdisplay" element={<PDFDisplay />} />
              <Route path="profile" element={<Profile />} />
              <Route path="switchItem/:accountId/:switchItemId" element={<SwitchDetail />}>
              </Route>
              <Route path="flaglist" element={<FlagList />} >
                <Route path=":accountId" element={<Comments />} />

              </Route>





              <Route path="inventory" element={<Inventory />} >
                <Route index element={<main style={{ padding: "1rem" }}>
                  <p>Select a Classification</p> </main>} />
                <Route path="itemlist" element={<ItemList />} >
                  <Route index element={<main style={{ padding: "1rem" }}>
                  </main>} />
                  <Route path=":itemId" element={<ItemDetail />}> </Route>


                </Route>

                <Route path="flaggedlist" element={<FlaggedList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>
                </Route>


                <Route path="gonelist" element={<GoneList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>

                </Route>
                <Route path="petslist" element={<PetsList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>

                </Route>
                <Route path="handylist" element={<HandyList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>
                </Route>
                <Route path="houselist" element={<HouseList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>
                </Route>
                <Route path="healthlist" element={<HealthList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>
                </Route>
                <Route path="paperlist" element={<PaperList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>
                </Route>
                <Route path="moneylist" element={<MoneyList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>
                </Route>
                <Route path="memberlist" element={<MemberList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>
                </Route>
                <Route path="technolist" element={<TechnoList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>
                </Route>
                <Route path="eventlist" element={<EventList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>
                </Route>
                <Route path="sociallist" element={<SocialList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>
                </Route>
                <Route path="contactslist" element={<ContactList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>
                </Route>
                <Route path="busylist" element={<BusyList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>
                </Route>
                <Route path="careerlist" element={<CareerList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>
                </Route>
                <Route path="memoirlist" element={<MemoirList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>
                </Route>
                <Route path="framedlist" element={<FramedList />} >
                  <Route path=":itemId" element={<ItemDetail />}> </Route>
                </Route>



              </Route>

              <Route index element={


                <main style={{ padding: "1rem" }}>

                  <HomePage />
                </main>} />

              <Route path="flaglist/:accountId" element={<FlagList />} >
                <Route index element={<main style={{ padding: "1rem" }}>
                  <h4>Select an item</h4> </main>} />
                <Route path=":switchItemId" element={<SwitchDetail />}>
                </Route>
              </Route >



            </Routes>
          </Box>
        </VStack>
      </Flex>
    </Container>
  );
}

export default App;

function PublicPage() {
  return <h3>Public</h3>;
}

/*
 
           
          


   


              

 

*/