export const ibepetsOptions = [
  { label: "Name", },
  { label: "Description" },
  { label: "Age" },
  { label: "Gender" },
  { label: "Pedigree" },
  { label: "Vaccination" },
  { label: "Vet Records" },
  { label: "Dry Feed" },
  { label: "Wet Food" },
  { label: "Special Care" },
  { label: "Instructions" },
  { label: "Other" },
];


export const ibegoneOptions = [
  { label: "Wished To Name", },
  { label: "Contact" },
  { label: "Wish" },
  { label: "Celebration List" },
  { label: "Instructions" },
  { label: "Other" },
];

export const ibehandyOptions = [
  { label: "Name", },
  { label: "Description" },
  { label: "Age" },
  { label: "Gender" },
  { label: "Pedigree" },
  { label: "Vaccination" },
  { label: "Vet Records" },
  { label: "Dry Feed" },
  { label: "Wet Food" },
  { label: "Special Care" },
  { label: "Instructions" },
  { label: "Other" },
];
export const ibehouseOptions = [
  { label: "Name", },
  { label: "Description" },
  { label: "Age" },
  { label: "Gender" },
  { label: "Pedigree" },
  { label: "Vaccination" },
  { label: "Vet Records" },
  { label: "Dry Feed" },
  { label: "Wet Food" },
  { label: "Special Care" },
  { label: "Instructions" },
  { label: "Other" },
];
export const ibehealthOptions = [
  { label: "Name", },
  { label: "Description" },
  { label: "Age" },
  { label: "Gender" },
  { label: "Pedigree" },
  { label: "Vaccination" },
  { label: "Vet Records" },
  { label: "Dry Feed" },
  { label: "Wet Food" },
  { label: "Special Care" },
  { label: "Instructions" },
  { label: "Other" },
];
export const ibepaperOptions = [
  { label: "Name", },
  { label: "Description" },
  { label: "Age" },
  { label: "Gender" },
  { label: "Pedigree" },
  { label: "Vaccination" },
  { label: "Vet Records" },
  { label: "Dry Feed" },
  { label: "Wet Food" },
  { label: "Special Care" },
  { label: "Instructions" },
  { label: "Other" },
];
export const ibemoneyOptions = [
  { label: "Replacement Value", },
  { label: "Budget - Monthly" },
  { label: "Budget - Annual" },
  { label: "Asset Value" },
  { label: "Pedigree" },
  { label: "Vaccination" },
  { label: "Vet Records" },
  { label: "Dry Feed" },
  { label: "Wet Food" },
  { label: "Special Care" },
  { label: "Instructions" },
  { label: "Other" },
];
export const ibememberOptions = [
  { label: "Name", },
  { label: "Description" },
  { label: "Age" },
  { label: "Gender" },
  { label: "Pedigree" },
  { label: "Vaccination" },
  { label: "Vet Records" },
  { label: "Dry Feed" },
  { label: "Wet Food" },
  { label: "Special Care" },
  { label: "Instructions" },
  { label: "Other" },
];
export const ibetechnoOptions = [
  { label: "Name", },
  { label: "Description" },
  { label: "Age" },
  { label: "Gender" },
  { label: "Pedigree" },
  { label: "Vaccination" },
  { label: "Vet Records" },
  { label: "Dry Feed" },
  { label: "Wet Food" },
  { label: "Special Care" },
  { label: "Instructions" },
  { label: "Other" },
];
export const ibeeventOptions = [
  { label: "Name", },
  { label: "Description" },
  { label: "Age" },
  { label: "Gender" },
  { label: "Pedigree" },
  { label: "Vaccination" },
  { label: "Vet Records" },
  { label: "Dry Feed" },
  { label: "Wet Food" },
  { label: "Special Care" },
  { label: "Instructions" },
  { label: "Other" },
];
export const ibesocialOptions = [
  { label: "Name", },
  { label: "Description" },
  { label: "Age" },
  { label: "Gender" },
  { label: "Pedigree" },
  { label: "Vaccination" },
  { label: "Vet Records" },
  { label: "Dry Feed" },
  { label: "Wet Food" },
  { label: "Special Care" },
  { label: "Instructions" },
  { label: "Other" },
];
export const ibecontactsOptions = [
  { label: "Name", },
  { label: "Description" },
  { label: "Phone - Business" },
  { label: "Phone - Personal" },
  { label: "Website" },
  { label: "Name & Phone" },
  { label: "Name & Desc" },
  { label: "Dry Feed" },
  { label: "Wet Food" },
  { label: "Special Care" },
  { label: "Instructions" },
  { label: "Other" },
];
export const ibebusyOptions = [
  { label: "Name", },
  { label: "Description" },
  { label: "Age" },
  { label: "Gender" },
  { label: "Pedigree" },
  { label: "Vaccination" },
  { label: "Vet Records" },
  { label: "Dry Feed" },
  { label: "Wet Food" },
  { label: "Special Care" },
  { label: "Instructions" },
  { label: "Other" },
];
export const ibecareerOptions = [
  { label: "Name", },
  { label: "Description" },
  { label: "Age" },
  { label: "Gender" },
  { label: "Pedigree" },
  { label: "Vaccination" },
  { label: "Vet Records" },
  { label: "Dry Feed" },
  { label: "Wet Food" },
  { label: "Special Care" },
  { label: "Instructions" },
  { label: "Other" },
];
export const ibememoirOptions = [
  { label: "Name", },
  { label: "Description" },
  { label: "Date" },
  { label: "Location" },
  { label: "Pedigree" },
  { label: "Vaccination" },
  { label: "Vet Records" },
  { label: "Dry Feed" },
  { label: "Wet Food" },
  { label: "Special Care" },
  { label: "Instructions" },
  { label: "Other" },
];
export const ibeframedOptions = [
  { label: "Name", },
  { label: "Description" },
  { label: "Age" },
  { label: "Gender" },
  { label: "Pedigree" },
  { label: "Vaccination" },
  { label: "Vet Records" },
  { label: "Dry Feed" },
  { label: "Wet Food" },
  { label: "Special Care" },
  { label: "Instructions" },
  { label: "Other" },
];