import React, { useEffect, useState, useRef } from 'react'
import { useSelector, connect } from "react-redux";

import {
  useNavigate,
  NavLink,
} from "react-router-dom";

import {
  Heading,
  Box,
  Text,
  //Checkbox,
  //Link,
  VStack,
  Button,
  ButtonGroup,
  IconButton,
  HStack,
  //Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Stack,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useBreakpointValue,
  //useToast,
} from '@chakra-ui/react';

import {
  StarIcon, CloseIcon,
  ChevronDownIcon, AddIcon,
} from '@chakra-ui/icons'

import ibtColors from "../common/ibtColors";


//import ImageUpload from '../items/ImageUpload'
import ItemRecordForm from '../forms/ItemRecordForm'
import ItemCommentForm from '../forms/ItemCommentForm'
import ProxyForm from '../forms/ProxyForm'
import ProxyNewForm from '../forms/ProxyNewForm'
import ProfileForm from '../forms/ProfileForm'
import PasswordForm from '../forms/PasswordForm'


import { refreshUser, profile, userpw } from "../../actions/userAction"

import { editProxy } from "../../actions/entityAction"

import { deleteCurrentItem, editNoImage, uploadDocument, pdfDocument } from "../../actions/itemAction"
//import { set, values } from 'lodash';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))




const Profile = ({ refreshUser, profile, userpw, editProxy, deleteCurrentItem, editNoImage, }, uploadDocument) => {
  //const [availableItems, setAvailableItems] = useState(" ");
  const [showProfile, setShowProfile] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showProxy, setShowProxy] = useState(false);
  const [showNewProxy, setShowNewProxy] = useState(false);

  //const [createPickList, setCreatePickList] = useState([])
  //onst [createClassList, setCreateClassList] = useState([])
  //const [showPickList, setShowPickList] = useState(false);

  //const [isUploading, setIsUploading] = useState(false);
  //const [attachment, setAttachment] = useState(null);
  //const [attachSelected, setAttachSelected] = useState(null);
  //const [showAttachment, setShowAttachment] = useState(false);

  // const [selectedRecord, setSelectedRecord] = useState(null);
  //const [showSelectedRecord, setShowSelectedRecord] = useState(false);


  //const [selectedComment, setSelectedComment] = useState(null);
  //const [showSelectedComment, setShowSelectedComment] = useState(false);

  // const [newThing, setNewThing] = useState(null);
  const [finalThing, setFinalThing] = useState(false);

  const [isOpen, setIsOpen] = useState(false)

  const onClose = () => setIsOpen(false)
  const cancelRef = useRef()


  const onProfileClose = () => setIsProfileOpen(false)
  const cancelProfileRef = useRef()
  const [isProfileOpen, setIsProfileOpen] = useState(false)


  const [isThingOpen, setIsThingOpen] = useState(false)


  const availableUser = useSelector((state) => state.user.user);

  //  const availableToken = useSelector((state) => state.user.token)
  // const availableEmail = useSelector((state) => state.user.googleEmail)
  const availableEntity = useSelector((state) => state.entity.entity);

  const availableToken = useSelector((state) => state.user.token)
  const availableEmail = useSelector((state) => state.user.googleEmail)
  //const availMessage = useSelector((state) => state.user.errorMessage);


  useEffect(() => {
    const refresh = async () => {
      await refreshUser(availableEmail)
    };

    if (availableToken && !availableUser) {
      refresh();
    }

  }, []);

  //use this for deleting the item
  const onThingClose = () => {
    setFinalThing(true)
    setIsThingOpen(false)

  }
  //const cancelThingRef =  useRef()

  let navigate = useNavigate();
  //let params = useParams();
  //let location = useLocation();
  //const toast = useToast();

  const colSpan = useBreakpointValue({ base: 2, md: 1 })
  const formSpan = useBreakpointValue({ base: 1, md: 2 })
  const boxWidth = useBreakpointValue({ base: 350, md: 1000 })


  const onProfileSubmit = async (values) => {

    await sleep(300)
    setShowProfile(!showProfile)

    // console.log(JSON.stringify(values, 0, 2))
    if (values.profileResponse !== 'CANCEL') {
      await profile(values, availableUser.email, (props) => {
        navigate(`/profile`)
      })
    }

  }


  const onPasswordSubmit = async (values) => {

    await sleep(300)
    setShowPassword(!showPassword)

    // console.log(JSON.stringify(values, 0, 2))
    if (values.profileResponse !== 'CANCEL') {
      await userpw(values, availableUser.email, (props) => {
        navigate(`/profile`)
      })
    }

  }


  const onProxySubmit = async (values) => {

    await sleep(300)
    setShowProxy(false)
    setShowNewProxy(false)

    // console.log(JSON.stringify(values, 0, 2))

    if (values.proxyResponse !== 'CANCEL') {
      await editProxy(values, availableUser.email, (props) => {
        navigate(`/profile`)
      })
    }



  }



  const renderProfile = () => {
    return (
      <>
        <ProfileForm onSubmit={onProfileSubmit}
        />
      </>
    )
  }


  const renderPassword = () => {
    return (
      <>
        <PasswordForm onSubmit={onPasswordSubmit}
        />
      </>
    )
  }


  const renderProxy = (status) => {
    return (
      <>
        <ProxyForm onSubmit={onProxySubmit} status={status}
        />
      </>
    )
  }


  const renderNewProxy = (status) => {
    return (
      <>
        <ProxyNewForm onSubmit={onProxySubmit} status={status}
        />
      </>
    )
  }



  const deleteHandler = async (id) => {
    //  console.log('dlete' + id)
    await deleteCurrentItem(availableUser._id, id, () => {
      navigate("/inventory/itemlist")
    })

  };



  return (
    <>
      <Box borderWidth='1px' borderRadius='lg' overflow='hidden'>
        {availableUser ? (
          <>

            {availableUser.avatar ? (<img className="ui small bordered left floated image"
              alt="alt" src={availableUser.avatar} />) : null}
            <VStack spacing={3}  >
              <Heading size="md">Details</Heading>

              <Text fontSize="3xl">{availableUser.name}</Text>
              <br />

              <Text >Status: {availableUser.status}</Text>

              <Text >Product: {availableUser.appleProduct}</Text>
              <Text>Renew Date: {availableUser.appleDate}</Text>


              <ButtonGroup variant='outline' spacing='6'>
                <Stack direction={['column', 'row']} spacing='24px'>
                  <Button onClick={() => setShowPassword(true)
                  } colorScheme="black">Change Password</Button>

                  <Button onClick={() => setShowProfile(true)
                  } colorScheme="black">Update Name or Avatar</Button>
                </Stack >
              </ButtonGroup>


              {showPassword ? renderPassword() : null}

              {showProfile ? renderProfile() : null}


              <AlertDialog
                isOpen={isThingOpen}
                leastDestructiveRef={cancelProfileRef}
                onProfileClose={onClose}
              >
                <AlertDialogOverlay>
                  <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                      Delete Existing Proxy and Add a New Proxy
                    </AlertDialogHeader>
                    <AlertDialogBody>
                      Are you sure? You can't undo this action afterwards.
                      This will delete all access your current proxy has to your account.

                    </AlertDialogBody>
                    <AlertDialogFooter>
                      <Button
                        ref={cancelProfileRef}
                        onClick={onThingClose}>
                        Cancel
                      </Button>
                      <Button colorScheme='red'
                        onClick={() => {
                          setShowNewProxy(true)
                          //console.log('Deleting' + show._id );
                          onThingClose()
                        }} ml={3}>
                        Continue
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialogOverlay>
              </AlertDialog>

              <br />

            </VStack>
          </>
        ) : null}

      </Box>

      <Box borderWidth='1px' borderRadius='lg' overflow='hidden'>



        {showNewProxy ? renderNewProxy("NEW") : null}

        {showProxy ? renderProxy("UPDATE") : null}


        {availableEntity ? (<VStack spacing={3} >
          <Text fontSize="3xl">Proxy: {availableEntity.proxy}</Text>
          <Text size="lg"> only one active proxy allowed per account</Text>
          <br />

          <Text fontSize="3xl">Email: {availableEntity.xrefuser} </Text>

          <Text size="lg">(Your Proxy email can not be updated. To make changes,
            your proxy must
            be deleted and replaced using the Add New Proxy Option)</Text>

          <Text fontSize="xl" >Notes: {availableEntity.notes}</Text>

          {availableUser.email === availableEntity.xrefuser ? (<>
            <ButtonGroup variant='outline' spacing='6' vertical>
              <Stack direction={['column', 'row']} spacing='24px'>
                <Button onClick={() => setShowNewProxy(true)
                } colorScheme='red'  >Add New Proxy</Button>


              </Stack>

            </ButtonGroup></>) : (<>

              <ButtonGroup variant='outline' spacing='6' vertical>
                <Stack direction={['column', 'row']} spacing='24px'>
                  <Button onClick={() => setIsThingOpen(true)
                  } colorScheme='red'  >Update Proxy</Button>


                  <Button onClick={() => setShowProxy(true)
                  } colorScheme="black">Update Proxy Name</Button>
                </Stack>

              </ButtonGroup></>)}





          <br />

        </VStack>
        ) : null}





      </Box>




    </>
  );

}






export default connect(null, { refreshUser, profile, userpw, editProxy, deleteCurrentItem, editNoImage, uploadDocument, })(Profile);

/*
<ProxyForm
          initialValues={{
            notes: availableEntity.notes,
            frequency: availableEntity.frequency,
            proxy: availableEntity.proxy,
            xrefuser: availableEntity.xrefuser,
            method: availableEntity.xrefuser,
            proxyFirst: availableEntity.proxyFirst,
            proxyLast: availableEntity.proxyLast,
          }}
          onEnter={(proxy, xrefuser, notes, method, proxyFirst, proxyLast) => {
            console.log(xrefuser + method + availableUser.email);
            if (method === xrefuser) {
              updateHandler({
                id: id,
                userId: availableUser._id,
                proxy: proxy,
                notes: notes,
                xrefuser: xrefuser,
                method: method,
                proxyFirst: proxyFirst,
                proxyLast: proxyLast,
              });
            }

            if (method !== xrefuser) {
              if (availableUser.email === method) {
                updateHandler({
                  id: id,
                  userId: availableUser._id,
                  proxy: proxy,
                  notes: notes,
                  xrefuser: xrefuser,
                  method: method,
                  proxyFirst: proxyFirst,
                  proxyLast: proxyLast,
                });
              } else
                updateHandler({
                  id: id,
                  userId: availableUser._id,
                  proxy: proxy,
                  notes: notes,
                  xrefuser: xrefuser,
                  method: method,
                  proxyFirst: proxyFirst,
                  proxyLast: proxyLast,
                });
            }
          }}
*/