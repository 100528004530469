import React, { useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";

import Styles from './StylesForms'

import {
  Text,
  // VStack,
  // Code,
  SimpleGrid,
  GridItem,
  // Select,
  useBreakpointValue,
  useToast,
  Box,
  Button,
  // ButtonGroup,
  CSSReset,
  Heading,
  // Icon,
  // Link,
  ThemeProvider,
  theme,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  // Checkbox,
  /// Progress,
  // Radio,
  // RadioGroup,
  // Stack,
  //Textarea
} from '@chakra-ui/react';

import { Form, Field, useField, useForm } from 'react-final-form'


import {
  FileUploadContainer,
  FormField,
  DragDropText,
  UploadFileBtn,
  FilePreviewContainer,
  ImagePreview,
  PreviewContainer,
  PreviewList,
  FileMetaData,
  RemoveFileIcon,
  InputLabel
} from "../file-upload/file-upload.styles";

import ImageUpload from '../items/ImageUpload'
import { uploadDocument } from "../../actions/itemAction"

import validate from './validateItemName'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))



const ItemNameForm = ({ uploadDocument, item, onSubmit }) => {
  // console.log(props.item)

  const [isUploading, setIsUploading] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [fileSelected, setFileSelected] = useState(null);


  let navigate = useNavigate();
  const toast = useToast();

  const availableUser = useSelector((state) => state.user.user);

  const colSpan = useBreakpointValue({ base: 2, md: 1 })
  const formSpan = useBreakpointValue({ base: 1, md: 2 })
  const boxWidth = useBreakpointValue({ base: 360, md: 800 })

  const onFormSubmit = async values => {
    // console.log('in NmeForm onSubmit' + values.itemName)
    if (photo) {
      values.image = photo
    }

    await sleep(300)
    await onSubmit(values);
    //window.alert(JSON.stringify(values, 0, 2))
  }

  const handlePhotoSelect = async (file) => {
    setIsUploading(true);
    //  console.log('UPLOAD PHOTO ' + file)

    let fileType2 = file.type.split("/")[1];
    const random2 = Math.random().toString().replace(/0\./, ""); // remove "0." from start of string
    const photoId = availableUser._id + random2 + "." + fileType2;
    // console.log(photoId)
    const blobName2 = `https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBgMobile${photoId}`;
    const result = await uploadDocument(file, photoId, blobName2);

    setFileSelected(file)
    setPhoto(blobName2)
    setIsUploading(false);

    toast({
      duration: 5000, status: "success", isClosable: true,
      title: "Upload Complete.",
      description: "Saved your photo on infoBee!",
    });
  };



  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <SimpleGrid columns={2} columnGap={3} rowGap={6} alignItems="flex-start"  >
        <GridItem colSpan={formSpan}>

          <Box w={boxWidth} p={1} m="10px auto" alignItems="flex-start">
            <Heading as="h1" size="xl" textAlign="center">
              infoBee Item Details
            </Heading>


            <Form
              onSubmit={onFormSubmit}
              initialValues={{
                id: item._id,
                itemName: item.name,
                text: item.text,
                category: item.category,
                image: item.image,
                itemId: item._id,
                wishname: item.wishname,
                wishitem: item.wishitem,
                flagged: item.flagged,
                ibegone: item.ibegone,
                ibepets: item.ibepets,
                ibehandy: item.ibehandy,
                ibehealth: item.ibehealth,
                ibehouse: item.ibehouse,
                ibepaper: item.ibepaper,
                ibemoney: item.ibemoney,
                ibemember: item.ibemember,
                ibetechno: item.ibetechno,
                ibeevent: item.ibeevent,
                ibesocial: item.ibesocial,
                ibecontacts: item.ibecontacts,
                ibebusy: item.ibebusy,
                ibecareer: item.ibecareer,
                ibememoir: item.ibememoir,
                ibeframed: item.ibeframed,
                records: item.records,
                verifyDate: item.verifyDate,
                itemDate: `${item.itemDate.substring(0, 4)}-${item.itemDate.substring(5, 7)}-${item.itemDate.substring(8, 10)}`,
                // itemDate: item.itemDate,
                trackType: item.trackType,
                wishes: item.wishes,
                picklist: item.picklist,
                comments: item.comments,
                itemResponse: 'INITIAL',

              }}
              validate={validate}
              render={({
                handleSubmit,
                form,
                errors,
                subscription = { submitting: true },
                submitting,
                pristine,
                values
              }) => (
                <>

                  <Box
                    as="form"
                    p={4}
                    borderWidth="3px"
                    rounded="lg"
                    shadow="1px 1px 3px rgba(0,0,0,0.3)"
                    onSubmit={handleSubmit}
                  >


                    <SimpleGrid columns={2} columnGap={1} rowGap={6} width="full">
                      <GridItem colSpan={colSpan}>
                        <div> {photo ? (<PreviewContainer>
                          <ImagePreview
                            src={URL.createObjectURL(fileSelected)}
                            alt={`file preview`}
                          />
                        </PreviewContainer>) : null}

                        </div>
                        <div>
                          <ImageUpload isUploading={isUploading}
                            onPhotoSelect={handlePhotoSelect}
                            label={"Upload Image"} />
                        </div>
                      </GridItem>

                      <GridItem colSpan={colSpan} mr={10}>
                        <InputControl name="itemName" label="Item Identification" maxLength={20} />

                      </GridItem>
                      <GridItem colSpan={colSpan} mr={10}>
                        <InputControl name="itemDate" label="Review Date" type="date" />
                      </GridItem>


                      <GridItem colSpan={2} minWidth="full" mr={10}>
                        <Text fontSize={12} style={{ alignItems: "flex-start" }}>Description </Text>
                        <Styles>
                          <div>
                            <Field name="text" component="textarea" minWidth="full"
                              fontSize={15}
                              placeholder="Description  " maxLength={600} />
                          </div>
                        </Styles>
                      </GridItem>



                    </SimpleGrid>


                    <Button
                      isLoading={submitting}
                      loadingText="Submitting"
                      variantcolor="teal"
                      disabled={submitting}
                      type="submit"
                    >
                      Submit
                    </Button>

                    <Button
                      isLoading={submitting}
                      onClick={() => values.itemResponse = "CANCEL"}
                      loadingText="Cancelling"
                      variantcolor="red"
                      type="cancel"
                    >
                      Cancel
                    </Button>



                  </Box>
                </>
              )}
            />
          </Box>
        </GridItem>

      </SimpleGrid>


    </ThemeProvider>
  )
}


const Control = ({ name, ...rest }) => {
  const {
    meta: { error, touched }
  } = useField(name, { subscription: { touched: true, error: true } })
  return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
  const {
    meta: { error }
  } = useField(name, { subscription: { error: true } })
  return <FormErrorMessage>{error}</FormErrorMessage>
}

const InputControl = ({ name, label, type, maxLength }) => {
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={4}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        {...input}
        isInvalid={meta.error && meta.touched}
        id={name}
        placeholder={label}
        type={type}
        maxLength={maxLength}
      />
      <Error name={name} />
    </Control>
  )
}



export default connect(null, { uploadDocument })(ItemNameForm);

/*
{fieldState => { <pre>{(JSON.stringify(fieldState, 0, 2))}</pre> }}  
*/