const validateEnvoi = values => {
  // console.log(values.envoiResponse + 'VALIDATE RESPONSE')
  const errors = {}

  // Validation doen in form
  if (values.envoiResponse !== 'CANCEL') {
    if (!values.userid) {
      errors.userid = 'Required'
    }
    if (!values.userid2) {
      errors.userid2 = 'Required'
    } else {
      if (values.userid !== values.userid2) {
        errors.userid2 = 'Email not the same'
      }
    }
  }



  return errors
}
export default validateEnvoi