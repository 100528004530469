import ibthost from './api/ibthost'

import {
  USER_ERROR,
  ENTITY_SUCCESS,
} from "./types";


//Delete current envoi

export const deleteEnvoi = (userId, itemId, callback) => {
  return async (dispatch) => {
    try {
      //console.log(userId + itemId)
      const response = await ibthost.delete(`/envoi/${userId}/${itemId}`);
      const response2 = await ibthost.get(`/item/${userId}`);

      dispatch({ type: ENTITY_SUCCESS, payload: response2.data });
      // await dispatch({ type: CLEAR_RECORDS });

      if (callback) {
        // console.log(callback);
        callback();
      }
    } catch (err) {
      await dispatch({ type: USER_ERROR, payload: ' Item Add Failed' });
      console.log('Delete Item Error ' + err)
    }
  };
};



export const addEnvoi = (formValues, userId, accountEmail, accountName, callback) => async (dispatch) => {

  // console.log("add envoi - new" + formValues._id);
  // console.log("avail" + accountEmail + accountName);

  const config = {
    headers: {
      'Access-Control-Allow-Origin': true,
      "Content-Type": "application/json",
    },
  };

  //const useridL = formValues.userid.toLowerCase(); 

  const body = JSON.stringify({
    accountEmail: accountEmail,
    accountName: accountName,
    name: formValues.envName,
    first: formValues.first,
    last: formValues.last,
    relation: formValues.relation,
    userid: formValues.userid.toLowerCase().trim(),
    authentication: formValues.authentication,
    descrip: formValues.descrip,
    envId: formValues.id,
  });

  //console.log(body)
  try {
    const response = await ibthost.put(`/envoi/${userId}`,
      body, config);


    dispatch({ type: ENTITY_SUCCESS, payload: response.data });

    if (callback) {
      callback();
    }

  } catch (err) {
    await dispatch({ type: USER_ERROR, payload: ' Envoi Add  Failed' });
    console.log('Envoi Add Error ' + err)
  }
};

//Edit Profile and Proxy

export const editProxy = (
  values, accountEmail, callback
) => {
  return async (dispatch) => {
    // console.log(values);
    // xrefuser = xrefuserL.toLowerCase();
    const now = new Date();
    if (!values.notes) {
      values.notes = "";
    }
    try {
      if (values.method !== values.xrefuser.toLowerCase().trim()) {
        let auditNote = `${values.notes} From 
         ${values.method} - To ${values.xrefuser.toLowerCase().trim() } ( ${now.toLocaleDateString()})`;
        const response = await ibthost.put(`/entity/${values.id}`, {
          proxy: values.proxy,
          xrefuser: values.xrefuser.toLowerCase().trim(),
          notes: auditNote,
          proxyFirst: values.proxyFirst,
          proxyLast: values.proxyLast,
        });

        // await dispatch({ type: ENTITY_SUCCESS, payload: response.data });

        try {
          //console.log(accountEmail);
          const response2 = await ibthost.put(`/proxy/${values.id}`, {
            proxy: values.proxy,
            xrefuser: values.xrefuser.toLowerCase().trim(),
            accountEmail: values.accountEmail,
            accountName: values.accountName,
            accountStatus: "Proxy",
            accountRole: "Proxy",
            method: values.method,
            proxyFirst: values.proxyFirst,
            proxyLast: values.proxyLast,
          });

          const responseEntity = await ibthost.get(`/item/${values.id}`);

          dispatch({ type: ENTITY_SUCCESS, payload: responseEntity.data });



          if (callback) {
            //  console.log(callback);
            callback();
          }
        } catch (err) {
          await dispatch({
            type: USER_ERROR,
            payload: `Connection ${err} - Please try again.`,
          });
        }
        //IF Proxy email changed then add new user and remove account from old user - use new api to be less confusing
        // shoul always go check and update anyhow -
      } else {
        let auditNote = `${values.notes}`;
        //console.log("not changed edit profile");
        const response = await ibthost.put(`/entity/${values.id}`, {
          notes: auditNote,
          proxy: values.proxy,
          xrefuser: values.xrefuser.toLowerCase().trim(),
          proxyFirst: values.proxyFirst,
          proxyLast: values.proxyLast,

          //wished,
        });

        const responseEntity = await ibthost.get(`/item/${values.id}`);

        dispatch({ type: ENTITY_SUCCESS, payload: responseEntity.data });

        // await dispatch({ type: ENTITY_SUCCESS, payload: response.data });

        if (callback) {
          //    console.log(callback);
          callback();
        }

        // await dispatch({ type: CLEAR_RECORDS });
      }
    } catch (err) {
      await dispatch({
        type: USER_ERROR,
        payload: `Connection ${err} - Please try again.`,
      });
    }
  };
};




/*
//Edit Profile

export const editProxy = (
  {
    userId,
    accountName,
    accountEmail,
    proxy,
    xrefuserL,
    notes,
    method,
    proxyFirst,
    proxyLast,
    name,
  },
  callback
) => {
  return async (dispatch) => {
    //  console.log(proxyFirst + proxyLast);
    xrefuser = xrefuserL.toLowerCase();
    const now = new Date();
    if (!notes) {
      notes = "";
    }
    try {
      if (method !== xrefuser) {
        let auditNote = `${notes} From  ${method} - To ${xrefuser} ( ${now.toLocaleDateString()})`;
        const response = await ibtApi.put(`/entity/${userId}`, {
          proxy,
          xrefuser,
          notes: auditNote,
          proxyFirst,
          proxyLast,
        });

        await dispatch({ type: ENTITY_SUCCESS, payload: response.data });

        try {
          //console.log(accountEmail);
          const response2 = await ibtApi.put(`/proxy/${userId}`, {
            proxy,
            xrefuser,
            accountEmail,
            accountName,
            accountStatus: "Proxy",
            accountRole: "Proxy",
            method,
            proxyFirst,
            proxyLast,
          });

          if (callback) {
            console.log(callback);
            callback();
          }
        } catch (err) {
          await dispatch({
            type: ENTITY_ERROR,
            payload: `Connection ${err} - Please try again.`,
          });
        }
        //IF Proxy email changed then add new user and remove account from old user - use new api to be less confusing
        // shoul always go check and update anyhow -
      } else {
        let auditNote = `${notes}`;
        //console.log("not changed edit profile");
        const response = await ibtApi.put(`/entity/${userId}`, {
          proxy,
          xrefuser,
          notes: auditNote,
          proxyFirst,
          proxyLast,

          //wished,
        });

        await dispatch({ type: ENTITY_SUCCESS, payload: response.data });

        if (callback) {
          console, log(callback);
          callback();
        }

        await dispatch({ type: CLEAR_RECORDS });
      }
    } catch (err) {
      await dispatch({
        type: ENTITY_ERROR,
        payload: `Connection ${err} - Please try again.`,
      });
    }
  };
};

*/
