const validateRegister = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Required'
  } else {
    if (values.email !== values.email2) {
      errors.email = 'Emails must match'
    }
  }
  if (!values.first) {
    errors.first = 'Required'
  }
  if (!values.last) {
    errors.last = 'Required'
  }


  return errors
}
export default validateRegister