import React, { useState } from 'react'
import { Navigate, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Box,
  Text,
  Heading,
  VStack,
  Button,
  ButtonGroup,
  Link,
  Image,
  SimpleGrid,
  Progress,
  Tabs, TabPanels, TabPanel, Tab, TabList,
  Flex,
  Center,
  HStack,
  useColorModeValue,
  useBreakpointValue,
} from '@chakra-ui/react';
//import './Dashboard.css';

//import logo from '../../icon.png';

import LoginForm from '../forms/LoginForm'
import { login } from "../../actions/userAction"

const imageRegister = require('../../images/imageRegister.PNG')
const imageLogin = require('../../images/IMG_8259.PNG')
const imageDashboard = require('../../images/imageDashboard.PNG')
const imageDetail = require('../../images/IMG_3323.PNG')
const imageCapture = require('../../images/Capture.PNG')

const HomePage = ({ login }) => {
  const [confirmEmail, setConfirmEmail] = useState("");
  const [showRegister, setShowRegister] = useState(false);

  let navigate = useNavigate();

  const availableUser = useSelector((state) => state.user.user);
  const availMessage = useSelector((state) => state.user.errorMessage);

  const textSize = useBreakpointValue({ base: "md", lg: "3xl" })
  const headSize = useBreakpointValue({ base: "lg", lg: "2xl" })


  const bg = useColorModeValue('gray.200', 'gray.700')

  const onSubmit = (formValues) => {
    setConfirmEmail(formValues.email)
    login(formValues, () => {
      navigate("/dashboard")
    })
  }

  return (
    <>
      <Box textAlign="center"  >

        <Heading as="i" color="black" size={headSize}>
          So, you found infoBee.</Heading>
        <br />
        <br />
        <Text as="i" fontSize={["lg", "xl", "4xl"]} >
          Congratulations! We welcome you to join us in a mission to help people all over the world get organized or prepare for the inevitable.
        </Text>
        <br />
        <br />
        <Box flexShrink={0}>
          <Image
            borderRadius='lg'
            width="full"
            src='https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBgMobile606a9ff30b22c30004025f532195920542467904.jpeg'
            alt='Bee River'
          />
        </Box>
        <br />
        <br />
        <Heading size={headSize}>
          Relax. Organize. Plan your tomorrow.</Heading>
        <br />


        <SimpleGrid columns={[1, 2, 4]} spacing='40px'>
          <Box p={4}  >
            <Box flexShrink={0}>
              <Image
                borderRadius='lg'
                //width={{ md: 250 }}
                width={450}
                height={155}
                src='https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBgMobile606a9ff30b22c30004025f534201161914904388.jpeg'
                alt='Grampa Bee'
              />
            </Box>
            <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
              <Text
                fontWeight='bold'
                textTransform='uppercase'
                fontSize='xl'
                letterSpacing='wide'
                color='teal.600'
              >
                Inventory
              </Text>
              <Text
                mt={1}
                display='block'
                fontSize='lg'
                lineHeight='normal'
                fontWeight='semibold'

              >
                Classify your items to make them easy to find
              </Text>
              <Text mt={2} color='gray.500' fontSize={["md", "lg", "xl"]}>
                Walk around your house and take pictures of all your applicances, furniture, pets and treasures.
                The table your grandfather made,
                the picture your grandmother painted and the ash tray your first born made in kindergarten for you.


              </Text>
            </Box>
          </Box>
          <Box p={4}  >
            <Box flexShrink={0}>
              <Image
                borderRadius='lg'
                //width={{ md: 250 }}
                height={155}
                width={450}
                //   src='https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBgMobile606a9ff30b22c30004025f538867180586554173.jpeg'
                //  alt='Dark Bee'

                src='https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBgMobile606a9ff30b22c30004025f534357371268548742.jpeg'
                alt='Lavendar Bee'
              />
            </Box>
            <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
              <Text
                fontWeight='bold'
                textTransform='uppercase'
                fontSize='xl'
                letterSpacing='wide'
                color='teal.600'
              >
                Records
              </Text>
              <Text
                mt={1}
                display='block'
                fontSize='lg'
                lineHeight='normal'
                fontWeight='semibold'

              >
                Keep Records for your Inventory Items
              </Text>
              <Text mt={2} color='gray.500' fontSize={["md", "lg", "xl"]}>
                Attach user manuals, pet pedigrees or add a collection of similar items.
              </Text>
            </Box>
          </Box>
          <Box p={4}  >
            <Box flexShrink={0}>
              <Image
                borderRadius='lg'
                //width={{ md: 250 }}
                height={155}
                width={450}
                src='https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBgMobile606a9ff30b22c30004025f5323408798705925582.jpeg'
                alt='Black Bee'
              />
            </Box>
            <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
              <Text
                fontWeight='bold'
                textTransform='uppercase'
                fontSize='xl'
                letterSpacing='wide'
                color='teal.600'
              >
                Sharing
              </Text>
              <Text
                mt={1}
                display='block'
                fontSize='lg'
                lineHeight='normal'
                fontWeight='semibold'

              >
                Share Items with Friends and Family
              </Text>
              <Text mt={2} color='gray.500' fontSize={["md", "lg", "xl"]}>
                Going on vacation? Not sure your kids want the picture your grandmother painted? Need a friend to pet sit this weekend?   Simple flag the item you with to share and delegate access to them.
              </Text>
            </Box>
          </Box>
          <Box p={4}  >
            <Box flexShrink={0}>
              <Image
                borderRadius='lg'
                //width={{ md: 250 }}
                width={450}
                height={155}
                src='https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBgMobile606a9ff30b22c30004025f539848680379710739.jpeg'
                alt='Bee and Honey'
              />
            </Box>
            <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
              <Text
                fontWeight='bold'
                textTransform='uppercase'
                fontSize='xl'
                letterSpacing='wide'
                color='teal.600'
              >
                Final Requests
              </Text>
              <Text
                mt={1}
                display='block'
                fontSize='lg'
                lineHeight='normal'
                fontWeight='semibold'

              >
                Make you wishes known to your loved ones.
              </Text>
              <Text mt={2} color='gray.500' fontSize={["md", "lg", "xl"]}>
                Whether you are young or old, it is never
                too late to discuss your last wishes and prepare for the inevitable.
              </Text>
            </Box>
          </Box>

        </SimpleGrid>
        <br />

        <br />
        <Box
          bg='black' w='100%' p={4} color='white'>
          <Button onClick={() => setShowRegister(!showRegister)} bg='black'>
            <Text fontSize="2xl"  >Lets get started!</Text></Button>

        </Box>
        <br />

        <br />
        <br />
        {showRegister ? (<>
          <Tabs defaultIndex={0}>
            <TabList>
              <Tab>
                <Text fontSize={["md", "lg", "2xl"]} >Register
                </Text> </Tab>
              <Tab>   <Text fontSize={["md", "lg", "2xl"]} >Login
              </Text> </Tab>
              <Tab>   <Text fontSize={["md", "lg", "2xl"]} >Use
              </Text> </Tab>
              <Tab>   <Text fontSize={["md", "lg", "2xl"]} >Mobile
              </Text> </Tab>
              <Tab>   <Text fontSize={["md", "lg", "2xl"]} >Upgrade
              </Text> </Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Box p={4} display={{ md: 'flex' }}>

                  <Box mt={{ base: 4, md: 0 }} ml={{ sm: 2 }}>

                    <Text
                      fontWeight='bold'
                      textTransform='uppercase'
                      fontSize={["md", "lg", "xl"]}
                      letterSpacing='wide'
                      lineHeight='normal'
                    // fontWeight='semibold'
                    >
                      1. Register using a valid email.
                    </Text>
                    <Text mt={2}
                      fontSize={["md", "lg", "xl"]}
                      letterSpacing='wide'
                      lineHeight='large'
                      color='gray.500'
                      display='block'>
                      Registering is easy and can be done from this web page.
                      A One Time Passcode (OTP) will be sent to your email address to verify your account.
                      Alternatively, you can down load the infoBee Mobile App for iOS and Android.

                    </Text>

                    <br />
                    <div alignitems="center">
                      <ButtonGroup variant='outline' spacing='1'>
                        <Button onClick={() => { navigate("/register") }
                        } colorScheme='grey'>Register</Button>
                        <Button onClick={() => { }}
                          colorScheme='black'>Apple</Button>
                        <Button onClick={() => { }}
                          colorScheme="black">Google</Button>
                      </ButtonGroup>
                    </div>

                    <br />
                    <Text fontSize={["md", "lg", "xl"]}
                      fontWeight='semibold'> If you were added as a delegate by a friend or family member,
                      the system would have automatically registed you as a user with the email provided.
                      Please check your email for instructions to complete the process.</Text>

                  </Box  >
                </Box>
              </TabPanel>
              <TabPanel>
                <Box p={4} display={{ md: 'flex' }}>
                  <Box flexShrink={0} alignItems="center" marginLeft={15} marginRight={25}>
                    <Image
                      borderRadius='lg'
                      //width={{ md: 250 }}
                      width={450}
                      src={imageDashboard}
                      alt={`infobee record`}
                    />
                  </Box>


                  <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
                    <br />
                    <Text
                      fontWeight='bold'
                      textTransform='uppercase'
                      fontSize={["md", "lg", "xl"]}
                      letterSpacing='wide'
                      lineHeight='normal'
                    // fontWeight='semibold'
                    >
                      2. Login.
                    </Text>
                    <Text mt={2}
                      fontSize={["md", "lg", "xl"]}
                      letterSpacing='wide'
                      lineHeight='large'
                      color='gray.500'
                      display='block'>
                      Once logged in, you can navigate to your items using one of the list options.
                      <Text>   You can change your profile name, avatar and password from your
                        Settings Page.As an account owner, you can also add a
                        Proxy.  You can add delegates from your dashboard</Text>
                      <Text>PROXY - You can add a Proxy to your account from the Profile page.
                        They will be responsible for finalizing your account and
                        carrying out your wishes.</Text>
                      <Text>DELEGATES - Friends and family that you want to have access to flagged items that you share with them.
                        All records except iBeHealth and iBeMoney can be shared.
                        You can send and reply to messages against each item. When adding a delegate,
                        an account will automatically be opened with thier email address.
                        An email will be sent to them with instructions on how to log in and download the Mobile app.
                        A delegate email address cannot be changed, you
                        will have to delete and re - add the delegate to initiate
                        a change.


                      </Text>

                    </Text>

                    <br />


                    <br />
                    <Text fontSize={["md", "lg", "xl"]}
                      fontWeight='semibold'> There are three levels of packages.  Features, list options and limits will be differnt for each package.
                    </Text>
                    <br />
                    <Text fontSize={["md", "lg", "xl"]}
                      fontWeight='semibold'> All users can add up to 30 items, each item can have 20 records associated with it.
                      Upgrading to the BASIC package allows you to add 500 items.
                      The EXTENDED package allows you to add 3000 items.

                    </Text>
                    <br />

                  </Box  >
                </Box>
              </TabPanel>
              <TabPanel>

                <Box p={4} display={{ md: 'flex' }}>
                  <Box flexShrink={0} alignItems="center" marginLeft={15} marginRight={25}>
                    <Image
                      borderRadius='lg'
                      //width={{ md: 250 }}
                      width={250}
                      src={imageDetail}
                      alt={`infobee record`}
                    />
                  </Box>


                  <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
                    <br />
                    <Text
                      fontWeight='bold'
                      textTransform='uppercase'
                      fontSize={["md", "lg", "xl"]}
                      letterSpacing='wide'
                      lineHeight='normal'
                    // fontWeight='semibold'
                    >
                      3. Add an item, attach records and share it with friends and family.
                    </Text>
                    <Text mt={2}
                      fontSize={["md", "lg", "xl"]}
                      letterSpacing='wide'
                      lineHeight='large'
                      color='gray.500'
                      display='block'>
                      All users can add up to 30 items, each item can have 20 records associated with it.
                      Upgrading to the BASIC package allows you to add 500 items.
                      The EXTENDED package allows you to add 3000 items.
                      You can upload a picture of the item and add a
                      description to help your Delegates identify the item.
                      Each item is associated with one to many classifications to make it easier to find and filter you information.
                      Different packages have different permissions to use the various classifications.
                      Images and PDF documents can be attached to the item as records.  Each record is also associated with a classification,
                      which groups the like items together to mke them easy to find.

                      You can add items by using the Add Item tab from the top
                      Menu.

                      Inventory, will give you a full list of your items. The other classifications are there to
                      help you keep organized.  To view an
                      item, expand the classification that it belongs to and click on
                      the item.
                    </Text>



                    <br />
                    <Text fontSize={["md", "lg", "xl"]}
                      fontWeight='semibold'> Flagging an item allows your Proxy and Delegates to
                      view and send messages about the item. This way they can express an interest
                      in the item or communicate issue or concerns. </Text>

                  </Box  >
                </Box>
              </TabPanel>
              <TabPanel>

                <Box p={4} display={{ md: 'flex' }}>
                  <Box flexShrink={0} alignItems="center" marginLeft={15} marginRight={25}>
                    <Image
                      borderRadius='lg'
                      //width={{ md: 250 }}
                      width={450}
                      src={imageCapture}
                      alt={`infobee record`}
                    />
                  </Box>


                  <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
                    <br />
                    <Text
                      fontWeight='bold'
                      textTransform='uppercase'
                      fontSize={["md", "lg", "xl"]}
                      letterSpacing='wide'
                      lineHeight='normal'
                    // fontWeight='semibold'
                    >
                      4. Download the Mobile app.
                    </Text>
                    <Text mt={2}
                      fontSize={["md", "lg", "xl"]}
                      letterSpacing='wide'
                      lineHeight='large'
                      color='gray.500'
                      display='block'>
                      You can down load the infoBee Mobile App for iOS and Android.

                    </Text>
                    <br />

                    <div alignitems="center">
                      <ButtonGroup variant='outline' spacing='6'>
                        <Button onClick={() => { }
                        } colorScheme='grey'>Google Play</Button>
                        <Button onClick={() => { }}
                          colorScheme='black'>Apple Store</Button>

                      </ButtonGroup>
                    </div>

                    <br />
                    <Text fontSize={["md", "lg", "xl"]}
                      fontWeight='semibold'> Take advantage of being able to access your information on the go. </Text>

                  </Box  >
                </Box>
              </TabPanel>
              <TabPanel>

                <Box p={4} display={{ md: 'flex' }}>


                  <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
                    <br />
                    <Text
                      fontWeight='bold'
                      textTransform='uppercase'
                      fontSize={["md", "lg", "xl"]}
                      letterSpacing='wide'
                      lineHeight='normal'
                    // fontWeight='semibold'
                    >
                      5. Upgrade your account to be able use additional features.
                    </Text>
                    <Text mt={2}
                      fontSize={["md", "lg", "xl"]}
                      letterSpacing='wide'
                      lineHeight='large'
                      color='gray.500'
                      display='block'>
                    </Text>
                    <Text fontSize={["md", "lg", "xl"]}
                      fontWeight='semibold'>    GUEST - free
                    </Text>
                    <Text mt={2}
                      fontSize={["md", "lg", "xl"]}
                      letterSpacing='wide'
                      lineHeight='large'
                      color='gray.500'
                      display='block'>
                      All users can add up to 30 items, each item can have 20 records associated with it.
                      Classifications include iBePets, iBeCareer, iBeHandy
                    </Text>


                    <br />
                    <Text fontSize={["md", "lg", "xl"]}
                      fontWeight='semibold'>    BASIC - $49.99/year
                    </Text>
                    <Text mt={2}
                      fontSize={["md", "lg", "xl"]}
                      letterSpacing='wide'
                      lineHeight='large'
                      color='gray.500'
                      display='block'>
                      Upgrading to the BASIC package allows you to add 500 items.
                      Additional Classifications include iBeHouse, iBeGone, iBePaper, iBeMoney, iBeMember
                    </Text>

                    <br />
                    <Text fontSize={["md", "lg", "xl"]}
                      fontWeight='semibold'>
                      EXTENDED - $149.99/year</Text>
                    <Text mt={2}
                      fontSize={["md", "lg", "xl"]}
                      letterSpacing='wide'
                      lineHeight='large'
                      color='gray.500'
                      display='block'>
                      The EXTENDED package allows you to add 3000 items.
                      Additional Classifications include iBeTechno, iBeEvent, iBeSocial, iBeContact, iBeBusy, iBeMemoir, iBeHealth and iBeFramed
                    </Text>

                  </Box  >
                </Box>
              </TabPanel>





            </TabPanels>

          </Tabs>
        </>) : null}

        <br />

        <br />
        <br />
        <br />



        <Center h={12} bg='gray.100' px={4} alignItems={'center'} justifyContent={'center'}>
          <HStack as={'nav'} spacing={4} display={{ base: 'flex', md: 'flex' }}>
            <Link px={2} py={1} target="_blank" rel="noopener noreferrer" rounded={'md'} _hover={{
              textDecoration: 'none', bg: bg,
            }} to="/termsconditions">
              <Text fontSize={["md", "lg", "xl"]} >Terms and Conditions    |</Text></Link>
            <Link to="/privacypolicy" target="_blank" rel="noopener noreferrer" >
              <Text fontSize={["md", "lg", "xl"]}  >Privacy Policy </Text></Link>


          </HStack>
        </Center>

      </Box>
    </>
  )

}


export default connect(null, { login })(HomePage);

/*
  <br />
        <Progress size='xs' isIndeterminate color="black" />
        <br />

         <Box flexShrink={0} alignItems="center" marginLeft={15} marginRight={25}>
                    <Image
                      borderRadius='lg'
                      //width={{ md: 250 }}
                      width={300}
                      src={imageRegister}
                      alt={`infobee record`}
                    />
                  </Box>
*/