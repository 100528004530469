const validateEmail = values => {
    const errors = {}
    
     if (!values.email) {
    errors.email = 'Required'
    }
     
  
  return errors
}
export default validateEmail