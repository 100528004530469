import React, { useEffect, useState } from 'react'
import { useSelector, connect } from "react-redux";
import { NavLink, Outlet, useSearchParams, useLocation, Link } from "react-router-dom";
import {
  Text,
  Box,
  Heading,
  Button,
  Input,
  //Divider,
  Stack,
  Avatar,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';

import { CheckIcon, } from '@chakra-ui/icons'

import ibtColors from "../../common/ibtColors";

import { refreshUser } from "../../../actions/userAction"



const BusyList = ({ refreshUser }) => {
  // const [infoBeeColor, setInfoBeeColor] = useState(" ");
  let [searchParams, setSearchParams] = useSearchParams();
  const availableUser = useSelector((state) => state.user.user);
  const availableEntity = useSelector((state) => state.entity.entity);
  const availableToken = useSelector((state) => state.user.token)
  const availableEmail = useSelector((state) => state.user.googleEmail)

  const secondaryTextColor = useColorModeValue('gray.600', 'gray.400')
  const primaryThingColor = ibtColors.ibtbusy

  const { isOpen, onOpen, onClose } = useDisclosure();

  const colSpan = useBreakpointValue({ base: 2, md: 1 })
  const formSpan = useBreakpointValue({ base: 1, md: 2 })
  const boxHeight = useBreakpointValue({ base: "40px", md: "60px" })
  const fontSize = useBreakpointValue({ base: 'xs', md: 'md' })
  const formContent = useBreakpointValue({ base: 'flex-start', ld: 'space-between' })
  const showMenu = useBreakpointValue({ base: false, lg: true })
  const btnRef = React.useRef()
  const [isListOpen, setIsListOpen] = useState(false)
  const [chooseList, setChooseList] = useState('iBeBusy');

  useEffect(() => {
    const refresh = async () => {
      await refreshUser(availableEmail)
    };

    if (availableToken && !availableUser) {
      refresh();
    }

  }, [availableToken, availableUser, availableEmail]);

  function QueryNavLink({ to, ...props }) {
    let location = useLocation();
    return <NavLink to={to + location.search} {...props} />;
  }


  return (
    <>
      <Box display={{ base: 'none', md: 'flex' }}>
        <nav style={{ borderRight: "solid 1px", padding: ".5rem" }} >
          {showMenu ? (<Heading size="lg" color={primaryThingColor} >All Inventory Items</Heading>) :
            (<Button onClick={isOpen ? onClose : onOpen} backgroundColor={primaryThingColor} color="white">Show Lists</Button>)}
          <br />
          <br />

          <Input
            value={searchParams.get("filter") || ""}
            style={{ padding: ".25rem" }}
            placeholder="   Item Contains Search"
            onChange={event => {
              let filter = event.target.value
              if (filter) {
                setSearchParams({ filter });
              } else {
                setSearchParams({});
              }
            }}
          />
          {availableEntity ? (
            availableEntity.item
              .filter(cls => {
                if (cls.ibebusy) { return true } else { return false }
              }).filter(item => {
                let filter = searchParams.get("filter");
                if (!filter) return true;
                let name = item.name.toLowerCase();
                //   return name.startsWith(filter.toLowerCase());
                return name.includes(filter.toLowerCase());
              }).map(item => (
                <QueryNavLink key={item.name + item._id} style={({ isActive }) => {
                  return { display: "block", margin: ".5rem 0", color: isActive ? "red" : "black" };
                }}
                  to={`/inventory/itemlist/${item._id}`}
                >
                  <Stack direction='row' h={boxHeight} borderWidth={2} borderColor={primaryThingColor} >
                    {item.image ? (<Avatar size={'lg'} src={item.image} display={{ base: 'none', md: 'flex' }}
                    />) :
                      <Avatar size={'lg'} bg="grey.100" display={{ base: 'none', md: 'flex' }} icon={<CheckIcon fontSize='1.5rem' />}
                      />}
                    <Heading key={item.name + item._id} size={fontSize} mt={3} color={secondaryTextColor}>{item.name}</Heading>
                  </Stack>
                </QueryNavLink>
              ))
          ) : null}
        </nav>
        <Outlet />

      </Box>
      <Box display={{ base: 'flex', md: 'none' }}>


        <Button ref={btnRef} colorScheme='teal' onClick={onOpen}>
          Open List Selection
        </Button>
        <Drawer
          isOpen={isOpen}
          placement='left'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody>
              <>

                <Button onClick={() => setIsListOpen(!isListOpen)}
                  backgroundColor={"grey"} color="white">Show Lists</Button>
                <Input
                  value={searchParams.get("filter") || ""}
                  style={{ padding: ".25rem" }}
                  placeholder="   Item Contains Search"
                  onChange={event => {
                    let filter = event.target.value
                    if (filter) {
                      setSearchParams({ filter });
                    } else {
                      setSearchParams({});
                    }
                  }}
                />
                <br />
                {isListOpen ? (<>
                  <Box pb={4}  >
                    <Stack as={'nav'} spacing={1}>
                      <div >
                        <Box backgroundColor={`${ibtColors.ibtinventory}`} marginTop={1}>
                          <Text onClick={() => {
                            setChooseList('inventory')
                            setIsListOpen(false)
                          }} fontSize="lg" color={"white"}
                            style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                          >inventoryC </Text>
                        </Box>
                        <Box backgroundColor={`${ibtColors.ibtflagged}`} marginTop={1}>
                          <Text onClick={() => {
                            setChooseList('flagged')
                            setIsListOpen(false)
                          }} fontSize="lg" color={"white"}
                            style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                          >flagged </Text>
                        </Box>
                        <Box backgroundColor={`${ibtColors.ibtpets}`} marginTop={1}>
                          <Text onClick={() => {
                            setChooseList('iBePets')
                            setIsListOpen(false)
                          }} fontSize="lg" color={"white"}
                            style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                          >iBePets </Text>
                        </Box>

                        <Box backgroundColor={`${ibtColors.ibtcareer}`} marginTop={1}>
                          <Text onClick={() => {
                            setChooseList('iBeCareer')
                            setIsListOpen(false)
                          }} fontSize="lg" color={"white"}
                            style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                          >iBeCareer  </Text>
                        </Box>
                        <Box backgroundColor={`${ibtColors.ibthandy}`} marginTop={1}>
                          <Text onClick={() => {
                            setChooseList('iBeHandy')
                            setIsListOpen(false)
                          }} fontSize="lg" color={"white"}
                            style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                          >iBeHandy  </Text>
                        </Box>
                        {availableUser.status ? (<>
                       
                        {availableUser.status === 'Basic'
                          || availableUser.status === 'Active'
                          ? (<>
                            <Box backgroundColor={`${ibtColors.ibthouse}`} marginTop={1}>
                              <Text onClick={() => {
                                setChooseList('iBeHouse')
                                setIsListOpen(false)
                              }} fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeHouse  </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtgone}`} marginTop={1}>
                              <Text onClick={() => {
                                setChooseList('iBeGone')
                                setIsListOpen(false)
                              }} fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeGone </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtpaper}`} marginTop={1}>
                              <Text onClick={() => {
                                setChooseList('iBePaper')
                                setIsListOpen(false)

                              }} fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBePaper </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtmoney}`} marginTop={1}>
                              <Text onClick={() => {
                                setChooseList('iBeMoney')
                                setIsListOpen(false)
                              }} fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeMoney  </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtmember}`} marginTop={1}>
                              <Text onClick={() => {
                                setChooseList('iBeMember')
                                setIsListOpen(false)
                              }} fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeMember  </Text>
                            </Box>
                          </>)
                          : <><Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                            <Text fontSize="lg" color={"white"}
                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                            >iBeHouse  </Text>
                          </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Text fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeGone </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Text fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBePaper </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Text fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeMoney  </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Text fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeMember  </Text>
                            </Box> </>}

                        {availableUser.status === 'Active'
                          ? (<>

                            <Box backgroundColor={`${ibtColors.ibttechno}`} marginTop={1}>
                              <Text onClick={() => {
                                setChooseList('iBeTechno')
                                setIsListOpen(false)
                              }} fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeTechno  </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtevent}`} marginTop={1}>
                              <Text onClick={() => {
                                setChooseList('iBeEvent')
                                setIsListOpen(false)
                              }} fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeEvent  </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtsocial}`} marginTop={1}>
                              <Text onClick={() => {
                                setChooseList('iBeSocial')
                                setIsListOpen(false)
                              }} fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeSocial  </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtcontacts}`} marginTop={1}>
                              <Text onClick={() => {
                                setChooseList('iBeContacts')
                                setIsListOpen(false)
                              }} fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeContact  </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbusy}`} marginTop={1}>
                              <Text onClick={() => {
                                setChooseList('iBeBusy')
                                setIsListOpen(false)
                              }} fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeBusy  </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtmemoir}`} marginTop={1}>
                              <Text onClick={() => {
                                setChooseList('iBeMemoir')
                                setIsListOpen(false)
                              }} fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeMemoir  </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibthealth}`} marginTop={1}>
                              <Text onClick={() => {
                                setChooseList('iBeHealth')
                                setIsListOpen(false)
                              }} fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeHealth </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtframed}`} marginTop={1}>
                              <Text onClick={() => {
                                setChooseList('iBeFramed')
                                setIsListOpen(false)
                              }} fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeFramed  </Text>
                            </Box>
                          </>) : <>

                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Text fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeTechno  </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Text fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeEvent  </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Text fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeSocial  </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Text fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeContact  </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Text fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeBusy  </Text>
                            </Box>

                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Text fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeMemoir  </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Text fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeHealth </Text>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Text
                                fontSize="lg" color={"white"}
                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                              >iBeFramed  </Text>
                            </Box>

                            </>}
                        </>) : null}

                      </div>

                    </Stack>
                  </Box>
                </>) : null}

                {availableEntity ? (
                  availableEntity.item
                    .filter(cls => {
                      if ((chooseList === 'iBePets' && cls.ibepets)
                        || (chooseList === 'iBeGone' && cls.ibegone)
                        || (chooseList === 'iBeHandy' && cls.ibehandy)
                        || (chooseList === 'iBeHouse' && cls.ibehouse)
                        || (chooseList === 'iBeHealth' && cls.ibehealth)
                        || (chooseList === 'iBePaper' && cls.ibepaper)
                        || (chooseList === 'iBeMoney' && cls.ibemoney)
                        || (chooseList === 'iBeMember' && cls.ibemember)
                        || (chooseList === 'iBeTechno' && cls.ibetechno)
                        || (chooseList === 'iBeEvent' && cls.ibeevent)
                        || (chooseList === 'iBeSocial' && cls.ibesocial)
                        || (chooseList === 'iBeContacts' && cls.ibecontacts)
                        || (chooseList === 'iBeBusy' && cls.ibebusy)
                        || (chooseList === 'iBeCareer' && cls.ibecareer)
                        || (chooseList === 'iBeMemoir' && cls.ibememoir)
                        || (chooseList === 'iBeFramed' && cls.ibeframed)
                        || (chooseList === 'flagged' && cls.flagged)
                        || (chooseList === 'inventory')
                      ) { return true } else { return false }
                    }).filter(item => {
                      let filter = searchParams.get("filter");
                      if (!filter) return true;
                      let name = item.name.toLowerCase();
                      //   return name.startsWith(filter.toLowerCase());
                      return name.includes(filter.toLowerCase());
                    }).map(item => (
                      <QueryNavLink onClick={onClose} key={item.name + item._id} style={({ isActive }) => {
                        return { display: "block", margin: ".5rem 0", color: isActive ? "red" : "black" };
                      }}
                        to={`/${item._id}`}
                      >
                        <Stack direction='row' h={boxHeight} borderWidth={2} borderColor="teal" >
                          {item.image ? (<Avatar size={'md'} src={item.image}
                          />) :
                            <Avatar size={'md'} bg="grey.100" icon={<CheckIcon fontSize='1.5rem' />}
                            />}
                          <Heading key={item.name + item._id} size={fontSize} mt={3} color={secondaryTextColor}>{item.name}</Heading>
                        </Stack>
                      </QueryNavLink>
                    ))
                ) : null}

              </>
            </DrawerBody>

            <DrawerFooter>
              <Button variant='outline' mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='blue'>Save</Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>




      </Box>
    </>
  );
}


export default connect(null, { refreshUser })(BusyList);
/*

useEffect(() => {
  const refresh = async () =>
  {
    //await refreshUser(availableEmail)
  };
    
    if (availableToken && !availableUser) {
        refresh();
    } 
 
}, [availableToken, availableUser]);
*/