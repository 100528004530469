import React, { useEffect, useState } from 'react'
import { Link, Outlet, } from "react-router-dom";
import { useSelector } from "react-redux";
import { connect } from "react-redux";

import {
  Heading, Text, Flex, VStack, Stack, Box, useBreakpointValue, Button
} from '@chakra-ui/react';

import ibtColors from "../common/ibtColors";

import { refreshUser } from "../../actions/userAction"

const Inventory = ({ refreshUser }) => {
  let [showInvent, setShowInvent] = useState(false);

  const availableUser = useSelector((state) => state.user.user);
  const availableToken = useSelector((state) => state.user.token)
  const availableEmail = useSelector((state) => state.user.googleEmail)

  const colSpan = useBreakpointValue({ base: 2, md: 1 })
  const formSpan = useBreakpointValue({ base: 1, md: 2 })
  const boxWidth = useBreakpointValue({ base: 350, md: 800 })
  const tabSize = useBreakpointValue({ base: 'xs', md: 'md' })
  const formContent = useBreakpointValue({ base: 'flex-start', ld: 'space-between' })
  const showMenu = useBreakpointValue({ base: false, lg: true })

  useEffect(() => {
    const refresh = async () => {
      await refreshUser(availableEmail)
    };

    if (availableToken && !availableUser) {
      refresh();
    }

  }, []);



  return (
    <div style={{ display: "flex" }}>

      <VStack as={'nav'} spacing={12} display={{ base: 'none', lg: 'flex' }}>


        <nav
          style={{ borderRight: "solid 1px", padding: ".5rem" }} >

          <Box pb={4}  >
            <Stack as={'nav'} spacing={.5}>
              <div >
                <Heading size="md">Classification</Heading>
                <Box backgroundColor={`${ibtColors.ibtdark}`} height={38} width={140}>
                  <Link to="/inventory/itemlist"  >
                    <Text fontSize="lg" color={"white"}
                      style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                      inventory</Text></Link>
                </Box>
                <Box backgroundColor={`${ibtColors.ibtflagged}`} height={38} width={140}>
                  <Link to="/inventory/flaggedlist"  >
                    <Text fontSize="lg" color={"white"}
                      style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                      Flagged</Text></Link>
                </Box>
                <Box backgroundColor={`${ibtColors.ibttodo}`} height={38} width={140}>
                  <Link to="/inventory/flaggedlist"  >
                    <Text fontSize="lg" color={"white"}
                      style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                      ToDo</Text></Link>
                </Box>
                <Box backgroundColor={`${ibtColors.ibtpets}`} height={38} width={140}>
                  <Link to="/inventory/petslist"   >
                    <Text fontSize="lg" color={"white"}
                      style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                      iBePets</Text></Link>
                </Box>
                <Box backgroundColor={`${ibtColors.ibtcareer}`} height={38} width={140}>
                  <Link to="/inventory/careerlist"   >
                    <Text fontSize="lg" color={"white"}
                      style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                      iBeCareer</Text></Link>
                </Box>
                <Box backgroundColor={`${ibtColors.ibthandy}`} height={38} width={140}>
                  <Link to="/inventory/handylist"   >
                    <Text fontSize="lg" color={"white"}
                      style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                      iBeHandy</Text></Link>
                </Box>
                {availableUser ? (<>
                  {availableUser.status === 'Basic'
                    || availableUser.status === 'Active'
                    ? (<>
                      <Box backgroundColor={`${ibtColors.ibthouse}`} height={38} width={140}>
                        <Link to="/inventory/houselist"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeHouse</Text></Link>
                      </Box><Box backgroundColor={`${ibtColors.ibtgone}`} height={38} width={140}>
                        <Link to="/inventory/gonelist"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeGone</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtpaper}`} height={38} width={140}>
                        <Link to="/inventory/paperlist"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBePaper</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtmoney}`} height={38} width={140}>
                        <Link to="/inventory/moneylist"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeMoney</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtmember}`} height={38} width={140}>
                        <Link to="/inventory/memberlist"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 15, marginRight: 15, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeMember</Text></Link>
                      </Box>
                    </>)
                    : (<>
                      <Box backgroundColor={`${ibtColors.ibtbasic}`} height={38} width={140}>
                        <Link to="/inventory"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeHouse</Text></Link>
                      </Box><Box backgroundColor={`${ibtColors.ibtbasic}`} height={38} width={140}>
                        <Link to="/inventory"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeGone</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtbasic}`} height={38} width={140}>
                        <Link to="/inventory"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBePaper</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtbasic}`} height={38} width={140}>
                        <Link to="/inventory"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeMoney</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtbasic}`} height={38} width={140}>
                        <Link to="/inventory"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 15, marginRight: 15, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeMember</Text></Link>
                      </Box>
                    </>)}


                  {availableUser.status === 'Active'
                    ? (<>
                      <Box backgroundColor={`${ibtColors.ibttechno}`} height={38} width={140}>
                        <Link to="/inventory/technolist"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeTechno</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtevent}`} height={38} width={140}>
                        <Link to="/inventory/eventlist"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeEvent</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtsocial}`} height={38} width={140}>
                        <Link to="/inventory/sociallist"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeSocial</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtcontacts}`} height={38} width={140}>
                        <Link to="/inventory/contactslist"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeContacts</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtbusy}`} height={38} width={140}>
                        <Link to="/inventory/busylist"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeBusy</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtmemoir}`} height={38} width={140}>
                        <Link to="/inventory/memoirlist"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeMemoir</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibthealth}`} height={38} width={140}>
                        <Link to="/inventory/healthlist"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeHealth</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtframed}`} height={38} width={140}>
                        <Link to="/inventory/framedlist"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeFramed</Text></Link>
                      </Box>
                    </>) : (<>
                      <Box backgroundColor={`${ibtColors.ibtbasic}`} height={38} width={140}>
                        <Link to="/inventory"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeTechno</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtbasic}`} height={38} width={140}>
                        <Link to="/inventory"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeEvent</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtbasic}`} height={38} width={140}>
                        <Link to="/inventory"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeSocial</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtbasic}`} height={38} width={140}>
                        <Link to="/inventory"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeContacts</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtbasic}`} height={38} width={140}>
                        <Link to="/inventory"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeBusy</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtbasic}`} height={38} width={140}>
                        <Link to="/inventory"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeMemoir</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtbasic}`} height={38} width={140}>
                        <Link to="/inventory"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeHealth</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtbasic}`} height={38} width={140}>
                        <Link to="/inventory"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 18, marginRight: 18, marginTop: 4, paddingTop: ".5rem" }}>
                            iBeFramed</Text></Link>
                      </Box>
                    </>)}</>) : null}




              </div>
            </Stack>
          </Box>
        </nav>



      </VStack>

      <Outlet />
    </div>
  );
}

export default connect(null, { refreshUser })(Inventory);