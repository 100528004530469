import {
  USER_SUCCESS,
  EMAIL_SUCCESS,
  USER_ERROR,
  RESET_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../actions/types";

const INITIAL_STATE = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  isSignedIn: null,
  loading: true,
  user: null,
  googleEmail: localStorage.getItem("email"),
  errorMessage: null,
};

export default function (state = INITIAL_STATE, action) {
  const { type, payload } = action;
  console.log("     ACTION ===" + action.type);
  switch (type) {
    case USER_SUCCESS:
      localStorage.setItem("email", payload.email);
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        isSignedIn: true,
        user: payload,
        googleEmail: payload.email,
      };

    case LOGIN_SUCCESS:
      localStorage.setItem("token", payload);
      return {
        ...state,
        token: payload,
        loading: false,
      };
    case EMAIL_SUCCESS:
      return {
        ...state,
        isSignedIn: true,
        loading: false,
        googleEmail: payload,
      };
    case USER_ERROR:
      return {
        ...state,
        loading: false,
        errorMessage: payload,
      };
    case RESET_ERROR:
      return {
        ...state,
        errorMessage: null,
      };
    case LOGOUT:
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        isSignedIn: false,
        loading: false,
        user: null,
        errorMessage: null,
      };
    default:
      return state;
  }
}

/*
 case GOOGLE_SUCCESS:
      return {
        ...state,
        isSignedIn: true,
        loading: false,
        googleEmail: payload,
      };
*/