import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'

import arrayMutators from 'final-form-arrays'
//import { FieldArray } from 'react-final-form-arrays'

import {
  Flex,
  Text,
  Heading,
  Button
} from '@chakra-ui/react';

import {
  ImagePreview,
  PreviewContainer,
} from "../file-upload/file-upload.styles";

import ibtColors from "../common/ibtColors";


export default class Wizard extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired
  }
  static Page = ({ children }) => children

  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      values: props.initialValues || {}
    }
  }
  next = values =>
    this.setState(state => ({
      page: Math.min(state.page + 1, this.props.children.length - 1),
      values
    }))

  previous = () =>
    this.setState(state => ({
      page: Math.max(state.page - 1, 0)
    }))

  records = () => {
    //this.setState(state => ({
    //  page: Math.max(state.page - 1, 0)
    // } ))
  }

  /**
   * NOTE: Both validate and handleSubmit switching are implemented
   * here because 🏁 Redux Final Form does not accept changes to those
   * functions once the form has been defined.
   */

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ]
    return activePage.props.validate ? activePage.props.validate(values) : {}
  }

  handleCancel = values => {
    return this.props.onSubmit("CANCEL")
  }

  handleSubmit = values => {
    const { children, onSubmit } = this.props
    const { page } = this.state
    const isLastPage = page === React.Children.count(children) - 1
    if (isLastPage) {
      return onSubmit(values)
    } else {
      this.next(values)
    }
  }



  render() {
    const { children } = this.props
    const { page, values } = this.state
    console.log('WIZARD' + (JSON.stringify(values.records, 0, 2)))
    const activePage = React.Children.toArray(children)[page]
    const isLastPage = page === React.Children.count(children) - 1
    return (
      <Form
        initialValues={values}
        validate={this.validate}
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators
        }}
        onSubmit={this.handleSubmit}
      >
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            {activePage}
            <div className="buttons">
              {page > 0 && (
                <Button type="button" onClick={this.previous}>
                  « Previous
                </Button>
              )}

              <Button onClick={this.handleCancel} type="cancel">Cancel »</Button>

              {!isLastPage &&
                <Button type="submit">Next »</Button>}
              {page === 4 && <Button onClick={this.records} >More »</Button>}

              {isLastPage && (
                <Button type="submit" disabled={submitting}>
                  Submit
                </Button>
              )}
            </div>
            <br />

            <Heading size="lg">Identifier: {values.itemName}</Heading>
            <div>
              <Flex align="center">
                {this.props.fileSelected ? (<PreviewContainer>
                  <ImagePreview
                    src={URL.createObjectURL(this.props.fileSelected)}
                    alt={`file preview`}
                  // height={100}
                  />
                </PreviewContainer>) : null}
              </Flex>
              <div>
                <Text size="xl" >Description: {values.text}</Text>
                <Text size="xl" >Review Item Date: {values.itemDate}</Text>

              </div>
              <div>

                {values.ibepets ? <Text size="xl" color={`${ibtColors.ibtpets}`}  >iBePets</Text> : null}
                {values.ibegone ? <Text color={`${ibtColors.ibtgone}`}>iBeGone</Text> : null}
                {values.ibehandy ? <Text color={`${ibtColors.ibthandy}`}>iBeHandy</Text> : null}
                {values.ibehouse ? <Text color={`${ibtColors.ibthouse}`}>iBeHouse</Text> : null}
                {values.ibehealth ? <Text color={`${ibtColors.ibthealth}`}>iBeHealth</Text> : null}
                {values.ibepaper ? <Text color={`${ibtColors.ibtpaper}`}>iBePaper</Text> : null}
                {values.ibemoney ? <Text color={`${ibtColors.ibtmoney}`}>iBeMoney</Text> : null}
                {values.ibemember ? <Text color={`${ibtColors.ibtmember}`}>iBeMember</Text> : null}

              </div>
              <div>

                {values.ibetechno ? <Text color={`${ibtColors.ibttechno}`}>iBeTechno</Text> : null}
                {values.ibeevent ? <Text color={`${ibtColors.ibtevent}`}>iBeEvent</Text> : null}
                {values.ibesocial ? <Text color={`${ibtColors.ibtsocial}`}>iBeSocial</Text> : null}
                {values.ibecontacts ? <Text color={`${ibtColors.ibtcontacts}`}>iBeContacts</Text> : null}
                {values.ibebusy ? <Text color={`${ibtColors.ibtbusy}`}>iBeBusy</Text> : null}
                {values.ibecareer ? <Text color={`${ibtColors.ibtcareer}`}>iBeCareer</Text> : null}
                {values.ibememoir ? <Text color={`${ibtColors.ibtmemoir}`}>iBeMemoir</Text> : null}
                {values.ibeframed ? <Text color={`${ibtColors.ibtframed}`}>iBeFramed</Text> : null}
              </div>
              {values.fields ? (<Text> {values.fields.records.length} </Text>) : null}

            </div>


          </form>
        )}
      </Form>
    )
  }
}