import React, { useState } from "react";

import {
  Box,
  Button,
  Text,
  //ButtonGroup,
  CSSReset,
  Heading,
  ThemeProvider,
  theme,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputRightElement,
  InputGroup,
  Input,
  useBreakpointValue,
} from '@chakra-ui/react';

import { Form, useField } from 'react-final-form'
import validate from './validateLogin'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const LoginForm = (props) => {

  const colSpan = useBreakpointValue({ base: 2, md: 1 })
  const formSpan = useBreakpointValue({ base: 1, md: 2 })
  const boxWidth = useBreakpointValue({ base: 260, md: 800 })

  const onSubmit = async values => {
    await props.onSubmit(values);
    await sleep(300)
    //window.alert(JSON.stringify(values, 0, 2))
  }


  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Box w={boxWidth} p={2} m="5px auto">


        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({
            handleSubmit,
            form,
            errors,
            subscription = { submitting: true },
            submitting,
            pristine,
            values
          }) => (
            <>

              <Box
                as="form"
                p={4}
                borderWidth="3px"
                rounded="lg"
                shadow="1px 1px 3px rgba(0,0,0,0.3)"
                onSubmit={handleSubmit}
              >
                <InputControl name="email" label="Email" type="email" required maxLength={60} />
                <InputControl name="password" label="Password"
                  type="password"
                  minLength={6} maxLength={40} />
                <br />
                <Button
                  isLoading={submitting}
                  loadingText="Submitting"
                  //colorScheme='#DBC14B'
                  colorScheme={"teal"}
                  size="lg"
                  // variantcolor="teal"
                  type="submit"
                >
                  Submit
                </Button>
                <br />
              </Box>
            </>
          )}
        />
      </Box>
    </ThemeProvider>
  )





}


const Control = ({ name, ...rest }) => {
  const {
    meta: { error, touched }
  } = useField(name, { subscription: { touched: true, error: true } })
  return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
  const {
    meta: { error }
  } = useField(name, { subscription: { error: true } })
  return <FormErrorMessage>{error}</FormErrorMessage>
}

const InputControl = ({ name, label, type, minLength, maxLength, pattern }) => {

  const [showPassword, setShowPassword] = useState(false);
  const { input, meta } = useField(name)


  return (
    <Control name={name} my={4}>
      <FormLabel htmlFor={name}>{label}</FormLabel>



      <InputGroup>
        {showPassword ? (<> <Input
          {...input}
          isInvalid={meta.error && meta.touched}
          id={name}
          placeholder={label}
          //  type={showPassword ? "text" : "password"}
          type="text"
          minLength={minLength}
          maxLength={maxLength}
          pattern={pattern}
        >

        </Input></>) : (<>
          <Input
            {...input}
            isInvalid={meta.error && meta.touched}
            id={name}
            placeholder={label}
            //  type={showPassword ? "text" : "password"}
            type={type}
            minLength={minLength}
            maxLength={maxLength}
            pattern={pattern}
          >

          </Input></>)}


        {type === 'password' ? (<InputRightElement width="4.5rem">
          <Button height="1.75rem" size="sm"
            onClick={() => setShowPassword(!showPassword)}
          >{showPassword ? 'Hide' : 'Show'}</Button>
        </InputRightElement>) : null}


      </InputGroup>

      <Error name={name} />
    </Control>
  )
}



export default LoginForm;

/*
                <InputControl name="email" label="Email" required  pattern={/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/}  />
 pattern={/\S+@\S+\.\S+/} 
 pattern={/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/}
*/