import React, { useEffect, useState } from 'react'
import { useSelector, connect } from "react-redux";

import {
  Text,
  Divider,
  Input,
  Heading,
  Stack,
  Avatar,
  Box,
  Button,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useBreakpointValue,

  //Link,
  useColorModeValue,
} from '@chakra-ui/react';

import { CheckIcon, } from '@chakra-ui/icons'

import ibtColors from "../../components/common/ibtColors";

import { NavLink, Outlet, useSearchParams, useParams, useLocation } from "react-router-dom";


import { refreshUser } from "../../actions/userAction"
import { switchUser } from "../../actions/switchAction"

const FlagList = ({ refreshUser, switchUser }) => {

  const [switchName, setSwitchName] = useState("");
  const [switchEmail, setSwitchEmail] = useState("");
  //  let items = getItems();
  let [searchParams, setSearchParams] = useSearchParams();

  let params = useParams();


  const availableUser = useSelector((state) => state.user.user);
  // const availableSwitch = useSelector((state) => state.switchUser.switchEntity);
  const availableToken = useSelector((state) => state.user.token)
  const availableEmail = useSelector((state) => state.user.googleEmail)
  // const availableEntity = useSelector((state) => state.entity.entity);
  const switchEntity = useSelector((state) => state.switchUser.switchEntity);

  const secondaryTextColor = useColorModeValue('gray.600', 'gray.400')

  const { isOpen, onOpen, onClose } = useDisclosure();

  const btnRef = React.useRef()
  const [isListOpen, setIsListOpen] = useState(true)
  const [chooseList, setChooseList] = useState('');


  const boxHeight = useBreakpointValue({ base: "40px", md: "60px" })
  const fontSize = useBreakpointValue({ base: 'xs', md: 'md' })
  const formContent = useBreakpointValue({ base: 'flex-start', ld: 'space-between' })
  const showMenu = useBreakpointValue({ base: false, lg: true })


  useEffect(() => {

    const refresh = async () => {
      await refreshUser(availableEmail)
    };

    const newUser = async (accountEmail) => {
      //    console.log('abc' + accountEmail)
      await switchUser(accountEmail)
    };

    if (availableToken && !availableUser) {
      refresh()
    }

    if (availableToken && availableUser) {
      availableUser.account.filter(act => {
        if (act.accountId === params.accountId) {
          setSwitchName(act.accountName)
          setSwitchEmail(act.accountEmail)
          return (newUser(act.accountEmail))
        }
      })
    }

  }, [availableUser, params.accountId, switchUser]);

  function QueryNavLink({ to, ...props }) {
    let location = useLocation();
    return <NavLink to={to + location.search} {...props} />;
  }

  return (
    <>
      <Box display={{ base: 'none', lg: 'flex' }}>
        <nav style={{ borderRight: "solid 1px", padding: "1rem" }} >
          <Heading>{switchName}'s Items</Heading>
          <Input
            value={searchParams.get("filter") || ""}
            placeholder="   Item Contains Search"
            onChange={event => {
              let filter = event.target.value;
              if (filter) {
                setSearchParams({ filter });
              } else {
                setSearchParams({});
              }
            }}
          />

          {switchEntity ? (
            switchEntity.item.filter(item => {
              let filter = searchParams.get("filter");
              if (!filter) return true;
              let name = item.name.toLowerCase();
              return name.startsWith(filter.toLowerCase());
            }).filter(flag => {
              if (flag.flagged) return true;
            }).filter(pick => {
              //console.log(pick.picklist.length)
              if (pick.picklist.length > 0)
                return true
            })
              .filter(pick => {
                //NEED A FOR LOOP OR SOMETHING
                for (let i = 0; i < pick.picklist.length; i++) {
                  if (pick.picklist[i].pickemail === availableUser.email)
                    return true
                }

              })
              .map(item => (
                <QueryNavLink key={item.name + item._id} style={({ isActive }) => {
                  return { display: "block", margin: ".5rem 0", color: isActive ? "red" : "black" };
                }}
                  to={`/flagList/${params.accountId}/${item._id}`}
                >
                  <Stack direction='row' h='62px' borderWidth={2} borderColor="teal" >
                    {item.image ? (<Avatar size={'lg'} src={item.image}
                    />) : <Avatar size={'lg'} bg="grey.100" icon={<CheckIcon fontSize='1.5rem' />}
                    />}
                    <Heading key={item.name + item._id} size="sm" mt={5} color={secondaryTextColor}>{item.name}</Heading>
                  </Stack>
                </QueryNavLink>

              ))
          ) : null}
        </nav>
        <Outlet />
      </Box>
      <Box display={{ base: 'flex', lg: 'none' }}>
        <Button ref={btnRef} colorScheme='teal' onClick={onOpen}>
          Auto Open Selection
        </Button>
        <Drawer
          isOpen={isOpen}
          placement='left'
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody>
              <>

                <Button onClick={() => setIsListOpen(!isListOpen)}
                  backgroundColor={"grey"} color="white">Show Lists</Button>
                <Input
                  value={searchParams.get("filter") || ""}
                  style={{ padding: ".25rem" }}
                  placeholder="   Item Contains Search"
                  onChange={event => {
                    let filter = event.target.value
                    if (filter) {
                      setSearchParams({ filter });
                    } else {
                      setSearchParams({});
                    }
                  }}
                />
                <br />
                {isListOpen ? (<>
                  <Box pb={4}  >
                    <Stack as={'nav'} spacing={1}>
                      <div >
                        <Box backgroundColor={`${ibtColors.ibtinventory}`} marginTop={1}>
                          <Text onClick={() => {
                            setChooseList('shared')
                            setIsListOpen(false)
                          }} fontSize="lg" color={"white"}
                            style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                          >switch items </Text>
                        </Box>
                      </div>

                    </Stack>
                  </Box>
                </>) : null}

                {switchEntity ? (
                  switchEntity.item
                    .filter(flag => {
                      if (flag.flagged) { return true } else { return false };
                    }).filter(pick => {
                      //console.log(pick.picklist.length)
                      if (pick.picklist.length > 0)
                        return true
                    })
                    .filter(pick => {
                      //NEED A FOR LOOP OR SOMETHING
                      for (let i = 0; i < pick.picklist.length; i++) {
                        if (pick.picklist[i].pickemail === availableUser.email)
                          return true
                      }
                    }).filter(item => {
                      let filter = searchParams.get("filter");
                      if (!filter) return true;
                      let name = item.name.toLowerCase();
                      //   return name.startsWith(filter.toLowerCase());
                      return name.includes(filter.toLowerCase());
                    }).map(item => (
                      <QueryNavLink onClick={onClose} key={item.name + item._id} style={({ isActive }) => {
                        return { display: "block", margin: ".5rem 0", color: isActive ? "red" : "black" };
                      }}
                        to={`/${params.accountId}/${item._id}`}
                      >
                        <Stack direction='row' h={boxHeight} borderWidth={2} borderColor="teal" >
                          {item.image ? (<Avatar size={'md'} src={item.image}
                          />) :
                            <Avatar size={'md'} bg="grey.100" icon={<CheckIcon fontSize='1.5rem' />}
                            />}
                          <Heading key={item.name + item._id} size={fontSize} mt={3} color={secondaryTextColor}>{item.name}</Heading>
                        </Stack>
                      </QueryNavLink>
                    ))
                ) : null}

              </>
            </DrawerBody>

            <DrawerFooter>
              <Button variant='outline' mr={3} onClick={onClose}>
                Cancel
              </Button>

            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Box>
    </>
  );
}


export default connect(null, { switchUser, refreshUser })(FlagList);