import React, { useState, useEffect } from 'react'
import { connect, useSelector } from "react-redux";
import Styles from '../wizard/StylesWizard'
import { Field, useField } from 'react-final-form'

import { FieldArray } from 'react-final-form-arrays'
import { useNavigate } from "react-router-dom";

//import arrayMutators from 'final-form-arrays'

//import { CreatableSelect } from "chakra-react-select";
import {
  ibepetsOptions, ibegoneOptions,
  ibehandyOptions, ibehealthOptions,
  ibehouseOptions, ibepaperOptions,
  ibemoneyOptions, ibememberOptions,
  ibetechnoOptions, ibeeventOptions,
  ibesocialOptions, ibecontactsOptions,
  ibebusyOptions, ibecareerOptions,
  ibememoirOptions, ibeframedOptions,

} from "../common/TypeData";



import {
  useColorMode,
  Button,
  Heading,
  CSSReset,
  //Icon,
  //Link,
  ThemeProvider,
  theme,
  useColorModeValue,
  useBreakpointValue,
  Box,
  Text,
  HStack,
  VStack,
  SimpleGrid,
  GridItem,
  //Grid,
  FormControl,
  //FormLabel,
  FormErrorMessage,
  //Input,
  Checkbox,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,

  useToast,
  //Table, Thead, Tbody, Tr, Th, Td, chakra,
} from '@chakra-ui/react';

import ibtColors from "../common/ibtColors";

import Wizard from '../wizard/ItemWizard'


import {
  ImagePreview,
  //PreviewContainer,
  PreviewContainer2,
} from "../file-upload/file-upload.styles";

//
import ImageUpload from '../items/ImageUpload'
import { uploadDocument } from "../../actions/itemAction"
import { refreshUser } from "../../actions/userAction"
import { itemNoImage } from "../../actions/itemAction";
//import { values } from 'lodash';




const ItemFast = ({ itemNoImage, uploadDocument, refreshUser }) => {


  const [photo, setPhoto] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [fileSelected, setFileSelected] = useState(null);
  //const [newDocInfo, setNewDocInfo] = useState({ profileImages: [] });
  const [attachment, setAttachment] = useState(null);
  const [attachSelected, setAttachSelected] = useState(null);
  const [chooseClass, setChooseClass] = useState('START');
  const [limitRecord, setLimitRecord] = useState(null);
  const [limitItem, setLimitItem] = useState(null);
  const [defaultDate, setDefaultDate] = useState(null);


  const [newThing, setNewThing] = useState(null);
  const [isThingOpen, setIsThingOpen] = useState(false)

  const onThingClose = () => {
    setIsThingOpen(false)
  }


  const [document, setDocument]
    = useState(['empty', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty'
      , 'empty', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty'
      , 'empty', 'empty', 'empty', 'empty', 'empty', 'empty']);
  const [documentSelected, setDocumentSelected] =
    useState(['empty', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty'
      , 'empty', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty', 'empty'
      , 'empty', 'empty', 'empty', 'empty', 'empty', 'empty']);
  const [documentIndex, setDocumentIndex] = useState(0);

  let navigate = useNavigate();
  const toast = useToast();
  const { toggleColorMode, colorMode } = useColorMode();
  const bgColor = useColorModeValue('#EDF2F7', '#2D3748')
  const textColor = useColorModeValue('#4A5568', '#A0AEC0')
  //  50: '#F7FAFC',
  // 100: '#EDF2F7',
  // 200: '#E2E8F0',
  // 300: '#CBD5E0',
  // 400: '#A0AEC0',
  // 500: '#718096',
  // 600: '#4A5568',
  // 700: '#2D3748',
  // 800: '#1A202C',
  // 900: '#171923',

  const availableUser = useSelector((state) => state.user.user);
  const availableEntity = useSelector((state) => state.entity.entity);
  const availableToken = useSelector((state) => state.user.token)
  const availableEmail = useSelector((state) => state.user.googleEmail)
  const availableDate = useSelector((state) => state.entity.defaultReviewDate)

  const colSpan = useBreakpointValue({ base: 1, md: 3 })
  const formSpan = useBreakpointValue({ base: 1, md: 2 })
  const boxWidth = useBreakpointValue({ base: 285, md: 800 })
  const baseClass = useBreakpointValue({ base: false, md: true })

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

  useEffect(() => {
    const refresh = async () => {
      await refreshUser(availableEmail)
    };

    if (availableToken && !availableUser) {
      refresh();
    }

    if (availableToken && availableUser) {
      if (availableEntity.item.length > 300) {
        setLimitItem('LIMIT')
      }
    }


  }, []);



  const onSubmit = async values => {

    //console.log(values)

    if (values === "CANCEL") {
      navigate("/inventory/itemlist")
    }

    const classItems = []

    if (values.ibepets) { classItems.push('ibepets') }
    if (values.ibegone) { classItems.push('ibegone') }
    if (values.ibehandy) { classItems.push('ibehandy') }
    if (values.ibehouse) { classItems.push('ibehouse') }
    if (values.ibehealth) { classItems.push('ibehealth') }
    if (values.ibepaper) { classItems.push('ibepaper') }
    if (values.ibemoney) { classItems.push('ibemoney') }
    if (values.ibemember) { classItems.push('ibemember') }
    if (values.ibetechno) { classItems.push('ibetechno') }
    if (values.ibeevent) { classItems.push('ibeevent') }
    if (values.ibesocial) { classItems.push('ibesocial') }
    if (values.ibecontacts) { classItems.push('ibecontacts') }
    if (values.ibebusy) { classItems.push('ibebusy') }
    if (values.ibecareer) { classItems.push('ibecareer') }
    if (values.ibememoir) { classItems.push('ibememoir') }


    for (let i = 0; i < values.records.length; i++) {
      //console.log('in validate records')
      // console.log(JSON.stringify(values.records, 0, 2))
      // console.log(JSON.stringify(values.records[i].type3.label, 0, 2))
      values.records[i].type = values.records[i].type3.label
      // if other - return to select something
      const checkThing = (res) => {
        return res.toLowerCase() === values.records[i].thing.toLowerCase();
      }

      const okayThing = classItems.find(checkThing)
      // console.log("OkayThing" + i + okayThing)

      if (!okayThing) {

        values.recordResponse = 'NOT OKAY'

        //  console.log(' not okay in new record' + okayThing)
        setNewThing(values.records[i].thing)
        setIsThingOpen(true);
      }
    }
    if (values !== "CANCEL") {
      values.image = photo

      //  console.log("document length" + document.length)

      for (let i = 0; i < document.length; i++) {
        if (document[i] !== 'empty') {
          values.records[i].document = document[i]
        }

      }


      if (values.recordResponse === 'NOT OKAY') {
        values.recordResponse = "INITIAL"
      } else {
        await sleep(300)
        //console.log(JSON.stringify(values, 0, 2))
        itemNoImage(values, availableUser._id, () => {
          navigate("/inventory/itemlist")
        })
      }
    }
  }


  const handlePhotoSelect = async (file) => {

    setIsUploading(true);
    //  Item Image
    let fileType2 = file.type.split("/")[1];
    const random2 = Math.random().toString().replace(/0\./, ""); // remove "0." from start of string
    const photoId = availableUser._id + random2 + "." + fileType2;
    const blobName2 = `https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBgMobile${photoId}`;
    const result = await uploadDocument(file, photoId, blobName2);
    setFileSelected(file)
    setPhoto(blobName2)
    setIsUploading(false);

    toast({
      duration: 5000, status: "success", isClosable: true,
      title: "Upload Complete.",
      description: "Saved your photo on infoBee!",
    });
  };

  const handleAddClass = (fields) => {
    // console.log(JSON.stringify(fields, 0, 2))
    if (fields.length > 18 || fields.length > availableUser.memberLimit) {
      setLimitRecord('LIMIT')
    } else {
      fields.push({
        thing: 'other', descrip: '',
        document: "",
        type: '', type2: '', type3: '',
        recordId: Math.random().toString().replace(/0\./, "")
      })
      setChooseClass('POPUP')
    }



    //console.log(JSON.stringify(fields, 0, 2))


  }

  const handleDocSelect = async (file, docIndex) => {
    //  console.log('INDEX' + docIndex )
    //  console.log('in handle select' + documentIndex)
    setIsUploading(true);
    // Record Image
    let fileType3 = file.type.split("/")[1];
    const random3 = Math.random().toString().replace(/0\./, ""); // remove "0." from start of string
    const docId = availableUser._id + random3 + "." + fileType3;
    //console.log(docId)
    const blobName3 = `https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBgMobile${docId}`;
    const result = await uploadDocument(file, docId, blobName3);
    setAttachSelected(file)
    setAttachment(blobName3)
    const tempSelected = documentSelected
    tempSelected[docIndex] = file
    // console.log(tempDocument)
    setDocumentSelected(tempSelected)
    const tempDocument = document
    tempDocument[docIndex] = blobName3
    // console.log(tempDocument)
    setDocument(tempDocument)
    //setDocument([blobName3])
    //console.log('Values in handledocselect' + values.document)
    setIsUploading(false);

    toast({
      duration: 5000, status: "success", isClosable: true,
      title: "Upload Attachment Complete.",
      description: "Saved your attachment on infoBee!",
    });
  };





  const Error = ({ name }) => (
    <Field
      name={name}
      subscription={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) =>
        touched && error ? <span>{error}</span> : null
      }
    />
  )

  const required = value => (value ? undefined : 'Required')




  return (
    <>
      <ThemeProvider theme={theme}>
        <CSSReset />
        {limitItem ? <Heading>ITEM LIMIT REACHED</Heading> : (
          <>
            <Styles>



              <Wizard
                initialValues={{
                  category: 'Other',
                  text: null,
                  image: null,
                  itemId: null,
                  itemDate: availableDate,
                  wishname: null,
                  wishitem: null,
                  flagged: false,
                  ibegone: false,
                  ibepets: false,
                  ibehandy: false,
                  ibehealth: false,
                  ibehouse: false,
                  ibepaper: false,
                  ibemoney: false,
                  ibemember: false,
                  ibetechno: false,
                  ibeevent: false,
                  ibesocial: false,
                  ibecontacts: false,
                  ibebusy: false,
                  ibecareer: false,
                  ibeframed: false,
                  records: [],
                  trackType: null,
                  wishes: [],
                  picklist: [],
                  comments: [],
                }}
                fileSelected={fileSelected}
                onSubmit={onSubmit}
              >

                <Wizard.Page>


                  <Box style={{ flexDirection: "column" }}>
                    <label style={{ color: textColor }}>Item Identifier</label>
                    <Field
                      name="itemName"
                      component="input"
                      type="text"
                      placeholder="Item Identification"
                      validate={required}
                      maxLength={20}
                      //  style={colorMode === 'light' ? {}

                      //   : { backgroundColor: '#4A5568', color: "green" }}

                      style={{ backgroundColor: bgColor, width: boxWidth }}
                    />
                    <Error name="itemName" />
                  </Box>
                  <Box style={{ flexDirection: "column" }}>
                    <label style={{ color: textColor }}>Description</label>
                    <Field name="text" component="textarea"
                      placeholder="Description and Notes"
                      maxLength={600}
                      style={{ backgroundColor: bgColor, width: boxWidth }} />
                  </Box>

                  <div>

                    <label style={{ color: textColor }}>Review Date</label>
                    <Field
                      name="itemDate"
                      component="input"
                      type="date"
                      style={{ backgroundColor: bgColor }}
                    /><Error name="itemDate" />
                  </div>
                  <br />
                  <div>

                    <ImageUpload isUploading={isUploading}
                      onPhotoSelect={handlePhotoSelect} label="Upload Image" />
                  </div>
                  <br />


                </Wizard.Page>


                <Wizard.Page>

                  <Text fontSize="xl" >Select Item Classification/s </Text>

                  {baseClass ? (
                    <>
                      <SimpleGrid columns={6} spacing={10} alignItems="center">

                        <VStack>
                          <div>
                            <CheckboxControl name="ibepets"
                              color={`${ibtColors.ibtpets}`}>iBePets</CheckboxControl>
                          </div>
                          {availableUser ? (<>
                            {availableUser.status === 'Basic'
                              || availableUser.status === 'Active'
                              ? (<>
                                <div  >
                                  <CheckboxControl name="ibegone"
                                    color={`${ibtColors.ibtgone}`}>iBeGone</CheckboxControl>
                                </div>
                              </>) : null}
                            {availableUser.status === 'Active'
                              ? (<>
                                <div  >
                                  <CheckboxControl name="ibetechno"
                                    color={`${ibtColors.ibttechno}`}>iBeTechno</CheckboxControl>
                                </div>
                                <div  >
                                  <CheckboxControl name="ibebusy"
                                    color={`${ibtColors.ibtbusy}`}>iBeBusy</CheckboxControl>
                                </div>
                              </>) : null}
                          </>) : null}

                        </VStack>

                        <VStack>
                          <div  >
                            <CheckboxControl name="ibecareer"
                              color={`${ibtColors.ibtcareer}`}>iBeCareer</CheckboxControl>
                          </div>
                          {availableUser ? (<>
                            {availableUser.status === 'Basic'
                              || availableUser.status === 'Active'
                              ? (<>

                                <div  >
                                  <CheckboxControl name="ibepaper"
                                    color={`${ibtColors.ibtpaper}`}>iBePaper</CheckboxControl>
                                </div>
                              </>) : null}
                            {availableUser.status === 'Active'
                              ? (<>
                                <div  >
                                  <CheckboxControl name="ibeevent"
                                    color={`${ibtColors.ibtevent}`}>iBeEvent</CheckboxControl>
                                </div>
                                <div  >
                                  <CheckboxControl name="ibememoir"
                                    color={`${ibtColors.ibtmemoir}`}>iBeMemoir</CheckboxControl>
                                </div>
                              </>) : null}
                          </>) : null}
                        </VStack>

                        <VStack>
                          <div  >
                            <CheckboxControl name="ibehandy"
                              color={`${ibtColors.ibthandy}`}>iBeHandy</CheckboxControl>
                          </div>
                          {availableUser ? (<>
                            {availableUser.status === 'Basic'
                              || availableUser.status === 'Active'
                              ? (<>
                                <div  >
                                  <CheckboxControl name="ibemoney"
                                    color={`${ibtColors.ibtmoney}`}>iBeMoney</CheckboxControl>
                                </div>

                              </>) : null}
                            {availableUser.status === 'Active'
                              ? (<>
                                <div  >
                                  <CheckboxControl name="ibesocial"
                                    color={`${ibtColors.ibtsocial}`}>iBeSocial</CheckboxControl>
                                </div>
                                <div  >
                                  <CheckboxControl name="ibehealth"
                                    color={`${ibtColors.ibthealth}`}>iBeHealth</CheckboxControl>
                                </div>
                              </>) : null}
                          </>) : null}

                        </VStack>

                        <VStack>
                          {availableUser ? (<>
                            {availableUser.status === 'Basic'
                              || availableUser.status === 'Active'
                              ? (<>
                                <div  >
                                  <CheckboxControl name="ibehouse"
                                    color={`${ibtColors.ibthouse}`}>iBeHouse</CheckboxControl>
                                </div>
                                <div  >
                                  <CheckboxControl name="ibemember"
                                    color={`${ibtColors.ibtmember}`}>iBeMember</CheckboxControl>
                                </div>
                              </>) : null}
                            {availableUser.status === 'Active'
                              ? (<>
                                <div  >
                                  <CheckboxControl name="ibecontacts"
                                    color={`${ibtColors.ibtcontacts}`}>iBeContacts</CheckboxControl>
                                </div>
                                <div  >
                                  <CheckboxControl name="ibeframed"
                                    color={`${ibtColors.ibtframed}`}>iBeFramed</CheckboxControl>
                                </div>
                              </>) : null}
                          </>) : null}

                        </VStack>






                      </SimpleGrid>



                    </>
                  ) : (<>

                    <SimpleGrid columns={6} spacing={10} alignItems="center">

                      <VStack>
                        <div>
                          <CheckboxControl name="ibepets"
                            color={`${ibtColors.ibtpets}`}>iBePets</CheckboxControl>
                        </div>
                        <div  >
                          <CheckboxControl name="ibehandy"
                            color={`${ibtColors.ibthandy}`}>iBeHandy</CheckboxControl>
                        </div>
                        {availableUser ? (<>
                          {availableUser.status === 'Basic'
                            || availableUser.status === 'Active'
                            ? (<>
                              <div  >
                                <CheckboxControl name="ibegone"
                                  color={`${ibtColors.ibtgone}`}>iBeGone</CheckboxControl>
                              </div>
                              <div  >
                                <CheckboxControl name="ibemoney"
                                  color={`${ibtColors.ibtmoney}`}>iBeMoney</CheckboxControl>
                              </div>
                            </>) : null}
                          {availableUser.status === 'Active'
                            ? (<>
                              <div  >
                                <CheckboxControl name="ibetechno"
                                  color={`${ibtColors.ibttechno}`}>iBeTechno</CheckboxControl>
                              </div>
                              <div  >
                                <CheckboxControl name="ibebusy"
                                  color={`${ibtColors.ibtbusy}`}>iBeBusy</CheckboxControl>
                              </div>
                              <div  >
                                <CheckboxControl name="ibesocial"
                                  color={`${ibtColors.ibtsocial}`}>iBeSocial</CheckboxControl>
                              </div>
                              <div  >
                                <CheckboxControl name="ibehealth"
                                  color={`${ibtColors.ibthealth}`}>iBeHealth</CheckboxControl>
                              </div>
                            </>) : null}
                        </>) : null}
                      </VStack>

                      <VStack>
                        <div  >
                          <CheckboxControl name="ibecareer"
                            color={`${ibtColors.ibtcareer}`}>iBeCareer</CheckboxControl>
                        </div>
                        {availableUser ? (<>
                          {availableUser.status === 'Basic'
                            || availableUser.status === 'Active'
                            ? (<>

                              <div  >
                                <CheckboxControl name="ibehouse"
                                  color={`${ibtColors.ibthouse}`}>iBeHouse</CheckboxControl>
                              </div>
                              <div  >
                                <CheckboxControl name="ibepaper"
                                  color={`${ibtColors.ibtpaper}`}>iBePaper</CheckboxControl>
                              </div>
                              <div  >
                                <CheckboxControl name="ibemember"
                                  color={`${ibtColors.ibtmember}`}>iBeMember</CheckboxControl>
                              </div>

                            </>) : null}
                          {availableUser.status === 'Active'
                            ? (<>

                              <div  >
                                <CheckboxControl name="ibeevent"
                                  color={`${ibtColors.ibtevent}`}>iBeEvent</CheckboxControl>
                              </div>
                              <div  >
                                <CheckboxControl name="ibememoir"
                                  color={`${ibtColors.ibtmemoir}`}>iBeMemoir</CheckboxControl>
                              </div>
                              <div  >
                                <CheckboxControl name="ibecontacts"
                                  color={`${ibtColors.ibtcontacts}`}>iBeContacts</CheckboxControl>
                              </div>
                              <div  >
                                <CheckboxControl name="ibeframed"
                                  color={`${ibtColors.ibtframed}`}>iBeFramed</CheckboxControl>
                              </div>
                            </>) : null}
                        </>) : null}
                      </VStack>




                    </SimpleGrid>


                  </>)}









                </Wizard.Page>

                <Wizard.Page>

                  {baseClass ? (<>

                    <FieldArray name="records">
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => (

                            <div style={{ borderWidth: 3, borderColor: "black" }}>

                              {setDocumentIndex(index)}

                              <HStack key={name}>

                                <SimpleGrid columns={5} columnGap={1} rowGap={1} width="full">

                                  <GridItem colSpan={1} mt={2}>
                                    <VStack>
                                      <Heading style={{ color: textColor }}> {index + 1}.</Heading>

                                      <br />
                                      <Button type="button" ml={6} size="sm"
                                        variant="outline"
                                        colorScheme="teal" onClick={() => fields.remove(index)}>
                                        Remove

                                      </Button>
                                      <br />

                                    </VStack>

                                  </GridItem>

                                  <GridItem colSpan={3} mt={2}>

                                    <VStack>

                                      {chooseClass === 'POPUP' ?
                                        (
                                          <div style={{ margin: 2 }}>
                                            <br />
                                            <Heading size="sm" style={{ color: textColor }}>Classification</Heading>
                                            <Field name={`${name}.thing`} component="select"
                                              style={{ backgroundColor: bgColor }}
                                              placeholder="Select a class"
                                              closemenuonselect="false" size="lg">
                                              <option value="inventory" >Select a Classification</option>
                                              <option value="iBePets" >iBePets</option>
                                              <option value="iBeCareer">iBeCareer</option>
                                              <option value="iBeHandy">iBeHandy</option>
                                              {availableUser ? (<>
                                                {availableUser.status === 'Basic'
                                                  || availableUser.status === 'Active'
                                                  ? (<>
                                                    <option value="iBeHouse">iBeHouse</option>
                                                    <option value="iBeGone">iBeGone</option>
                                                    <option value="iBePaper">iBePaper</option>
                                                    <option value="iBeMoney">iBeMoney</option>
                                                    <option value="iBeMember">iBeMember</option>
                                                  </>) : null}


                                                {availableUser.status === 'Active'
                                                  ? (<>
                                                    <option value="iBeTechno">iBeTechno</option>
                                                    <option value="iBeEvent">iBeEvent</option>
                                                    <option value="iBeSocial">iBeSocial</option>
                                                    <option value="iBeContacts">iBeContacts</option>
                                                    <option value="iBeBusy">iBeBusy</option>

                                                    <option value="iBeMemoir">iBeMemoir</option>

                                                    <option value="iBeHealth" >iBeHealth</option>
                                                    <option value="iBeFramed">iBeFramed</option>
                                                  </>) : null}
                                              </>) : null}
                                            </Field>

                                            <Error name="classifications" />
                                          </div>
                                        ) : null}


                                    </VStack>
                                  </GridItem>



                                  {fields.value[index].thing !== 'other' ? (
                                    <>


                                      <div flexDirection="center">

                                        {document[index] !== 'empty' ? (<>

                                          <PreviewContainer2>
                                            <ImagePreview
                                              src={URL.createObjectURL(documentSelected[index])}
                                              alt={`file preview`}
                                            />
                                          </PreviewContainer2>
                                          <ImageUpload isUploading={isUploading}
                                            onPhotoSelect={handleDocSelect} docIndex={index} label={"Upload  "} />

                                        </>

                                        ) : (<>
                                          <br />
                                          <Text>Upload Image or PDF</Text>
                                          <ImageUpload isUploading={isUploading}
                                            onPhotoSelect={handleDocSelect} docIndex={index} label={"Upload  "} />
                                        </>)}


                                      </div>







                                    </>

                                  ) : null}


                                </SimpleGrid>

                                {fields.value[index].thing !== 'other' ? (
                                  <>



                                    <div  >
                                      <br />
                                      <Heading size="md" ml={270} mr={270} style={{ color: textColor }}>  Details</Heading>
                                      <Field name={`${name}.descrip`} component="textarea"
                                        placeholder={`Details for the Record (${fields.value[index].thing}) `}
                                        maxLength={600}
                                        style={{ backgroundColor: bgColor, width: 450 }}
                                      // Add later validate={required} 
                                      />

                                    </div>

                                  </>
                                ) : null}

                                <AlertDialog

                                  isOpen={isThingOpen}
                                  // leastDestructiveRef={cancelThingRef}
                                  onClose={onThingClose}
                                >

                                  <AlertDialogOverlay>
                                    <AlertDialogContent>
                                      <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                        Classification Not Selected </AlertDialogHeader>
                                      <AlertDialogBody> Pick a different classification
                                        or return to previous screen to add it for the item.
                                        A list of selected classifications can be seen at the bottom of the screen.
                                      </AlertDialogBody>
                                      <AlertDialogFooter>
                                        <Button colorScheme='teal' onClick={onThingClose}> okay </Button>

                                      </AlertDialogFooter>
                                    </AlertDialogContent>
                                  </AlertDialogOverlay>
                                </AlertDialog>
                              </HStack>




                            </div>

                          ))}

                          <br />
                          {fields.length === 0 ? (<div >
                            <button
                              type="button"
                              onClick={() => handleAddClass(fields)}
                            >
                              Add a Record
                            </button>
                          </div>) : (<div >
                            <button
                              type="button"
                              onClick={() => handleAddClass(fields)}
                            >
                              Add More Records
                            </button>

                            {limitRecord ? <Heading size="md" >Maximum Number of Records Reached</Heading> : null}
                          </div>)}
                        </>



                      )}


                    </FieldArray>

                  </>) : (<>

                    <FieldArray name="records">
                      {({ fields }) => (
                        <>
                          {fields.map((name, index) => (

                            <div style={{
                              borderWidth: 3, borderColor: "black", margin: 1
                            }}>

                              {setDocumentIndex(index)}
                              <VStack key={name} style={{
                                alignItems: "flex-start"

                              }} >

                                <Heading style={{ color: textColor }}> {index + 1}.</Heading>

                                <Button type="button" ml={4} size="sm"
                                  variant="outline"
                                  colorScheme="teal" onClick={() => fields.remove(index)}>
                                  Remove

                                </Button>

                                {chooseClass === 'POPUP' ?
                                  (
                                    <div style={{ margin: 1 }}>
                                      <br />
                                      <Heading size="sm" style={{ color: textColor }}>Classification</Heading>
                                      <Field name={`${name}.thing`} component="select"
                                        style={{ backgroundColor: bgColor }}
                                        placeholder="Select a class"
                                        closemenuonselect="false" size="lg">
                                        <option value="inventory" >Select a Classification</option>
                                        <option value="iBePets" >iBePets</option>
                                        <option value="iBeCareer">iBeCareer</option>
                                        <option value="iBeHandy">iBeHandy</option>
                                        {availableUser ? (<>
                                          {availableUser.status === 'Basic'
                                            || availableUser.status === 'Active'
                                            ? (<>
                                              <option value="iBeHouse">iBeHouse</option>
                                              <option value="iBeGone">iBeGone</option>
                                              <option value="iBePaper">iBePaper</option>
                                              <option value="iBeMoney">iBeMoney</option>
                                              <option value="iBeMember">iBeMember</option>
                                            </>) : null}


                                          {availableUser.status === 'Basic'
                                            || availableUser.status === 'Active'
                                            ? (<>
                                              <option value="iBeTechno">iBeTechno</option>
                                              <option value="iBeEvent">iBeEvent</option>
                                              <option value="iBeSocial">iBeSocial</option>
                                              <option value="iBeContacts">iBeContacts</option>
                                              <option value="iBeBusy">iBeBusy</option>

                                              <option value="iBeMemoir">iBeMemoir</option>

                                              <option value="iBeHealth" >iBeHealth</option>
                                              <option value="iBeFramed">iBeFramed</option>
                                            </>) : null}
                                        </>) : null}
                                      </Field>

                                      <Error name="classifications" />
                                    </div>
                                  ) : null}







                                {fields.value[index].thing !== 'other' ? (
                                  <>


                                    <div>

                                      {document[index] !== 'empty' ? (<>

                                        <PreviewContainer2>
                                          <ImagePreview
                                            src={URL.createObjectURL(documentSelected[index])}
                                            alt={`file preview`}
                                          />
                                        </PreviewContainer2>
                                        <ImageUpload isUploading={isUploading}
                                          onPhotoSelect={handleDocSelect} docIndex={index} label={"Upload  "} />

                                      </>

                                      ) : (<>
                                        <br />
                                        <Text>Upload Image or PDF</Text>
                                        <ImageUpload isUploading={isUploading}
                                          onPhotoSelect={handleDocSelect} docIndex={index} label={"Upload  "} />
                                      </>)}

                                    </div>


                                  </>

                                ) : null}


                                {fields.value[index].thing !== 'other' ? (
                                  <>



                                    <Box flex={1} >
                                      <br />
                                      <Heading size="md" ml={70} mr={70} style={{ color: textColor }}>  Details</Heading>
                                      <Field name={`${name}.descrip`} component="textarea"
                                        placeholder={`Details for the Record (${fields.value[index].thing}) `}
                                        maxLength={600}
                                        style={{ backgroundColor: bgColor, width: boxWidth }}
                                      // Add later validate={required} 
                                      />
                                      <Error name={`${name}.descrip`} />
                                    </Box>

                                  </>
                                ) : null}

                              </VStack>


                              <AlertDialog

                                isOpen={isThingOpen}
                                // leastDestructiveRef={cancelThingRef}
                                onClose={onThingClose}
                              >

                                <AlertDialogOverlay>
                                  <AlertDialogContent>
                                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                      Classification Not Selected </AlertDialogHeader>
                                    <AlertDialogBody> Pick a different classification
                                      or return to previous screen to add it for the item.
                                      A list of selected classifications can be seen at the bottom of the screen.
                                    </AlertDialogBody>
                                    <AlertDialogFooter>
                                      <Button colorScheme='teal' onClick={onThingClose}> okay </Button>

                                    </AlertDialogFooter>
                                  </AlertDialogContent>
                                </AlertDialogOverlay>
                              </AlertDialog>



                            </div>

                          ))}

                          <br />
                          {fields.length === 0 ? (<div >
                            <Button
                              type="button"
                              onClick={() => handleAddClass(fields)}
                            >
                              Add a Record
                            </Button>
                          </div>) : (<div >
                            <Button
                              type="button"
                              onClick={() => handleAddClass(fields)}
                            >
                              Add More Records
                            </Button>

                            {limitRecord ? <Heading size="md" >Maximum Number of Records Reached</Heading> : null}
                          </div>)}
                        </>



                      )}


                    </FieldArray>
                  </>)}


                </Wizard.Page>
              </Wizard>
            </Styles>
          </>
        )
        }

      </ThemeProvider>
    </>
  )


}


const CheckboxControl = ({ name, value, children, color, maxLength }) => {
  //console.log('checkbox' + name + value + children)
  const {
    input: { checked, ...input },
    meta: { error, touched, invalid }
  } = useField(name, {
    type: 'checkbox' // important for RFF to manage the checked prop
  })
  return (
    <FormControl isInvalid={touched && invalid} my={4}>
      <Checkbox spacing='1.5rem' iconSize='12rem' color={color} size="lg"
        maxLength={maxLength}
        {...input} isInvalid={touched && invalid} my={2}>

        {children}


      </Checkbox>

      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}






export default connect(null, { itemNoImage, uploadDocument, refreshUser })(ItemFast);

/*
const PetsSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibepetsOptions}
        placeholder="Start typing for a custom label"
        style={{
          color: textColor, backgroundColor: "red"
        }}
        closeMenuOnSelect={true} >

      </CreatableSelect >
    )
  }

  const GoneSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibegoneOptions}
        placeholder="Start typing for a custom label"
        style={{ color: textColor, }}
        closeMenuOnSelect={true}>

      </CreatableSelect>
    )
  }

  const HandySelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibehandyOptions}
        placeholder="Start typing for a custom label"
        style={{ color: textColor, backgroundColor: bgColor }}
        closeMenuOnSelect={true}>

      </CreatableSelect>
    )
  }
  const HouseSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibehouseOptions}
        placeholder="Start typing for a custom label"
        style={{ color: textColor, backgroundColor: bgColor }}
        closeMenuOnSelect={true}>

      </CreatableSelect>
    )
  }
  const HealthSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibehealthOptions}
        placeholder="Start typing for a custom label"
        style={{ color: textColor, backgroundColor: bgColor }}
        closeMenuOnSelect={true}>

      </CreatableSelect>
    )
  }
  const PaperSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibepaperOptions}
        placeholder="Start typing for a custom label"
        style={{ color: textColor, backgroundColor: bgColor }}
        closeMenuOnSelect={true}>

      </CreatableSelect>
    )
  }
  const MoneySelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibemoneyOptions}
        placeholder="Start typing for a custom label"
        style={{ color: textColor, backgroundColor: bgColor }}
        closeMenuOnSelect={true}>

      </CreatableSelect>
    )
  }
  const MemberSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibememberOptions}
        placeholder="Start typing for a custom label"
        style={{ color: textColor, backgroundColor: bgColor }}
        closeMenuOnSelect={true}>

      </CreatableSelect>
    )
  }
  const TechnoSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibetechnoOptions}
        placeholder="Start typing for a custom label"
        style={{ color: textColor, backgroundColor: bgColor }}
        closeMenuOnSelect={true}>

      </CreatableSelect>
    )
  }
  const EventSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibeeventOptions}
        placeholder="Start typing for a custom label"
        style={{ color: textColor, backgroundColor: bgColor }}
        closeMenuOnSelect={true}>

      </CreatableSelect>
    )
  }
  const SocialSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibesocialOptions}
        placeholder="Start typing for a custom label"
        style={{ color: textColor, backgroundColor: bgColor }}
        closeMenuOnSelect={true}>

      </CreatableSelect>
    )
  }
  const ContactsSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibecontactsOptions}
        placeholder="Start typing for a custom label"
        style={{ color: textColor, backgroundColor: bgColor }}
        closeMenuOnSelect={true}>

      </CreatableSelect>
    )
  }
  const BusySelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibebusyOptions}
        placeholder="Start typing for a custom label"
        style={{ color: textColor, backgroundColor: bgColor }}
        closeMenuOnSelect={true}>

      </CreatableSelect>
    )
  }
  const CareerSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibecareerOptions}
        placeholder="Start typing for a custom label"
        style={{ color: textColor, backgroundColor: bgColor }}
        closeMenuOnSelect={true}>

      </CreatableSelect>
    )
  }
  const MemoirSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibememoirOptions}
        placeholder="Start typing for a custom label"
        style={{ color: textColor, backgroundColor: bgColor }}
        closeMenuOnSelect={true}>

      </CreatableSelect>
    )
  }
  const FramedSelectAdapter = ({ input, ...rest }) => {
    // console.log(" inselector" +  (JSON.stringify(input, 0, 2)))
    return (
      <CreatableSelect
        {...input} {...rest}
        //searchable               
        options={ibeframedOptions}
        placeholder="Start typing for a custom label"
        style={{ color: textColor, backgroundColor: bgColor }}
        closeMenuOnSelect={true}>

      </CreatableSelect>
    )
  }//



    <br />
                                                <Heading size="md" ml={70} mr={70} style={{ color: textColor }}>{fields.value[index].thing}  Label</Heading>

   <br />
                                          <Heading size="md" ml={70} mr={70} style={{ color: textColor }}>{fields.value[index].thing}  Label</Heading>


    
                                    {fields.value[index].thing !== 'other' ? (
                                      <>




                                        <div style={{ margin: 2 }}>



                                          {fields.value[index].thing === 'iBePetsX' ?
                                            (<Field
                                              name={`${name}.type3`}
                                              component={PetsSelectAdapter}
                                              style={{ backgroundColor: bgColor }}
                                            />) : null
                                          }

                                          {fields.value[index].thing === 'iBeGoneX' ?
                                            (
                                              <Field
                                                name={`${name}.type3`} maxLength={15}

                                                style={{ backgroundColor: bgColor }}
                                                component={GoneSelectAdapter}
                                              />) : null
                                          }

                                          {fields.value[index].thing === 'iBeHandyX' ?
                                            (
                                              <Field
                                                name={`${name}.type3`} maxLength={15}
                                                style={{ backgroundColor: bgColor }}
                                                component={HandySelectAdapter}
                                              />) : null
                                          }

                                          {fields.value[index].thing === 'iBeHouseX' ?
                                            (
                                              <Field
                                                name={`${name}.type3`} maxLength={15}

                                                style={{ backgroundColor: bgColor }}
                                                component={HouseSelectAdapter}
                                              />) : null
                                          }

                                          {fields.value[index].thing === 'iBeHealthX' ?
                                            (
                                              <Field
                                                name={`${name}.type3`} maxLength={15}

                                                style={{ backgroundColor: bgColor }}
                                                component={HealthSelectAdapter}
                                              />) : null
                                          }

                                          {fields.value[index].thing === 'iBePaperX' ?
                                            (
                                              <Field
                                                name={`${name}.type3`} maxLength={15}

                                                style={{ backgroundColor: bgColor }}
                                                component={PaperSelectAdapter}
                                              />) : null
                                          }

                                          {fields.value[index].thing === 'iBeMoneyX' ?
                                            (
                                              <Field
                                                name={`${name}.type3`} maxLength={15}

                                                style={{ backgroundColor: bgColor }}
                                                component={MoneySelectAdapter}
                                              />) : null
                                          }


                                          {fields.value[index].thing === 'iBeMemberX' ?
                                            (
                                              <Field
                                                name={`${name}.type3`} maxLength={15}

                                                style={{ backgroundColor: bgColor }}
                                                component={MemberSelectAdapter}
                                              />) : null
                                          }

                                          {fields.value[index].thing === 'iBeTechnoX' ?
                                            (
                                              <Field
                                                name={`${name}.type3`} maxLength={15}

                                                style={{ backgroundColor: bgColor }}
                                                component={TechnoSelectAdapter}
                                              />) : null
                                          }

                                          {fields.value[index].thing === 'iBeEventX' ?
                                            (
                                              <Field
                                                name={`${name}.type3`} maxLength={15}

                                                style={{ backgroundColor: bgColor }}
                                                component={EventSelectAdapter}
                                              />) : null
                                          }

                                          {fields.value[index].thing === 'iBeSocialX' ?
                                            (
                                              <Field
                                                name={`${name}.type3`} maxLength={15}

                                                style={{ backgroundColor: bgColor }}
                                                component={SocialSelectAdapter}
                                              />) : null
                                          }

                                          {fields.value[index].thing === 'iBeContactsX' ?
                                            (
                                              <Field
                                                name={`${name}.type3`} maxLength={15}

                                                style={{ backgroundColor: bgColor }}
                                                component={ContactsSelectAdapter}
                                              />) : null
                                          }

                                          {fields.value[index].thing === 'iBeBusyX' ?
                                            (
                                              <Field
                                                name={`${name}.type3`} maxLength={15}

                                                style={{ backgroundColor: bgColor }}
                                                component={BusySelectAdapter}
                                              />) : null
                                          }

                                          {fields.value[index].thing === 'iBeCareerX' ?
                                            (
                                              <Field
                                                name={`${name}.type3`} maxLength={15}

                                                style={{ backgroundColor: bgColor }}
                                                component={CareerSelectAdapter}
                                              />) : null
                                          }

                                          {fields.value[index].thing === 'iBeMemoirX' ?
                                            (
                                              <Field
                                                name={`${name}.type3`} maxLength={15}

                                                style={{ backgroundColor: bgColor }}
                                                component={MemoirSelectAdapter}
                                              />) : null
                                          }


                                          {fields.value[index].thing === 'iBeFramedX' ?
                                            (
                                              <Field
                                                name={`${name}.type3`} maxLength={15}

                                                style={{ backgroundColor: bgColor }}
                                                component={FramedSelectAdapter}
                                              />) : null
                                          }

                                        </div>


                                      </>
                                    ) : null}                                      

                                          {fields.value[index].thing !== 'other' ? (
                                            <>
                                              <div  >

                                                {fields.value[index].thing === 'iBePetsX' ?
                                                  (<Field
                                                    name={`${name}.type3`}
                                                    component={PetsSelectAdapter}
                                                    style={{ backgroundColor: bgColor }}
                                                  />) : null
                                                }

                                                {fields.value[index].thing === 'iBeGoneX' ?
                                                  (
                                                    <Field
                                                      name={`${name}.type3`} maxLength={15}

                                                      style={{ backgroundColor: bgColor }}
                                                      component={GoneSelectAdapter}
                                                    />) : null
                                                }

                                                {fields.value[index].thing === 'iBeHandyX' ?
                                                  (
                                                    <Field
                                                      name={`${name}.type3`} maxLength={15}
                                                      style={{ backgroundColor: bgColor }}
                                                      component={HandySelectAdapter}
                                                    />) : null
                                                }

                                                {fields.value[index].thing === 'iBeHouseX' ?
                                                  (
                                                    <Field
                                                      name={`${name}.type3`} maxLength={15}

                                                      style={{ backgroundColor: bgColor }}
                                                      component={HouseSelectAdapter}
                                                    />) : null
                                                }

                                                {fields.value[index].thing === 'iBeHealthX' ?
                                                  (
                                                    <Field
                                                      name={`${name}.type3`} maxLength={15}

                                                      style={{ backgroundColor: bgColor }}
                                                      component={HealthSelectAdapter}
                                                    />) : null
                                                }

                                                {fields.value[index].thing === 'iBePaperX' ?
                                                  (
                                                    <Field
                                                      name={`${name}.type3`} maxLength={15}

                                                      style={{ backgroundColor: bgColor }}
                                                      component={PaperSelectAdapter}
                                                    />) : null
                                                }

                                                {fields.value[index].thing === 'iBeMoneyX' ?
                                                  (
                                                    <Field
                                                      name={`${name}.type3`} maxLength={15}

                                                      style={{ backgroundColor: bgColor }}
                                                      component={MoneySelectAdapter}
                                                    />) : null
                                                }


                                                {fields.value[index].thing === 'iBeMemberX' ?
                                                  (
                                                    <Field
                                                      name={`${name}.type3`} maxLength={15}

                                                      style={{ backgroundColor: bgColor }}
                                                      component={MemberSelectAdapter}
                                                    />) : null
                                                }

                                                {fields.value[index].thing === 'iBeTechnoX' ?
                                                  (
                                                    <Field
                                                      name={`${name}.type3`} maxLength={15}

                                                      style={{ backgroundColor: bgColor }}
                                                      component={TechnoSelectAdapter}
                                                    />) : null
                                                }

                                                {fields.value[index].thing === 'iBeEventX' ?
                                                  (
                                                    <Field
                                                      name={`${name}.type3`} maxLength={15}

                                                      style={{ backgroundColor: bgColor }}
                                                      component={EventSelectAdapter}
                                                    />) : null
                                                }

                                                {fields.value[index].thing === 'iBeSocialX' ?
                                                  (
                                                    <Field
                                                      name={`${name}.type3`} maxLength={15}

                                                      style={{ backgroundColor: bgColor }}
                                                      component={SocialSelectAdapter}
                                                    />) : null
                                                }

                                                {fields.value[index].thing === 'iBeContactsX' ?
                                                  (
                                                    <Field
                                                      name={`${name}.type3`} maxLength={15}

                                                      style={{ backgroundColor: bgColor }}
                                                      component={ContactsSelectAdapter}
                                                    />) : null
                                                }

                                                {fields.value[index].thing === 'iBeBusyX' ?
                                                  (
                                                    <Field
                                                      name={`${name}.type3`} maxLength={15}

                                                      style={{ backgroundColor: bgColor }}
                                                      component={BusySelectAdapter}
                                                    />) : null
                                                }

                                                {fields.value[index].thing === 'iBeCareerX' ?
                                                  (
                                                    <Field
                                                      name={`${name}.type3`} maxLength={15}

                                                      style={{ backgroundColor: bgColor }}
                                                      component={CareerSelectAdapter}
                                                    />) : null
                                                }

                                                {fields.value[index].thing === 'iBeMemoirX' ?
                                                  (
                                                    <Field
                                                      name={`${name}.type3`} maxLength={15}

                                                      style={{ backgroundColor: bgColor }}
                                                      component={MemoirSelectAdapter}
                                                    />) : null
                                                }


                                                {fields.value[index].thing === 'iBeFramedX' ?
                                                  (
                                                    <Field
                                                      name={`${name}.type3`} maxLength={15}

                                                      style={{ backgroundColor: bgColor }}
                                                      component={FramedSelectAdapter}
                                                    />) : null
                                                }

                                              </div>


                                            </>
                                          ) : null}


   <div>
                                  
                                    
                                    { fields.value[index].thing === 'iBePets' ?
                                      (<Field
                                      name={`${name}.type3`}
                                      component={PetsSelectAdapter}
                                      />) :  null
                                     }
                                    
                                     { fields.value[index].thing === 'iBeGone' ?
                                      (<Field
                                      name={`${name}.type3`}
                                      component={ReactSelectAdapter}
                                      />) :  null
                                     }   



                                    </div>
                                 

const ItemFast = ({ itemNoImage, uploadDocument }) => {





   

  
     
  
  
  return (
  
    ) 
    
   
}
 



export default connect(null, { itemNoImage, uploadDocument })(ItemFast);

 
   <Tooltip hasArrow label='Search places' bg='gray.300' color='black'>
                  HOVER OVER ME FOR TOOP TIP NOT WORKING
                </Tooltip>
                 <Tooltip hasArrow label='Search places' bg='gray.300' color='black'>
                    <Heading size="lg">Select Item Classification/s </Heading>
                   
                </Tooltip>
 


*/