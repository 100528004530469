import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    Box,
    Flex,
    Text,
    Center,
    Button,
    HStack,
    useColorModeValue,
    Heading,
    AspectRatio,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react';
//import './Dashboard.css';

//import logo from '../../icon.png';

//import LoginForm from '../forms/LoginForm'
import { googleSignOut } from "../../actions/userAction"

const HelpPage = ({ googleSignOut }) => {
    const [confirmEmail, setConfirmEmail] = useState("");

    let navigate = useNavigate();

    const availableUser = useSelector((state) => state.user.user);
    const availMessage = useSelector((state) => state.user.errorMessage);


    const bg = useColorModeValue('gray.200', 'gray.700')

    const onSubmit = (formValues) => {
        setConfirmEmail(formValues.email)
        googleSignOut()

    }

    return (
        <>
            <Box textAlign="center" fontSize="xl" >
                {availableUser ? (
                    <>
                        <h2>{availableUser.name}</h2>
                        <p> {availableUser.email}</p>
                    </>
                ) : null}



                <Accordion allowToggle>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                    Your Account Status
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>

                        {
                            availableUser.status === "Guest" ? (
                                <AccordionPanel pb={4}>
                                    <Box alignItems="space-between">
                                        <Text>
                                            Your account is set up as a Guest User,
                                            upgrade now to get more benefits and features.
                                        </Text>
                                        <Text>BASIC - $49.99/year</Text>
                                        <Text>EXTENDED - $149.99/year</Text>
                                        <Button>Upgrade Now!</Button>
                                        <AspectRatio maxW='280px' ratio={1}>
                                            <iframe
                                                title='naruto'
                                                src='https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBeGoneUpgrade.mp4'
                                                allowFullScreen
                                            />
                                        </AspectRatio>
                                    </Box>
                                </AccordionPanel>

                            ) : null}
                        {
                            availableUser.status === "Basic" ? (
                                <AccordionPanel pb={4}>
                                    <Box alignItems="space-between">
                                        <Text>
                                            You have upgraded to a Basic User,
                                            upgrade now to get full benefits and features.
                                            Your subscription will renew on
                                            {availableUser.appleDate}

                                        </Text>
                                        <Text>BASIC - $49.99/year</Text>
                                        <Text>EXTENDED - $149.99/year</Text>
                                        <Button>Cancel</Button>
                                        <Button>Upgrade Now!</Button>
                                        <AspectRatio maxW='280px' ratio={1}>
                                            <iframe
                                                title='naruto'
                                                src='https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBeGoneUpgrade.mp4'
                                                allowFullScreen
                                            />
                                        </AspectRatio>
                                    </Box>

                                </AccordionPanel>

                            ) : null}
                        {
                            availableUser.status === "Active" ? (
                                <AccordionPanel pb={4}>
                                    <Box alignItems="space-between" justifyContent="center">
                                        <Text> Your have full benefits and features. Your subscription will renew on
                                            {availableUser.appleDate}</Text>
                                        <Text>BASIC - $49.99/year</Text>
                                        <Text>EXTENDED - $149.99/year</Text>
                                        <Button>CANCEL</Button>
                                        <AspectRatio maxW='280px' ratio={1}>
                                            <iframe
                                                title='upgrade'
                                                src='https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBeGoneUpgrade.mp4'
                                                allowFullScreen
                                            />
                                        </AspectRatio>
                                    </Box>


                                </AccordionPanel>

                            ) : null}

                    </AccordionItem>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                    Logout
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Box alignItems="space-between" justifyContent="center">
                                <Text>  You can logout from Navbar , or from the Menu on smaller devices.</Text>
                                <Button>LOGOUT</Button>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                    Profile and Settings
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Box alignItems="space-between" justifyContent="center">
                                <Text>   You can change your profile name, avatar and password from your
                                    Settings Page.As an account owner, you can also add a
                                    Proxy.  You can add delegates from your dashboard</Text>
                                <Text>PROXY - You can add a Proxy to your account from the Profile page.
                                    They will be responsible for finalizing your account and
                                    carrying out your wishes.</Text>
                                <Text>DELEGATES - Friends and family that you want to have access to flagged items that you share with them.
                                    All records except iBeHealth and iBeMoney can be shared.
                                    You can send and reply to messages against each item. When adding a delegate,
                                    an account will automatically be opened with thier email address.
                                    An email will be sent to them with instructions on how to log in and download the Mobile app.
                                    A delegate email address cannot be changed, you
                                    will have to delete and re - add the delegate to initiate
                                    a change.


                                </Text>


                                <AspectRatio maxW='280px' ratio={1}>
                                    <iframe
                                        title='upgrade'
                                        src='https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBeGoneProxy.mp4'
                                        allowFullScreen
                                    />
                                </AspectRatio>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                    Track your Items
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Box alignItems="space-between" justifyContent="center">
                                <Text>  All users can add up to 30 items, each item can have 20 records associated with it.
                                    Upgrading to the Basic package allows you to add 500 items.
                                    The extended package allows you to add 3000 items.
                                    You can upload a picture of the item and add a
                                    description to help your Delegates identify the item.
                                    Each item is associated with one to many classifications to make it easier to find and filter you information.
                                    Different packages have different permissions to use the various classifications.
                                    See Help on Classification for additional details.

                                    Images and PDF documents can be attached to the item as records.  Each record is also associated with a classification,
                                    which groups the like items together to mke them easy to find.

                                    You can add items by using the Add Item tab from the top
                                    Menu.

                                    Inventory, will give you a full list of your items. The other classifications are there to
                                    help you keep organized.You can search on an Item
                                    Name.This is a partial search - for example 'iss' will
                                    show all items containing the characters 'iss' To edit an
                                    item, expand the classification that it belongs to and click on
                                    the item.
                                </Text>

                                <AspectRatio maxW='280px' ratio={1}>
                                    <iframe
                                        title='upgrade'
                                        src='https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBeGoneAddItems.mp4'
                                        allowFullScreen
                                    />
                                </AspectRatio>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                    How to Flag and Share Items
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Box alignItems="space-between" justifyContent="center">
                                <Text> Flagging an item allows your Proxy and Delegates to
                                    comment on the item.This way they can express an interest
                                    in the item or let you know they are unable or not
                                    interested in having the item.</Text>
                              
                                <AspectRatio maxW='280px' ratio={1}>
                                    <iframe
                                        title='upgrade'
                                        src='https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBeGoneFlagged.mp4'
                                        allowFullScreen
                                    />
                                </AspectRatio>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <h2>
                            <AccordionButton>
                                <Box flex='1' textAlign='left'>
                                    More Information about infoBee
                                </Box>
                                <AccordionIcon />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                            <Box alignItems="space-between" justifyContent="center">
                                <Text>    
                                    More information on who we are and about the application
                                    can be found on our website.
                                    You can navigate there by clicking on the infoBee heading on top of this page.
                                   
                                </Text>
                                <Button>Home Page</Button>
                                <Text>Share your ideas and feedback for us to include in a
                                    future release</Text>
                                    
                                <Button>Contact Us!</Button>
                                <AspectRatio maxW='280px' ratio={1}>
                                    <iframe
                                        title='upgrade'
                                        src='https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBeGoneUpgrade.mp4'
                                        allowFullScreen
                                    />
                                </AspectRatio>
                            </Box>
                        </AccordionPanel>
                    </AccordionItem>
                   
                </Accordion>







                <Center h={12} bg='gray.100' px={4} alignItems={'center'} justifyContent={'center'}>
                    <HStack as={'nav'} spacing={4} >
                        <Link px={2} py={1} target="_blank" rel="noopener noreferrer" rounded={'md'} _hover={{
                            textDecoration: 'none', bg: bg,
                        }} to="/termsconditions">
                            <Heading size="sm"  >Terms and Conditions    |</Heading></Link>
                        <Link to="/privacypolicy" target="_blank" rel="noopener noreferrer" >
                            <Heading size="sm"  >Privacy Policy |</Heading></Link>
                        <Link to="/contact"    >
                            <Heading size="sm"  >Contact Us! |</Heading></Link>
                        <Link to={`/onetimepasscode/reset${confirmEmail}`}    >
                            <Heading size="sm"  >Reset Password </Heading></Link>
                    </HStack>
                </Center>

            </Box>
        </>
    )

}


export default connect(null, { googleSignOut })(HelpPage);

/*

source = {{
    uri: "https://cs210033fffa5848b74.blob.core.windows.net/ibegone2020images/iBeGoneSubscriptions.mp4",
                      }}



        */
