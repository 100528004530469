//assign object to a variable before exporting as default

export default {
  //olive
  //primary: "#C8A920",
  primary: "#DBC14B",
  inventory: "#30B8C3",
  ibtinventory: "#36454F",
  ibttodo: "#a6855c",
  secondary: "#007aff",
  lightaccent: "#30B8C3",
  accent: "#00849b",
  settings: "grey",
  //accent: "#e9a825",
  header: "#12161b",
  tab_inactive: "#586776",
  tab_active: "#12161b",
  info_900: "#0E192A",
  danger: "#FF5A35",
  //ibtpets: "blue",
  ibtpets: "#0F2CB3", //peacock

  //ibtgone: "#30B8C3",
  ibthandy: "#4e77a3",//pacifica
  //ibthandy: "#A343C4",
  ibthealth: "#E30B5C", // raspberry
  //ibthouse: "#b5814c",
  //ibthouse: "#B87C4C",
  ibthouse: "#F28500", //tangerine
  ibtbasic: "#cccccc",
  ibtpaper: "#F4C430", //saffron
  //ibthealth: "crimson",
  ibtgone: "#950714", //cranberry
  //ibthealth: "red",
  //ibtpaper: "orange",
  //ibtmoney: "green",
  // ibtmoney: "#50C878", //emerald
  ibtmoney: "#4f7942", //fern
  //ibtmember: "#B5363B",
  //ibtmemoir: "#660c21", //pomegranate
  ibtcareer: "#E0115F", //ruby
  ibtmember: "#78184a", //pansy
  ibtsomething: "pink",
  //ibtevent: "#690fd1",
  ibtevent: " #0f52ba",//saphire
  //#690fd1
  //#4c68f5
  //#212866
  //ibtsocial: "magenta",
  // ibtmemoir: "#FFB7C5", //blossom
  ibtsocial: "#a6855c", //flaxen
  //ibtsocial: " #fcd5a4",  //smoothie
  //ibtcontacts: "#4c68f5",
  ibtcontacts: "#68a0b0", //crystal blue
  //ibtbusy: "tomato",
  ibtbusy: "#E35335", //poppy
  //ibtcareer: "olive",
  ibtmemoir: "#9a8b4f", //willow
  // ibtcareer: "#52f54c",
  //ibtmemoir: "orchid",
  //ibtmemoir: "gold",
  ibtframed: "#9D9A9C",
  ibtdark: "#36454F",
  //ibttechno: "#736f6b",
  //ibttechno: "#d8bfd8", //thistle
  ibtframed: "#3c7759", //juniper
  ibttechno: "#72BCD2", // lagoon
  ibtflagged: "#3c7759",
  //ibtflagged: "#9bc471", //sweet leaf
  //nice pink #F194FF
  //purple #6a51ae
};