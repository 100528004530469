const validatePasscode = values => {
  const errors = {}

  if (values.Response !== 'CANCEL') {
    if (!values.password) {
      errors.password = 'Required'
    }
    //  if (!values.passcode) {
    // errors.passcode = 'Required'
    // }

    if (values.password !== values.password2) {
      errors.password2 = 'Password Must Match'
    }
  }



  return errors
}
export default validatePasscode