import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { connect } from "react-redux";

import {
  useParams, useNavigate, useSearchParams, useLocation, NavLink,
} from "react-router-dom";

//import { testnav } from "../../actions/login"

import {
  Heading,
  Box,
  Text,
  Image,
  //Checkbox,
  //Link,
  VStack,
  Stack,
  Avatar,
  Input,
  Button,
  IconButton,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Badge,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  //AlertDialog,
  //AlertDialogBody,
  //AlertDialogFooter,
  //AlertDialogHeader,
  //AlertDialogContent,
  //AlertDialogOverlay,
  AspectRatio,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
  // useToast,
} from '@chakra-ui/react';

import { CloseIcon, ChevronDownIcon, AddIcon, CheckIcon } from '@chakra-ui/icons'

import ibtColors from "../common/ibtColors";




import ItemCommentForm from '../forms/ItemCommentForm'

import { refreshUser } from "../../actions/userAction"
import { switchUser } from "../../actions/switchAction"

import { deleteCurrentItem, editSwitch, pdfDocument } from "../../actions/itemAction"


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))


const SwitchDetail = ({ refreshUser, deleteCurrentItem, editSwitch, pdfDocument, switchUser }) => {

  let [searchParams, setSearchParams] = useSearchParams();
  let entryPath = useLocation();
  //const [availableItems, setAvailableItems] = useState(" ");

  const [switchName, setSwitchName] = useState("");
  const [switchEmail, setSwitchEmail] = useState("");
  const [showItem, setShowItem] = useState(false);

  const [chooseList, setChooseList] = useState('');

  // const [displayComments, setDisplayComments] = useState([]);
  const [selectedComment, setSelectedComment] = useState(null);
  const [showSelectedComment, setShowSelectedComment] = useState(false);
  //const onCommentClose = () => setIsCommentOpen(false)
  //const cancelCommentRef = useRef()
  const [isCommentOpen, setIsCommentOpen] = useState(false)
  const [newMessage, setNewMessage] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  // const [showItem, setShowItem] = useState(false);


  const availableUser = useSelector((state) => state.user.user);
  const availableToken = useSelector((state) => state.user.token)
  const availableEmail = useSelector((state) => state.user.googleEmail)
  const switchEntity = useSelector((state) => state.switchUser.switchEntity);
  // const availableEntity = useSelector((state) => state.entity.entity);
  const btnRef = React.useRef()

  const { isOpen: isDrawOpen, onOpen: onDrawOpen, onClose: onDrawClose } = useDisclosure();
  const { isOpen: isDetailOpen, onOpen: onDetailOpen, onClose: onDetailClose } = useDisclosure()
  const [isListOpen, setIsListOpen] = useState(false)

  const showMenu = useBreakpointValue({ base: false, lg: true })

  const boxHeight = useBreakpointValue({ base: "40px", md: "60px" })
  const fontSize = useBreakpointValue({ base: 'xs', md: 'md' })

  const colSpan = useBreakpointValue({ base: 2, md: 1 })
  const formSpan = useBreakpointValue({ base: 1, md: 2 })
  const boxWidth = useBreakpointValue({ base: 350, md: 800 })
  const modalSize = useBreakpointValue({ base: 'sm', md: '4xl' })
  const formContent = useBreakpointValue({ base: 'flex-start', })
  const showTable = useBreakpointValue({ base: false, md: true })
  const navTo = useBreakpointValue({
    base: '/',
    md: '/inventory/itemlist/'
  })

  const secondaryTextColor = useColorModeValue('gray.600', 'gray.400')

  function QueryNavLink({ to, ...props }) {
    let location = useLocation();

    return <NavLink to={to + location.search} {...props} />;
  }


  useEffect(() => {
    const refresh = async () => {
      await refreshUser(availableEmail)
    };

    if (availableToken && !availableUser) {
      refresh();
    }

    /*
        if (availableToken && availableUser) {
    
          if (switchEntity.item.length !== 0) {
            switchEntity.item.map((show) => {
              if (show._id === params.switchItemId) {
                for (let i = 0; i < show.comments.length; i++) {
                  //  console.log(show.comment[i])
                  if (!show.comments[i].read) {
                    console.log('found unread')
                    setNewMessage(true)
                  }
                }
              }
            })
          }
    
        }
    */

    const newUser = async (accountEmail) => {
      console.log('abc' + accountEmail)
      await switchUser(accountEmail)
    };

    //console.log('PATH' + entryPath.pathname.substring(1, 7))
    if (entryPath.pathname.substring(1, 7) === 'switch') {
      console.log('found' + entryPath.pathname)
      if (availableToken && availableUser) {
        availableUser.account.filter(act => {
          if (act.accountId === params.accountId) {
            setSwitchName(act.accountName)
            setSwitchEmail(act.accountEmail)
            return (newUser(act.accountEmail))
          }
        })
      }
      //setIsListOpen(true)
      onDrawOpen(true)
    }

  }, [availableToken, availableUser, availableEmail]);



  let navigate = useNavigate();
  let params = useParams();
  //let location = useLocation();


  //const availableUser = useSelector((state) => state.user.user);
  //const availableToken = useSelector((state) => state.user.token)
  //const availableEmail = useSelector((state) => state.user.googleEmail)



  const onCommentSubmit = async values => {
    await sleep(300)
    console.log('What happened? ' + values.commentResponse)
    if (values.commentResponse === 'OKAY') {
      // console.log('SUBMIT' + (JSON.stringify(values.comments, 0, 2)))
      console.log(params.accountId)

      editSwitch(values, params.accountId, (props) => {
        setSelectedComment(null)
        setShowSelectedComment(!showSelectedComment)
        // navigate(`/flaglist/${props}`);
        navigate(`/flagList/${params.accountId}/${props}`);
        //flagList/609849b5faa2515b1ccc0987/620f0c8312afc11f18c9a95e
        // cant find the new one because we not refreshing state

      })
    }
  }

  const onCommentRead = async (values, list) => {
    await sleep(300)
    //console.log('Values Comment available' + (JSON.stringify(values, 0, 2)))
    // console.log('Comment selected list' + (JSON.stringify(list, 0, 2)))
    //console.log(values.comments.length)
    for (let i = 0; i < values.comments.length; i++) {
      //console.log(values.comments[i].commentlist.length)
      for (let c = 0; c < values.comments[i].commentlist.length; c++) {
        // console.log(values.comments[i].commentlist[c]._id + '***' + list._id)
        if (values.comments[i].commentlist[c].commentemail === availableUser.email) {
          values.comments[i].commentlist[c].commentread = true
        }
      }
    }

    console.log('Comment available updated' + (JSON.stringify(values.comments, 0, 2)))
    values.itemName = values.name
    values.itemId = values._id
    await sleep(300)
    console.log('Comment updated' + (JSON.stringify(values, 0, 2)))

    console.log('SUBMIT' + (JSON.stringify(values.comments, 0, 2)))
    console.log('params' + params.accountId)


    editSwitch(values, params.accountId, (props) => {
      console.log('props' + props)
      //  setSelectedComment(null)
      // setShowSelectedComment(!showSelectedComment)
      // navigate(`/flaglist/${props}`);
      // navigate(`/flaglist/${params.accountId}/${props}`);
      navigate(`/flagList/${params.accountId}/${props}`);


    })
    // }
  }


  const onViewPdf = async (pdffile) => {

    //  console.log('PDF' + pdffile)
    // setShowPickList(!showPickList)

    if (pdffile.toLowerCase().includes("pdf")) {
      await pdfDocument(pdffile, () => {
        navigate(`/pdfdisplay`)
      })
    }
  }


  const renderDetails = () => {
    return (
      switchEntity.item.map((show) => {
        if (show._id !== params.switchItemId) {
          return (null)
        } else {
          return (
            <Box key={show._id} marginTop={15} justifyContent="center">
              <Button ref={btnRef} marginTop={15} colorScheme='gray' onClick={() => {
                setIsListOpen(true)
                onDrawOpen()
              }}>
                Open List Selection
              </Button>

              <Heading key={show._id} size="xl" justifyContent="center" marginTop={15} >{show.name}   </Heading>
              <VStack justifyContent="center">

                <Box p={4} justifyContent="center">
                  {show.image ? (<Box flexShrink={0} alignItems="center" marginLeft={15}>
                    <Image
                      borderRadius='lg'
                      //width={{ md: 250 }}
                      width={350}
                      src={show.image}
                      alt={`infobee item ${show.name}`}
                    />
                  </Box>) : null}
                  <Text
                    fontWeight='bold'
                    textTransform='uppercase'
                    fontSize='xl'
                    letterSpacing='wide'
                    lineHeight='normal'
                  // fontWeight='semibold'
                  >
                    Description
                  </Text>
                  <Text mt={2}
                    width={350}
                    fontSize='xl'
                    letterSpacing='wide'
                    lineHeight='large'
                    color='gray.500'
                    display='block'>
                    {show.text}
                  </Text>
                  <br />
                  <Text>Verify Item Date: {show.itemDate.substring(0, 10)}</Text>
                  <br />

                </Box>


              </VStack>

            </Box>

          )
        }
      })
    )
  }

  const renderRecords = () => {

    return (
      switchEntity.item.map((show) => {
        if (show._id !== params.switchItemId) {
          return (null)
        } else {
          return (
            <div key={show._id}>
              <br />

              <Accordion defaultIndex={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]} allowMultiple allowToggle>

                {show.ibepets ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtpets}`}>
                            iBePets </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBePets', `${ibtColors.ibtpets}`)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibegone ? (
                  <>
                    <AccordionItem  >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtgone}`}>
                            iBeGone </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeGone', `${ibtColors.ibtgone}`)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}



                {show.ibehandy ? (
                  <>
                    <AccordionItem  >
                      <AccordionButton _expanded={{ bg: `black.100` }}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibthandy}`}>
                            iBeHandy </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeHandy', `${ibtColors.ibthandy}`)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibehouse ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{ bg: `${ibtColors.ibthouse}.100` }}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibthouse}`}>
                            iBeHouse </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeHouse', `${ibtColors.ibthouse}`)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibehealth ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibthealth}`}>
                            iBeHealth </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeHealth', `${ibtColors.ibthealth}`)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibepaper ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtpaper}`}>
                            iBePaper </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBePaper', `${ibtColors.ibtpaper}`)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}



                {show.ibemember ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{ bg: `${ibtColors.ibtmember}.100` }}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtmember}`}>
                            iBeMember </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeMember', `${ibtColors.ibtmember}`)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibetechno ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{ bg: `${ibtColors.ibttechno}.100` }}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibttechno}`}>
                            iBeTechno </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeTechno', `${ibtColors.ibttechno}`)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibeevent ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{ bg: `${ibtColors.ibtevent}.100` }}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtevent}`}>
                            iBeEvent </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeEvent', `${ibtColors.ibtevent}`)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibesocial ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{ bg: `${ibtColors.ibtsocial}.100` }}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtsocial}`}>
                            iBeSocial </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeSocial', `${ibtColors.ibtsocial}`)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibecontacts ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{ bg: `${ibtColors.ibtcontacts}.100` }}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtcontacts}`}>
                            iBeContacts </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeContacts', `${ibtColors.ibtcontacts}`)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibebusy ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{ bg: `${ibtColors.ibtbusy}.100` }}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtbusy}`}>
                            iBeBusy </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeBusy', `${ibtColors.ibtbusy}`)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibecareer ? (

                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{ bg: `${ibtColors.ibtcareer}.100` }}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtcareer}`}>
                            iBeCareer </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeCareer', `${ibtColors.ibtcareer}`)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibememoir ? (

                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{ bg: `${ibtColors.ibtmemoir}.100` }}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtmemoir}`}>
                            iBeMemoir </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeMemoir', `${ibtColors.ibtmemoir}`)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibeframed ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{ bg: `${ibtColors.ibtframed}.100` }}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtframed}`}>
                            iBeFramed </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeFramed', `${ibtColors.ibtframed}`)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}


                <AccordionItem >
                  <AccordionButton _expanded={{}}>
                    <VStack flex='1' textAlign='center' >
                      <Heading size="lg" color={`${ibtColors.ibtframed}`}>
                        No Matching Classification </Heading>
                    </VStack>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    {renderAltTable(show.records, 'other', `${ibtColors.ibtframed}`, show)}
                  </AccordionPanel>
                </AccordionItem>



              </Accordion>



            </div>

          )
        }
      })
    )
  }


  const renderRecordTable = (records, thing, color) => {

    function isClass(value) {
      return value.thing === thing
    }

    let filtered = records.filter(isClass)

    return (
      <>
        <Table variant='striped' colorScheme='gray.400'>
          {filtered.length > 0 ? (
            <Thead>
              <Tr>
                <Th><Heading size="md" mr={1} style={{ color: color }} ></Heading>Action</Th>
                {thing === 'other' ? (<Th><Heading size="md" style={{ color: color }} >  </Heading> </Th>) : null}
                {showTable ? (<>
                  <Th><Heading size="md" mr={160} >Description </Heading> </Th>
                  <Th><Heading size="md"  >Date</Heading></Th>
                  <Th><Heading size="md" style={{ color: color }}> </Heading>Attachment</Th>
                </>) : (<>
                  <Th><Heading size="md" mr={95} > </Heading>Description</Th>
                  <Th><Heading size="md" style={{ color: color }}> </Heading>Attached</Th>
                </>)}
              </Tr>
            </Thead>

          ) : null}

          {filtered.length > 0 ? (
            filtered.map((rec) =>

              <>
                {rec.thing === thing ? (
                  <Tbody key={rec._id + rec.descrip}>
                    <Tr>
                      <Td>
                        <Menu>
                          <MenuButton px={1} py={1} transition='all 0.2s' borderRadius='md'
                            borderWidth='1px' _hover={{ bg: 'gray.400' }} _expanded={{ bg: `${color}.200` }}
                            _focus={{ boxShadow: 'outline' }}   >
                            <ChevronDownIcon />
                          </MenuButton>
                          <MenuList>
                            <MenuItem onClick={() => { onViewPdf(rec.document) }}>View Attachment</MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => {
                              setSelectedRecord(rec)
                              onDetailOpen()
                              // setShowItem(true)
                            }}
                            >View Item</MenuItem>
                            <MenuDivider />
                          </MenuList>
                        </Menu>
                      </Td>

                      {showTable ? (
                        <>
                          <Td><Text noOfLines={5} onClick={() => {
                            setSelectedRecord(rec)
                            onDetailOpen()
                            // setShowItem(true)
                          }}>{rec.descrip}</Text>
                          </Td>
                          {rec.date ? (<Td>{rec.date.substring(0, 15)}</Td>) : (<Td> </Td>)}
                          {rec.document ? (<Td onClick={() => {
                            if (rec.document.toLowerCase().includes("pdf")) { onViewPdf(rec.document) } else {
                              setSelectedRecord(rec)
                              onDetailOpen()
                              //  setShowItem(true)
                            }
                          }}>
                            <AspectRatio maxW='100px' ratio={4 / 3}>
                              <Image src={rec.document} alt='alt record' objectFit='cover' />
                            </AspectRatio>
                          </Td>) : <Td></Td>}

                        </>
                      ) : (
                        <>
                          <Td><Text noOfLines={5} fontSize="sm" onClick={() => {
                            setSelectedRecord(rec)
                            onDetailOpen()
                          }}>{rec.descrip}</Text>
                          </Td>
                          {rec.document ? (<Td onClick={() => {
                            if (rec.document.toLowerCase().includes("pdf")) { onViewPdf(rec.document) } else {
                              setSelectedRecord(rec)
                              onDetailOpen()
                            }
                          }}>
                            <AspectRatio maxW='60px' ratio={4 / 3}>
                              <Image src={rec.document} alt='alt record' objectFit='cover' />
                            </AspectRatio>
                          </Td>) : <Td></Td>}
                        </>
                      )}
                    </Tr>
                  </Tbody>) : null}
              </>
            )
          ) : null}
        </Table>

      </>
    )
  }



  const renderAltTable = (records, thing, color, show) => {

    return (

      switchEntity.item.map((rem) => {
        if (rem._id !== params.switchItemId) {
          return (null)
        } else {

          const filteredClass = []
          //  console.log(JSON.stringify(rem.records, 0, 2))
          const validList = []
          if (rem.ibepets) {
            validList.push("iBePets")
          }
          if (rem.ibegone) {
            validList.push("iBeGone")
          }
          if (rem.ibehandy) {
            validList.push("iBeHandy")
          }
          if (rem.ibehouse) {
            validList.push("iBeHouse")
          }
          if (rem.ibehealth) {
            validList.push("iBeHealth")
          }
          if (rem.ibepaper) {
            validList.push("iBePaper")
          }
          if (rem.ibemoney) {
            validList.push("iBeMoney")
          }
          if (rem.ibemember) {
            validList.push("iBeMember")
          }
          if (rem.ibetechno) {
            validList.push("iBeTechno")
          }
          if (rem.ibeevent) {
            validList.push("iBeEvent")
          }
          if (rem.ibesocial) {
            validList.push("iBeSocial")
          }
          if (rem.ibecontacts) {
            validList.push("iBeContacts")
          }
          if (rem.ibebusy) {
            validList.push("iBeBusy")
          }
          if (rem.ibecareer) {
            validList.push("iBeCareer")
          }
          if (rem.ibememoir) {
            validList.push("iBeMemoir")
          }
          if (rem.ibeframed) {
            validList.push("iBeFramed")
          }
          // console.log(validList)

          for (let i = 0; i < rem.records.length; i++) {
            // console.log(rem.records[i].thing)
            const checkThing = (res) => {
              return res === rem.records[i].thing;
            }

            const removeThing = validList.find(checkThing)

            if (!removeThing) {
              // console.log(rem.records[i].thing)
              //console.log('matched' + i)
              filteredClass.push(rem.records[i])
            }

          }
       
          //console.log(JSON.stringify(filteredClass, 0, 2))
          // console.log('filteredClass' + thing + JSON.stringify(filteredClass, 0, 2))

          return (
            <>

              <Table variant='striped' colorScheme='gray.400'>
                {filteredClass.length > 0 ? (
                  <Thead>
                    <Tr>
                      <Th><Heading size="md" mr={1} style={{ color: color }} ></Heading>Action</Th>
                      <Th><Heading size="md" > </Heading>Classification</Th>

                      {showTable ? (<>
                        <Th><Heading size="md" mr={160} >Description </Heading> </Th>
                        <Th><Heading size="md"  >Date</Heading></Th>
                        <Th><Heading size="md" style={{ color: color }}> </Heading>Attachment</Th>
                      </>) : (<>
                        <Th><Heading size="md" mr={95} > </Heading>Description</Th>

                      </>)}
                    </Tr>
                  </Thead>

                ) : null}

                {filteredClass.length > 0 ? (
                  filteredClass.map((rec) =>
                    <>

                      <Tbody key={rec._id + rec.descrip}>
                        <Tr>
                          <Td>
                            <Menu>
                              <MenuButton px={1} py={1} transition='all 0.2s' borderRadius='md'
                                borderWidth='1px' _hover={{ bg: 'gray.400' }} _expanded={{ bg: `${color}.200` }}
                                _focus={{ boxShadow: 'outline' }}   >
                                <ChevronDownIcon />
                              </MenuButton>
                              <MenuList>
                                <MenuItem onClick={() => { onViewPdf(rec.document) }}>View Attachment</MenuItem>
                                <MenuDivider />
                                <MenuItem onClick={() => {
                                  setSelectedRecord(rec)
                                  onDetailOpen()
                                  // setShowItem(true)
                                }}
                                >View Item</MenuItem>
                                <MenuDivider />
                              </MenuList>
                            </Menu>
                          </Td>
                          <Td  ><Text>{rec.thing}</Text></Td>

                          {showTable ? (
                            <>
                              <Td><Text noOfLines={5} onClick={() => {
                                setSelectedRecord(rec)
                                onDetailOpen()
                                // setShowItem(true)
                              }}>{rec.descrip}</Text>
                              </Td>
                              {rec.date ? (<Td>{rec.date.substring(0, 15)}</Td>) : (<Td> </Td>)}
                              {rec.document ? (<Td onClick={() => {
                                if (rec.document.toLowerCase().includes("pdf")) { onViewPdf(rec.document) } else {
                                  setSelectedRecord(rec)
                                  onDetailOpen()
                                  //  setShowItem(true)
                                }
                              }}>
                                <AspectRatio maxW='100px' ratio={4 / 3}>
                                  <Image src={rec.document} alt='alt record' objectFit='cover' />
                                </AspectRatio>
                              </Td>) : <Td></Td>}

                            </>
                          ) : (
                            <>
                              <Td><Text noOfLines={5} fontSize="sm" onClick={() => {
                                setSelectedRecord(rec)
                                onDetailOpen()
                              }}>{rec.descrip}</Text>
                              </Td>

                            </>
                          )}
                        </Tr>
                      </Tbody>
                    </>
                  )
                ) : null}
              </Table>
            </>
          )
        }
      })

    )
  }








  const renderComments = () => {
    return (

      switchEntity.item.map((item) => {
        if (item._id !== params.switchItemId) {
          return (null)
        } else {
          //  console.log(JSON.stringify(item, 0, 2))
          const tempComments = []

          for (let i = 0; i < item.comments.length; i++) {
            //  console.log('outer' + item.comments[i].commentfrom)
            if (item.comments[i].commentfrom === availableUser.email) {

              tempComments.push(item.comments[i]);
            }
            for (let c = 0; c < item.comments[i].commentlist.length; c++) {
              // console.log('INNER LOOP' + item.comments[i].commentlist[c].commentemail)
              //console.log('INNER LOOP' + item.comments[i].commentlist[c].commentemail)

              if (item.comments[i].commentlist[c].commentemail === availableUser.email) {
                // console.log('Found' + item.comments[i].commentfrom)
                tempComments.push(item.comments[i]);
              }
            }
          }

          return (
            <div key={item._id}>
              {showSelectedComment ? (renderSelectedComment(item)) : null}
              <br />


              <Table variant='striped' colorScheme='gray.400'>

                <Thead>
                  <Tr>
                    <Th><Heading size="md" ></Heading>Action</Th>
                    <Th><Heading size="md"  >From/To </Heading> </Th>
                    <Th><Heading mr={180} size="md"  >Comment </Heading> </Th>
                    <Th><Heading size="md" mr={14}  >Date </Heading> </Th>
                  </Tr>
                </Thead>


                {tempComments.map((comment) =>

                  // filterComments(item.comments)   

                  <Tbody key={comment._id}>
                    <Tr>
                      <Td>
                        {comment.commentfrom !== availableUser.email ? (
                          <>
                            <Menu>
                              <MenuButton px={1} py={2} transition='all 0.2s' borderRadius='md'
                                borderWidth='1px' _hover={{ bg: 'gray.400' }}
                                _focus={{ boxShadow: 'outline' }}   >
                                <ChevronDownIcon />
                              </MenuButton>
                              <MenuList>
                                <MenuItem onClick={() => {
                                  setSelectedComment(comment)
                                  setShowSelectedComment(true)
                                }}>Reply</MenuItem>
                                <MenuDivider />

                              </MenuList>
                            </Menu>

                          </>
                        ) : <Text></Text>}

                        {availableUser.email !== comment.commentfrom ? (
                          <>
                            {comment.read ? (<>
                              <CheckIcon /><Text as='em' color="green">read</Text>
                            </>)
                              : (<>
                                <Button onClick={() => onCommentRead(item, comment)}
                                  size='md' height='50px' width='50px' borderWidth='2px' borderColor="teal">

                                  <Text as='i'  >unread</Text>
                                </Button>
                              </>)}
                          </>
                        ) : <Text>SENT</Text>}
                      </Td>

                      <Td>
                        <HStack>
                          <Heading size="sm">FROM:  </Heading>
                          <Text> {comment.commentfrom} </Text>

                        </HStack>
                        <br />
                        <Heading size="sm">TO:</Heading>

                        <VStack>
                          {comment.commentlist.length > 0 ? (
                            comment.commentlist.map((list) => {
                              return (
                                <>
                                  <HStack>
                                    <Text>{list.commentemail}</Text>
                                    {list.commentread ? (
                                      <>

                                        <CheckIcon /><Text as='em' color="green">read</Text>
                                      </>)
                                      : (
                                        <>
                                          <div onClick={() => onCommentRead(item, comment)}
                                            size='md' height='50px' width='50px' border='2px' borderColor="black">

                                            <Text as='i' >unread</Text>
                                          </div>
                                        </>
                                      )}

                                    )

                                  </HStack>

                                </>
                              )
                            })) : null}

                        </VStack>
                      </Td>

                      <Td>{comment.comment}</Td>

                      <Td>{comment.date.substring(0, 10)}</Td>


                    </Tr>
                  </Tbody>
                )}

              </Table>

            </div>
          )
        }
      })
    )
  }


  const renderCommentsMobile = () => {
    return (

      switchEntity.item.map((item) => {
        if (item._id !== params.switchItemId) {
          return (null)
        } else {
          //  console.log(JSON.stringify(item, 0, 2))
          const tempComments = []

          for (let i = 0; i < item.comments.length; i++) {
            //  console.log('outer' + item.comments[i].commentfrom)
            if (item.comments[i].commentfrom === availableUser.email) {

              tempComments.push(item.comments[i]);
            }
            for (let c = 0; c < item.comments[i].commentlist.length; c++) {
              // console.log('INNER LOOP' + item.comments[i].commentlist[c].commentemail)
              //console.log('INNER LOOP' + item.comments[i].commentlist[c].commentemail)

              if (item.comments[i].commentlist[c].commentemail === availableUser.email) {
                // console.log('Found' + item.comments[i].commentfrom)
                tempComments.push(item.comments[i]);
              }
            }
          }

          return (
            <div key={item._id}>
              {showSelectedComment ? (renderSelectedComment(item)) : null}
              <br />

              <Table variant='striped' colorScheme='gray.400'  >
                <Thead size="lg">
                  <Tr>
                    <Th><Heading size="md" ></Heading>Action</Th>
                    <Th><Heading size="md" ></Heading>Message</Th>

                  </Tr>
                </Thead>
                {item.comments ? (
                  item.comments.map((comment) =>
                    <>
                      <Tbody key={comment._id} >
                        <Tr>
                          <Td>
                            <Menu>
                              <MenuButton px={1} py={2} transition='all 0.2s' borderRadius='md'
                                borderWidth='1px' _hover={{ bg: 'gray.400' }}
                                _focus={{ boxShadow: 'outline' }}   >
                                <ChevronDownIcon />
                              </MenuButton>
                              <MenuList>

                                <MenuItem onClick={() => {
                                  setSelectedComment(comment)
                                  setShowSelectedComment(true)
                                }}>Reply</MenuItem>
                                <MenuDivider />

                              </MenuList>



                            </Menu>
                            <br />
                            <br />


                          </Td>


                          <Td>
                            <VStack>
                              <HStack>
                                <Text fontSize="md">FROM:  </Text>
                                <Text fontSize="md"> {comment.commentfrom} </Text>
                              </HStack>


                              <VStack>

                                {comment.commentlist.length > 0 ? (
                                  comment.commentlist.map((list) => {
                                    return (
                                      <>
                                        <Text fontSize="md">TO: {list.commentemail}</Text>


                                        <HStack>

                                          {list.commentread ? (
                                            <>

                                              <CheckIcon /><Text as='em' color="green">read</Text>
                                            </>)
                                            : (
                                              <>
                                                <div onClick={() => onCommentRead(item, comment)}
                                                  size='md' height='50px' width='50px' border='2px' bordercolor="black">
                                                  <Button onClick={() => onCommentRead(item, comment)}>
                                                    <Text as='i'
                                                    >acknowledge</Text>
                                                  </Button>

                                                </div>
                                              </>
                                            )}

                                          )

                                        </HStack>
                                        {availableUser.email !== comment.commentfrom ? (
                                          <>
                                            {comment.read ? (<>
                                              <CheckIcon /><Text as='em' color="green">read</Text>
                                            </>)
                                              : (<>

                                                <Button onClick={() => onCommentRead(item, comment)}
                                                  size='md' height='50px' width='50px' borderWidth='2px' bordercolor="teal">

                                                  <Text as='i'  >unread</Text>
                                                </Button>
                                              </>)}
                                          </>
                                        ) : <Text>SENT</Text>}


                                      </>
                                    )
                                  })) : null}

                              </VStack>
                              <br />
                              <Text fontSize="md">{comment.comment}</Text>
                              {comment.date ? (<Text fontSize="md">{comment.date.substring(0, 10)}</Text>) : null}
                            </VStack>
                          </Td>

                        </Tr>

                      </Tbody>
                    </>
                  )
                ) : null}
              </Table>

            </div>
          )
        }
      })
    )
  }

  const renderCommentlist = (commentlist) => {
    // console.log(commentlist)
    const showlist = []
    commentlist.map((item) => {
      if (item.commentread) {
        showlist.push(`${item.commentemail}(Read) -- `);
      } else {
        //add counter
        showlist.push(`${item.commentemail}(Not Read) --  `);
      }

    })

    return showlist

  }

  const renderCommentStatus = (commentlist) => {
    // console.log(commentlist)
    const showlist = []
    commentlist.map((item) => {

      showlist.push(item.commentemail);


    })

    return showlist

  }

  const renderSelectedComment = (comment) => {
    // console.log(selectedComment)

    return (
      selectedComment ? (
        <>
          <ItemCommentForm onSubmit={onCommentSubmit}
            comment={comment} item={"REPLY"} selectedComment={selectedComment} />
        </>
      ) : null

    )
  }




  return (

    <>
      {switchEntity ? (<main style={{ padding: ".25rem" }}>

        {renderDetails()}

        <Modal
          onClose={onDetailClose}
          size={modalSize} isOpen={isDetailOpen}
          isCentered
          motionPreset='slideInBottom'
          scrollBehavior='inside'
        >

          <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropInvert='80%'
            backdropBlur='2px'
          />
          <ModalContent>
            <ModalHeader>Record Details</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {selectedRecord ? (<>  <Box
              >

                {selectedRecord.document ? (
                  selectedRecord.document.includes('pdf') ? null : (<Box flexShrink={0} alignItems="center" marginLeft={15}>
                    <Image
                      borderRadius='lg'
                      width={{ base: 250, md: 800 }}

                      height="auth"
                      src={selectedRecord.document}
                      alt={`infobee record`}
                    />
                  </Box>)) : null}


                <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
                  <br />
                  <Text
                    fontWeight='bold'
                    textTransform='uppercase'
                    fontSize='xl'
                    letterSpacing='wide'
                    lineHeight='normal'
                  // fontWeight='semibold'
                  >
                    Description
                  </Text>
                  <Text mt={2}
                    fontSize='xl'
                    letterSpacing='wide'
                    lineHeight='large'
                    color='gray.500'
                    display='block'>
                    {selectedRecord.descrip}
                  </Text>
                  <br />
                  {selectedRecord.date ? (<Text>Record Date: {selectedRecord.date.substring(0, 10)}</Text>) : null}

                  <br />
                  <Text fontSize="2xl"
                    fontWeight='semibold'>  ({selectedRecord.thing}) </Text>

                </Box  >

              </Box></>) : <Text>NO RECORD</Text>}

            </ModalBody>
            <ModalFooter>
              <Button onClick={onDetailClose}>Close</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>



        <Tabs variant='enclosed-colored' size="lg" defaultindex={1}>
          <TabList m='.5em'>

            <Tab _selected={{ color: 'white', bg: 'gray.400' }}>
              <Heading m='.5em' size="md">Records</Heading></Tab>

            <Tab _selected={{ color: 'white', bg: 'gray.400' }}>
              <Heading m='.5em' size="md">Messages
                {!newMessage ? (<Badge ml='1' fontSize='0.4em' colorScheme='green'>
                  new
                </Badge>) : null}

              </Heading> </Tab>
          </TabList>
          <TabPanels>

            <TabPanel>
              {renderRecords()}
            </TabPanel>

            <TabPanel>
              {showTable ? renderComments() : renderCommentsMobile()}
            </TabPanel>
          </TabPanels>
        </Tabs>


      </main>) : null}




      <Drawer
        isOpen={isDrawOpen}
        placement='left'
        onClose={onDrawClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody>
            <>
              <br />

              <Input
                value={searchParams.get("filter") || ""}
                style={{ padding: ".25rem" }}
                placeholder="   Item Contains Search"
                onChange={event => {
                  let filter = event.target.value
                  if (filter) {
                    setSearchParams({ filter });
                  } else {
                    setSearchParams({});
                  }
                }}
              />
              {switchEntity ? (
                switchEntity.item
                  .filter(flag => {
                    if (flag.flagged) { return true } else { return false };
                  }).filter(pick => {
                    //console.log(pick.picklist.length)
                    if (pick.picklist.length > 0)
                      return true
                  })
                  .filter(pick => {
                    //NEED A FOR LOOP OR SOMETHING
                    for (let i = 0; i < pick.picklist.length; i++) {
                      if (pick.picklist[i].pickemail === availableUser.email)
                        return true
                    }

                  })
                  .filter(item => {
                    let filter = searchParams.get("filter");
                    if (!filter) return true;
                    let name = item.name.toLowerCase();
                    //   return name.startsWith(filter.toLowerCase());
                    return name.includes(filter.toLowerCase());
                  }).map(item => (
                    <QueryNavLink onClick={onDrawClose} key={item.name + item._id} style={({ isActive }) => {
                      return { display: "block", margin: ".5rem 0", color: isActive ? "red" : "black" };
                    }}
                      to={`/switchItem/${params.accountId}/${item._id}`}
                    >
                      <Stack direction='row' h={boxHeight} borderWidth={2} bordercolor="teal" >
                        {item.image ? (<Avatar size={'md'} src={item.image}
                        />) :
                          <Avatar size={'md'} bg="grey.100" icon={<CheckIcon fontSize='1.5rem' />}
                          />}
                        <Heading key={item.name + item._id} size={fontSize} mt={3} color={secondaryTextColor}>{item.name}</Heading>
                      </Stack>
                    </QueryNavLink>
                  ))
              ) : null}

            </>
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onDrawClose}>
              Cancel
            </Button>

          </DrawerFooter>
        </DrawerContent>
      </Drawer>


    </>
  );
}

export default connect(null, { refreshUser, deleteCurrentItem, editSwitch, pdfDocument, switchUser })(SwitchDetail);
//  {showItem ? (<Modal />) : null}
/*

        <Modal
          onClose={onDetailClose}
          size="md" isOpen={isDetailOpen}
          isCentered
          motionPreset='slideInBottom'
          scrollBehavior='inside'
        >

          <ModalOverlay
            bg='none'
            backdropFilter='auto'
            backdropInvert='80%'
            backdropBlur='2px'
          />
     


const Modal = () => {
    //let history = useHistory();

    console.log(selectedRecord)

    let back = e => {
      e.stopPropagation();
      setShowItem(false)
      // history.goBack();
    };

    return (
      <Box
        onClick={back}
        style={{
          position: "absolute",
          top: 500,
          left: 0,
          bottom: -500,
          right: 0,
          background: "rgba(0, 0, 0, 0.15)"
        }}
      >
        <Box borderWidth='1px' borderRadius='lg' overflow='hidden'
          // className="modal"
          style={{
            position: "absolute",
            backgroundColor: "#E2E8F0",
            color: "#171923",
            top: 25,
            left: "10%",
            right: "10%",
            padding: 15,
            border: "2px solid #444"
          }}
        >
          <Heading>{selectedRecord.type} ({selectedRecord.thing}) {selectedRecord.date ? (selectedRecord.date.substring(0, 10)) : null}</Heading>
          <br />

          <Text fontSize="2xl" >{selectedRecord.descrip}</Text>
          <br />
          <Text fontSize="2xl" >{selectedRecord.document}</Text>
          <br />
          <div>



            {selectedRecord.document ? (
              selectedRecord.document.includes('pdf') ? null : (
                <>

                  <img style={{
                    padding: "1",
                    margin: "2",
                    width: "100%",
                    height: "100"
                  }}
                    alt="alt" src={selectedRecord.document} />

                </>
              )



            ) : null}

            <br />


            <br />

          </div>
          <button type="button" onClick={back}>
            Close
          </button>

        </Box>
      </Box >
    );
  }

*/