import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { Link, } from "react-router-dom";
import { connect } from "react-redux";
import {
  Box,
  Flex,
  HStack,
  Heading,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
} from '@chakra-ui/react';


//import logo from '../../icon.png';
import EmailForm from '../forms/EmailForm'
import PasswordForm from '../forms/PasswordForm'
import { otp, reset } from "../../actions/userAction"

const OneTimePasscode = ({ otp, reset }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");

  let navigate = useNavigate();
  let params = useParams();

  // const availableUser = useSelector((state) => state.user.user);
  const availMessage = useSelector((state) => state.user.errorMessage);

  const bg = useColorModeValue('gray.200', 'gray.700')


  const onPasswordSubmit = async (formValues) => {
    // console.log("on password submit")

    await otp(formValues, () => {
      navigate("/login")
    })

  }

  const onSubmit = (formValues) => {
    if (formValues.emailResponse !== 'CANCEL') {
      setConfirmEmail(formValues.email)
      // setShowAlert(true)
      reset(formValues, () => {
        setShowAlert(true)
      })
    } else {
      navigate("/login")
    }
  }

  return (
    <>
      <Box textAlign="center" fontSize="xl">
        <h2 >infoBee - Register!</h2>
        <h4>To upgrade to a paid subscription, please load the infoBee app</h4>

        <Box as="pre" my={10}>
          {availMessage ? (<h4 style={{ color: "red" }}>ERROR - {availMessage}</h4>) : null}
        </Box>

        {showAlert ? (
          <>
            <Alert status='success'>
              <AlertIcon />
              <Box flex='1'>

                <AlertDescription display='block'>
                  Your request has been received. Please check your email for a One Time Passcode to complete your process.
                </AlertDescription>
              </Box>
              <CloseButton position='absolute' right='8px' top='8px' />
            </Alert>
            <br />
            <Heading>{confirmEmail}</Heading>

          </>
        ) : null}

        {!showAlert ? (<EmailForm onSubmit={onSubmit} />)
          : (<PasswordForm onSubmit={onPasswordSubmit} email={confirmEmail} status={"RESET"} />)}


      </Box>


      <Box bg='gray.100' px={4} alignItems={'center'}>
        <Flex h={12} alignItems={'center'} justifyContent={'center'}>

          <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
            <Link px={2} py={1} target="_blank" rel="noopener noreferrer" rounded={'md'} _hover={{
              textDecoration: 'none', bg: bg,
            }} to="/termsconditions">
              <Heading size="sm"  >Terms and Conditions    |</Heading></Link>
            <Link to="/privacypolicy" target="_blank" rel="noopener noreferrer" >
              <Heading size="sm"  >Privacy Policy </Heading></Link>

          </HStack>
        </Flex>
      </Box>
    </>
  )

}


export default connect(null, { otp, reset })(OneTimePasscode);

/*

  useEffect(() => {
    const requestReset = async () => {
      await reset(confirmEmail, () => {
        setShowAlert(true)
      })
    };

    //  const emailPart1 = params.email.substring(1, 5)
    const emailPart2 = params.email.substring(5)
    setConfirmEmail(emailPart2)

    if (emailPart2) {
      requestReset();
    }

  }, [confirmEmail, params.email]);
*/