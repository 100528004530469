import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, NavLink, Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { connect } from "react-redux";

import {
  Box,
  Button,
  ButtonGroup,
  Text,
  Heading,
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPanels,
  VStack,
  Avatar,
  Stack,
  GridItem,
  SimpleGrid,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  //useToast,
  useColorModeValue,
  useBreakpointValue,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from '@chakra-ui/react';

import {
  AiOutlineSetting, AiOutlineKey, AiOutlineCheckSquare, AiOutlineContacts,
  AiOutlineDollar, AiOutlineFileAdd, AiOutlineFlag, AiOutlineFolderOpen,
  AiOutlineHeart, AiOutlineHome, AiOutlineInfoCircle, AiOutlineLaptop,
  AiOutlineMedicineBox, AiOutlineMore, AiOutlinePicture, AiOutlineProfile,
  AiOutlinePushpin, AiOutlineQuestion, AiOutlineTag, AiOutlineTeam,
  AiOutlineCamera, AiOutlineTwitter, AiOutlineUserAdd, AiOutlineUpload,
  AiOutlineCalendar
} from "react-icons/ai";

import { Icon } from '@chakra-ui/react'
//import { AiOutlineSetting } from "react-icons/io";


import ibtColors from "../common/ibtColors";

import { addEnvoi, deleteEnvoi } from "../../actions/entityAction"
import { refreshUser } from "../../actions/userAction"
import EnvoiForm from '../forms/EnvoiForm'

const Dashboard = ({ addEnvoi, deleteEnvoi, refreshUser }) => {

  const [showDelegate, setShowDelegate] = useState(false);
  const [showDelegateList, setShowDelegateList] = useState(true);
  const [envoi, setEnvoi] = useState(null);
  const [envoiDelete, setEnvoiDelete] = useState(null);
  const [isListOpen, setIsListOpen] = useState(false)

  const { isOpen: isDrawOpen, onOpen: onDrawOpen, onClose: onDrawClose } = useDisclosure();

  const btnRef = React.useRef()

  let navigate = useNavigate();
  // const toast = useToast();

  const availableUser = useSelector((state) => state.user.user);
  const availableEntity = useSelector((state) => state.entity.entity);
  const availableToken = useSelector((state) => state.user.token)
  const availableEmail = useSelector((state) => state.user.googleEmail)
  //const availMessage = useSelector((state) => state.user.errorMessage);

  const colSpan = useBreakpointValue({ md: 2, lg: 3, })
  const buttonSize = useBreakpointValue({ base: "sm", md: "md" })
  const boxWidth = useBreakpointValue({ base: '140px', lg: '155px' })
  const avatarSize = useBreakpointValue({ base: '2xl', lg: '2xl' })

  const formContent = useBreakpointValue({ base: 'flex-start', lg: 'space-between' })
  const showTable = useBreakpointValue({ base: false, md: true })
  const showMenu = useBreakpointValue({ base: false, md: true })


  const [isEnvoiOpen, setIsEnvoiOpen] = useState(false)

  const onEnvoiClose = () => setIsEnvoiOpen(false)
  const cancelEnvoiRef = useRef()


  const secondaryTextColor = useColorModeValue('gray.600', 'gray.400')

  useEffect(() => {
    const refresh = async () => {
      await refreshUser(availableEmail)
    };

    if (availableToken && !availableUser) {
      refresh();
    }

  }, []);


  const onEnvoiSubmit = (formValues) => {
    setShowDelegate(false)
    //console.log('in Envoi onSubmit' + (JSON.stringify(formValues, 0, 2)))

    if (formValues.envoiResponse !== 'CANCEL') {

      addEnvoi(formValues, availableUser._id, availableUser.email, availableUser.name, () => {
        navigate("/dashboard")
      })
    }

  }



  const deleteHandler = async (id) => {
    // console.log('dlete' + id)

    await deleteEnvoi(availableUser._id, id, () => {
      navigate("/dashboard")
    })

  };


  const renderBox = (thing, color, navThing, thingIcon) => {

    return (
      <>
        <br />
        <Button onClick={() => navigate(`${navThing}`)}
          size={buttonSize} height='100px' width={boxWidth} border='4px'
          style={{
            borderColor: color,
            backgroundColor: "white"
          }}>
          <div flexDirection="space-between">

            {thing === 'settings' ? (<Icon as={AiOutlineSetting} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'inventory' ? (<Icon as={AiOutlineTag} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'Flagged' ? (<Icon as={AiOutlineFlag} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'ToDo' ? (<Icon as={AiOutlineCheckSquare} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'Help' ? (<Icon as={AiOutlineQuestion} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'iBePets' ? (<Icon as={AiOutlineHeart} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'iBeCareer' ? (<Icon as={AiOutlineUpload} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'iBeHandy' ? (<Icon as={AiOutlineInfoCircle} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'iBeHouse' ? (<Icon as={AiOutlineHome} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'iBeGone' ? (<Icon as={AiOutlineKey} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'iBePaper' ? (<Icon as={AiOutlineFolderOpen} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'iBeMoney' ? (<Icon as={AiOutlineDollar} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'iBeMember' ? (<Icon as={AiOutlinePushpin} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'iBeTechno' ? (<Icon as={AiOutlineLaptop} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'iBeEvent' ? (<Icon as={AiOutlineUserAdd} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'iBeSocial' ? (<Icon as={AiOutlineTwitter} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'iBeContact' ? (<Icon as={AiOutlineContacts} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'iBeBusy' ? (<Icon as={AiOutlineCalendar} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'iBeMemoir' ? (<Icon as={AiOutlineCamera} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'iBeHealth' ? (<Icon as={AiOutlineMedicineBox} w={12} h={12}
              marginTop={5} color={color} />) : null}
            {thing === 'iBeFramed' ? (<Icon as={AiOutlinePicture} w={12} h={12}
              marginTop={5} color={color} />) : null}
            <Text fontSize="3xl" style={{
              // padding: ".5rem",
              color: color,
            }}>{thing}</Text>
          </div>

        </Button>
      </>
    )

  }


  const renderEnvoi = (envoi) => {
    return (
      <>
        {availableEntity.envoi.length > 3 ? (

          <Heading>DELEGATE MAX REACHED</Heading>)
          : (<EnvoiForm onSubmit={onEnvoiSubmit} envoi={envoi} />)}
      </>

    )
  }


  return (

    <div>
      {availableUser ? (
        <>
          {showTable ? (
            <>
              <SimpleGrid columns={colSpan} spacing={3} rowGap={3} width="full">
                <GridItem colSpan={1}>
                  <br />
                  <br />

                  <Tabs align='center' variant='enclosed-colored'
                  // display={{ md: 'none' }}
                  >
                    <TabList>
                      <Tab _selected={{ color: 'white', bg: ibtColors.ibtbasic }}>
                        <Text fontSize="xl">Profile</Text></Tab>
                      <Tab _selected={{ color: 'white', bg: ibtColors.ibtbasic }}>
                        <Text fontSize="xl">Delegates</Text></Tab>
                      <Tab _selected={{ color: 'white', bg: ibtColors.ibtbasic }}>
                        <Text fontSize="xl">Switch User</Text></Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <br />
                        {availableEntity ? (
                          <>
                            <br />
                            <Avatar size={avatarSize} src={availableUser.avatar} mb={2}></Avatar>

                            <Heading size="xl" textAlign="center">

                              {availableUser.name}

                            </Heading>
                            <br />
                            <Text fontSize="2xl">{availableUser.email}</Text>
                            <Text fontSize="2xl">STATUS - {availableUser.infoBeeStatus}</Text>
                            <br />

                            {availableEntity.xrefuser !== availableUser.email
                              ? (<Text fontSize="2xl" >Proxy -  {availableEntity.proxy}</Text>)
                              : (<Heading onClick={() => navigate("/profile")} color={'teal'} size="md">
                                Proxy not assigned.
                                <Button>Add Now!</Button>
                              </Heading>)}



                          </>

                        ) : null}

                      </TabPanel>

                      <TabPanel>

                        <br />
                        {showDelegate ? (renderEnvoi(envoi)) : null}
                        <br />
                        <div  >
                          <Button onClick={() => {
                            const newEnvoi = {
                              _id: null,
                              name: null,
                              userid: null,
                              //  recordDate: selectedRecord.date,
                              descrip: null,
                              type: null,
                            };
                            setEnvoi(newEnvoi)
                            setShowDelegate(!showDelegate);
                            //  console.log('Toggle' + showDelegate)
                          }} backgroundColor={`${ibtColors.ibtinventory}`} size="sm">
                            <Text color={"white"}>ADD NEW DELEGATE
                            </Text>
                          </Button>

                          <br />
                          <br />



                          {availableEntity ? (
                            availableEntity.envoi.map(env => (
                              <div key={env._id + env.accountRole}>

                                <NavLink key={env._id + env.accountRole} style={({ isActive }) => {

                                  return { display: "block", margin: "1rem", color: isActive ? "red" : "black" };

                                }}
                                  to={`/dashboard`} >
                                  <div style={{ flexDirection: "row", borderWidth: 2, borderColor: "grey" }}>



                                    <Text color={secondaryTextColor}>{env.name}</Text>


                                    <Text color={secondaryTextColor}>{env.userid}</Text>



                                    <div   >
                                      <ButtonGroup>
                                        <Button onClick={() => {
                                          setEnvoiDelete(env._id)
                                          setIsEnvoiOpen(true)
                                        }

                                        } size="sm">
                                          DELETE</Button>
                                        <Button onClick={() => {
                                          setEnvoi(env)
                                          setShowDelegate(!showDelegate);
                                        }} color="teal" size="sm">EDIT</Button>
                                      </ButtonGroup>


                                    </div>


                                  </div>



                                </NavLink>

                                <AlertDialog
                                  isOpen={isEnvoiOpen}
                                  leastDestructiveRef={cancelEnvoiRef}
                                  onClose={onEnvoiClose}
                                >
                                  <AlertDialogOverlay>
                                    <AlertDialogContent>
                                      <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                        Delete Delegate
                                      </AlertDialogHeader>
                                      <AlertDialogBody>
                                        Are you sure? You can't undo this action afterwards.
                                        This will delete all access your delagate has to your items.

                                      </AlertDialogBody>
                                      <AlertDialogFooter>
                                        <Button
                                          ref={cancelEnvoiRef}
                                          onClick={onEnvoiClose}>
                                          Cancel
                                        </Button>
                                        <Button colorScheme='red'
                                          onClick={() => {
                                            deleteHandler(envoiDelete)
                                            //console.log('Deleting');
                                            onEnvoiClose()
                                          }} ml={3}>
                                          Continue
                                        </Button>
                                      </AlertDialogFooter>
                                    </AlertDialogContent>
                                  </AlertDialogOverlay>
                                </AlertDialog>
                              </div>

                            ))


                          ) : null}

                        </div>

                      </TabPanel>
                      <TabPanel>
                        <div style={{ padding: ".5rem" }}>
                          {availableUser.account.length === 0 ? (<Heading size="lg" style={{
                          }}>NO SWITCH USERS  </Heading>) : null}


                          {availableUser ? (


                            availableUser.account.map(act => (
                              <NavLink key={act._id + act.accountRole} style={({ isActive }) => {
                                return { display: "block", margin: "1rem 0", color: isActive ? "red" : "black" };
                              }}
                                to={`/flaglist/${act.accountId}`}
                              >
                                <div style={{ borderWidth: 2, borderColor: "teal" }}>
                                  <Text fontSize="2xl" color={secondaryTextColor}>{act.accountName} - {act.accountRole}</Text>
                                  <Text color={secondaryTextColor}>{act.accountEmail}</Text>


                                </div>
                              </NavLink>
                            ))
                          ) : null}

                        </div>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </GridItem>

                <GridItem colSpan={2}>

                  <SimpleGrid columns={4} spacing={3} rowGap={3} width="full">

                    <Box>
                      <VStack>
                        {renderBox("inventory", ibtColors.ibtinventory, "/inventory/itemlist", "AiOutlineSetting")}
                        {renderBox("iBePets", ibtColors.ibtpets, "/inventory/petslist", "AiOutlineSetting")}
                        {availableUser.infoBeeStatus === 'Basic'
                          || availableUser.infoBeeStatus === 'Active'
                          ? (<>

                            {renderBox("iBeGone", ibtColors.ibtgone, "/inventory/gonelist")}</>)
                          : (<>

                            {renderBox("iBeGone", ibtColors.ibtbasic, "/dashboard")}</>)}



                        {availableUser.infoBeeStatus === 'Active'
                          ? (<>
                            {renderBox("iBeTechno", ibtColors.ibttechno, "/inventory/technolist")}
                            {renderBox("iBeBusy", ibtColors.ibtbusy, "/inventory/busylist")}
                          </>)
                          : (<>
                            {renderBox("iBeTechno", ibtColors.ibtbasic, "/dashboard")}
                            {renderBox("iBeBusy", ibtColors.ibtbasic, "/dashboard")}</>)}


                      </VStack>

                    </Box>

                    <Box>
                      <VStack>
                        {renderBox("Flagged", ibtColors.ibtflagged, "/inventory/flaggedlist")}
                        {renderBox("iBeCareer", ibtColors.ibtcareer, "/inventory/careerlist")}
                        {availableUser.infoBeeStatus === 'Basic'
                          || availableUser.infoBeeStatus === 'Active'
                          ? (<>
                            {renderBox("iBePaper", ibtColors.ibtpaper, "/inventory/paperlist")}</>)
                          : (<> {renderBox("iBePaper", ibtColors.ibtbasic, "/dashboard")} </>)}


                        {availableUser.infoBeeStatus === 'Active'
                          ? (<>
                            {renderBox("iBeEvent", ibtColors.ibtevent, "/inventory/eventlist")}
                            {renderBox("iBeMemoir", ibtColors.ibtmemoir, "/inventory/memoirlist")}</>)
                          : (<>
                            {renderBox("iBeEvent", ibtColors.ibtbasic, "/dashboard")}
                            {renderBox("iBeMemoir", ibtColors.ibtbasic, "/dashboard")}</>)}



                      </VStack>
                    </Box>

                    <Box>
                      <VStack>
                        {renderBox("ToDo", ibtColors.ibttodo, "/inventory/flaggedlist")}
                        {renderBox("iBeHandy", ibtColors.ibthandy, "/inventory/handylist")}
                        {availableUser.infoBeeStatus === 'Basic'
                          || availableUser.infoBeeStatus === 'Active'
                          ? (<>
                            {renderBox("iBeMoney", ibtColors.ibtmoney, "/inventory/moneylist")}
                          </>)
                          : (<> {renderBox("iBeMoney", ibtColors.ibtbasic, "/dashboard")}
                          </>)}


                        {availableUser.infoBeeStatus === 'Active'
                          ? (<>
                            {renderBox("iBeSocial", ibtColors.ibtsocial, "/inventory/sociallist")}
                            {renderBox("iBeHealth", ibtColors.ibthealth, "/inventory/healthlist")}
                          </>)
                          : (<>
                            {renderBox("iBeSocial", ibtColors.ibtbasic, "/dashboard")}
                            {renderBox("iBeHealth", ibtColors.ibtbasic, "/dashboard")}</>)}


                      </VStack>
                    </Box>

                    <Box>
                      <VStack>
                        {renderBox("Help", ibtColors.ibtframed, "/inventory/flaggedlist")}
                        {availableUser.infoBeeStatus === 'Basic'
                          || availableUser.infoBeeStatus === 'Active'
                          ? (<>
                            {renderBox("iBeHouse", ibtColors.ibthouse, "/inventory/houselist")}
                            {renderBox("iBeMember", ibtColors.ibtmember, "/inventory/memberlist")}</>)
                          : (<>
                            {renderBox("iBeHouse", ibtColors.ibtbasic, "/dashboard")}
                            {renderBox("iBeMember", ibtColors.ibtbasic, "/dashboard")}</>)}


                        {availableUser.infoBeeStatus === 'Active'
                          ? (<>   {renderBox("iBeContact", ibtColors.ibtcontacts, "/inventory/contactslist")}
                            {renderBox("iBeFramed", ibtColors.ibtframed, "/inventory/framedlist")}
                          </>) : (<>
                            {renderBox("iBeContact", ibtColors.ibtbasic, "/dashboard")}
                            {renderBox("iBeFramed", ibtColors.ibtbasic, "/dashboard")}</>)}


                      </VStack>
                    </Box>
                  </SimpleGrid>
                </GridItem>



              </SimpleGrid>
            </>) : (<>

              <SimpleGrid columns={4} spacing={3} rowGap={3} width="full">

                <GridItem colSpan={3}>

                  <br />

                  <Tabs marginLeft={16} align='center' variant='enclosed-colored' defaultIndex={1}
                  // display={{ md: 'none' }}
                  >
                    <TabList marginTop={1}>
                      <Tab onClick={() => {
                        setIsListOpen(true)
                        onDrawOpen()
                      }}
                        _selected={{ color: 'white', bg: ibtColors.ibtbasic }}  >
                        <Text margin={.25} fontSize="sm">Menu</Text></Tab>
                      <Tab _selected={{ color: 'white', bg: ibtColors.ibtbasic }} margin={.25}>
                        <Text margin={.25} fontSize="sm">Profile</Text></Tab>
                      <Tab _selected={{ color: 'white', bg: ibtColors.ibtbasic }}>
                        <Text fontSize="sm">Delegates</Text></Tab>
                      <Tab _selected={{ color: 'white', bg: ibtColors.ibtbasic }}>
                        <Text fontSize="sm">Switch User</Text></Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel></TabPanel>
                      <TabPanel>
                        <br />
                        {availableEntity ? (
                          <>
                            <br />
                            <Avatar size={avatarSize} src={availableUser.avatar} mb={2}

                            />

                            <Heading size="lg" textAlign="center">

                              {availableUser.name}

                            </Heading>
                            <br />
                            <Text fontSize={["xl", "xl", "xl",]}>{availableUser.email}</Text>
                            <Text fontSize="xl">STATUS - {availableUser.infoBeeStatus}</Text>
                            <br />

                            {availableEntity.xrefuser !== availableUser.email
                              ? (<Text fontSize="xl"> Proxy: {availableEntity.proxy}</Text>)
                              : (<Text fontSize="xl" onClick={() => navigate("/profile")} color={'teal'} size="md">
                                Proxy not assigned.
                                <Button>Add Now!</Button>
                              </Text>)}



                          </>

                        ) : null}

                      </TabPanel>

                      <TabPanel>
                        {showDelegate ? (renderEnvoi(envoi)) : null}
                        <br />
                        <div  >
                          <Button onClick={() => {
                            const newEnvoi = {
                              _id: null,
                              name: null,
                              userid: null,
                              //  recordDate: selectedRecord.date,
                              descrip: null,
                              type: null,
                            };
                            setEnvoi(newEnvoi)
                            setShowDelegate(!showDelegate);
                            //  console.log('Toggle' + showDelegate)
                          }} backgroundColor={`${ibtColors.ibtinventory}`} size="sm">
                            <Text color={"white"}>ADD NEW DELEGATE
                            </Text>
                          </Button>

                          <br />
                          <br />



                          {availableEntity ? (
                            availableEntity.envoi.map(env => (
                              <div key={env._id + env.accountRole}>

                                <NavLink key={env._id} style={({ isActive }) => {

                                  return { margin: "1rem", color: isActive ? "red" : "black" };

                                }}
                                  to={`/dashboard`} >
                                  <div style={{ flexDirection: "row", borderWidth: 2, borderColor: "grey" }}>
                                    <Text color={secondaryTextColor}>{env.name}</Text>
                                    <Text color={secondaryTextColor}>{env.userid}</Text>
                                    <div   >
                                      <ButtonGroup>
                                        <Button onClick={() => {
                                          setEnvoiDelete(env._id)
                                          setIsEnvoiOpen(true)
                                        }

                                        } size="sm">
                                          DELETE</Button>
                                        <Button onClick={() => {
                                          setEnvoi(env)
                                          setShowDelegate(!showDelegate);
                                        }} color="teal" size="sm">EDIT</Button>
                                      </ButtonGroup>
                                    </div>
                                  </div>
                                </NavLink>

                                <AlertDialog
                                  isOpen={isEnvoiOpen}
                                  leastDestructiveRef={cancelEnvoiRef}
                                  onClose={onEnvoiClose}
                                >
                                  <AlertDialogOverlay>
                                    <AlertDialogContent>
                                      <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                        Delete Delegate
                                      </AlertDialogHeader>
                                      <AlertDialogBody>
                                        Are you sure? You can't undo this action afterwards.
                                        This will delete all access your delagate has to your items.

                                      </AlertDialogBody>
                                      <AlertDialogFooter>
                                        <Button
                                          ref={cancelEnvoiRef}
                                          onClick={onEnvoiClose}>
                                          Cancel
                                        </Button>
                                        <Button colorScheme='red'
                                          onClick={() => {
                                            deleteHandler(envoiDelete)
                                            //console.log('Deleting');
                                            onEnvoiClose()
                                          }} ml={3}>
                                          Continue
                                        </Button>
                                      </AlertDialogFooter>
                                    </AlertDialogContent>
                                  </AlertDialogOverlay>
                                </AlertDialog>
                              </div>

                            ))


                          ) : null}

                        </div>

                      </TabPanel>
                      <TabPanel>
                        <div style={{ padding: ".5rem" }}>
                          {availableUser.account.length === 0 ? (<Heading size="lg" style={{
                          }}>NO SWITCH USERS  </Heading>) : null}


                          {availableUser ? (
                            availableUser.account.map(act => (
                              <NavLink key={act._id} style={({ isActive }) => {
                                return { display: "flex", margin: "1rem 0", color: isActive ? "red" : "black" };
                              }}
                                to={`/switchItem/${act.accountId}/base`}
                              >
                                <div style={{ borderWidth: 2, borderColor: "teal" }}>
                                  <Text fontSize="2xl" color={secondaryTextColor}>{act.accountName}</Text>
                                  <Text fontSize="2xl" color={secondaryTextColor}>({act.accountRole})</Text>
                                  <Text color={secondaryTextColor}>{act.accountEmail}</Text>
                                </div>
                              </NavLink>
                            ))
                          ) : null}

                        </div>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </GridItem>



              </SimpleGrid>
            </>)}



        </>
      ) : null}



      <br />
      <br />



      <br />


      <Drawer
        isOpen={isDrawOpen}
        placement='left'
        onClose={onDrawClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />

          <DrawerBody>
            <>

              <Button onClick={() => setIsListOpen(!isListOpen)}
                backgroundColor={"grey"} color="white">Show Lists</Button>

              <br />
              {isListOpen ? (<>
                <Box pb={4}  >
                  <Stack as={'nav'} spacing={1}>
                    <div >
                      <Box backgroundColor={`${ibtColors.ibtinventory}`} marginTop={1}>

                        <Link to="/itemId"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                            inventory</Text></Link>

                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtflagged}`} marginTop={1}>
                        <Link to="/itemId"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                            flagged</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibttodo}`} marginTop={1}>
                        <Link to="/itemId"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                            ToDo</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtpets}`} marginTop={1}>
                        <Link to="/itemId"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                            iBePets</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibtcareer}`} marginTop={1}>
                        <Link to="/itemId"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                            iBeCareer</Text></Link>
                      </Box>
                      <Box backgroundColor={`${ibtColors.ibthandy}`} marginTop={1}>
                        <Link to="/itemId"   >
                          <Text fontSize="lg" color={"white"}
                            style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                            iBeHandy</Text></Link>
                      </Box>
                      {availableUser ? (<>

                        {availableUser.infoBeeStatus === 'Basic'
                          || availableUser.infoBeeStatus === 'Active'
                          ? (<>
                            <Box backgroundColor={`${ibtColors.ibthouse}`} marginTop={1}>
                              <Link to="/itemId"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeHouse</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtgone}`} marginTop={1}>
                              <Link to="/itemId"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeGone</Text></Link>
                            </Box >

                            <Box backgroundColor={`${ibtColors.ibtpaper}`} marginTop={1}>
                              <Link to="/itemId"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBePaper</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtmoney}`} marginTop={1}>
                              <Link to="/itemId"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeMoney</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtmember}`} marginTop={1}>
                              <Link to="/itemId"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeMember</Text></Link>
                            </Box>
                          </>) : (<>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Link to="/dashboard"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeHouse</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Link to="/dashboard"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeGone</Text></Link>
                            </Box >

                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Link to="/dashboard"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBePaper</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Link to="/dashboard"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeMoney</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Link to="/dashboard"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeMember</Text></Link>
                            </Box></>)}




                        {availableUser.infoBeeStatus === 'Active'
                          ? (<>
                            <Box backgroundColor={`${ibtColors.ibttechno}`} marginTop={1}>
                              <Link to="/itemId"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeTechno</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtevent}`} marginTop={1}>
                              <Link to="/itemId"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeEvent</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtsocial}`} marginTop={1}>
                              <Link to="/itemId"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeSocial</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtcontacts}`} marginTop={1}>
                              <Link to="/itemId"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeContact</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbusy}`} marginTop={1}>
                              <Link to="/itemId"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeBusy</Text></Link>
                            </Box>

                            <Box backgroundColor={`${ibtColors.ibtmemoir}`} marginTop={1}>
                              <Link to="/itemId"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeMemoir</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibthealth}`} marginTop={1}>
                              <Link to="/itemId"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeHealth</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtframed}`} marginTop={1}>
                              <Link to="/itemId"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeMemoir</Text></Link>
                            </Box>
                          </>) : (<>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Link to="/dashboard"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeTechno</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Link to="/dashboard"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeEvent</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Link to="/dashboard"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeSocial</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Link to="/dashboard"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeContact</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Link to="/dashboard"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeBusy</Text></Link>
                            </Box>

                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Link to="/dashboard"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeMemoir</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Link to="/dashboard"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeHealth</Text></Link>
                            </Box>
                            <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                              <Link to="/dashboard"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 8, marginBottom: 4, paddingTop: ".5rem" }}>
                                  iBeFramed</Text></Link>
                            </Box>
                          </>)}

                      </>) : null}



                    </div>

                  </Stack>
                </Box>
              </>) : null}


            </>
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onDrawClose}>
              Cancel
            </Button>

          </DrawerFooter>
        </DrawerContent>
      </Drawer>



    </div>




  )
}

export default connect(null, { addEnvoi, deleteEnvoi, refreshUser })(Dashboard);

/*
 <Box pb={4}  >
                      <Stack as={'nav'} spacing={.5}>
                        <div >
                          <Heading size="md">Menu</Heading>
                          <Box backgroundColor={`${ibtColors.ibtinventory}`} height={35} width={100}>
                            <Link to="/inventory/itemlist"  >
                              <Text fontSize="lg" color={"white"}
                                style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                inventory</Text></Link>
                          </Box>
                          <Box backgroundColor={`${ibtColors.ibtflagged}`} height={35} width={100}>
                            <Link to="/inventory/flaggedlist"  >
                              <Text fontSize="lg" color={"white"}
                                style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                flagged</Text></Link>
                          </Box>
                          <Box backgroundColor={`${ibtColors.ibttodo}`} height={35} width={100}>
                            <Link to="/inventory/flaggedlist"  >
                              <Text fontSize="lg" color={"white"}
                                style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                ToDo</Text></Link>
                          </Box>
                          <Box backgroundColor={`${ibtColors.ibtpets}`} height={35} width={100}>
                            <Link to="/inventory/petslist"   >
                              <Text fontSize="lg" color={"white"}
                                style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                iBePets</Text></Link>
                          </Box>
                          <Box backgroundColor={`${ibtColors.ibtcareer}`} height={35} width={100}>
                            <Link to="/inventory/careerlist"   >
                              <Text fontSize="lg" color={"white"}
                                style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                iBeCareer</Text></Link>
                          </Box>

                          <Box backgroundColor={`${ibtColors.ibthandy}`} height={35} width={100}>
                            <Link to="/inventory/handylist"   >
                              <Text fontSize="lg" color={"white"}
                                style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                iBeHandy</Text></Link>
                          </Box>
                          {availableUser.infoBeeStatus === 'Basic'
                            || availableUser.infoBeeStatus === 'Active'
                            ? (<>
                              <Box backgroundColor={`${ibtColors.ibthouse}`} height={35} width={100}>
                                <Link to="/inventory/houselist"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeHouse</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtgone}`} height={35} width={100}>
                                <Link to="/inventory/gonelist"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeGone</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtpaper}`} height={35} width={100}>
                                <Link to="/inventory/paperlist"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBePaper</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtmoney}`} height={35} width={100}>
                                <Link to="/inventory/moneylist"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeMoney</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtmember}`} height={35} width={100}>
                                <Link to="/inventory/memberlist"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeMember</Text></Link>
                              </Box>
                            </>)
                            : <>
                              <Box backgroundColor={`${ibtColors.ibtbasic}`} height={35} width={100}>
                                <Link to="/dashboard"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeHouse</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtbasic}`} height={35} width={100}>
                                <Link to="/dashboard"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeGone</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtbasic}`} height={35} width={100}>
                                <Link to="/dashboard"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBePaper</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtbasic}`} height={35} width={100}>
                                <Link to="/dashboard"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeMoney</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtbasic}`} height={35} width={100}>
                                <Link to="/dashboard"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeMember</Text></Link>
                              </Box></>}

                          {availableUser.infoBeeStatus === 'Active'
                            ? (<>                          <Box backgroundColor={`${ibtColors.ibttechno}`} height={35} width={110}>
                              <Link to="/inventory/technolist"   >
                                <Text fontSize="lg" color={"white"}
                                  style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                  iBeTechno</Text></Link>
                            </Box>
                              <Box backgroundColor={`${ibtColors.ibtevent}`} height={35} width={100}>
                                <Link to="/inventory/eventlist"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeEvent</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtsocial}`} height={35} width={100}>
                                <Link to="/inventory/sociallist"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeSocial</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtcontacts}`} height={35} width={100}>
                                <Link to="/inventory/contactslist"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeContact</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtbusy}`} height={35} width={100}>
                                <Link to="/inventory/busylist"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeBusy</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtmemoir}`} height={35} width={100}>
                                <Link to="/inventory/memoirlist"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeMemoir</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibthealth}`} height={35} width={100}>
                                <Link to="/inventory/healthlist"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeHealth</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtframed}`} height={35} width={100}>
                                <Link to="/inventory/framedlist"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeFramed</Text></Link>
                              </Box> </>)
                            : <>
                              <Box backgroundColor={`${ibtColors.ibtbasic}`} height={35} width={100}>
                                <Link to="/dashboard"  >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeTechno</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtbasic}`} height={35} width={100}>
                                <Link to="/dashboard"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeEvent</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtbasic}`} height={35} width={100}>
                                <Link to="/dashboard"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeSocial</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtbasic}`} height={35} width={100}>
                                <Link to="/dashboard"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeContact</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtbasic}`} height={35} width={100}>
                                <Link to="/dashboard"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeBusy</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtbasic}`} height={35} width={100}>
                                <Link to="/dashboard"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeMemoir</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtbasic}`} height={35} width={100}>
                                <Link to="/dashboard"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeHealth</Text></Link>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtbasic}`} height={35} width={100}>
                                <Link to="/dashboard"   >
                                  <Text fontSize="lg" color={"white"}
                                    style={{ marginLeft: 12, marginRight: 12, marginTop: 4, paddingTop: ".5rem" }}>
                                    iBeFramed</Text></Link>
                              </Box>
                            </>}

                        </div>
                      </Stack>
                    </Box>
*/
