import {
  //SWITCH_ACCOUNT,
  SWITCH_SUCCESS,
  SWITCH_UPDATE,
  SWITCH_LIST,
  SWITCH_CLEAR,
  LOGIN_SUCCESS,
  LOGOUT,
} from "../actions/types";

const initialState = {
  switchEntity: null,
  switchComments: [],
  //loading: true,
  errorMessage: "",
  records: [],
  //switchAccount: {},
  //finalized: false,
  //validateBack: false,
};

export default function (state = initialState, action) {
  console.log("REDUCER SWITCH ENTITY -- " + action.type);

  const { type, payload } = action;
  switch (type) {
    case SWITCH_SUCCESS:
      return { ...state, switchEntity: payload, errorMessage: "" };
    case SWITCH_UPDATE:
      return { ...state, switchEntity: payload, errorMessage: "" };
    case SWITCH_CLEAR:
      return {
        ...state,
        switchComments: [],
        errorMessage: "",
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        switchComments: [],
        errorMessage: "",
      };
   
    case SWITCH_LIST:
      //console.log(JSON.stringify(payload.item.length, 0, 2))
      //console.log('SWICTH ACTION ' + JSON.stringify(payload.user, 0, 2))
      const flaggedItems = []
      for (let i = 0; i < payload.item.length; i++) {
        // console.log('comments in Loop' + payload.item[i]._id  )
        if (payload.item[i].flagged) {
          //  console.log('found item to keep')
          flaggedItems.push(payload.item[i]);
        }
      }
      return {
        ...state,
        switchComments: [
          ...state.switchComments,
          {
            comment: flaggedItems,
            switchId: payload.user,
            id: Math.random().toString(),
          },
        ], errorMessage: ""
      };
    case LOGOUT:
      return {
        ...state,
        switchEntity: null,
        switchComments: [],
        records: [],
        errorMessage: "",
      };
    default:
      return state;
  }
}

/*

 case SWITCH_ACCOUNT:
      return {
        ...state,
        switchAccount: [...state.switchAccount, action.payload],
      };
import {
    SWITCH_ACCOUNT,
    UPDATE_ACCOUNT,
    SWITCH_CLEAR,
    SELECT_ITEM,
    MESSAGE_CLEAR,
    LOGIN_SUCCESS,
    //AUTO_LOGIN,
    USER_SUCCESS,
    LOGOUT_SUCCESS,
    USER_ERROR,
    LOAD_MESSAGES,
    DELETE_MESSAGE,
    ADD_MESSAGE,
    //RESET_SUCCESS,
    //REGISTER_SUCCESS,
} from "../actions/types";

const initialState = {
    user: null,
    stateToken: null,
    // ibgToken: false,
    //didTryAutoLogin: false,
    //didLoad: false,
    //didLogout: false,
    errorMessage: "",
    //didReset: false,
    //didRegister: false,
    isSignout: false,
    selectedMessage: {},
    switchAccount: [
        {
            accountId: "123",
            accountName: "ReducerDefault",
            accountEmail: "abc@ibetechy.com",
            accountRole: "Reducer",
            accountNote: "",
            accountFlagged: {
                _id: "60e238e673555495bc56667f",
                flagged: true,
                grabbed: [],
                category: "Useful",
                name: "PixelDefault",
                image: "image",
                text: "Default Item",
                wishitem: "wishitem",
                wishname: "wishname",
                wishes: [],
                records: [],
                itemDate: {
                    $date: 1625438438348,
                },
            },
        },
    ],
    itemMessages: [],
};

export default function (state = initialState, action) {
  //  console.log("SWITCH REDUCER " + action.type);
    const { type, payload } = action;
    switch (type) {
      
        case UPDATE_ACCOUNT:
            return {
                ...state,
                switchAccount: state.switchAccount.filter(
                    (item) => item.accountId != action.payload
                ),
            };
        case LOAD_MESSAGES:
            return {
                ...state,
                itemMessages: action.payload,
            };
        case DELETE_MESSAGE:
            //console.log(action.payload);
            return {
                ...state,
                itemMessages: state.itemMessages.filter(
                    (item) => item._id != action.payload
                ),
            };
        case ADD_MESSAGE:
            return {
                ...state,
                itemMessages: [
                    ...state.itemMessages,
                    //{
                    action.payload,
                    //done: false,
                    //id: Math.random().toString(),
                    // },
                ],
            };
        case USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                //didLoad: true,
                errorMessage: "",
                didReset: false,
                //stateLoading: false,
                //ibgToken: true,
                //stateUnload: false,
            };
        case USER_ERROR:
            return {
                ...state,
                errorMessage: action.payload,
            };
        case SWITCH_CLEAR:
            return {
                //...state,
                //errorMessage: "",
                //stateUnload: false,
                //ibgToken: false,
                //token: "",
                switchAccount: [],
                selectedMessage: {},
                //isSignout: true,
                //didTryAutoLogin: true,
                //didLoad: false,
                //didLogout: false,
                //didReset: false,
                //didRegister: false,
                //switchAccount: {},
                //entity: {},
                //availableProfile: {},
                //availableUser: {},
                //user: {},
                //finalized: false,
            };
        case MESSAGE_CLEAR:
            return {
                ...state,
                //errorMessage: "",
                //stateUnload: false,
                //ibgToken: false,
                //token: "",
                // switchAccount: [],
                selectedMessage: {},
                //isSignout: true,
            };
        case SELECT_ITEM:
            return {
                ...state,
                selectedMessage: action.payload,
                //errorMessage: "",
            };
        default:
            return state;
    }
}


*/