import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from "react-redux";
import { connect } from "react-redux";

import {
  Route,
} from "react-router-dom";

//import { testnav } from "../../actions/login"

//import { Form, Field, useField, useForm } from 'react-final-form'

import {
  Heading,
  Button,

  // Input,
  GridItem,
  Text,
  ButtonGroup,
  SimpleGrid,
  Box,
  CSSReset,
  VStack,

  useToast,
} from '@chakra-ui/react';
import Styles from '../forms/StylesForms'

import { Form, Field, useField, } from 'react-final-form'

//import { StarIcon,  CloseIcon, TriangleUpIcon, ChevronUpIcon, ChevronDownIcon, TriangleDownIcon, EmailIcon, PhoneIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'

//import ibtColors from "../common/ibtColors";

const DownloadItems = () => {
  //const [availableItems, setAvailableItems] = useState(" ");
  //const [showItem, setShowItem] = useState(false);
  const [createExtractList, setCreateExtractList] = useState([])
  const [createItemList, setCreateItemList] = useState([])


  // let navigate = useNavigate();
  //let params = useParams();
  //let location = useLocation();
  const toast = useToast();


  const availableUser = useSelector((state) => state.user.user);
  const availableToken = useSelector((state) => state.user.token)
  const availableEntity = useSelector((state) => state.entity.entity);





  useEffect(() => {
    const getItems = async () => {
      // console.log('UseEffect in Download - Help' )
      const extractItems = []
      const extractRecords = []
      for (let i = 0; i < availableEntity.item.length; i++) {
        // console.log('Itemlist in Loop'  + availableEntity.item.name) 
        let extractItem = {
          //  "uuid": availableEntity.item[i]._id,
          "itemName": availableEntity.item[i].name,
          "itemImage": availableEntity.item[i].image,
          "itemDescrip": availableEntity.item[i].text,
          "flagged": availableEntity.item[i].flagged,
        }
        extractItems.push(extractItem);

        for (let r = 0; r < availableEntity.item[i].records.length; r++) {
          //  console.log('Itemlist in Loop' + availableEntity.item[i].records[r].descrip )

          let extractRecord = {
            //  "uuid": availableEntity.item[i].records[r]._id,
            "itemName": availableEntity.item[i].name,
            "itemDescrip": availableEntity.item[i].text,
            "flagged": availableEntity.item[i].flagged,
            "recordThing": availableEntity.item[i].records[r].thing,
            "recordDesc": availableEntity.item[i].records[r].descrip,
            // "recordType": availableEntity.item[i].records[r].type,

            "recordDate": availableEntity.item[i].records[r].date,
            // "recordDocument": availableEntity.item[i].records[r].document,
          }
          extractRecords.push(extractRecord);
        }

      }
      //console.log(extractItems)
      //console.log((JSON.stringify(extractItems, 0, 2)))
      setCreateExtractList(extractRecords)
      setCreateItemList(extractItems)
    };
    <Route path="download" element={<DownloadItems />} />

    getItems();

  }, []);


  const onFormSubmit = async values => {

    const classItems = []

    if (values.ibepets) { classItems.push('ibepets') }
    if (values.ibegone) { classItems.push('ibegone') }
    if (values.ibehandy) { classItems.push('ibehandy') }
    if (values.ibehouse) { classItems.push('ibehouse') }

  }

  const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData =
      typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData;

    var CSV = "";

    //This condition will generate the Label/Header
    if (ShowLabel) {
      var row = "";

      //This loop will extract the label from 1st index of on array
      for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        row += index + ",";
      }

      row = row.slice(0, -1);

      //append Label row with line break
      CSV += row + "\r\n";
    }

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
      var row = "";

      //2nd loop will extract each column and convert it in string comma-seprated
      for (var index in arrData[i]) {
        row += '"' + arrData[i][index] + '",';
      }

      row.slice(0, row.length - 1);

      //add a line break after each row
      CSV += row + "\r\n";
    }

    if (CSV === "") {
      alert("Invalid data");
      return;
    }

    //Generate a file name
    var fileName = "MyReport_";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = "data:text/csv;charset=utf-8," + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };



  return (
    <>
      <div className="App">



      </div>
      <CSSReset />

      <Box w={1200} p={4} m="20px auto">
        <Heading as="h1" size="xl" textAlign="center">
          infoBee DownLoads
        </Heading>


        <br />



        <Form
          onSubmit={onFormSubmit}
          initialValues={{
            recordThing: "",
            category: "",
            cancelResponse: 'INITIAL',
          }}
          // validate={validateRecord}
          render={({
            handleSubmit,
            form,
            errors,
            //  subscription = { submitting: true },
            submitting,
            pristine,
            values
          }) => (
            <>
              <Box as="form" p={4} borderWidth="3px"
                rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)"
                onSubmit={handleSubmit}
              >

                <SimpleGrid columns={1} columnGap={3} rowGap={3} width="full">


                  <GridItem colSpan={1} width="full">
                    <Text fontSize="2xl">FILTER RECORDS</Text>
                    <Styles>
                      <div>
                        <Field name="recordThing" component="select"
                          placeholder="Select a classification..."
                          closemenuonselect="false" size="sm">

                          <option value="inventory" >ALL  </option>
                          <option value="iBePets" >iBePets</option>
                          <option value="iBeGone">iBeGone</option>
                          <option value="iBeHandy">iBeHandy</option>
                          <option value="iBeHealth" >iBeHealth </option>
                          <option value="iBeHouse"  >iBeHouse</option>
                          <option value="iBePaper">iBePaper</option>
                          <option value="iBeFinacial">iBeFinancial</option>
                          <option value="iBeMember">iBeMember</option>
                          <option value="iBeTechno">iBeTechno</option>
                          <option value="iBeEvent">iBeEvent</option>
                          <option value="iBeSocial">iBeSocial</option>
                          <option value="iBeContacts">iBeContacts</option>
                          <option value="iBeBusy">iBeBusy</option>
                          <option value="iBeCareer">iBeCareer</option>
                          <option value="iBeMemoir">iBeMemoir</option>
                          <option value="iBeFramed">iBeFramed</option>
                        </Field>
                      </div>
                    </Styles>
                  </GridItem>


                  <GridItem colSpan={1}>
                    <VStack>
                      <br />
                      <br />
                      <div>

                        <ButtonGroup flexDirection="center">
                          {!values.recordThing || values.recordThing === 'inventory' ? (<Button
                            //  isLoading={submitting}
                            onClick={() => JSONToCSVConvertor(createExtractList
                              , "infoBee Records for Captured Items", true)}
                            loadingText="DownLoading"
                            colorScheme="teal"
                            type="submit"
                          > <Heading size="md">DOWNLOAD ALL RECORDS
                            </Heading>
                          </Button>) : (<Button
                            //  isLoading={submitting}
                            onClick={() => JSONToCSVConvertor(createExtractList
                              .filter(cls => {
                                if (cls.recordThing === values.recordThing) return true
                              })
                              , "infoBee Records for Captured Items", true)}
                            loadingText="DownLoading"
                            colorScheme="teal"
                            type="submit"
                          > <Heading size="md">{`Download ${values.recordThing} records`}
                            </Heading>
                          </Button>)}

                          <Button
                            onClick={() => JSONToCSVConvertor(createItemList, "infoBee Items ", true)}
                            //   isLoading={submitting}
                            loadingText="Submitting"
                            // size="md"
                            colorScheme="teal"
                            type="submit"
                          >  <Heading size="md">DOWNLOAD ALL ITEMS (No records)
                            </Heading></Button>



                        </ButtonGroup>
                      </div>
                    </VStack>
                  </GridItem>

                </SimpleGrid>
              </Box>
            </>
          )}>




        </Form>




      </Box>
    </>
  );
}

export default connect(null, {})(DownloadItems);

/*


       
        <pre>{JSON.stringify(createExtractList, null, "\t")}</pre>
       

Heading,
  Box,
  Text,
  Checkbox,
  //Link,
  VStack,
  Button,
  IconButton,
  HStack,
  Divider,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  Code,
  SimpleGrid,
  GridItem,
  Grid,
   Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
   Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,



 <div>
        <h4>Generated Table</h4>
      
        <ReactTable tableJSON={createExtractList} />
      </div>
      */