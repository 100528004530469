import React, { useEffect, useState, useRef } from 'react'
import { useSelector, connect } from "react-redux";

import {
  useParams, useNavigate,
  NavLink, useSearchParams, useLocation
} from "react-router-dom";

import {
  Heading,
  Box,
  Text,
  Input,
  Avatar,
  //Checkbox,
  //Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  AspectRatio,
  Image,
  VStack,
  Button,
  ButtonGroup,
  IconButton,
  HStack,
  Stack,
  //Divider,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Badge,
  // Icon,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  Table,
  Thead,
  Tbody,
  // Tfoot,
  Tr,
  Th,
  Td,
  // TableCaption,
  Drawer,
  DrawerBody,
  DrawerFooter,
  //DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useBreakpointValue,
  useDisclosure,
  useColorModeValue,
  //useToast,
} from '@chakra-ui/react';

import {
  StarIcon, CloseIcon, CheckIcon,
  ChevronDownIcon, AddIcon,
} from '@chakra-ui/icons'

import ibtColors from "../common/ibtColors";
//import ImageUpload from '../items/ImageUpload'
import ItemRecordForm from '../forms/ItemRecordForm'
import ItemCommentForm from '../forms/ItemCommentForm'
import ItemFlagForm from '../forms/ItemFlagForm'
import ItemClassForm from '../forms/ItemClassForm'
import ItemNameForm from '../forms/ItemNameForm'

import { refreshUser } from "../../actions/userAction"
//import RenderCount from '../common/RenderCount'

import { deleteCurrentItem, editNoImage, uploadDocument, pdfDocument } from "../../actions/itemAction"

//import { set, values } from 'lodash';

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const ItemDetail = ({ refreshUser, deleteCurrentItem, editNoImage, pdfDocument }, uploadDocument) => {
  let [searchParams, setSearchParams] = useSearchParams();


  const [chooseList, setChooseList] = useState('');
  const [showClass, setShowClass] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  //const [showItem, setShowItem] = useState(false);

  const [createPickList, setCreatePickList] = useState([])
  //onst [createClassList, setCreateClassList] = useState([])
  const [showPickList, setShowPickList] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [showAttachment, setShowAttachment] = useState(false);

  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showSelectedRecord, setShowSelectedRecord] = useState(false);


  const [selectedComment, setSelectedComment] = useState(null);
  const [showSelectedComment, setShowSelectedComment] = useState(false);
  const [newMessage, setNewMessage] = useState(false);

  const [showFlagError, setShowFlagError] = useState(false);
  // const [finalThing, setFinalThing] = useState(false);

  const cancelRef = useRef()
  const cancelThingRef = useRef()


  const onCommentClose = () => setIsCommentOpen(false)
  const cancelCommentRef = useRef()
  const [isCommentOpen, setIsCommentOpen] = useState(false)
  const cancelRecordRef = useRef()
  const cancelUnknownRef = useRef()
  const [isListOpen, setIsListOpen] = useState(false)
  //set to true to have it closed on click 


  //const [isThingOpen, setIsThingOpen] = useState(false)

  const { isOpen: isDetailOpen, onOpen: onDetailOpen, onClose: onDetailClose } = useDisclosure()
  const { isOpen: isDrawOpen, onOpen: onDrawOpen, onClose: onDrawClose } = useDisclosure();
  const { isOpen: isItemOpen, onOpen: onItemOpen, onClose: onItemClose } = useDisclosure();
  const { isOpen: isRecordOpen, onOpen: onRecordOpen, onClose: onRecordClose } = useDisclosure();
  const { isOpen: isUnknownOpen, onOpen: onUnknownOpen, onClose: onUnknownClose } = useDisclosure();

  const availableUser = useSelector((state) => state.user.user);
  const availableToken = useSelector((state) => state.user.token)
  const availableEmail = useSelector((state) => state.user.googleEmail)
  const availableEntity = useSelector((state) => state.entity.entity);


  const btnRef = React.useRef()

  let navigate = useNavigate();
  let params = useParams();
  let entryPath = useLocation();
  //const toast = useToast();

  const boxHeight = useBreakpointValue({ base: "40px", md: "60px" })
  const fontSize = useBreakpointValue({ base: 'xs', md: 'md' })
  const showMenu = useBreakpointValue({ base: false, lg: true })
  //const showMenu = useBreakpointValue({ base: false, lg: true })

  // const colSpan = useBreakpointValue({ base: 2, md: 1 })
  //const formSpan = useBreakpointValue({ base: 1, md: 2 })
  const boxWidth = useBreakpointValue({ base: 365, md: 500, lg: 900 })
  const tabSize = useBreakpointValue({ base: 'xs', md: 'md' })
  const formContent = useBreakpointValue({ base: 'flex-start', })
  const showTable = useBreakpointValue({ base: false, md: true })
  const modalSize = useBreakpointValue({ base: 'sm', md: '4xl' })
  // const showMed = useBreakpointValue({ base: false, lg: true })
  const navTo = useBreakpointValue({
    base: '/',
    md: '/inventory/itemlist/'
  })
  const navDel = useBreakpointValue({
    base: '/',
    md: '/inventory/itemlist/'
  })

  const secondaryTextColor = useColorModeValue('gray.600', 'gray.400')



  const primaryThingColor = ibtColors.ibtpets

  useEffect(() => {
    const refresh = async () => {
      await refreshUser(availableEmail)
    };

    if (availableToken && !availableUser) {
      refresh();
    }

    //onOpen()

  }, [availableToken, availableUser, availableEmail]);

  useEffect(() => {
    const availPick = async () => {
      // console.log('Picklist in Loop' )
      const pickItems = []
      for (let i = 0; i < availableEntity.envoi.length; i++) {
        //  console.log('Picklist in Loop' + pickItems) 
        let pickItem = {
          "pickemail": availableEntity.envoi[i].userid,
          "pickfirst": availableEntity.envoi[i].name,
          "picked": false
        }
        pickItems.push(pickItem);
      }
      setCreatePickList(pickItems)
    };

    if (availableToken && availableUser) {
      availPick();
      availableEntity.item.map((show) => {
        if (show._id === params.itemId) {
          for (let i = 0; i < show.comments.length; i++) {
            //  console.log(show.comment[i])
            if (!show.comments[i].read) {
              setNewMessage(true)
            }
          }
        }
      })

    }

    if (entryPath.pathname === '/itemId') {
      //  console.log('PATH' + entryPath.pathname)
      setIsListOpen(true)
      onDrawOpen(true)
    }

  }, []);



  const onFlagSubmit = async (values) => {
    await sleep(300)
    //console.log('SUBMIT' + (JSON.stringify(values, 0, 2)))

    if (values.flagResponse === 'MINIMUN') {
      // setShowPickList(false)
      setShowFlagError(true)
    }
    if (values.flagResponse === 'CANCEL') {
      setShowPickList(false)
      //setShowFlagError(true)
    }
    if (values.flagResponse === 'INITIAL') {
      setShowPickList(false)
      await editNoImage(values, availableUser._id, (props) => {
        navigate(`${navTo}${props}`)
      })
    }

  }

  const onViewPdf = async (pdffile) => {

    //  console.log('PDF' + pdffile)
    // setShowPickList(!showPickList)

    if (pdffile.toLowerCase().includes("pdf")) {
      await pdfDocument(pdffile, () => {
        // navigate(`/inventory/itemlist/pdfdisplay`)
        navigate(`/pdfdisplay`)
      })
    }
  }


  const onClassSubmit = async (values) => {
    await sleep(300)
    // setShowClass(!showClass)

    //add logic to change records to other if deselected?

    //values.itemId = values.id
    //  console.log('SUBMIT' + (JSON.stringify(values, 0, 2)))

    if (values.flagResponse !== 'CANCEL') {

      await editNoImage(values, availableUser._id, (props) => {
        navigate(`${navTo}${props}`)
      })
    }

  }

  const onDetailSubmit = async (values) => {
    // console.log('detail item update submit')
    await sleep(300)
    setShowDetail(!showDetail)
    //  console.log('SUBMIT' + (JSON.stringify(values, 0, 2)))

    if (values.itemResponse !== 'CANCEL') {
      await editNoImage(values, availableUser._id, (props) => {
        navigate(`${navTo}${props}`)
      })
    }
  }



  const onRecordSubmit = async values => {
    await sleep(300)
    // console.log('What happened cancel? ' + values.cancelResponse)
    if (values.cancelResponse === 'CANCEL') {
      setSelectedRecord(null)
      setShowAttachment(false)
      setShowSelectedRecord(!showSelectedRecord)
      setAttachment(null)
    } else {
      // console.log('What happened? ' + values.recordResponse)
      //check device size and set path
      if (values.recordResponse === 'OKAY') {
        //console.log('SUBMIT' + (JSON.stringify(values, 0, 2)))
        editNoImage(values, availableUser._id, (props) => {
          setSelectedRecord(null)
          setShowAttachment(false)
          setShowSelectedRecord(!showSelectedRecord)
          setAttachment(null)
          navigate(`${navTo}${props}`);

        })
        // }
      }
    }
  }

  const onRecordDelete = async (recordId, item) => {
    //  console.log('in RecordForm onDelete' + recordId)
    await sleep(300)

    // console.log('SUBMIT' + JSON.stringify(recordId, 0, 2))
    const values = item
    const tempRecords = []
    for (let i = 0; i < item.records.length; i++) {
      // console.log('comments in Loop' + item.comments[i]._id + selectedComment)
      if (item.records[i]._id !== recordId) {
        //  console.log('found comment to keep')
        tempRecords.push(item.records[i]);
      }
    }
    // console.log('SUBMIT' + (JSON.stringify(tempRecords, 0, 2)))
    values.records = tempRecords
    values.itemName = item.name
    values.itemId = item._id
    values.image = item.image

    // console.log('SUBMIT' + (JSON.stringify(values, 0, 2)))

    editNoImage(values, availableUser._id, (props) => {
      setSelectedRecord(null)
      // setShowSelectedComment(!showSelectedComment)
      // navigate(`${navTo}${props}`);
      //  console.log('Props in Delete Record' + props + navTo + navDel)
      navigate(`${navTo}${props}`);
    })
    // console.log(itemValues.records)
    // await props.onDeleteRecord(itemValues, temporaryData2);
  }

  const onCommentSubmit = async values => {
    await sleep(300)
    // console.log('What happened? ' + values.commentResponse)

    if (values.cancelResponse === 'CANCEL') {
      setSelectedComment(null)
      setShowSelectedComment(!showSelectedComment)
    }
    if (values.cancelResponse !== 'CANCEL') {
      //console.log('SUBMIT' + (JSON.stringify(values, 0, 2)))

      editNoImage(values, availableUser._id, (props) => {
        setSelectedComment(null)
        setShowSelectedComment(!showSelectedComment)
        navigate(`${navTo}${props}`);

      })
    }
  }

  const onCommentRead = async (values, comment) => {
    await sleep(300)
    //console.log('Comment available' + (JSON.stringify(values, 0, 2)))
    //console.log('Comment read' + (JSON.stringify(comment, 0, 2)))

    values.itemName = values.name
    values.itemId = values._id

    for (let i = 0; i < values.comments.length; i++) {
      if (values.comments[i]._id === comment._id) {
        values.comments[i].read = true
      }
    }

    //console.log('Comment updated' + (JSON.stringify(values.comments, 0, 2)))

    // values.comments.commentlist.commentread
    // if (values.commentResponse === 'OKAY') {
    // console.log('SUBMIT' + (JSON.stringify(values.comments, 0, 2)))
    // console.log('params' + params.accountId)

    editNoImage(values, availableUser._id, (props) => {
      //  console.log('props' + props)
      navigate(`${navTo}${props}`);

    })
    // }
  }

  const countComment = (commentread) => {
    if (!commentread) {
      // console.log('setting')
      // setNewMessage(true)
    }
  }

  const onCommentDelete = async (selectedComment, item) => {
    // console.log('SUBMIT' + JSON.stringify(selectedComment, 0, 2))
    const values = item
    const tempComments = []
    for (let i = 0; i < item.comments.length; i++) {
      // console.log('comments in Loop' + item.comments[i]._id + selectedComment)
      if (item.comments[i]._id !== selectedComment) {
        //  console.log('found comment to keep')
        tempComments.push(item.comments[i]);
      }

    }
    // console.log('SUBMIT' + (JSON.stringify(tempComments, 0, 2)))
    values.comments = tempComments
    values.itemName = item.name
    values.itemId = item._id
    values.image = item.image

    // console.log('SUBMIT' + (JSON.stringify(values, 0, 2)))

    editNoImage(values, availableUser._id, (props) => {
      setSelectedComment(null)
      // setShowSelectedComment(!showSelectedComment)
      navigate(`${navTo}${props}`);
    })

  }

  function QueryNavLink({ to, ...props }) {
    let location = useLocation();

    return <NavLink to={to + location.search} {...props} />;
  }




  const renderRecordTable = (records, thing, color, show) => {

    function isClass(value) {
      return value.thing === thing || thing === "other"
    }

    let filteredClass = records.filter(isClass)



    //console.log('filteredClass' + thing + JSON.stringify(filteredClass, 0, 2))
    return (

      <Box style={{ margin: .5 }}>
        <Table whitespace="wrap" overflowX="hidden" overflowY="hidden" style={{
          margin: .5,
          paddingleft: 1, paddingRight: 1,
        }} variant='striped' colorScheme='gray.400'>
          {filteredClass.length > 0 ? (
            <Thead style={{ margin: .5 }}>
              <Tr style={{ margin: .5 }}>
                {showTable ? (<>
                  <Th style={{ margin: .5 }}><Heading size="md" style={{ color: color }} ></Heading>Action</Th>
                  <Th ><Heading size="md" mr={160} >Description </Heading> </Th>
                  <Th><Heading size="md"  >Date</Heading></Th>
                  <Th><Heading size="md" style={{ color: color }}> </Heading>Attachment</Th>
                </>) : (<>
                  <Th textAlign="center" w="5%" maxW={1}>
                    <Heading size="md" mr={1} ml={1} style={{
                      width: 20
                    }} > </Heading></Th>
                  <Th textAlign="center" w="80%"><Heading size="md" mr={35}  > </Heading>Description</Th>
                  <Th textAlign="center" w="10%"><Heading size="md" style={{ color: color }}> </Heading>Attached</Th>
                </>
                )}
              </Tr>
            </Thead>

          ) : null}

          {filteredClass.length > 0 ? (
            filteredClass.map((rec) => {
              //  console.log('MAP rec' + rec.thing)
              return (
                <>
                  <Tbody key={rec._id + rec.descrip} style={{ margin: 1 }}>
                    <Tr style={{ margin: .5 }}>
                      <Td textAlign="center" w="5%" style={{ margin: .5 }}>
                        <Menu style={{ margin: .5 }}>
                          <MenuButton transition='all 0.2s' borderRadius='md'
                            borderWidth='1px' _hover={{ bg: 'gray.400' }} _expanded={{ bg: `${color}.200` }}
                            _focus={{ boxShadow: 'outline' }} style={{ margin: .25 }}  >
                            <ChevronDownIcon style={{ margin: .25 }} />
                          </MenuButton>
                          <MenuList style={{ margin: 1 }}>
                            <MenuItem onClick={() => {
                              //  console.log('Clickling and Clicking');
                              setSelectedRecord(rec)
                              setShowSelectedRecord(true)
                            }}>Edit Record</MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => { onViewPdf(rec.document) }}>Document</MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => {
                              setSelectedRecord(rec)
                              onDetailOpen()
                              // setShowItem(true)
                            }}
                            >View Item</MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => {
                              setSelectedRecord(rec)
                              onRecordOpen()
                            }}>Delete Record</MenuItem>

                          </MenuList>
                        </Menu>



                        <AlertDialog
                          isOpen={isRecordOpen}
                          leastDestructiveRef={cancelRecordRef}
                          onClose={onRecordClose}
                        >
                          <AlertDialogOverlay>
                            <AlertDialogContent>
                              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                Delete Record
                              </AlertDialogHeader>
                              <AlertDialogBody>
                                Are you sure? You can't undo this action afterwards.
                              </AlertDialogBody>
                              <AlertDialogFooter>
                                <Button ref={cancelRecordRef}
                                  onClick={onRecordClose}>
                                  Cancel
                                </Button>
                                <Button colorScheme='red'
                                  onClick={() => {
                                    onRecordDelete(selectedRecord._id, show);
                                    onRecordClose()
                                  }} ml={3}>
                                  Delete
                                </Button>
                              </AlertDialogFooter>
                            </AlertDialogContent>
                          </AlertDialogOverlay>
                        </AlertDialog>

                      </Td>



                      {showTable ? (
                        <>
                          <Td><Text noOfLines={5} onClick={() => {
                            setSelectedRecord(rec)
                            onDetailOpen()
                          }}>{rec.descrip}</Text>
                          </Td>
                          {rec.date ? (<Td>{rec.date.substring(0, 15)}</Td>) : (<Td> </Td>)}
                          {rec.document ? (<Td onClick={() => {
                            if (rec.document.toLowerCase().includes("pdf")) { onViewPdf(rec.document) } else {
                              setSelectedRecord(rec)
                              onDetailOpen()
                              //  setShowItem(true)
                            }
                          }}>
                            <AspectRatio maxW='100px' ratio={4 / 3}>
                              <Image src={rec.document} alt='alt record' objectFit='cover' />
                            </AspectRatio>
                          </Td>) : <Td></Td>}
                         

                        </>
                      ) : (

                        <>
                          <Td textAlign="center" w="80%"><Text noOfLines={5} fontSize="sm" onClick={() => {
                            setSelectedRecord(rec)
                            onDetailOpen()
                          }}>{rec.descrip}</Text>
                          </Td>

                          {rec.document ? (<Td textAlign="center" w="10%" onClick={() => {
                            if (rec.document.toLowerCase().includes("pdf")) { onViewPdf(rec.document) } else {
                              setSelectedRecord(rec)
                              onDetailOpen()
                            }
                          }}>
                            <AspectRatio maxW='60px' ratio={4 / 3}>
                              <Image src={rec.document} alt='alt record' objectFit='cover' />
                            </AspectRatio>
                          </Td>) : <Td textAlign="center" w="10%" ></Td>}

                        </>
                      )}
                    </Tr>
                  </Tbody>
                </>
              )
            })
          ) : null}




        </Table>
      </Box >


    )
  }




  const renderAltTable = (records, thing, color, show) => {
    return (

      availableEntity.item.map((rem) => {
        if (rem._id !== params.itemId) {
          return (null)
        } else {

          const filteredClass = []
          //  console.log(JSON.stringify(rem.records, 0, 2))
          const validList = []
          if (rem.ibepets) {
            validList.push("iBePets")
          }
          if (rem.ibegone) {
            validList.push("iBeGone")
          }
          if (rem.ibehandy) {
            validList.push("iBeHandy")
          }
          if (rem.ibehouse) {
            validList.push("iBeHouse")
          }
          if (rem.ibehealth) {
            validList.push("iBeHealth")
          }
          if (rem.ibepaper) {
            validList.push("iBePaper")
          }
          if (rem.ibemoney) {
            validList.push("iBeMoney")
          }
          if (rem.ibemember) {
            validList.push("iBeMember")
          }
          if (rem.ibetechno) {
            validList.push("iBeTechno")
          }
          if (rem.ibeevent) {
            validList.push("iBeEvent")
          }
          if (rem.ibesocial) {
            validList.push("iBeSocial")
          }
          if (rem.ibecontacts) {
            validList.push("iBeContacts")
          }
          if (rem.ibebusy) {
            validList.push("iBeBusy")
          }
          if (rem.ibecareer) {
            validList.push("iBeCareer")
          }
          if (rem.ibememoir) {
            validList.push("iBeMemoir")
          }
          if (rem.ibeframed) {
            validList.push("iBeFramed")
          }
          // console.log(validList)

          for (let i = 0; i < rem.records.length; i++) {
            // console.log(rem.records[i].thing)
            const checkThing = (res) => {
              return res === rem.records[i].thing;
            }

            const removeThing = validList.find(checkThing)

            if (!removeThing) {
              filteredClass.push(rem.records[i])
            }

          }
          // console.log('filteredClass' + thing + JSON.stringify(filteredClass, 0, 2))

          return (
            <>
              <Table key={rem._id} variant='striped' colorScheme='gray.400'>
                {filteredClass.length > 0 ? (
                  <Thead>
                    <Tr>
                      <Th><Heading size="md" style={{ color: color }} ></Heading>Action</Th>
                      {showTable ? (<>
                        <Th><Heading size="md" style={{ color: color }}> </Heading></Th>
                        <Th><Heading size="md" mr={160}  >Description </Heading> </Th>
                        <Th><Heading size="md"  >Date</Heading></Th>
                        <Th><Heading size="md" style={{ color: color }}> </Heading>Attachment</Th>
                      </>) : (<>
                        <Th><Heading size="md" style={{ color: color }}> </Heading></Th>
                        <Th><Heading size="md" ml={65}  > </Heading>Description</Th>
                      </>)}
                    </Tr>
                  </Thead>

                ) : null}



                {filteredClass.length > 0 ? (
                  filteredClass.map((rec) => {
                    return (
                      <>
                        <Tbody key={rec._id + rec.descrip}>
                          <Tr>
                            <Td>
                              <Menu>
                                <MenuButton px={1} py={1} transition='all 0.2s' borderRadius='md'
                                  borderWidth='1px' _hover={{ bg: 'gray.400' }} _expanded={{ bg: `${color}.200` }}
                                  _focus={{ boxShadow: 'outline' }}   >
                                  <ChevronDownIcon />
                                </MenuButton>
                                <MenuList>
                                  <MenuItem onClick={() => {
                                    //  console.log('Clickling and Clicking');
                                    setSelectedRecord(rec)
                                    setShowSelectedRecord(true)
                                  }}>Edit Record</MenuItem>
                                  <MenuDivider />
                                  <MenuItem onClick={() => { onViewPdf(rec.document) }}>View Attachment</MenuItem>
                                  <MenuDivider />
                                  <MenuItem onClick={() => {
                                    setSelectedRecord(rec)
                                    onDetailOpen()
                                    // setShowItem(true)
                                  }}
                                  >View Item</MenuItem>
                                  <MenuDivider />
                                  <MenuItem onClick={() => {
                                    setSelectedRecord(rec)
                                    onUnknownOpen()
                                  }}>Delete Record</MenuItem>


                                </MenuList>
                              </Menu>



                              <AlertDialog
                                isOpen={isUnknownOpen}
                                leastDestructiveRef={cancelUnknownRef}
                                onClose={onUnknownClose}
                              >

                                <AlertDialogOverlay>
                                  <AlertDialogContent>
                                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                      Delete Record
                                    </AlertDialogHeader>
                                    <AlertDialogBody>
                                      Are you sure? You can't undo this action afterwards.
                                    </AlertDialogBody>
                                    <AlertDialogFooter>
                                      <Button ref={cancelUnknownRef}
                                        onClick={onUnknownClose}>
                                        Cancel
                                      </Button>
                                      <Button colorScheme='red'
                                        onClick={() => {
                                          onRecordDelete(selectedRecord._id, show);
                                          onUnknownClose()
                                        }} ml={3}>
                                        Delete
                                      </Button>
                                    </AlertDialogFooter>
                                  </AlertDialogContent>
                                </AlertDialogOverlay>
                              </AlertDialog>

                            </Td>



                            {showTable ? (
                              <>
                                <Td  ><Text>{rec.thing}</Text></Td>
                                <Td><Text noOfLines={5} onClick={() => {
                                  setSelectedRecord(rec)
                                  onDetailOpen()
                                  //setShowItem(true)
                                }}>{rec.descrip}</Text>
                                </Td>
                                {rec.date ? (<Td>{rec.date.substring(0, 15)}</Td>) : (<Td> </Td>)}
                                {rec.document ? (<Td onClick={() => {
                                  if (rec.document.toLowerCase().includes("pdf")) { onViewPdf(rec.document) } else {
                                    setSelectedRecord(rec)
                                    onDetailOpen()
                                    // setShowItem(true)
                                  }
                                }}>
                                  <AspectRatio maxW='100px' ratio={4 / 3}>
                                    <Image src={rec.document} alt='alt record' objectFit='cover' />
                                  </AspectRatio>
                                </Td>) : <Td></Td>}

                              </>
                            ) : (
                              <>
                                <Td  ><Text fontSize="sm">{rec.thing}</Text></Td>

                                <Td><Text noOfLines={5} fontSize="sm" onClick={() => {
                                  setSelectedRecord(rec)
                                  onDetailOpen()
                                  // setShowItem(true)
                                }}>{rec.descrip}</Text>
                                </Td>

                              </>
                            )}


                          </Tr>
                        </Tbody>
                      </>

                    )
                  })
                ) : null}
              </Table>
            </>
          )
        }
      })

    )
  }


  const renderComments = () => {
    return (

      availableEntity.item.map((item) => {
        if (item._id !== params.itemId) {
          return (null)
        } else {

          return (
            <div key={item._id}>
              {showSelectedComment ? (renderSelectedComment(item)) : null}
              <br />



              <Table variant='striped' colorScheme='gray.400'  >
                <Thead size="lg">
                  <Tr>
                    <Th><Heading size="md" ></Heading>Action</Th>
                    <Th><Heading size="md"  >From/To </Heading> </Th>
                    <Th><Heading mr={180} size="md"  >Messages </Heading> </Th>
                    <Th><Heading size="md" mr={14}  >Date </Heading> </Th>
                  </Tr>
                </Thead>
                {item.comments ? (
                  item.comments.map((comment) =>
                    <>
                      <Tbody key={comment._id} >
                        <Tr>
                          <Td>
                            <Menu>
                              <MenuButton px={1} py={2} transition='all 0.2s' borderRadius='md'
                                borderWidth='1px' _hover={{ bg: 'gray.400' }}
                                _focus={{ boxShadow: 'outline' }}   >
                                <ChevronDownIcon />
                              </MenuButton>
                              <MenuList>

                                <MenuItem onClick={() => {
                                  setSelectedComment(comment)

                                  setIsCommentOpen(true);
                                }}>Delete Comment</MenuItem>


                                <MenuDivider />

                              </MenuList>


                              <AlertDialog
                                isCommentOpen={isCommentOpen}
                                leastDestructiveRef={cancelCommentRef}
                                onCommentClose={onCommentClose}
                              >
                                <AlertDialogOverlay>
                                  <AlertDialogContent>
                                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                      Delete Comment
                                    </AlertDialogHeader>
                                    <AlertDialogBody>
                                      Are you sure? You can't undo this action afterwards.

                                    </AlertDialogBody>
                                    <AlertDialogFooter>
                                      <Button ref={cancelCommentRef} onClick={onCommentClose}>
                                        Cancel
                                      </Button>
                                      <Button colorScheme='red'
                                        onClick={() => {
                                          onCommentDelete(selectedComment._id, item);

                                          //  console.log('Deleting' + selectedComment._id + selectedComment.comment);
                                          onCommentClose()
                                        }} ml={3}>
                                        Delete
                                      </Button>
                                    </AlertDialogFooter>
                                  </AlertDialogContent>
                                </AlertDialogOverlay>
                              </AlertDialog>

                            </Menu>
                            <br />
                            <br />

                            {availableUser.email !== comment.commentfrom ? (
                              <>

                                {comment.read ? (<>
                                  <CheckIcon /><Text as='em' color="green">read</Text>
                                </>)
                                  : (<>


                                    <Button onClick={() => onCommentRead(item, comment)}
                                      size='md' height='50px' width='50px' borderWidth='2px' bordercolor="teal">

                                      <Text as='i'  >unread</Text>
                                    </Button>



                                  </>)}
                              </>
                            ) : <Text>SENT</Text>}
                          </Td>


                          <Td>
                            <HStack>
                              <Heading size="sm">FROM:  </Heading>
                              <Text> {comment.commentfrom} </Text>

                            </HStack>
                            <br />
                            <Heading size="sm">TO:</Heading>

                            <VStack>
                              {comment.commentlist.length > 0 ? (
                                comment.commentlist.map((list) => {
                                  return (
                                    <HStack key={list.commentemail}>
                                      <Text>{list.commentemail}</Text>
                                      {list.commentread ? (
                                        <>
                                          <CheckIcon /><Text as='em' color="green">read</Text>
                                        </>)
                                        : (
                                          <>
                                            <div onClick={() => onCommentRead(item, comment)}
                                              size='md' height='50px' width='50px' border='2px' bordercolor="black">

                                              <Text as='i' onClick={() => onCommentRead(item, comment)}>unread</Text>
                                            </div>
                                          </>
                                        )}
                                      )

                                    </HStack>
                                  )
                                })) : null}

                            </VStack>
                          </Td>
                          <Td>{comment.comment}</Td>
                          {comment.date ? <Td>{comment.date.substring(0, 10)}</Td> : (<Td></Td>)}

                        </Tr>
                      </Tbody>
                    </>
                  )
                ) : null}
              </Table>

            </div>
          )
        }
      })
    )
  }

  const renderCommentsMobile = () => {
    return (

      availableEntity.item.map((item) => {
        if (item._id !== params.itemId) {
          return (null)
        } else {

          return (
            <div key={item._id}>
              {showSelectedComment ? (renderSelectedComment(item)) : null}
              <br />



              <Table variant='striped' colorScheme='gray.400'  >
                <Thead size="lg">
                  <Tr>
                    <Th><Heading size="md" ></Heading>Action</Th>
                    <Th><Heading size="md" ></Heading>Message</Th>

                  </Tr>
                </Thead>
                {item.comments ? (
                  item.comments.map((comment) =>
                    <>
                      <Tbody key={comment._id} >
                        <Tr>
                          <Td>
                            <Menu>
                              <MenuButton px={1} py={2} transition='all 0.2s' borderRadius='md'
                                borderWidth='1px' _hover={{ bg: 'gray.400' }}
                                _focus={{ boxShadow: 'outline' }}   >
                                <ChevronDownIcon />
                              </MenuButton>
                              <MenuList>

                                <MenuItem onClick={() => {
                                  setSelectedComment(comment)

                                  setIsCommentOpen(true);
                                }}>Delete Comment</MenuItem>


                                <MenuDivider />

                              </MenuList>


                              <AlertDialog
                                isCommentOpen={isCommentOpen}
                                leastDestructiveRef={cancelCommentRef}
                                onCommentClose={onCommentClose}
                              >
                                <AlertDialogOverlay>
                                  <AlertDialogContent>
                                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                      Delete Comment
                                    </AlertDialogHeader>
                                    <AlertDialogBody>
                                      Are you sure? You can't undo this action afterwards.

                                    </AlertDialogBody>
                                    <AlertDialogFooter>
                                      <Button ref={cancelCommentRef} onClick={onCommentClose}>
                                        Cancel
                                      </Button>
                                      <Button colorScheme='red'
                                        onClick={() => {
                                          onCommentDelete(selectedComment._id, item);

                                          // console.log('Deleting' + selectedComment._id + selectedComment.comment);
                                          onCommentClose()
                                        }} ml={3}>
                                        Delete
                                      </Button>
                                    </AlertDialogFooter>
                                  </AlertDialogContent>
                                </AlertDialogOverlay>
                              </AlertDialog>

                            </Menu>
                            <br />
                            <br />

                            {availableUser.email !== comment.commentfrom ? (
                              <>

                                {comment.read ? (<>
                                  <CheckIcon /><Text as='em' color="green">read</Text>
                                </>)
                                  : (<>


                                    <Button onClick={() => onCommentRead(item, comment)}
                                      size='md' height='50px' width='50px' borderWidth='2px' bordercolor="teal">

                                      <Text as='i'  >unread</Text>
                                    </Button>



                                  </>)}
                              </>
                            ) : <Text>SENT</Text>}
                          </Td>


                          <Td>
                            <VStack>
                              <HStack>
                                <Text fontSize="lg">FROM:  </Text>
                                <Text fontSize="md"> {comment.commentfrom} </Text>
                              </HStack>
                              <VStack>
                                <Text fontSize="lg">TO:  </Text>
                                {comment.commentlist.length > 0 ? (
                                  comment.commentlist.map((list) => {
                                    return (

                                      <HStack key={list.commentemail}>
                                        <Text fontSize="md">{list.commentemail}</Text>
                                        {list.commentread ? (
                                          <>

                                            <CheckIcon /><Text as='em' color="green">read</Text>
                                          </>)
                                          : (
                                            <>
                                              <div onClick={() => onCommentRead(item, comment)}
                                                size='md' height='50px' width='50px' border='2px' bordercolor="black">
                                                <Button onClick={() => onCommentRead(item, comment)}>
                                                  <Text as='i'
                                                  >acknowledge</Text>
                                                </Button>

                                              </div>
                                            </>
                                          )}
                                        )
                                      </HStack>
                                    )
                                  })) : null}

                              </VStack>
                              <br />
                              <Text fontSize="md">{comment.comment}</Text>
                              {comment.date ? (<Text fontSize="md">{comment.date.substring(0, 10)}</Text>) : null}
                            </VStack>
                          </Td>

                        </Tr>

                      </Tbody>
                    </>
                  )
                ) : null}
              </Table>

            </div>
          )
        }
      })
    )
  }


  const renderCommentlist = (commentlist) => {
    //  console.log(commentlist)
    const showlist = []
    commentlist.map((item) => {
      if (item.commentread) {
        showlist.push(`${item.commentemail}(Read) -- `);
      } else {
        //add counter
        showlist.push(`${item.commentemail}(Not Read) --  `);
      }
      //showlist.push(`${item.commentemail}(${item.commentemail}) `);
    })

    return showlist

  }

  const renderSelectedComment = (comment) => {
    //  console.log('selectedComment' + JSON.stringify(selectedComment, 0, 2))
    return (
      selectedComment ? (
        <>
          <ItemCommentForm onSubmit={onCommentSubmit}
            comment={comment} item={comment.item} selectedComment={selectedComment} />
        </>
      ) : null

    )
  }

  const renderPicklist = () => {

    return (

      availableEntity.item.map((item) => {
        if (item._id !== params.itemId) {
          return (null)
        } else {

          return (
            <div key={item._id}>
              <Table variant='striped' colorScheme='gray.400' >
                <Thead>
                  <Tr >
                    <Th><Text mr={40} fontSize="lg"  >Email </Text> </Th>
                    <Th><Text fontSize="lg"  >Name </Text> </Th>



                  </Tr>
                </Thead>
                {item.picklist.length > 0 ? (
                  item.picklist.map((pick) =>
                    <Tbody key={pick._id}>
                      <Tr>
                        <Td key={pick._id}>
                          <Text fontSize="md">{pick.pickemail}</Text></Td>
                        <Td><Text fontSize="md">{pick.pickfirst}</Text></Td>

                      </Tr>
                    </Tbody>

                  )
                ) : null}
              </Table>
              <h3 style={{ color: "black" }}>Users who can view this item when flagged </h3>

            </div>)
        }
      })
    )
  }





  const renderRecords = () => {

    return (
      availableEntity.item.map((show) => {
        if (show._id !== params.itemId) {
          return (null)
        } else {
          //  setOtherRecords(show.records)
          // console.log('Records' + show.records)
          return (
            <Box key={show._id} style={{ margin: 1 }} >
              {showSelectedRecord ? (renderSelectedRecord(show)) : null}
              <br />

              <Accordion key={show._id} defaultIndex={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]} allowMultiple allowToggle>

                {show.ibepets ? (
                  <Box style={{ margin: 1 }} >
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtpets}`}>
                            iBePets </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel style={{ margin: 1 }}>
                        {renderRecordTable(show.records, 'iBePets', `${ibtColors.ibtpets}`, show)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </Box>
                ) : null}

                {show.ibegone ? (
                  <>
                    <AccordionItem  >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtgone}`}>
                            iBeGone </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeGone', `${ibtColors.ibtgone}`, show)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}



                {show.ibehandy ? (
                  <>
                    <AccordionItem  >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibthandy}`}>
                            iBeHandy </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeHandy', `${ibtColors.ibthandy}`, show)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibehouse ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibthouse}`}>
                            iBeHouse </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeHouse', `${ibtColors.ibthouse}`, show)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibehealth ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibthealth}`}>
                            iBeHealth </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel style={{ margin: .5 }}>
                        {renderRecordTable(show.records, 'iBeHealth', `${ibtColors.ibthealth}`, show)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibepaper ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtpaper}`}>
                            iBePaper </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBePaper', `${ibtColors.ibtpaper}`, show)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibemoney ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtmoney}`}>
                            iBeMoney </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeMoney', `${ibtColors.ibtmoney}`, show)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibemember ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtmember}`}>
                            iBeMember </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeMember', `${ibtColors.ibtmember}`, show)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibetechno ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibttechno}`}>
                            iBeTechno </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeTechno', `${ibtColors.ibttechno}`, show)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibeevent ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtevent}`}>
                            iBeEvent </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeEvent', `${ibtColors.ibtevent}`, show)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibesocial ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtsocial}`}>
                            iBeSocial </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeSocial', `${ibtColors.ibtsocial}`, show)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibecontacts ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtcontacts}`}>
                            iBeContacts </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeContacts', `${ibtColors.ibtcontacts}`, show)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibebusy ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtbusy}`}>
                            iBeBusy </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeBusy', `${ibtColors.ibtbusy}`, show)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibecareer ? (

                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtcareer}`}>
                            iBeCareer </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeCareer', `${ibtColors.ibtcareer}`, show)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibememoir ? (

                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtmemoir}`}>
                            iBeMemoir </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeMemoir', `${ibtColors.ibtmemoir}`, show)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                {show.ibeframed ? (
                  <>
                    <AccordionItem >
                      <AccordionButton _expanded={{}}>
                        <VStack flex='1' textAlign='center' >
                          <Heading size="lg" color={`${ibtColors.ibtframed}`}>
                            iBeFramed </Heading>
                        </VStack>
                        <AccordionIcon />
                      </AccordionButton>
                      <AccordionPanel>
                        {renderRecordTable(show.records, 'iBeFramed', `${ibtColors.ibtframed}`, show)}
                      </AccordionPanel>
                    </AccordionItem>
                    <br />
                  </>
                ) : null}

                <AccordionItem >
                  <AccordionButton _expanded={{}}>
                    <VStack flex='1' textAlign='center' >
                      <Heading size="lg" color={`${ibtColors.ibtinventory}`}>
                        No Matching Classification </Heading>
                    </VStack>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel>
                    {renderAltTable(show.records, 'other', `${ibtColors.ibtinventory}`, show)}
                  </AccordionPanel>
                </AccordionItem>

              </Accordion>

            </Box>

          )
        }
      })
    )
  }

  const renderSelectedRecord = (show) => {
    //console.log(selectedRecord)
    // console.log(JSON.stringify(show.records, 0, 2))
    return (
      selectedRecord ? (
        <>
          {show.records.length > 18
            || show.records.length > availableUser.memberLimit
            ? (<Heading>RECORD MAX REACHED</Heading>)
            : (<ItemRecordForm onSubmit={onRecordSubmit}
              show={show} selectedRecord={selectedRecord} />)}

        </>
      ) : null

    )
  }

  const renderFlagOptions = (show) => {
    return (

      <>
        <ItemFlagForm onSubmit={onFlagSubmit}
          show={show} item={show.item} pickFrom={createPickList} />
      </>

    )
  }

  const renderClassification = () => {
    return (

      availableEntity.item.map((item) => {
        if (item._id !== params.itemId) {
          return (null)
        } else {

          return (
            <div key={item._id}>

              <ItemClassForm onSubmit={onClassSubmit}
                item={item} />
            </div>)
        }
      })
    )
  }



  const renderUpdateDetail = (show) => {

    return (
      <>
        <ItemNameForm onSubmit={onDetailSubmit}
          item={show} />
      </>

    )
  }


  const renderDetails = () => {
    return (
      availableEntity.item.map((show) => {
        if (show._id !== params.itemId) {
          return (null)
        } else {
          return (
            <>
              <div key={show._id}>

                <>

                  <Drawer
                    isOpen={isDrawOpen}
                    placement='left'
                    onClose={onDrawClose}
                    finalFocusRef={btnRef}
                  >
                    <DrawerOverlay />
                    <DrawerContent>
                      <DrawerCloseButton />

                      <DrawerBody>
                        <>

                          <Button onClick={() => setIsListOpen(true)}
                            backgroundColor={"grey"} color="white">Show Lists</Button>

                          <br />
                          {isListOpen ? (<>
                            <Box pb={4}  >
                              <Stack as={'nav'} spacing={1}>
                                <div >
                                  <Box backgroundColor={`${ibtColors.ibtinventory}`} marginTop={1}>

                                    <Text onClick={() => {
                                      setChooseList('inventory')
                                      setIsListOpen(false)
                                    }} fontSize="lg" color={"white"}
                                      style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                    >
                                      inventory</Text>

                                  </Box>
                                  <Box backgroundColor={`${ibtColors.ibtflagged}`} marginTop={1}>
                                    <Text onClick={() => {
                                      setChooseList('flagged')
                                      setIsListOpen(false)
                                    }} fontSize="lg" color={"white"}
                                      style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                    >Flagged </Text>
                                  </Box>
                                  <Box backgroundColor={`${ibtColors.ibttodo}`} marginTop={1}>
                                    <Text onClick={() => {
                                      //  setChooseList('iBePets')
                                      // setIsListOpen(false)
                                    }} fontSize="lg" color={"white"}
                                      style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                    >ToDo </Text>
                                  </Box>
                                  <Box backgroundColor={`${ibtColors.ibtpets}`} marginTop={1}>
                                    <Text onClick={() => {
                                      setChooseList('iBePets')
                                      setIsListOpen(false)
                                    }} fontSize="lg" color={"white"}
                                      style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                    >iBePets </Text>
                                  </Box>
                                  <Box backgroundColor={`${ibtColors.ibtcareer}`} marginTop={1}>
                                    <Text onClick={() => {
                                      setChooseList('iBeCareer')
                                      setIsListOpen(false)
                                    }} fontSize="lg" color={"white"}
                                      style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                    >iBeCareer  </Text>
                                  </Box>
                                  <Box backgroundColor={`${ibtColors.ibthandy}`} marginTop={1}>
                                    <Text onClick={() => {
                                      setChooseList('iBeHandy')
                                      setIsListOpen(false)
                                    }} fontSize="lg" color={"white"}
                                      style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                    >iBeHandy  </Text>
                                  </Box>

                                  {availableUser ? (
                                    <>
                                      {availableUser.status === 'Basic'
                                        || availableUser.status === 'Active'
                                        ? (<>
                                          <Box backgroundColor={`${ibtColors.ibthouse}`} marginTop={1}>
                                            <Text onClick={() => {
                                              setChooseList('iBeHouse')
                                              setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeHouse  </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibtgone}`} marginTop={1}>
                                            <Text onClick={() => {
                                              setChooseList('iBeGone')
                                              setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeGone </Text>
                                          </Box >

                                          <Box backgroundColor={`${ibtColors.ibtpaper}`} marginTop={1}>
                                            <Text onClick={() => {
                                              setChooseList('iBePaper')
                                              setIsListOpen(false)

                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBePaper </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibtmoney}`} marginTop={1}>
                                            <Text onClick={() => {
                                              setChooseList('iBeMoney')
                                              setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeMoney  </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibtmember}`} marginTop={1}>
                                            <Text onClick={() => {
                                              setChooseList('iBeMember')
                                              setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeMember  </Text>
                                          </Box>
                                        </>) : (<>
                                          <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                            <Text onClick={() => {
                                              //  setChooseList('iBeHouse')
                                              //   setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeHouse  </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                            <Text onClick={() => {
                                              // setChooseList('iBeGone')
                                              // setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeGone </Text>
                                          </Box >

                                          <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                            <Text onClick={() => {
                                              //  setChooseList('iBePaper')
                                              // setIsListOpen(false)

                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBePaper </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                            <Text onClick={() => {
                                              //  setChooseList('iBeMoney')
                                              //  setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeMoney  </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                            <Text onClick={() => {
                                              // setChooseList('iBeMember')
                                              // setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeMember  </Text>
                                          </Box></>)}




                                      {availableUser.status === 'Active'
                                        ? (<>
                                          <Box backgroundColor={`${ibtColors.ibttechno}`} marginTop={1}>
                                            <Text onClick={() => {
                                              setChooseList('iBeTechno')
                                              setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeTechno  </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibtevent}`} marginTop={1}>
                                            <Text onClick={() => {
                                              setChooseList('iBeEvent')
                                              setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeEvent  </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibtsocial}`} marginTop={1}>
                                            <Text onClick={() => {
                                              setChooseList('iBeSocial')
                                              setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeSocial  </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibtcontacts}`} marginTop={1}>
                                            <Text onClick={() => {
                                              setChooseList('iBeContacts')
                                              setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeContact  </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibtbusy}`} marginTop={1}>
                                            <Text onClick={() => {
                                              setChooseList('iBeBusy')
                                              setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeBusy  </Text>
                                          </Box>

                                          <Box backgroundColor={`${ibtColors.ibtmemoir}`} marginTop={1}>
                                            <Text onClick={() => {
                                              setChooseList('iBeMemoir')
                                              setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeMemoir  </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibthealth}`} marginTop={1}>
                                            <Text onClick={() => {
                                              setChooseList('iBeHealth')
                                              setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeHealth </Text>
                                            <Box backgroundColor={`${ibtColors.ibtframed}`} marginTop={1}>
                                              <Text onClick={() => {
                                                setChooseList('iBeFramed')
                                                setIsListOpen(false)
                                              }} fontSize="lg" color={"white"}
                                                style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                              >iBeFramed  </Text>
                                            </Box>
                                          </Box>
                                        </>) : (<>
                                          <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                            <Text onClick={() => {
                                              //   setChooseList('iBeTechno')
                                              //  setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeTechno  </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                            <Text onClick={() => {
                                              //   setChooseList('iBeEvent')
                                              //   setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeEvent  </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                            <Text onClick={() => {
                                              //  setChooseList('iBeSocial')
                                              //  setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeSocial  </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                            <Text onClick={() => {
                                              //  setChooseList('iBeContacts')
                                              // setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeContact  </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                            <Text onClick={() => {
                                              //   setChooseList('iBeBusy')
                                              //  setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeBusy  </Text>
                                          </Box>

                                          <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                            <Text onClick={() => {
                                              //  setChooseList('iBeMemoir')
                                              //  setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeMemoir  </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                            <Text onClick={() => {
                                              // setChooseList('iBeHealth')
                                              // setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeHealth </Text>
                                          </Box>
                                          <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                            <Text onClick={() => {
                                              //  setChooseList('iBeFramed')
                                              //  setIsListOpen(false)
                                            }} fontSize="lg" color={"white"}
                                              style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                            >iBeFramed  </Text>
                                          </Box>
                                        </>)}


                                    </>
                                  ) : null}



                                </div>

                              </Stack>
                            </Box>
                            <Input
                              value={searchParams.get("filter") || ""}
                              style={{ padding: ".25rem" }}
                              placeholder="   Item Contains Search"
                              onChange={event => {
                                let filter = event.target.value
                                if (filter) {
                                  setSearchParams({ filter });
                                } else {
                                  setSearchParams({});
                                }
                              }}
                            />
                          </>) : null}

                          {availableEntity ? (
                            availableEntity.item
                              .filter(cls => {
                                // if (cls.ibepets)
                                // if (`cls.${chooseList}`)

                                if ((chooseList === 'iBePets' && cls.ibepets)
                                  || (chooseList === 'iBeGone' && cls.ibegone)
                                  || (chooseList === 'iBeHandy' && cls.ibehandy)
                                  || (chooseList === 'iBeHouse' && cls.ibehouse)
                                  || (chooseList === 'iBeHealth' && cls.ibehealth)
                                  || (chooseList === 'iBePaper' && cls.ibepaper)
                                  || (chooseList === 'iBeMoney' && cls.ibemoney)
                                  || (chooseList === 'iBeMember' && cls.ibemember)
                                  || (chooseList === 'iBeTechno' && cls.ibetechno)
                                  || (chooseList === 'iBeEvent' && cls.ibeevent)
                                  || (chooseList === 'iBeSocial' && cls.ibesocial)
                                  || (chooseList === 'iBeContacts' && cls.ibecontacts)
                                  || (chooseList === 'iBeBusy' && cls.ibebusy)
                                  || (chooseList === 'iBeCareer' && cls.ibecareer)
                                  || (chooseList === 'iBeMemoir' && cls.ibememoir)
                                  || (chooseList === 'iBeFramed' && cls.ibeframed)
                                  || (chooseList === 'flagged' && cls.flagged)
                                  || (chooseList === 'inventory')


                                ) { return true } else { return false }
                              }).filter(item => {
                                let filter = searchParams.get("filter");
                                if (!filter) return true;
                                let name = item.name.toLowerCase();
                                //   return name.startsWith(filter.toLowerCase());
                                return name.includes(filter.toLowerCase());
                              }).map(item => (
                                <QueryNavLink onClick={onDrawClose} key={item.name + item._id} style={({ isActive }) => {
                                  return { display: "block", margin: ".5rem 0", color: isActive ? "red" : "black" };
                                }}
                                  to={`/${item._id}`}
                                >
                                  <Stack direction='row' h={boxHeight} borderWidth={2} bordercolor="teal" >
                                    {item.image ? (<Avatar size={'md'} src={item.image}
                                    />) :
                                      <Avatar size={'md'} bg="grey.100" icon={<CheckIcon fontSize='1.5rem' />}
                                      />}
                                    <Heading key={item.name + item._id} size={fontSize} mt={3} color={secondaryTextColor}>{item.name}</Heading>
                                  </Stack>
                                </QueryNavLink>
                              ))
                          ) : null}

                        </>
                      </DrawerBody>

                      <DrawerFooter>
                        <Button variant='outline' mr={3} onClick={onDrawClose}>
                          Cancel
                        </Button>

                      </DrawerFooter>
                    </DrawerContent>
                  </Drawer>
                </>


                <VStack justifyContent={formContent}>
                  {!showMenu ? (<Button ref={btnRef} colorScheme='teal' marginTop={15} onClick={() => {
                    setIsListOpen(true)
                    onDrawOpen()
                  }}>
                    Open Classification List
                  </Button>) : null}

                  <HStack justifyContent={formContent}>

                    {show.flagged ? (<i onClick={() => { setShowPickList(true) }} className="flag icon big circular green"  ></i>)
                      : (<i onClick={() => { setShowPickList(true) }} className="flag icon big circular  outline"  ></i>)}
                    {show.flagged ? (<Text
                      //fontWeight='bold'
                      textTransform='uppercase'
                      letterSpacing='wide'
                      //  display='block'
                      lineHeight='normal'
                      fontWeight='semibold'
                      fontSize={["lg", "xl", "2xl"]} color="green">{show.name}</Text>)
                      : (<Text key={show._id}
                        fontWeight='semibold'
                        textTransform='uppercase'
                        letterSpacing='wide'
                        lineHeight='normal'
                        fontSize={["lg", "xl", "2xl"]}
                      >{show.name}   </Text>)}

                  </HStack>

                  <div>
                    {showPickList ? (
                      <>
                        {!show.flagged ?
                          (<>
                            {showFlagError ?
                              (<Heading size="md" color="red">Select at least one Delegate to view your item </Heading>)
                              : (<Text fontSize="xl">Select at least one Delegate to view your item </Text>)}


                          </>
                          ) : null}
                        {renderFlagOptions(show)}
                      </>
                    ) : null}
                  </div>

                  <Box p={4} display={{ lg: 'flex' }}>
                    {show.image ? (<Box flexShrink={0} alignItems="center" marginLeft={15}>
                      <Image
                        borderRadius='lg'
                        //width={{ md: 250 }}
                        width={350}
                        src={show.image}
                        alt={`infobee item ${show.name}`}
                      />
                    </Box>) : null}


                    <Box mt={{ base: 4, md: 4 }} ml={{ md: 6 }}>
                      <Text
                        fontWeight='bold'
                        textTransform='uppercase'
                        fontSize='xl'
                        letterSpacing='wide'
                        lineHeight='normal'
                      // fontWeight='semibold'
                      >
                        Description
                      </Text>



                      <Text mt={2}
                        width={350}
                        fontSize='xl'
                        letterSpacing='wide'
                        lineHeight='large'
                        color='gray.500'
                        display='block'>
                        {show.text}
                      </Text>
                      <br />
                      <Text>Verify Item Date: {show.itemDate.substring(0, 10)}</Text>
                      <br />

                      <div alignitems="center">
                        <ButtonGroup variant='outline' spacing='6'>
                          <Button
                            //  onClick={() => setIsThingOpen(true)}
                            onClick={onItemOpen}
                            colorScheme='red'>Delete</Button>
                          <Button onClick={() => { setShowPickList(true) }}
                            colorScheme='green'>Flag Item</Button>
                          <Button onClick={() => setShowDetail(true)
                          } colorScheme="black">Update</Button>
                        </ButtonGroup>
                      </div>
                      <AlertDialog
                        isOpen={isItemOpen}
                        leastDestructiveRef={cancelThingRef}
                        onClose={onItemClose}
                      >
                        <AlertDialogOverlay>
                          <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                              Delete Item
                            </AlertDialogHeader>
                            <AlertDialogBody>
                              Are you sure? You can't undo this action afterwards.
                              This will delete all records and messages associated with this item.
                            </AlertDialogBody>
                            <AlertDialogFooter>
                              <Button ref={cancelRef} onClick={onItemClose}>
                                Cancel
                              </Button>
                              <Button colorScheme='red'
                                onClick={() => {
                                  deleteHandler(show._id)

                                  //console.log('Deleting' + show._id );
                                  onItemClose()
                                }} ml={3}>
                                Delete
                              </Button>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialogOverlay>
                      </AlertDialog>
                    </Box>
                  </Box>
                </VStack>

                <Box borderWidth='1px' borderRadius='lg' overflow='hidden'>

                  {showDetail ? renderUpdateDetail(show) : null}

                </Box>

                <div>
                  <br />

                  {showClass ? (<>

                    <HStack justifyContent="space-between">
                      <Heading size="md" >Classification</Heading>
                      {!showClass ? (<IconButton
                        onClick={() => {
                          const selectedRecord = {
                            _id: null,
                            document: null,
                          };
                          // setSelectedRecord(selectedRecord)
                          setShowClass(true)
                        }}
                        variant='outline'
                        colorScheme='white'
                        //  aria-label='Close'
                        boxSize={10}
                        icon={<AddIcon boxSize={8} />}
                      />) :
                        (<IconButton
                          onClick={() => {
                            // setSelectedRecord(null)  
                            setShowClass(false)
                          }}
                          variant='outline'
                          colorScheme='white'
                          //  aria-label='Edit'
                          boxSize={10}
                          icon={<CloseIcon boxSize={8} />}
                        />)}
                    </HStack>


                  </>

                  ) : null}


                </div>
              </div >








            </>


          )
        }
      }
      )

    )
  }

  const deleteHandler = async (id) => {
    // 
    //  console.log('dlete' + id)
    await deleteCurrentItem(availableUser._id, id, () => {
      navigate("/inventory/itemlist")
    })

  };






  return (
    <Box style={{ margin: 1 }}>
      {availableEntity ? (
        <Box style={{ margin: .5 }} width={boxWidth}   >
          {renderDetails()}

          <Modal
            onClose={onDetailClose}
            size={modalSize} isOpen={isDetailOpen}
            isCentered
            motionPreset='slideInBottom'
            scrollBehavior='inside'
          >

            <ModalOverlay
              bg='none'
              backdropFilter='auto'
              backdropInvert='80%'
              backdropBlur='2px'
            />
            <ModalContent>
              <ModalHeader>Record Details</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {selectedRecord ? (<>  <Box
                >

                  {selectedRecord.document ? (
                    selectedRecord.document.includes('pdf') ? null : (<Box flexShrink={0} alignItems="center" marginLeft={15}>
                      <Image
                        borderRadius='lg'
                        width={{ base: 250, md: 800 }}

                        height="auth"
                        src={selectedRecord.document}
                        alt={`infobee record`}
                      />
                    </Box>)) : null}


                  <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
                    <br />
                    <Text
                      fontWeight='bold'
                      textTransform='uppercase'
                      fontSize='xl'
                      letterSpacing='wide'
                      lineHeight='normal'
                    // fontWeight='semibold'
                    >
                      Description
                    </Text>
                    <Text mt={2}
                      fontSize='xl'
                      letterSpacing='wide'
                      lineHeight='large'
                      color='gray.500'
                      display='block'>
                      {selectedRecord.descrip}
                    </Text>
                    <br />
                    {selectedRecord.date ? (<Text>Record Date: {selectedRecord.date.substring(0, 10)}</Text>) : null}

                    <br />
                    <Text fontSize="2xl"
                      fontWeight='semibold'>  ({selectedRecord.thing}) </Text>

                  </Box  >

                </Box></>) : <Text>NO RECORD</Text>}

              </ModalBody>
              <ModalFooter>
                <Button onClick={onDetailClose}>Close</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>


          {params.itemId === 'itemId' ? (
            <Box style={{ margin: 5 }}>
              <Button ref={btnRef} colorScheme='gray' onClick={() => {
                setIsListOpen(true)
                onDrawOpen()
              }}>
                Open List
              </Button>
              <Drawer
                isOpen={isDrawOpen}
                placement='left'
                onClose={onDrawClose}
                finalFocusRef={btnRef}
              >
                <DrawerOverlay />
                <DrawerContent>
                  <DrawerCloseButton />

                  <DrawerBody>
                    <>

                      <Button onClick={() => setIsListOpen(!isListOpen)}
                        backgroundColor={"grey"} color="white">Show Lists</Button>

                      <br />
                      {isListOpen ? (<>
                        <Box pb={4}  >
                          <Stack as={'nav'} spacing={1}>
                            <div >
                              <Box backgroundColor={`${ibtColors.ibtinventory}`} marginTop={1}>

                                <Text onClick={() => {
                                  setChooseList('inventory')
                                  setIsListOpen(false)
                                }} fontSize="lg" color={"white"}
                                  style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                >
                                  inventory</Text>

                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtflagged}`} marginTop={1}>
                                <Text onClick={() => {
                                  setChooseList('flagged')
                                  setIsListOpen(false)
                                }} fontSize="lg" color={"white"}
                                  style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                >Flagged </Text>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibttodo}`} marginTop={1}>
                                <Text onClick={() => {
                                  //  setChooseList('iBePets')
                                  // setIsListOpen(false)
                                }} fontSize="lg" color={"white"}
                                  style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                >ToDo </Text>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtpets}`} marginTop={1}>
                                <Text onClick={() => {
                                  setChooseList('iBePets')
                                  setIsListOpen(false)
                                }} fontSize="lg" color={"white"}
                                  style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                >iBePets </Text>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibtcareer}`} marginTop={1}>
                                <Text onClick={() => {
                                  setChooseList('iBeCareer')
                                  setIsListOpen(false)
                                }} fontSize="lg" color={"white"}
                                  style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                >iBeCareer  </Text>
                              </Box>
                              <Box backgroundColor={`${ibtColors.ibthandy}`} marginTop={1}>
                                <Text onClick={() => {
                                  setChooseList('iBeHandy')
                                  setIsListOpen(false)
                                }} fontSize="lg" color={"white"}
                                  style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                >iBeHandy  </Text>
                              </Box>
                              {availableUser ? (<>

                                {availableUser.status === 'Basic'
                                  || availableUser.status === 'Active'
                                  ? (<>
                                    <Box backgroundColor={`${ibtColors.ibthouse}`} marginTop={1}>
                                      <Text onClick={() => {
                                        setChooseList('iBeHouse')
                                        setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeHouse  </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibtgone}`} marginTop={1}>
                                      <Text onClick={() => {
                                        setChooseList('iBeGone')
                                        setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeGone </Text>
                                    </Box >

                                    <Box backgroundColor={`${ibtColors.ibtpaper}`} marginTop={1}>
                                      <Text onClick={() => {
                                        setChooseList('iBePaper')
                                        setIsListOpen(false)

                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBePaper </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibtmoney}`} marginTop={1}>
                                      <Text onClick={() => {
                                        setChooseList('iBeMoney')
                                        setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeMoney  </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibtmember}`} marginTop={1}>
                                      <Text onClick={() => {
                                        setChooseList('iBeMember')
                                        setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeMember  </Text>
                                    </Box>
                                  </>) : (<>
                                    <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                      <Text onClick={() => {
                                        //  setChooseList('iBeHouse')
                                        //   setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeHouse  </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                      <Text onClick={() => {
                                        // setChooseList('iBeGone')
                                        // setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeGone </Text>
                                    </Box >

                                    <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                      <Text onClick={() => {
                                        //  setChooseList('iBePaper')
                                        // setIsListOpen(false)

                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBePaper </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                      <Text onClick={() => {
                                        //  setChooseList('iBeMoney')
                                        //  setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeMoney  </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                      <Text onClick={() => {
                                        // setChooseList('iBeMember')
                                        // setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeMember  </Text>
                                    </Box></>)}




                                {availableUser.status === 'Active'
                                  ? (<>
                                    <Box backgroundColor={`${ibtColors.ibttechno}`} marginTop={1}>
                                      <Text onClick={() => {
                                        setChooseList('iBeTechno')
                                        setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeTechno  </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibtevent}`} marginTop={1}>
                                      <Text onClick={() => {
                                        setChooseList('iBeEvent')
                                        setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeEvent  </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibtsocial}`} marginTop={1}>
                                      <Text onClick={() => {
                                        setChooseList('iBeSocial')
                                        setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeSocial  </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibtcontacts}`} marginTop={1}>
                                      <Text onClick={() => {
                                        setChooseList('iBeContacts')
                                        setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeContact  </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibtbusy}`} marginTop={1}>
                                      <Text onClick={() => {
                                        setChooseList('iBeBusy')
                                        setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeBusy  </Text>
                                    </Box>

                                    <Box backgroundColor={`${ibtColors.ibtmemoir}`} marginTop={1}>
                                      <Text onClick={() => {
                                        setChooseList('iBeMemoir')
                                        setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeMemoir  </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibthealth}`} marginTop={1}>
                                      <Text onClick={() => {
                                        setChooseList('iBeHealth')
                                        setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeHealth </Text>
                                      <Box backgroundColor={`${ibtColors.ibtframed}`} marginTop={1}>
                                        <Text onClick={() => {
                                          setChooseList('iBeFramed')
                                          setIsListOpen(false)
                                        }} fontSize="lg" color={"white"}
                                          style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                        >iBeFramed  </Text>
                                      </Box>
                                    </Box>
                                  </>) : (<>
                                    <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                      <Text onClick={() => {
                                        //   setChooseList('iBeTechno')
                                        //  setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeTechno  </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                      <Text onClick={() => {
                                        //   setChooseList('iBeEvent')
                                        //   setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeEvent  </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                      <Text onClick={() => {
                                        //  setChooseList('iBeSocial')
                                        //  setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeSocial  </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                      <Text onClick={() => {
                                        //  setChooseList('iBeContacts')
                                        // setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeContact  </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                      <Text onClick={() => {
                                        //   setChooseList('iBeBusy')
                                        //  setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeBusy  </Text>
                                    </Box>

                                    <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                      <Text onClick={() => {
                                        //  setChooseList('iBeMemoir')
                                        //  setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeMemoir  </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                      <Text onClick={() => {
                                        // setChooseList('iBeHealth')
                                        // setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeHealth </Text>
                                    </Box>
                                    <Box backgroundColor={`${ibtColors.ibtbasic}`} marginTop={1}>
                                      <Text onClick={() => {
                                        //  setChooseList('iBeFramed')
                                        //  setIsListOpen(false)
                                      }} fontSize="lg" color={"white"}
                                        style={{ padding: ".75rem", marginLeft: 15, marginRight: 3, }}
                                      >iBeFramed  </Text>
                                    </Box>
                                  </>)}

                              </>) : null}



                            </div>

                          </Stack>
                        </Box>
                      </>) : null}
                      <Input
                        value={searchParams.get("filter") || ""}
                        style={{ padding: ".25rem" }}
                        placeholder="   Item Contains Search"
                        onChange={event => {
                          let filter = event.target.value
                          if (filter) {
                            setSearchParams({ filter });
                          } else {
                            setSearchParams({});
                          }
                        }}
                      />
                      {availableEntity ? (
                        availableEntity.item
                          .filter(cls => {
                            // if (cls.ibepets)
                            // if (`cls.${chooseList}`)
                            // console.log(chooseList)
                            if ((chooseList === 'iBePets' && cls.ibepets)
                              || (chooseList === 'iBeGone' && cls.ibegone)
                              || (chooseList === 'iBeHandy' && cls.ibehandy)
                              || (chooseList === 'iBeHouse' && cls.ibehouse)
                              || (chooseList === 'iBeHealth' && cls.ibehealth)
                              || (chooseList === 'iBePaper' && cls.ibepaper)
                              || (chooseList === 'iBeMoney' && cls.ibemoney)
                              || (chooseList === 'iBeMember' && cls.ibemember)
                              || (chooseList === 'iBeTechno' && cls.ibetechno)
                              || (chooseList === 'iBeEvent' && cls.ibeevent)
                              || (chooseList === 'iBeSocial' && cls.ibesocial)
                              || (chooseList === 'iBeContacts' && cls.ibecontacts)
                              || (chooseList === 'iBeBusy' && cls.ibebusy)
                              || (chooseList === 'iBeCareer' && cls.ibecareer)
                              || (chooseList === 'iBeMemoir' && cls.ibememoir)
                              || (chooseList === 'iBeFramed' && cls.ibeframed)
                              || (chooseList === 'flagged' && cls.flagged)
                              || (chooseList === 'inventory')

                            ) { return true } else { return false }
                          }).filter(item => {
                            let filter = searchParams.get("filter");
                            if (!filter) return true;
                            let name = item.name.toLowerCase();
                            //   return name.startsWith(filter.toLowerCase());
                            return name.includes(filter.toLowerCase());
                          }).map(item => (
                            <QueryNavLink onClick={onDrawClose} key={item.name + item._id} style={({ isActive }) => {
                              return { display: "block", margin: ".5rem 0", color: isActive ? "red" : "black" };
                            }}
                              to={`/${item._id}`}
                            >
                              <Stack direction='row' h={boxHeight} borderWidth={2} bordercolor="teal" >
                                {item.image ? (<Avatar size={'md'} src={item.image}
                                />) :
                                  <Avatar size={'md'} bg="grey.100" icon={<CheckIcon fontSize='1.5rem' />}
                                  />}
                                <Heading key={item.name + item._id} size={fontSize} mt={3} color={secondaryTextColor}>{item.name}</Heading>
                              </Stack>
                            </QueryNavLink>
                          ))
                      ) : null}

                    </>
                  </DrawerBody>

                  <DrawerFooter>
                    <Button variant='outline' mr={3} onClick={onDrawClose}>
                      Cancel
                    </Button>

                  </DrawerFooter>
                </DrawerContent>
              </Drawer>

            </Box>
          ) : (
            <Box style={{ margin: 1 }}>
              <Tabs variant='enclosed-colored' size="lg"
                style={{ margin: 1 }} defaultindex={2}>
                <TabList defaultindex={2} m='.5em'>

                  <Tab _selected={{ color: 'white', bg: 'gray.400' }}>
                    <Heading size={tabSize}>Records</Heading></Tab>
                  <Tab _selected={{ color: 'white', bg: 'gray.400' }}>
                    <Heading size={tabSize}>Class</Heading> </Tab>
                  <Tab _selected={{ color: 'white', bg: 'gray.400' }}>
                    <Heading size={tabSize}>Select List</Heading></Tab>
                  <Tab _selected={{ color: 'white', bg: 'gray.400' }}>
                    <Heading size={tabSize}>Messages
                      {!newMessage ? (<Badge ml='1' fontSize='0.4em' colorScheme='green'>
                        new
                      </Badge>) : null}

                    </Heading> </Tab>
                </TabList>
                <TabPanels>

                  <TabPanel  >
                    <Box style={{ margin: 1 }}>
                      <HStack justifyContent={formContent} style={{ margin: 1 }}>
                        <Heading size="md" mr={140}>Add Record</Heading>
                        {!showSelectedRecord ? (<IconButton mr={10}
                          onClick={() => {
                            var today = new Date();
                            var dd = String(today.getDate()).padStart(2, '0');
                            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                            var yyyy = today.getFullYear();
                            today = yyyy + '-' + mm + '-' + dd;
                            const selectedRecord = {
                              _id: null,
                              descrip: null,
                              thing: 'other',
                              thingDate: today,
                              type: null,
                              document: null,
                            };
                            //console.log(selectedRecord.thingDate + 'before form')
                            setSelectedRecord(selectedRecord)
                            setShowSelectedRecord(true)
                          }}
                          variant='outline'
                          colorScheme='white'
                          // aria-label='Close'
                          boxSize={8}
                          icon={<AddIcon boxSize={6} />}
                        >ADD</IconButton>) :
                          (<IconButton
                            onClick={() => {
                              setSelectedRecord(null)
                              setShowSelectedRecord(false)
                            }}
                            variant='outline'
                            colorScheme='white'
                            // aria-label='Edit'
                            boxSize={8}
                            icon={<CloseIcon boxSize={6} />}
                          />)}
                      </HStack>
                      {renderRecords()}
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    {renderClassification()}
                  </TabPanel>
                  <TabPanel>
                    {renderPicklist()}
                  </TabPanel>
                  <TabPanel>
                    <HStack justifyContent={formContent}>

                      <Heading size="md" mr={150} >Add Message</Heading>
                      {!showSelectedComment ? (<IconButton mr={250}
                        onClick={() => {
                          var today = new Date();
                          var dd = String(today.getDate()).padStart(2, '0');
                          var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                          var yyyy = today.getFullYear();
                          today = yyyy + '-' + mm + '-' + dd;
                          const selectedComment = {
                            _id: null,
                            descrip: null,
                            //  thing: null,
                            date: today,
                            // type: null,
                            //  document: null,
                          };
                          setSelectedComment(selectedComment)
                          setShowSelectedComment(true)
                        }}
                        variant='outline'
                        colorScheme='white'
                        // aria-label='Close'
                        boxSize={8}
                        icon={<AddIcon boxSize={6} />}
                      />) :
                        (<IconButton
                          onClick={() => {
                            setSelectedComment(null)
                            setShowSelectedComment(false)
                          }}
                          variant='outline'
                          colorScheme='white'
                          //  aria-label='Edit'
                          boxSize={8}
                          icon={<CloseIcon boxSize={6} />}
                        />)}
                    </HStack>


                    <div>
                      {showTable ? renderComments() : renderCommentsMobile()}

                    </div>

                  </TabPanel>
                </TabPanels>
              </Tabs >


            </Box>
          )}
        </Box>) : null}
    </Box>
  );

}

export default connect(null, { refreshUser, deleteCurrentItem, editNoImage, uploadDocument, pdfDocument })(ItemDetail);

/*

import {
  AiOutlineSetting, AiOutlineKey, AiOutlineCheckSquare, AiOutlineContacts,
  AiOutlineDollar, AiOutlineFileAdd, AiOutlineFlag, AiOutlineFolderOpen,
  AiOutlineHeart, AiOutlineHome, AiOutlineInfoCircle, AiOutlineLaptop,
  AiOutlineMedicineBox, AiOutlineMore, AiOutlinePicture, AiOutlineProfile,
  AiOutlinePushpin, AiOutlineQuestion, AiOutlineTag, AiOutlineTeam,
  AiOutlineCamera, AiOutlineTwitter, AiOutlineUserAdd, AiOutlineUpload,
  AiOutlineCalendar
} from "react-icons/ai";
    <img className="ui small bordered left floated image"
                      alt="alt" src={show.image} />

                    <AspectRatio maxW='100px' ratio={4 / 3}>
                      <img className="ui small bordered left floated image"
                        alt="alt" src={show.image} />
                    </AspectRatio>

                   

                    <AspectRatio maxW='200px' ratio={4 / 3}>
                      <Image src={show.image} alt='alt record' />
                    </AspectRatio>
                  </>


  <img style={{
                              padding: "1",
                              margin: "2",
                              width: "100px",
                              height: "100px"
                            }}
                              alt="alt" src={rec.document} />


const renderAvailClass = (item) => { 
        
       // console.log('Picklist in Loop' )
        const classItems = []

          if (item.ibepets) {
          classItems.push('ibepets')
        }
         if (item.ibegone) {
          classItems.push('ibegone')
   
     
        
        setCreateClassList(classItems)
          };
  

                 <NavLink to={`/inventory/itemlist/modalclass/${params.itemId} `} 
          >
            <div
      style={{ width: 150, height: 50,  background:  "DarkOrchid.200"  }}> 
         
              <p>old link Classifcation</p>
              </div>
              </NavLink>



              const ModalX = () => {
    //let history = useHistory();

    console.log(selectedRecord)

    let back = e => {
      e.stopPropagation();
      setShowItem(false)
      // history.goBack();
    };
    return (
      <>
        <Box
          borderWidth='1px' borderRadius='lg' overflow='hidden'
          p={4} display={{ md: 'flex' }}
          onClick={back}
          // className="modal"
          style={{
            position: "absolute",
            backgroundColor: "#E2E8F0",
            color: "#171923",
            top: 750,
            left: "10%",
            right: "10%",
            padding: 15,
            border: "2px solid #444",
          }}
        >

          {selectedRecord.document ? (
            selectedRecord.document.includes('pdf') ? null : (<Box flexShrink={0} alignItems="center" marginLeft={15}>
              <Image
                borderRadius='lg'
                //width={{ md: 250 }}
                width={350}
                src={selectedRecord.document}
                alt={`infobee record`}
              />
            </Box>)) : null}


          <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
            <br />
            <Text
              fontWeight='bold'
              textTransform='uppercase'
              fontSize='xl'
              letterSpacing='wide'
              lineHeight='normal'
            // fontWeight='semibold'
            >
              Description
            </Text>



            <Text mt={2}
              fontSize='xl'
              letterSpacing='wide'
              lineHeight='large'
              color='gray.500'
              display='block'>
              {selectedRecord.descrip}
            </Text>
            <br />
            {selectedRecord.date ? (<Text>Record Date: {selectedRecord.date.substring(0, 10)}</Text>) : null}

            <br />
            <Text fontSize="2xl"
              fontWeight='semibold'>  ({selectedRecord.thing}) </Text>
            <br />


          </Box  >
          <Button onClick={back} alignItems="center">
            Close
          </Button>
        </Box>

      </>
    );
  }
*/