import React from "react";

import {
  Text,
  SimpleGrid,
  GridItem,
  Box,
  Button,
  CSSReset,
  Heading,
  ThemeProvider,
  theme,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  useBreakpointValue,
} from '@chakra-ui/react';

import { Form, useField, } from 'react-final-form'
import validate from './validateRegister'
//import RenderCount from '../common/RenderCount'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const RegisterForm = (props) => {

  const colSpan = useBreakpointValue({ base: 2, md: 1 })
  const formSpan = useBreakpointValue({ base: 1, md: 2 })
  const boxWidth = useBreakpointValue({ base: 250, md: 800 })

  const onSubmit = async values => {
    await props.onSubmit(values);
    await sleep(300)
    // window.alert('Registrating in Progress - please check your email')
  }

  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Box w={boxWidth} p={2} m="10px auto">
        <Text fontSize={["lg", "2xl", "3xl"]} textAlign="center">
          infoBee Register
        </Text>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({
            handleSubmit,
            form,
            errors,
            subscription = { submitting: true },
            submitting,
            pristine,
            values
          }) => (
            <><Box as="form" p={4} borderWidth="3px" rounded="lg"
              shadow="1px 1px 3px rgba(0,0,0,0.3)"
              onSubmit={handleSubmit}  >
              <SimpleGrid columns={2} columnGap={3} rowGap={6} width="full">
                <GridItem colSpan={colSpan}>
                  <InputControl name="first" label="First Name" maxLength={15} />
                </GridItem>
                <GridItem colSpan={colSpan}>
                  <InputControl name="last" label="Last Name" maxLength={30} />
                </GridItem>
                <GridItem colSpan={2}>
                  <InputControl name="email" type="email" label="Email" maxLength={60} />
                </GridItem>
                <GridItem colSpan={2}>
                  <InputControl name="email2" type="email" label="Confirm Email" maxLength={60} />
                </GridItem>
              </SimpleGrid>

              <Text>By registering you are accepting infoBee Terms and Conditions</Text>

              <br />
              <Button
                isLoading={submitting}
                loadingText="Submitting"
                colorScheme={"teal"}
                size="lg"
                type="submit"
              >
                Submit
              </Button>
              <br />
            </Box>
            </>
          )}
        />
      </Box>
    </ThemeProvider>
  )
}


const Control = ({ name, ...rest }) => {
  const {
    meta: { error, touched }
  } = useField(name, { subscription: { touched: true, error: true } })
  return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
  const {
    meta: { error }
  } = useField(name, { subscription: { error: true } })
  return <FormErrorMessage>{error}</FormErrorMessage>
}

const InputControl = ({ name, label, type, minLength, maxLength, pattern }) => {
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={4}>

      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        {...input}
        isInvalid={meta.error && meta.touched}
        id={name}
        placeholder={label}
        type={type}
        minLength={minLength}
        maxLength={maxLength}
        pattern={pattern}
      />
      <Error name={name} />
    </Control>
  )
}

export default RegisterForm;

/*

*/