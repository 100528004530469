import React from 'react';
import { Flex, Button } from '@chakra-ui/react';

const ImageUpload = ({ isUploading = false, onPhotoSelect, docIndex, label }) => {

    // console.log("index in upload" + docIndex)

    let hiddenInput = null;

    return (
        <Flex px="4" py="4" justify="center" >

            <br />

            <Flex align="center">
                <Button
                    onClick={() => hiddenInput.click()}
                    size="sm"
                    variant="outline"
                    colorScheme="teal"
                    isLoading={isUploading}
                    loadingText="Uploading..."
                >
                    {label}
                </Button>

                <input
                    hidden
                    type='file'
                    ref={el => hiddenInput = el}
                    onChange={(e) => onPhotoSelect(e.target.files[0], docIndex)}
                />
            </Flex>
        </Flex>
    );
};

export default ImageUpload;