import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Box,
  Flex,
  Text,
  Center,
  HStack,
  useColorModeValue,
  Heading,
} from '@chakra-ui/react';
//import './Dashboard.css';

//import logo from '../../icon.png';

import LoginForm from '../forms/LoginForm'
import { login } from "../../actions/userAction"

const Login = ({ login }) => {
  const [confirmEmail, setConfirmEmail] = useState("");

  let navigate = useNavigate();

  const availableUser = useSelector((state) => state.user.user);
  const availMessage = useSelector((state) => state.user.errorMessage);


  const bg = useColorModeValue('gray.200', 'gray.700')

  const onSubmit = (formValues) => {
    setConfirmEmail(formValues.email)
    login(formValues, () => {
      navigate("/dashboard")
    })
  }

  return (
    <>
      <Box textAlign="center" fontSize="xl" >
        {availableUser ? (
          <>
            <h2>{availableUser.name}</h2>
            <p> {availableUser.email}</p>
          </>
        ) : null}


        {availMessage ? (<h4 style={{ color: "red" }}>ERROR - {availMessage}</h4>) : null}

        <LoginForm onSubmit={onSubmit} />


        <Center h={12} bg='gray.100' px={4} alignItems={'center'} justifyContent={'center'}>
          <HStack as={'nav'} spacing={4} >
            <Link px={2} py={1} target="_blank" rel="noopener noreferrer"
              rounded={'md'} _hover={{
                textDecoration: 'none', bg: bg,
              }} to="/termsconditions">
              <Text fontSize={["xs", "sm", "lg"]}  >Terms & Conditions   </Text></Link>
            <Link to="/privacypolicy" target="_blank" rel="noopener noreferrer" >
              <Text fontSize={["xs", "sm", "lg"]}  >Privacy Policy </Text></Link>
            <Link to="/contact"    >
              <Text fontSize={["xs", "sm", "lg"]}  >Contact Us </Text></Link>

            <Link to={`/onetimepasscode/reset${confirmEmail}`}    >
              <Text fontSize={["xs", "sm", "lg"]} >Reset Password </Text></Link>
          </HStack>
        </Center>

      </Box>
    </>
  )

}


export default connect(null, { login })(Login);