import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import {
  // useToast,
  Box,
  Button,
  ButtonGroup,
  CSSReset,
  Heading,
  ThemeProvider,
  theme,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useBreakpointValue,

  Input,
  Checkbox,
} from '@chakra-ui/react';

import { Form, useField, } from 'react-final-form'

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))



const ItemFlagForm = ({ onSubmit, show, item, pickFrom }) => {
  // const [photo, setPhoto] = useState(null);
  //  const [fileSelected, setFileSelected] = useState(null);


  let navigate = useNavigate();
  // const toast = useToast();

  // const availableUser = useSelector((state) => state.user.user);

  const colSpan = useBreakpointValue({ base: 2, md: 1 })
  const formSpan = useBreakpointValue({ base: 1, md: 2 })
  const boxWidth = useBreakpointValue({ base: 350, md: 800 })

  const onFormSubmit = async values => {
    // values.flagged = !values.flagged

    if (values.flagResponse === "CANCEL") {
      values.picklist = []
    } else {
      if (!values.flagged) {
        let new_array = values.selectlist.filter
          (element => {
            //  console.log(element)
            if (element.picked) {
              // console.log(element.pickemail)
              return (
                { ...element, pickemail: element.pickemail, pickfirst: element.pickfirst })
            }
          })
        values.picklist = new_array

        if (new_array.length === 0) {
          console.log('NOT ENOUGH LENGTH' + new_array.length)
          values.flagResponse = "MINIMUN"
        } else {
          values.flagged = true
          values.flagResponse = "INITIAL"
        }

      } else {
        values.picklist = []
        values.flagged = false

      }
    }


    await sleep(300)
    await onSubmit(values);
    // console.log(JSON.stringify(values, 0, 2))
  }


  const mySelectList = (values) => {
    //  console.log("values: " + values.itemName);

    return (
      !values.flagged ? (pickFrom.map((myValue, myIndex) => {
        return (
          <div key={myIndex}>
            <CheckboxControl key={myIndex} name={`selectlist[${myIndex}].picked`} >
              <Heading size="md"> {myValue.pickfirst} - {myValue.pickemail}</Heading>
            </CheckboxControl>
          </div>
        )
      })) : <p>UNFLAGGING THE ITEM WILL CLEAR SELECTED USERS</p>

    )
  }


  return (
    <ThemeProvider theme={theme}>
      <CSSReset />
      <Box w={boxWidth} p={4} m="20px auto">

        <Form
          onSubmit={onFormSubmit}
          initialValues={{
            id: show._id,
            itemName: show.name,
            text: show.text,
            category: show.category,
            image: show.image,
            itemId: show._id,
            wishname: show.wishname,
            wishitem: show.wishitem,
            flagged: show.flagged,
            ibegone: show.ibegone,
            ibepets: show.ibepets,
            ibehandy: show.ibehandy,
            ibehealth: show.ibehealth,
            ibehouse: show.ibehouse,
            ibepaper: show.ibepaper,
            ibemoney: show.ibemoney,
            ibemember: show.ibemember,
            ibetechno: show.ibetechno,
            ibeevent: show.ibeevent,
            ibesocial: show.ibesocial,
            ibecontacts: show.ibecontacts,
            ibebusy: show.ibebusy,
            ibecareer: show.ibecareer,
            ibememoir: show.ibememoir,
            ibeframed: show.ibeframed,
            records: show.records,
            itemDate: show.itemDate,
            trackType: show.trackType,
            wishes: show.wishes,
            comments: show.comments,
            picklist: show.picklist,
            selectlist: pickFrom,
            flagResponse: 'INITIAL',
          }}
          //validate={validate}
          render={({
            handleSubmit,
            form,
            errors,
            subscription = { submitting: true },
            submitting,
            pristine,
            values
          }) => (
            <>

              <>
                <Box as="form" p={4} borderWidth="3px"
                  rounded="lg" shadow="1px 1px 3px rgba(0,0,0,0.3)"
                  onSubmit={handleSubmit}
                >
                  {mySelectList(values)}



                  <ButtonGroup>
                    <Button
                      isLoading={submitting}
                      onClick={() => {
                        values.flagResponse = "CANCEL";
                      }
                      }
                      loadingText="Cancelling"
                      type="cancel"
                    >
                      Cancel
                    </Button>

                    <Button
                      isLoading={submitting}
                      loadingText="Submitting"
                      colorScheme="teal"
                      disabled={submitting}
                      type="submit"
                    >
                      Toggle Flag
                    </Button>

                  </ButtonGroup>
                </Box>
              </>
            </>
          )}
        />
      </Box>
    </ThemeProvider>
  )
}





const CheckboxControl = ({ name, value, children, isChecked }) => {
  const {
    input: { checked, ...input },
    meta: { error, touched, invalid }
  } = useField(name, {
    type: 'checkbox' // important for RFF to manage the checked prop
  })
  return (
    <FormControl isInvalid={touched && invalid} my={4}>
      <Checkbox {...input} isInvalid={touched && invalid} my={4}
        defaultIsChecked={isChecked}>
        {children}
      </Checkbox>
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
}




export default connect(null, {})(ItemFlagForm);

/*

*/