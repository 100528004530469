import React, { useEffect } from "react";

function PrivacyPolicy() {

  useEffect(() => {
   window.location.href = "https://www.termsfeed.com/live/80385364-b2b9-4240-99e2-83d7091d7d07";
  }, []);

  return (
    <div>
          <h2>Privacy Policy</h2>
         
    </div>
  );
}

export default PrivacyPolicy;