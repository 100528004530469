import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Box,
  Flex,
  HStack,
  Text,

  Heading,
  useColorModeValue,
  useBreakpointValue,
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
} from '@chakra-ui/react';


//import logo from '../../icon.png';
import RegisterForm from '../forms/RegisterForm'
import PasswordForm from '../forms/PasswordForm'
import { register, otp } from "../../actions/userAction"

const Register = ({ register, otp }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [confirmEmail, setConfirmEmail] = useState("");

  let navigate = useNavigate();

  // const availableUser = useSelector((state) => state.user.user); 
  const availMessage = useSelector((state) => state.user.errorMessage);

  const bg = useColorModeValue('gray.200', 'gray.700')
  const boxWidth = useBreakpointValue({ base: '250px', lg: '800px' })


  const onSubmit = (formValues) => {
    register(formValues, () => {
      //    navigate(`/onetimepasscode/${formValues.email}`) 
      setShowAlert(true)
    })
    setShowAlert(true)
    setConfirmEmail(formValues.email)
  }

  const onPasswordSubmit = (formValues) => {
    otp(formValues, () => {
      navigate("/login")
      //  setShowAlert(true)
    })
    //  setShowAlert(true)
    setConfirmEmail(formValues.email)
  }

  return (
    <>
      <Box width={boxWidth} textAlign="center" fontSize="xl">
        <br />
        <Text fontSize={["md", "lg", "2xl"]}>To upgrade to a paid subscription, please download the infoBee app</Text>

        <Box as="pre" my={10}>
          {availMessage ? (<h4 style={{ color: "red" }}>ERROR - {availMessage}</h4>) : null}
        </Box>

        {showAlert ? (
          <>
            <Alert status='success'>
              <AlertIcon />
              <Box flex='1'>
                <AlertDescription display='block'>
                  <Text fontSize={["md", "lg", "xl"]}> Your registration has been received.
                    Please check your email for a One Time Passcode to complete your process.</Text>
                 
                </AlertDescription>
              </Box>
              <CloseButton position='absolute' right='8px' top='8px' />
            </Alert>
            <br />
            <Text fontSize={["lg", "xl", "2xl"]}>{confirmEmail}</Text>
          </>
        ) : null}

        {!showAlert ? (<RegisterForm onSubmit={onSubmit} />)
          : (<PasswordForm onSubmit={onPasswordSubmit} email={confirmEmail} status={"REGISTER"} />)}

      </Box>

      <Box bg='gray.100' px={4} alignItems={'center'} width={boxWidth}>
        <Flex h={12} alignItems={'center'} justifyContent={'center'}>
          <HStack as={'nav'} spacing={2} display={{ base: 'flex', md: 'flex' }}>
            <Link px={2} py={1} target="_blank" rel="noopener noreferrer"
              rounded={'md'} _hover={{
                textDecoration: 'none', bg: bg,
              }} to="/termsconditions">
              <Text fontSize={["xs", "sm", "lg"]}  >Terms & Conditions   </Text></Link>
            <Link to="/privacypolicy" target="_blank" rel="noopener noreferrer" >
              <Text fontSize={["xs", "sm", "lg"]}  >Privacy Policy </Text></Link>
            <Link to="/contact"    >
              <Text fontSize={["xs", "sm", "lg"]}  >Contact Us! </Text></Link>
          </HStack>
        </Flex>
      </Box>
    </>
  )

}


export default connect(null, { register, otp })(Register);