import {
    extendTheme,
    theme as base,
    //withDefaultColorScheme,
    withDefaultVariant,
} from '@chakra-ui/react';

import { mode } from '@chakra-ui/theme-tools'

const inputSelectStyles = {
    variants: {
        filled: {
            field: {
                _focus: {
                    borderColor: 'brand.500',
                }
            }
        }
    },
    sizes: {
        md: {
            filed: {
                borderRadius: 'none',
            }
        }
    }
}

const brandRing = {
    _focus: {
        ring: 2,
        ringColor: 'brand.400'

    }
}

const theme = extendTheme(


    {
        colors: {
            brand: {
                50: '#F7FAFC',
                100: '#EDF2F7',
                200: '#E2E8F0',
                300: '#CBD5E0',
                400: '#A0AEC0',
                500: '#718096',
                600: '#4A5568',
                700: '#2D3748',
                800: '#1A202C',
                900: '#171923',
            }
            /* TEAL
  brand: {
                50: '#E6FFFA',
                100: '#B2F5EA',
                200: '#81E6D9',
                300: '#4FD1C5',
                400: '#38B2AC',
                500: '#319795',
                600: '#2C7A7B',
                700: '#285E61',
                800: '#234E52',
                900: '#1D4044',
            }
                      /* TEAL
  brand: {
                50: '#E6FFFA',
                100: '#B2F5EA',
                200: '#81E6D9',
                300: '#4FD1C5',
                400: '#38B2AC',
                500: '#319795',
                600: '#2C7A7B',
                700: '#285E61',
                800: '#234E52',
                900: '#1D4044',
            } 
            */
            
        },
        fonts: {
            heading: `Montserrat, ${base.fonts?.heading}`,
            body: `Inter, ${base.fonts?.heading}`
        },
        components: {
            Button: {
                variants: {
                    primary: (props) => ({
                        rounded: 'none',
                        ...brandRing,
                        color: mode('white', 'gray.800')(props),
                        backgroundColor: mode('brand.500', 'brand.200')(props),

                        _hover: {
                            backgroundColor: mode('brand.600', 'brand.300')(props),
                        },
                        _active: {
                            backgroundColor: mode('brand.700', 'brand.400')(props),
                        },
                    })
                }
            },
            Divider: {
                baseStyle: {
                    fontWeight: 'bold', // Normally, it is "semibold"
                    colorScheme: "red",
                    size: "lg"
                },

            },
            Input: { ...inputSelectStyles },
            Select: { ...inputSelectStyles },

        }

    },
    //  withDefaultColorScheme({
    //  colorScheme: 'brand',
    //  components: ['Checkbox']
    // }),
    withDefaultVariant({
        variant: 'filled',
        components: ['Input', 'Select']
    })
)

export default theme;