import ibthost from './api/ibthost'

import {
  USER_ERROR,
  ENTITY_SUCCESS,
  SWITCH_UPDATE,
  PDF_SUCCESS,
  ////  CLEAR_ENTITY,
} from "./types";

export const itemNoImage = (formValues, userId, callback) => async (dispatch) => {
  // console.log("test add item" +  formValues.picklist[0].pickEmail);
  const config = {
    headers: {
      'Access-Control-Allow-Origin': true,
      "Content-Type": "application/json",
    },
  };

  // console.log('in action' + formValues.picklist[0])
  const body = JSON.stringify({
    name: formValues.itemName,
    text: formValues.text,
    category: formValues.category,
    image: formValues.image,
    itemId: null,
    wishname: null,
    wishitem: null,
    flagged: false,
    ibegone: formValues.ibegone,
    ibepets: formValues.ibepets,
    ibehandy: formValues.ibehandy,
    ibehealth: formValues.ibehealth,
    ibehouse: formValues.ibehouse,
    ibepaper: formValues.ibepaper,
    ibefinancial: formValues.ibefinancial,
    ibemoney: formValues.ibemoney,
    ibemember: formValues.ibemember,
    ibetechno: formValues.ibetechno,
    ibeevent: formValues.ibeevent,
    ibesocial: formValues.ibesocial,
    ibecontacts: formValues.ibecontacts,
    ibebusy: formValues.ibebusy,
    ibecareer: formValues.ibecareer,
    ibememoir: formValues.ibememoir,
    ibeframed: formValues.ibeframed,
    records: formValues.records,
    itemDate: formValues.itemDate,
    trackType: null,
    wishes: [],
    picklist: formValues.picklist,
    comments: formValues.comments,
  });


  try {


    const responseItem = await ibthost.put(`/thingadd/${userId}`,
      body,
      config
    );
    // console.log("-------- add item  SUCC"  );

    await dispatch({ type: ENTITY_SUCCESS, payload: responseItem.data });

    if (callback) {
      callback()
    }


  } catch (err) {
    await dispatch({ type: USER_ERROR, payload: ' Item Add Failed' });
    console.log('Add Item Error ' + err)
  }
};

//Delete current item

export const deleteCurrentItem = (userId, itemId, callback) => {
  return async (dispatch) => {
    try {
      // console.log(userId + itemId)
      const response = await ibthost.delete(`/item/${userId}/${itemId}`);
      const response2 = await ibthost.get(`/item/${userId}`);

      dispatch({ type: ENTITY_SUCCESS, payload: response2.data });
      // await dispatch({ type: CLEAR_RECORDS });

      if (callback) {
        // console.log(callback);
        callback();
      }
    } catch (err) {
      await dispatch({ type: USER_ERROR, payload: ' Item Add Failed' });
      console.log('Delete Item Error ' + err)
    }
  };
};

export const editNoImage = (formValues, userId, callback) => async (dispatch) => {
  console.log("test edit item" + formValues.itemName + formValues.itemId);
  const config = {
    headers: {
      'Access-Control-Allow-Origin': true,
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    name: formValues.itemName,
    text: formValues.text,
    category: formValues.category,
    image: formValues.image,
    itemId: formValues.itemId,
    wishname: formValues.wishname,
    wishitem: formValues.wishitem,
    flagged: formValues.flagged,
    ibegone: formValues.ibegone,
    ibepets: formValues.ibepets,
    ibehandy: formValues.ibehandy,
    ibehealth: formValues.ibehealth,
    ibehouse: formValues.ibehouse,
    ibepaper: formValues.ibepaper,
    ibefinancial: formValues.ibefinancial,
    ibemoney: formValues.ibemoney,
    ibemember: formValues.ibemember,
    ibetechno: formValues.ibetechno,
    ibeevent: formValues.ibeevent,
    ibesocial: formValues.ibesocial,
    ibecontacts: formValues.ibecontacts,
    ibebusy: formValues.ibebusy,
    ibecareer: formValues.ibecareer,
    ibememoir: formValues.ibememoir,
    ibeframed: formValues.ibeframed,
    records: formValues.records,
    itemDate: formValues.itemDate,
    trackType: formValues.trackType,
    wishes: formValues.wishes,
    picklist: formValues.picklist,
    comments: formValues.comments,
  });


  try {

    // console.log(body)

    const responseItem = await ibthost.put(`/thingedit/${userId}`,
      body,
      config
    );


    await dispatch({ type: ENTITY_SUCCESS, payload: responseItem.data });

    //console.log("-------- add item  SUCC" + responseItem.data.item[0]._id );

    if (callback) {
      //console.log(callback)
      // const newCallback = 
      //  `() => {navigate("/inventory/itemlist/${responseItem.data.item[0]._id}");`

      //console.log(newCallback)
      //newCallback()
      callback(responseItem.data.item[0]._id)
    }


  } catch (err) {
    await dispatch({ type: USER_ERROR, payload: ' Item Edit Failed' });
    console.log('Add Item Error ' + err)
  }
};



// Add Record Document to Blob STorage
export const uploadDocument = (file, photoId, blobName2) => async (dispatch) => {

  try {
    let formData2 = new FormData();

    formData2.append("photo", file);

    //  console.log("FORM DATA ====" + formData2)

    //  console.log("Blob" + photoId, blobName2);
    const responseBlob = await ibthost.post(`/image/${photoId}`, formData2);
  }
  catch (err) {
    await dispatch({ type: USER_ERROR, payload: 'Document Add to Blob' });
    console.log('Adding document to Blob ' + err)
  }
};

// Add PDF Document  
export const pdfDocument = (pdffile, callback) => async (dispatch) => {

  try {
    await dispatch({ type: PDF_SUCCESS, payload: pdffile });
    //  console.log("CALLBACK" + callback);
    if (callback) {
      //   console.log(callback);
      callback();
    }

  }
  catch (err) {
    await dispatch({ type: USER_ERROR, payload: 'View PDF' });
    console.log('View pdf ' + err)
  }
};

export const editSwitch = (formValues, userId, callback) => async (dispatch) => {
  console.log("test edit switch item" + formValues.itemName + formValues.itemId);
  const config = {
    headers: {
      'Access-Control-Allow-Origin': true,
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    name: formValues.itemName,
    text: formValues.text,
    category: formValues.category,
    image: formValues.image,
    itemId: formValues.itemId,
    wishname: formValues.wishname,
    wishitem: formValues.wishitem,
    flagged: formValues.flagged,
    ibegone: formValues.ibegone,
    ibepets: formValues.ibepets,
    ibehandy: formValues.ibehandy,
    ibehealth: formValues.ibehealth,
    ibehouse: formValues.ibehouse,
    ibepaper: formValues.ibepaper,
    ibefinancial: formValues.ibefinancial,
    ibemoney: formValues.ibemoney,
    ibemember: formValues.ibemember,
    ibetechno: formValues.ibetechno,
    ibeevent: formValues.ibeevent,
    ibesocial: formValues.ibesocial,
    ibecontacts: formValues.ibecontacts,
    ibebusy: formValues.ibebusy,
    ibecareer: formValues.ibecareer,
    ibememoir: formValues.ibememoir,
    ibeframed: formValues.ibeframed,
    records: formValues.records,
    verifyDate: formValues.verifyDate,
    trackType: formValues.trackType,
    wishes: formValues.wishes,
    picklist: formValues.picklist,
    comments: formValues.comments,
  });


  try {

    // console.log(body)

    const responseItem = await ibthost.put(`/thingedit/${userId}`,
      body,
      config
    );


    await dispatch({ type: SWITCH_UPDATE, payload: responseItem.data });

    //console.log("-------- add item  SUCC" + responseItem.data.item[0]._id );

    if (callback) {
      callback(responseItem.data.item[0]._id)
    }


  } catch (err) {
    await dispatch({ type: USER_ERROR, payload: ' Item Edit Failed' });
    console.log('Add Item Error ' + err)
  }
};
